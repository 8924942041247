import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, NodeAPI } from 'constants/index';

export const fetchRepeatedTasksSectionsAction: any = createAction(
  'tasks/FETCH_REPEATED_TASKS_SECTIONS',
  async ({ skipFilters, skipPagination } = { skipFilters: false, skipPagination: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'repeatedTasksSectionsCount' | 'repeatedTasksSectionsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.REPEATED_TASKS_SECTIONS)(
        state
      );
      return NodeAPI.get('v1/secure/workSections', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipFilters ? null : query,
          lang: appLangSelector(state).toLowerCase(),
        },
      }).then(res => ({
        repeatedTasksSectionsCount: res.data.count,
        repeatedTasksSectionsHash: _keyBy(res.data.rows, (item: Tasks.Task) => `_${item.id}_`),
      }));
    }
);

export const createRepeatedTasksSectionAction: any = createAction(
  'tasks/CREATE_REPEATED_TASKS_SECTION',
  (data: Type.CreateRepeatedTasksSectionActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.post('v1/secure/workSections', data).then(async () => {
        await dispatch(fetchRepeatedTasksSectionsAction());
        dispatch(setSuccessToastAction('Repeated tasks section has been created'));
      });
  }
);

export const editRepeatedTasksSectionAction: any = createAction(
  'tasks/EDIT_REPEATED_TASKS_SECTION',
  (id: number, data: Type.CreateRepeatedTasksSectionActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.put(`v1/secure/workSections/${id}`, data).then(async () => {
        await dispatch(fetchRepeatedTasksSectionsAction());
        dispatch(setSuccessToastAction('Repeated tasks section has been saved'));
      });
  }
);

export const deleteRepeatedTasksSectionAction: any = createAction(
  'tasks/DELETE_REPEATED_TASKS_SECTION',
  async (id: number) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.delete(`v1/secure/workSections/${id}`).then(async () => {
        await dispatch(fetchRepeatedTasksSectionsAction());
        dispatch(setSuccessToastAction('Repeated tasks section has been deleted'));
      });
  }
);
