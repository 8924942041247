import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.manuals;

const manualsItemsHashSelector = createSelector(rootSelector, manuals => manuals.itemsHash);

export const manualsItemsSelector = createSelector(manualsItemsHashSelector, itemsHash => Object.values(itemsHash));

export const manualsItemByIdSelectorFactory = (id: number | null) =>
  createSelector(manualsItemsHashSelector, itemsHash => itemsHash[`_${id}_`] || null);

export const manualsCountSelector = createSelector(rootSelector, manuals => manuals.count);
