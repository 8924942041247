import styled from 'styled-components';
import React from 'react';
import { useLocale } from 'hooks';
import { AppErrorsTypes } from 'constants/index';

const PageAppExceptions: React.FC<{ appError?: App.AppErrorsTypes }> = ({
  appError = AppErrorsTypes.PAGE_NOT_FOUND,
}) => {
  const { getIntl } = useLocale();

  const getColor = () => {
    switch (appError) {
      case AppErrorsTypes.NO_SCENARIOS:
      case AppErrorsTypes.NO_SIMULATIONS:
      case AppErrorsTypes.NON_LOADFLOW_PORTFOLIO:
      case AppErrorsTypes.UPGRADE_YOUR_PLAN:
        return 'text-warning';
      default:
        return 'text-danger';
    }
  };

  const getStatus = () => {
    switch (appError) {
      case AppErrorsTypes.NO_SCENARIOS:
      case AppErrorsTypes.NO_SIMULATIONS:
      case AppErrorsTypes.NON_LOADFLOW_PORTFOLIO:
      case AppErrorsTypes.UPGRADE_YOUR_PLAN:
        return '';
      default:
        return 404;
    }
  };

  const getTitle = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'User not found';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'Tenant not found';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'No assigned tenant';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'No scenario found';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'No simulation found';
      case AppErrorsTypes.NON_LOADFLOW_PORTFOLIO:
        return "Portfolio doesn't support this view";
      case AppErrorsTypes.UPGRADE_YOUR_PLAN:
        return 'Please upgrade your account';
      default:
        return 'Page not found';
    }
  };

  const getMessage = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'We could not find the user you were looking for. Please contact your administrator';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'We could not find the tenant you were looking for. Meanwhile, you may use the user menu to select another tenant';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'We could not find any assigned tenant to your account. Please contact your administrator';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'We could not find a scenario. Meanwhile, you may use the menu to select another portfolio';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'We could not find a simulation. Meanwhile, you may use the menu to select another portfolio and/or scenario';
      case AppErrorsTypes.NON_LOADFLOW_PORTFOLIO:
        return 'We could not show view for non-load flow portfolio. Meanwhile, you may use the menu to select another portfolio';
      case AppErrorsTypes.UPGRADE_YOUR_PLAN:
        return 'You should upgrade your account to be able to work with this view. Please contact administrator';
      default:
        return 'We could not find the page you were looking for. Meanwhile, you may use the dashboard menu';
    }
  };

  const color = getColor();
  const status = getStatus();
  return (
    <StyledContainer className="error-page">
      {status && <h2 className={`headline ${color}`}>{status}</h2>}
      <div className="error-content">
        <h3>
          <i className={`fas fa-exclamation-triangle ${color}`} /> {getIntl(getTitle())}.
        </h3>
        <p>{getIntl(getMessage())}.</p>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: unset;
  max-width: 650px;
  margin: 4rem auto 0;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  > .error-content {
    margin-left: 0;
  }
`;

export default PageAppExceptions;
