import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectCustomersRightsMissingTypes } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Customers.SelectMissingTypes | null;
}

const SelectCustomerMissingType: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const { selectOptions, selectValue } = useMemo(
    () =>
      Object.values<Customers.SelectMissingTypes>(SelectCustomersRightsMissingTypes).reduce(
        (acc: any, type) => {
          const item = { value: type, label: getIntl(type) };
          if (type === value) acc.selectValue = item;
          acc.selectOptions.push(item);
          return acc;
        },
        { selectOptions: [], selectValue: null }
      ),
    [value, getIntl]
  );

  return <FormReactSelect value={selectValue} options={selectOptions} {...props} />;
};

export default SelectCustomerMissingType;
