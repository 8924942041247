import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewManageDERs, ViewGlobalDevelopment } from 'components/NewLoad';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageNewLoad: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isNewLoadEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <RequiredScenarioCheck>
        <Switch>
          {/* RequiredSimulationCheck - allowed to access this page without simulations */}
          <Route exact path={Routes.NewLoadGlobalDevelopment} component={ViewGlobalDevelopment} />
          <Route path={Routes.NewLoad} component={ViewManageDERs} />
        </Switch>
      </RequiredScenarioCheck>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageNewLoad;
