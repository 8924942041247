import React, { useMemo, useState, useEffect } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRoutesOptionsAction } from 'modules/options';
import { routesOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectRoute: React.FC<Props> = ({ value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const options = useSelector(routesOptionsSelector);

  useEffect(() => {
    if (options) return;
    setLoading(true);
    dispatch(fetchRoutesOptionsAction()).finally(() => setLoading(false));
  }, [options, dispatch]);

  const { selectValue, selectOptions } = useMemo(
    () =>
      (options || ([] as any)).reduce(
        (acc: { selectValue: Type.SelectOption; selectOptions: Type.SelectOption[] }, i: Type.SelectOption) => {
          const item = { value: i.value, label: getIntl(i.label) };
          if (value === item.value) acc.selectValue = item;
          acc.selectOptions.push(item);
          return acc;
        },
        { selectValue: null, selectOptions: [] }
      ),
    [getIntl, options, value]
  );

  return (
    <FormReactSelect
      isLoading={loading}
      isDisabled={!options?.length}
      {...props}
      mutedTextLabelKey="Route"
      value={selectValue}
      options={selectOptions}
    />
  );
};

export default SelectRoute;
