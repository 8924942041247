import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import { PageAppExceptions } from 'pages';
import {
  ViewTransformers,
  ViewCables,
  ViewCabinets,
  ViewFuses,
  ViewTotalLoading,
  ViewVoltageDrop,
} from 'components/NetworkLoading';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes, StorageKeys } from 'constants/index';

const PageNetworkLoading: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isNetworkLoadingEnabled}>
      <RequiredPortfolioLoadflowEnabledCheck>
        <RequiredScenarioCheck>
          <Switch>
            <Route
              exact
              path={Routes.NetworkLoading}
              render={() => (
                <RequiredSimulationCheck>
                  <ViewTransformers />
                </RequiredSimulationCheck>
              )}
            />
            <Route
              exact
              path={Routes.NetworkLoadingCables}
              render={() => (
                <RequiredSimulationCheck>
                  <ViewCables />
                </RequiredSimulationCheck>
              )}
            />
            <Route
              exact
              path={Routes.NetworkLoadingCabinets}
              render={() => (
                <RequiredSimulationCheck>
                  <ViewCabinets />
                </RequiredSimulationCheck>
              )}
            />
            {appCurrentUserPermissions.isNetworkLoadingFusesEnabled && (
              <Route
                exact
                path={Routes.NetworkLoadingFuses}
                render={() => (
                  <RequiredSimulationCheck>
                    <ViewFuses />
                  </RequiredSimulationCheck>
                )}
              />
            )}

            <Route exact path={Routes.NetworkLoadingTotalLoading} component={ViewTotalLoading} />

            {featureToggles[StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP] && (
              <Route
                exact
                path={Routes.NetworkLoadingViewVoltageDrop}
                render={() => (
                  <RequiredSimulationCheck>
                    <ViewVoltageDrop />
                  </RequiredSimulationCheck>
                )}
              />
            )}

            <Route path="*" component={PageAppExceptions} />
          </Switch>
        </RequiredScenarioCheck>
      </RequiredPortfolioLoadflowEnabledCheck>
    </PagePermissionsProvider>
  );
};

export default PageNetworkLoading;
