import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { simulationOptionsHashSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { IDsState } from './ViewAnalysisTool';

interface Props extends Omit<FormReactSelectProps, 'value'> {
  id: string;
  value: number | null;
  setIds: React.Dispatch<React.SetStateAction<IDsState[]>>;
}

const SelectAnalysisToolScenario: React.FC<Props> = ({ value, options, id, setIds, ...props }) => {
  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);
  const simulationOptionsHash = useSelector(simulationOptionsHashSelector);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption<number>): void => {
      setIds(prev =>
        prev.map(item => {
          const simulation = simulationOptionsHash[`${item.portfolioId}_${option.value}`]?.[0];
          const simulationId = simulation?.value || null;
          const versionId = simulation?.versionId || null;
          return item.id === id ? { ...item, scenarioId: option.value, simulationId, versionId } : item;
        })
      );
    },
    [id, setIds, simulationOptionsHash]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      value={selectValue}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      mutedTextLabelKey="Scenario"
      {...props}
    />
  );
};

export default SelectAnalysisToolScenario;
