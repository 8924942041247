import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { ignoreMessagesSelector } from 'modules/networkLoading/selectors';
import { fetchAssetCommentsAction } from 'modules/networkLoading';
import { Alert, Spinner } from 'components/_common';
import Card from './Card';
interface Props {
  uuid: string;
}

const TabComments: React.FC<Props> = ({ uuid }) => {
  const [loading, setLoading] = useState(true);
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const items = useSelector(ignoreMessagesSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId) return;
    dispatch(fetchAssetCommentsAction({ uuid, portfolioId, scenarioId })).finally(() => setLoading(false));
  }, [dispatch, uuid, portfolioId, scenarioId]);

  if (loading) return <Spinner isInFullHeightContainer />;
  if (!items.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <>
      {items.map((item: NetworkLoading.IgnoreMessages, index: number) => (
        <Card key={item.id} marginBottom={items.length - 1 !== index}>
          <Row className="mb-1">
            <Col>
              <label>{getIntl('Time')}:</label>
              <span>{dateFormat(item.time)}</span>
            </Col>
            <Col>
              <label>{getIntl('Name')}:</label>
              <span>{item.username}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{getIntl('Explanation')}:</label>
              <span className={classNames({ muted: !item.explanation })}>
                {item.explanation || getIntl('No explanation was provided')}
              </span>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};

export default TabComments;
