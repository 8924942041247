import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { extRepeatedTaskSelectorFactory } from 'modules/tasks/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { NodeAPI } from 'constants/index';

export const repeatedTasksLoadingAction = createAction('tasks/REPEATED_TASKS_LOADING');

export const fetchRepeatedTasksAction = createAction(
  'tasks/FETCH_REPEATED_TASKS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'repeatedTasksHash' | 'repeatedTasksLoading' | 'repeatedTasksFetched'>> => {
      dispatch(repeatedTasksLoadingAction());
      return NodeAPI.get('v1/secure/workTasks').then(res => ({
        repeatedTasksFetched: true,
        repeatedTasksLoading: false,
        repeatedTasksHash: _keyBy(res.data.rows, (item: Tasks.RepeatedTask) => `_${item.id}_`),
      }));
    }
);

export const createRepeatedTaskAction: any = createAction(
  'tasks/CREATE_REPEATED_TASK',
  (data: Type.CreateRepeatedTaskActionProps) => {
    return (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'repeatedTasksHash'>> => {
      const state = getState();
      const extWorkTask = extRepeatedTaskSelectorFactory(data.departmentNumber, data.taskNumber)(state);
      return NodeAPI.post('v1/secure/workTasks', {
        departmentNumber: data.departmentNumber,
        taskNumber: String(data.taskNumber),
        workSectionId: data.workSectionId,
        actionInApp: data.actionInApp,
        // auto filled in the action
        projectNumber: extWorkTask?.projectNumber,
        description: extWorkTask?.description,
        taskStatus: extWorkTask?.taskStatus,
        mainActivity: extWorkTask?.mainActivity,
      }).then(res => {
        dispatch(setSuccessToastAction('Task has been created'));
        return {
          repeatedTasksHash: _keyBy(res.data.rows, (item: Tasks.RepeatedTask) => `_${item.id}_`),
        };
      });
    };
  }
);

export const editRepeatedTaskAction: any = createAction(
  'tasks/EDIT_REPEATED_TASK',
  (id: number, data: Type.CreateRepeatedTaskActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<Pick<Tasks.Root, 'repeatedTasksHash'>> =>
      NodeAPI.put(`v1/secure/workTasks/${id}`, data).then(res => {
        dispatch(setSuccessToastAction('Task has been saved'));
        return {
          repeatedTasksHash: _keyBy(res.data.rows, (item: Tasks.RepeatedTask) => `_${item.id}_`),
        };
      });
  }
);

export const deleteRepeatedTaskAction: any = createAction('tasks/DELETE_REPEATED_TASK', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<Pick<Tasks.Root, 'repeatedTasksHash'>> =>
    NodeAPI.delete(`v1/secure/workTasks/${id}`).then(res => {
      dispatch(setSuccessToastAction('Task has been deleted'));
      return {
        repeatedTasksHash: _keyBy(res.data.rows, (item: Tasks.RepeatedTask) => `_${item.id}_`),
      };
    });
});
