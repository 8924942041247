import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, matchPath, generatePath } from 'react-router-dom';
import { FormReactSelect } from 'components/_common';
import { getStorageItem, setStorageItem, substringTenantName } from 'utils';
import { StorageKeys, Routes, DefaultRoute } from 'constants/index';

const SelectTenants: React.FC = () => {
  const { pathname } = useLocation();

  const lastRoute = useMemo(() => {
    // navigate away from form builders
    if ([Routes.FormBuilder, Routes.InspectionBuilder, Routes.InstructionBuilder].includes(pathname)) {
      return pathname.replace('/builder', '');
    }
    // remove asset code from url on tenant change action
    if (Boolean(matchPath(pathname, { path: Routes.Map, exact: true }))) {
      return generatePath(Routes.Map);
    }
    if (pathname === Routes.Root) {
      return getStorageItem(StorageKeys.LAST_ROUTE) || DefaultRoute;
    }

    return pathname;
  }, [pathname]);

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      setStorageItem({
        [StorageKeys.SELECTED_TENANT]: option.value,
        [StorageKeys.LAST_ROUTE]: lastRoute,
        [StorageKeys.PAGINATION]: null,
        [StorageKeys.PORTFOLIO_ID]: null,
        [StorageKeys.SCENARIO_ID_HASH]: null,
        [StorageKeys.INVESTMENT_SCENARIO_ID]: null,
      });
      window.location.replace(window.location.origin);
    },
    [lastRoute]
  );

  const { value, options } = useMemo(() => {
    const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
    if (!claims?.tenants?.length) return [];
    const selectedTenant = getStorageItem(StorageKeys.SELECTED_TENANT);

    return claims.tenants.sort().reduce(
      (acc: any, tenant: string) => {
        const label = substringTenantName(tenant);
        const option = { value: tenant, label };
        if (tenant === selectedTenant) acc.value = option;
        acc.options.push(option);
        return acc;
      },
      { value: null, options: [] }
    );
  }, []);

  if (!options?.length) return null;
  return (
    <Form.Group data-marker="select_tenant">
      <FormReactSelect
        labelKey="Select tenant"
        value={value}
        options={options}
        onChange={handleSelectChange}
        isDisabled={options?.length === 1}
        isSearchable={false}
      />
    </Form.Group>
  );
};

export default SelectTenants;
