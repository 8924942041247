import React from 'react';
import { DataTableTemplate } from 'components/_common';
import ModalPlan from './ModalPlan';
import TablePlans from './TablePlans';

const ViewCreatePlans: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <ModalPlan />
    <TablePlans />
  </DataTableTemplate>
);

export default ViewCreatePlans;
