import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { ignoreMessagesSelector, ignoreMessagesCountSelector } from 'modules/networkLoading/selectors';
import { fetchAssetCommentsAction } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
const TableIgnoreMessages: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(ignoreMessagesSelector);
  const count = useSelector(ignoreMessagesCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(() => {
    if (!portfolioId || !scenarioId) return Promise.resolve();
    return dispatch(fetchAssetCommentsAction({ uuid, portfolioId, scenarioId }));
  }, [dispatch, uuid, portfolioId, scenarioId]);

  return (
    <DataTable
      paginationType={PaginationType.TRANSFORMERS_IGNORE_MESSAGES}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName="p-0 border-top-0 bg-transparent"
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
    >
      {items?.map((item: NetworkLoading.IgnoreMessages) => (
        <tr key={item.id}>
          <td>{dateFormat(item.time)}</td>
          <td>{item.username}</td>
          <td>{item.explanation}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableIgnoreMessages;
