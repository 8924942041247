import { createAction } from 'redux-actions';
import mixpanel from 'mixpanel-browser';
import { mixpanelPageViewEventPayloadSelector } from '../composite.selectors';
import { NodeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const setLangAction = createAction('app/SET_LANG');

export const setTimezoneAction = createAction('app/SET_TIMEZONE');

export const fetchCurrentUserInfoAction: any = createAction(
  'app/FETCH_CURRENT_USER',
  async (): Promise<Omit<App.Root, 'lang' | 'appCurrentUserFetched'>> => {
    return NodeAPI.get('v1/secure/permissions/users/current').then(res => res?.data);
  }
);

export const setAppErrorAction = createAction('app/SET_APP_ERROR');

export const trackPageViewAction = createAction(
  'app/TRACK_MAP_LAYERS',
  async () => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    const mixpanelPageViewEventPayload = mixpanelPageViewEventPayloadSelector(getState());
    mixpanel.track('Dashboard page view event', mixpanelPageViewEventPayload!);
  }
);

export const trackMapLayersAction = createAction(
  'app/TRACK_MAP_LAYERS',
  async (payload: any) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    const mixpanelPageViewEventPayload = mixpanelPageViewEventPayloadSelector(getState());
    mixpanel.track('Dashboard map layer event', { ...mixpanelPageViewEventPayload, ...payload });
  }
);
