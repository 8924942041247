import { createSelector } from 'reselect';
import {
  isPageTasksMobileSubmittedTabActiveSelector,
  isPageTasksUpcomingTabActiveSelector,
  isPageTasksTabActiveSelector,
  isPageTasksFinishedTabActiveSelector,
  isPageTaskDetailsActiveSelectorFactory,
} from 'modules/router/selectors';
import { employeesGanttChartTasksHashSelector, tasksGanttChartHashSelector } from 'modules/employees/selectors';
import {
  isPageTasksEmployeesGanttChartTabActiveSelector,
  isPageTasksTasksGanttChartTabActiveSelector,
} from 'modules/router/selectors';
import { PaginationType } from 'constants/index';

const rootSelector = (state: State.Root) => state.tasks;

// ------------------------------------
// NEW TASKS Selectors
// ------------------------------------
export const newTasksCountSelector = createSelector(rootSelector, tasks => tasks.newTasksCount);

const newTasksHashSelector = createSelector(rootSelector, tasks => tasks.newTasksHash);

export const newTasksSelector = createSelector(newTasksHashSelector, newTasksHash => Object.values(newTasksHash));

// ------------------------------------
// TASKS COMPLAINTS Selectors
// ------------------------------------
export const tasksComplaintsCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.tasksComplaintsCount
);

const tasksComplaintsHashSelector = createSelector(rootSelector, tasks => tasks.tasksComplaintsHash);

export const tasksComplaintsSelector = createSelector(tasksComplaintsHashSelector, tasksComplaintsHash =>
  Object.values(tasksComplaintsHash)
);

// ------------------------------------
// MOBILE SUBMITTED TASKS Selectors
// ------------------------------------
export const mobileSubmittedTasksCountSelector = createSelector(rootSelector, tasks => tasks.mobileSubmittedTasksCount);

const mobileSubmittedTasksHashSelector = createSelector(rootSelector, tasks => tasks.mobileSubmittedTasksHash);

export const mobileSubmittedTasksSelector = createSelector(mobileSubmittedTasksHashSelector, mobileSubmittedTasksHash =>
  Object.values(mobileSubmittedTasksHash)
);

// ------------------------------------
// UPCOMING TASKS Selectors
// ------------------------------------
export const upcomingTasksCountSelector = createSelector(rootSelector, tasks => tasks.upcomingTasksCount);

const upcomingTasksHashSelector = createSelector(rootSelector, tasks => tasks.upcomingTasksHash);

export const upcomingTasksSelector = createSelector(upcomingTasksHashSelector, upcomingTasksHash =>
  Object.values(upcomingTasksHash)
);

// ------------------------------------
// TASKS Selectors
// ------------------------------------
export const tasksCountSelector = createSelector(rootSelector, tasks => tasks.tasksCount);

const tasksHashSelector = createSelector(rootSelector, tasks => tasks.tasksHash);

export const tasksSelector = createSelector(tasksHashSelector, tasksHash => Object.values(tasksHash));

export const tasksByUUIDCodeSelector = createSelector(rootSelector, tasks => tasks.tasksByUUIDCode);

// ------------------------------------
// FINISHED TASKS Selectors
// ------------------------------------
export const finishedTasksCountSelector = createSelector(rootSelector, tasks => tasks.finishedTasksCount);

const finishedTasksHashSelector = createSelector(rootSelector, tasks => tasks.finishedTasksHash);

export const finishedTasksSelector = createSelector(finishedTasksHashSelector, finishedTasksHash =>
  Object.values(finishedTasksHash)
);

// Extra selectors
export const tasksActiveTypeSelector = createSelector(
  [
    isPageTasksMobileSubmittedTabActiveSelector,
    isPageTasksUpcomingTabActiveSelector,
    isPageTasksTabActiveSelector,
    isPageTasksFinishedTabActiveSelector,
  ],
  (
    isPageTasksMobileSubmittedTabActive: boolean,
    isPageTasksUpcomingTabActive: boolean,
    isPageTasksTabActive: boolean,
    isPageTasksFinishedTabActive: boolean
  ): Type.PaginationType | null => {
    switch (true) {
      case isPageTasksMobileSubmittedTabActive:
        return PaginationType.TASKS_MOBILE_SUBMITTED;
      case isPageTasksUpcomingTabActive:
        return PaginationType.UPCOMING_TASKS;
      case isPageTasksTabActive:
        return PaginationType.TASKS;
      case isPageTasksFinishedTabActive:
        return PaginationType.TASKS_FINISHED;
      default:
        return null;
    }
  }
);

export const taskSelectorFactory = (id: number | null) =>
  createSelector(
    [
      mobileSubmittedTasksHashSelector,
      upcomingTasksHashSelector,
      tasksHashSelector,
      finishedTasksHashSelector,
      employeesGanttChartTasksHashSelector,
      tasksGanttChartHashSelector,
      taskDetailsSelector,
      isPageTasksMobileSubmittedTabActiveSelector,
      isPageTasksUpcomingTabActiveSelector,
      isPageTasksTabActiveSelector,
      isPageTasksFinishedTabActiveSelector,
      isPageTasksEmployeesGanttChartTabActiveSelector,
      isPageTasksTasksGanttChartTabActiveSelector,
      isPageTaskDetailsActiveSelectorFactory(id),
    ],
    (
      mobileSubmittedTasksHash,
      upcomingTasksHash,
      tasksHash,
      finishedTasksHash,
      employeesGanttChartTasksHash,
      tasksGanttChartHash,
      taskDetails,
      //
      isPageTasksMobileSubmittedTabActive,
      isPageTasksUpcomingTabActive,
      isPageTasksTabActive,
      isPageTasksFinishedTabActive,
      isPageTasksEmployeesGanttChartTabActive,
      isPageTasksTasksGanttChartTabActive,
      isPageTaskDetailsActive
    ) => {
      if (!id) return;
      switch (true) {
        case isPageTasksMobileSubmittedTabActive:
          return mobileSubmittedTasksHash[`_${id}_`];
        case isPageTasksUpcomingTabActive:
          return upcomingTasksHash[`_${id}_`];
        case isPageTasksTabActive:
          return tasksHash[`_${id}_`];
        case isPageTasksFinishedTabActive:
          return finishedTasksHash[`_${id}_`];
        case isPageTasksEmployeesGanttChartTabActive:
          return employeesGanttChartTasksHash[`_${id}_`];
        case isPageTasksTasksGanttChartTabActive:
          return tasksGanttChartHash[`_${id}_`];
        case isPageTaskDetailsActive:
          return taskDetails || undefined;
        default:
          return;
      }
    }
  );

// ------------------------------------
// REPEATED TASKS SECTIONS Selectors
// ------------------------------------
export const repeatedTasksSectionsCountSelector = createSelector(
  rootSelector,
  tasks => tasks.repeatedTasksSectionsCount
);

const repeatedTasksSectionsHashSelector = createSelector(rootSelector, tasks => tasks.repeatedTasksSectionsHash);

export const repeatedTasksSectionsSelector = createSelector(
  repeatedTasksSectionsHashSelector,
  repeatedTasksSectionsHash => Object.values(repeatedTasksSectionsHash)
);

export const repeatedTasksSectionsByDepartmentNumberSelectorFactory = (departmentNumber: number | null) =>
  createSelector(repeatedTasksSectionsSelector, repeatedTasksSections => {
    return repeatedTasksSections.filter(section => section.departmentNumber === departmentNumber);
  });

export const repeatedTasksSectionSelectorFactory = (id: number | null) =>
  createSelector(repeatedTasksSectionsHashSelector, repeatedTasksSectionsHash => repeatedTasksSectionsHash[`_${id}_`]);

// ------------------------------------
// EXT REPEATED TASKS Selectors
// ------------------------------------
const extRepeatedTasksHashSelectorFactory = (departmentNumber: number | null) =>
  createSelector(rootSelector, tasks => {
    return tasks.tasksDepartmentsHash[departmentNumber!]?.externalTasksHash || {};
  });

const extRepeatedTasksIdsSelector = createSelector(rootSelector, (tasks: Tasks.Root): string[] => {
  if (!Object.keys(tasks.tasksDepartmentsHash).length) return [];
  return Object.values(tasks.tasksDepartmentsHash).reduce((acc: string[], department: Tasks.Department): string[] => {
    const taskNumbers = Object.values(department.externalTasksHash || {}).map(i => i.taskNumber);
    return [...acc, ...taskNumbers];
  }, []);
});

export const extRepeatedTasksSelectorFactory = (departmentNumber: number | null) =>
  createSelector(extRepeatedTasksHashSelectorFactory(departmentNumber), extRepeatedTasksHash => {
    return Object.values(extRepeatedTasksHash);
  });

export const extRepeatedTaskSelectorFactory = (departmentNumber: number | null, taskNumber: string | null) =>
  createSelector(extRepeatedTasksHashSelectorFactory(departmentNumber), extRepeatedTasksHash => {
    return extRepeatedTasksHash?.[`_${taskNumber}_`] || null;
  });

// ------------------------------------
// REPEATED TASKS Selectors
// ------------------------------------
export const repeatedTasksFetchedSelector = createSelector(rootSelector, tasks => tasks.repeatedTasksFetched);

export const repeatedTasksLoadingSelector = createSelector(rootSelector, tasks => tasks.repeatedTasksLoading);

const repeatedTasksHashSelector = createSelector(rootSelector, tasks => tasks.repeatedTasksHash);

const repeatedTasksSelector = createSelector(repeatedTasksHashSelector, repeatedTasksHash =>
  Object.values(repeatedTasksHash)
);

export const expandedRepeatedTasksSelectorFactory = (departmentNumber: number | null) =>
  createSelector([repeatedTasksSelector, extRepeatedTasksIdsSelector], (repeatedTasks, extRepeatedTasksIds) => {
    return repeatedTasks.reduce((acc: Tasks.RepeatedTask[], task: Tasks.RepeatedTask) => {
      if (task.departmentNumber !== departmentNumber) return acc;
      const deleted = Boolean(extRepeatedTasksIds.length) && !extRepeatedTasksIds.includes(task.taskNumber);
      const expandedTask = { ...task, deleted };
      acc.push(expandedTask);
      return acc;
    }, []);
  });

export const repeatedTasksSplitBySectionHashSelectorFactory = (departmentNumber: number | null) =>
  createSelector(expandedRepeatedTasksSelectorFactory(departmentNumber), expandedRepeatedTasks => {
    return expandedRepeatedTasks.reduce((acc: { [key: string]: Tasks.RepeatedTask[] }, task: Tasks.RepeatedTask) => {
      if (acc[task.workSectionName]) {
        acc[task.workSectionName].push(task);
      } else {
        acc[task.workSectionName] = [task];
      }
      return acc;
    }, {});
  });

export const repeatedTaskSelectorFactory = (id: number | null) =>
  createSelector(repeatedTasksHashSelector, repeatedTasksHash => repeatedTasksHash[`_${id}_`]);

// ------------------------------------
// TASKS_CHECKLISTS Selectors
// ------------------------------------
export const checklistsCountSelector = createSelector(rootSelector, tasks => tasks.checklistsCount);

const checklistsHashSelector = createSelector(rootSelector, tasks => tasks.checklistsHash);

export const checklistsSelector = createSelector(checklistsHashSelector, checklistsHash =>
  Object.values(checklistsHash)
);

export const checklistSelectorFactory = (id: number | null) =>
  createSelector(checklistsHashSelector, checklistsHash => checklistsHash[`_${id}_`]);

export const materialsCountSelector = createSelector(rootSelector, tasks => tasks.materialsCount);

export const materialsHashSelector = createSelector(rootSelector, tasks => tasks.materialsHash);

export const materialsSelector = createSelector(materialsHashSelector, hash => Object.values(hash));

export const materialSelectorFactory = (id: number | null) =>
  createSelector(materialsHashSelector, hash => hash[`_${id}_`]);

export const totalHoursSelector = createSelector(rootSelector, tasks => tasks.totalHours);

export const totalHoursAmountSelector = createSelector(rootSelector, tasks => tasks.totalHoursAmount);

export const employeeHoursAvailableSelector = createSelector(rootSelector, tasks => tasks.employeeHoursAvailable);

// ------------------------------------
// TASK HARDWARE Selectors
// ------------------------------------
export const taskMaterialsCountSelector = createSelector(rootSelector, tasks => tasks.taskMaterialsCount);

export const taskMaterialsSelector = createSelector(rootSelector, tasks => tasks.taskMaterials);

// ------------------------------------
// TASK REGISTERED HOURS Selectors
// ------------------------------------

export const usersRegisteredHoursCountSelector = createSelector(rootSelector, tasks => tasks.usersRegisteredHoursCount);

const usersRegisteredHoursHashSelector = createSelector(rootSelector, tasks => tasks.usersRegisteredHoursHash);

export const usersRegisteredHoursSelector = createSelector(usersRegisteredHoursHashSelector, usersRegisteredHoursHash =>
  Object.values(usersRegisteredHoursHash)
);

export const tasksRegisteredHoursCountSelector = createSelector(rootSelector, tasks => tasks.tasksRegisteredHoursCount);

const tasksRegisteredHoursHashSelector = createSelector(rootSelector, tasks => tasks.tasksRegisteredHoursHash);

export const tasksRegisteredHoursSelector = createSelector(tasksRegisteredHoursHashSelector, tasksRegisteredHoursHash =>
  Object.values(tasksRegisteredHoursHash)
);

// ------------------------------------
// OTHER Selectors
// ------------------------------------
export const tasksDepartmentsFetchedSelector = createSelector(rootSelector, tasks => tasks.tasksDepartmentsFetched);

const tasksDepartmentsHashSelector = createSelector(rootSelector, tasks => tasks.tasksDepartmentsHash);

export const tasksDepartmentsSelector = createSelector(tasksDepartmentsHashSelector, tasksDepartmentsHash =>
  Object.values(tasksDepartmentsHash)
);

export const taskDetailsSelector = createSelector(rootSelector, tasks => tasks.taskDetails);
