import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { createManufacturerAction } from 'modules/manufacturers';
import { ManufacturersTable } from 'components/_tables';
import { DataTableTemplate, Button, FormInput } from 'components/_common';

const ViewManufacturers: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  return (
    <DataTableTemplate paddingBottom>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().strict(false).trim().required("Field can't be empty!"),
        })}
        onSubmit={(values, actions) =>
          dispatch(createManufacturerAction(values.name.trim())).then(() => actions.resetForm())
        }
      >
        {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={3}>
                <FormInput
                  name="name"
                  type="text"
                  placeholderKey="Manufacturer"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
                />
              </Col>
              <Col sm={3}>
                <Button type="submit" labelKey="Add" loading={isSubmitting} />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <ManufacturersTable />
    </DataTableTemplate>
  );
};

export default ViewManufacturers;
