import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, NodeAPI } from 'constants/index';

export const fetchNewTasksAction: any = createAction(
  'tasks/FETCH_NEW_TASKS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'newTasksCount' | 'newTasksHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.NEW_TASKS)(state);
      return NodeAPI.get('v2/secure/tasks/external/unassigned', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          lang: appLangSelector(state).toLowerCase(),
        },
      }).then(res => ({
        newTasksCount: res.data.count,
        newTasksHash: _keyBy(res.data.rows, (item: Tasks.NewTask) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const deleteNewTaskAction: any = createAction(
  'tasks/DELETE_NEW_TASK',
  (taskNumber: string) => (dispatch: Shared.CustomDispatch) => {
    return NodeAPI.delete(`v2/secure/tasks/external/unassigned/${taskNumber}`).then(async () => {
      await dispatch(fetchNewTasksAction());
      dispatch(setSuccessToastAction('New task has been deleted'));
    });
  }
);
