import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { reloadNodeConfigurationAction } from 'modules/permissions';
import { Tabs, Tab, DataTableTemplate, Button } from 'components/_common';
import TableTenants from './TableTenants';
import ModalTenants from './ModalTenants';
import TableFeatures from './TableFeatures';
import ModalFeatures from './ModalFeatures';
import { Routes } from 'constants/index';
import { IconRecalculate } from '@utiligize/shared/resources';

const ViewSupervisor: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleReloadButtonClick = useCallback(() => {
    setLoading(true);
    dispatch(reloadNodeConfigurationAction()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <DataTableTemplate>
      <Button
        icon={<IconRecalculate />}
        labelKey="Reload Node configuration"
        onClick={handleReloadButtonClick}
        variant="primary"
        loading={loading}
      />
      <Tabs mountOnEnter unmountOnExit>
        <Tab eventKey={Routes.AdminSupervisor} title={getIntl('Tenants')}>
          <TableTenants />
          <ModalTenants />
        </Tab>
        <Tab eventKey={`${Routes.AdminSupervisor}${Routes.HASH.Features}`} title={getIntl('Features')}>
          <TableFeatures />
          <ModalFeatures />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewSupervisor;
