import { combineActions, handleActions } from 'redux-actions';
import {
  fetchPermissionsGroupsAction,
  createPermissionsGroupAction,
  editPermissionsGroupAction,
  deletePermissionsGroupAction,
  fetchUsersAction,
  createUserAction,
  editUserAction,
  deleteUserAction,
  fetchPermissionsTypesAction,
  fetchAccessLogsAction,
  fetchPermissionsTenantsAction,
  fetchPermissionsFeaturesAction,
  editPermissionsTenantAction,
  editPermissionsFeatureAction,
  patchPermissionsGroupAction,
  reloadNodeConfigurationAction,
} from './actions';

const initialState: Permissions.Root = {
  groupsCount: 0,
  groupsHash: {} as Permissions.Root['groupsHash'],
  usersCount: 0,
  usersHash: {} as Permissions.Root['usersHash'],
  permissionsTypes: null,
  accessLogs: [],
  accessLogsCount: 0,
  tenantsHash: {} as Permissions.Root['tenantsHash'],
  tenantsCount: 0,
  featuresHash: {} as Permissions.Root['featuresHash'],
  featuresCount: 0,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchPermissionsGroupsAction,
    fetchUsersAction,
    fetchPermissionsTypesAction,
    fetchAccessLogsAction,
    fetchPermissionsTenantsAction,
    fetchPermissionsFeaturesAction
  ).toString()]: {
    next: (
      state: Permissions.Root,
      action: Shared.ReduxAction<Partial<Permissions.Root> & { skipStoreUpdate: true | undefined }>
    ): Permissions.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchPermissionsGroupsAction,
  createPermissionsGroupAction,
  editPermissionsGroupAction,
  deletePermissionsGroupAction,
  fetchUsersAction,
  createUserAction,
  editUserAction,
  deleteUserAction,
  fetchPermissionsTypesAction,
  fetchAccessLogsAction,
  fetchPermissionsTenantsAction,
  fetchPermissionsFeaturesAction,
  editPermissionsTenantAction,
  editPermissionsFeatureAction,
  patchPermissionsGroupAction,
  reloadNodeConfigurationAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
