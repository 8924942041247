import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale, usePaginationHandlers } from 'hooks';
import {
  fusesSelector,
  fusesCountSelector,
  tableTransformersCablesCabinetsFusesWaitForDependenciesSelector,
} from 'modules/networkLoading/selectors';
import { fetchFusesAction } from 'modules/networkLoading';
import { setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button, SolvedOption, AssetCode } from 'components/_common';
import { PaginationType, ProfileModalTypes, TableHeadersKeys } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableFuses: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.NETWORK_LOADING_FUSES;
  const { columns, filters } = usePaginationHandlers({ type });
  const Keys = TableHeadersKeys[type]!;
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  const items = useSelector(fusesSelector);
  const count = useSelector(fusesCountSelector);
  const waitForDependencies = useSelector(tableTransformersCablesCabinetsFusesWaitForDependenciesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchFusesAction(params)).then((action: Shared.ReduxAction<Pick<NetworkLoading.Root, 'fusesHash'>>) =>
        Object.values(action.payload?.fusesHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(
        setLayoutAction({
          profileModalMeta: { id: assetName, type: ProfileModalTypes.Fuse },
          ...(filters?.year ? { selectedChartYear: filters?.year } : {}),
        })
      );
    },
    [dispatch, filters?.year]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={waitForDependencies}
      triggerTableUpdateDeps={[portfolioId, scenarioId, simulationId]}
      maxHeight="calc(100vh - 323px)"
      isSettingsButtonEnabled
    >
      {items?.map((item: NetworkLoading.Fuse) => (
        <tr key={item.id}>
          {columns?.includes(Keys.customer_asset_name) && (
            <td>
              <AssetCode uuid={item.id} code={item.customer_asset_name} />
            </td>
          )}
          {columns?.includes(Keys.date_commissioned) && (
            <td>{item.date_commissioned ? dateFormat(item.date_commissioned) : '-'}</td>
          )}
          {columns?.includes(Keys.amp_rating) && <td>{numberFormat(item.amp_rating)}</td>}
          {columns?.includes(Keys.ignore) && (
            <td className="text-center">
              <SolvedOption uuid={item.id} checked={item.ignore} paginationType={type} />
            </td>
          )}
          {columns?.includes(Keys.explanation) && <td>{item.explanation || '-'}</td>}
          {columns?.includes(Keys.max_amps) && <td>{numberFormat(item.max_amps)}</td>}
          {columns?.includes(Keys.max_loading_time) && <td>{dateFormat(item.max_loading_time, 'L LT')}</td>}
          {columns?.includes(Keys.utilization) && <td>{numberFormat(item.utilization)}</td>}
          <td className="text-right">
            <Button
              dataMarker="actions_profile"
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableFuses;
