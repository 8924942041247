import React, { useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectCustomerPercentile: React.FC<Props> = ({ value = null, ...props }) => {
  const { selectOptions, selectValue } = useMemo(
    () =>
      [99, 99.5, 99.9, 100].reduce(
        (acc: any, percentile: number) => {
          const item = { value: percentile, label: percentile };
          if (percentile === value) acc.selectValue = item;
          acc.selectOptions.push(item);
          return acc;
        },
        { selectOptions: [], selectValue: null }
      ),
    [value]
  );

  return <FormReactSelect value={selectValue} options={selectOptions} {...props} />;
};

export default SelectCustomerPercentile;
