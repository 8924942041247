import { MapParams, VoltageColorsMap } from 'constants/index';
import { getTileTimeStamp } from 'utils/map';

export const needColumnRegex = /^(ml__|ec__|yl__|n_1_ml__)/;
export const hasAdditionalRegex = /^(ts__|dq__|n_1__|yr__)/;
export const hasTextRegex = /(transformers|cabinets)/;
export const columnZoomThreshold = 10;
export const additionalLayersZoom = 14;
export const zoomInterpolateRatio = 1.5;
export const additionalAssetOpacity = 0.5;
export const assetOpacity = 0.8;
export const highlightActiveOpacity = 0.8;
export const highlightInactiveOpacity = 0.6;
export const columnFarOpacity = 1.0;
export const columnNearOpacity = 0.8;
export const textMinZoom = 12;

export const voltageColors = [
  ['==', ['get', 'voltage_level_id'], 4],
  VoltageColorsMap[4],
  ['==', ['get', 'voltage_level_id'], 3],
  VoltageColorsMap[3],
  ['==', ['get', 'voltage_level_id'], 2],
  VoltageColorsMap[2],
  ['==', ['get', 'voltage_level_id'], 1],
  VoltageColorsMap[1],
  '#918f86',
];

export const getVoltageIcons = (name: string) => [
  ['==', ['get', 'voltage_level_id'], 4],
  `icon-${name}-purple`,
  ['==', ['get', 'voltage_level_id'], 3],
  `icon-${name}-purple`,
  ['==', ['get', 'voltage_level_id'], 2],
  `icon-${name}-red`,
  ['==', ['get', 'voltage_level_id'], 1],
  `icon-${name}-blue`,
  `icon-${name}-gray`,
];

export const getVoltageTextZoom = (voltageId?: number): number => {
  switch (voltageId) {
    case 4:
    case 3:
      return 10;
    case 2:
      return 14;
    case 1:
      return 17;
    default:
      return textMinZoom;
  }
};

export const voltageRatio = ['number', ['get', 'voltage_level_id'], 1];

const getMaxLoadIcons = (legendItem: Map.LegendItem, priority = 0, isLine?: boolean): Map.StyleSubLayer[] => [
  {
    id: `${legendItem.name}_investment`,
    filter: false,
    type: 'symbol',
    minzoom: legendItem.minz,
    source: legendItem.name,
    'source-layer': legendItem.table,
    order: 0,
    layout: {
      'icon-image': 'icon-investment-3d',
      'icon-size': 0.4,
      'icon-anchor': 'left',
      'icon-offset': [5, 0],
      'icon-rotation-alignment': 'viewport',
      'symbol-placement': isLine ? 'line-center' : 'point',
      'symbol-sort-key': priority + 0,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  },
  {
    id: `${legendItem.name}_flexibility`,
    filter: false,
    type: 'symbol',
    minzoom: legendItem.minz,
    source: legendItem.name,
    'source-layer': legendItem.table,
    order: 0,
    layout: {
      'icon-image': 'icon-flexibility-3d',
      'icon-size': 0.4,
      'icon-anchor': 'right',
      'icon-offset': [-5, 0],
      'icon-rotation-alignment': 'viewport',
      'symbol-placement': isLine ? 'line-center' : 'point',
      'symbol-sort-key': priority + 2,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  },
];

export const getPointStyle = ({
  legendItem,
  settings,
  iconClass,
  icon,
  iconAdditional,
  iconSize,
  iconAlignment,
  color,
  circleSize,
  minZoom,
  maxZoom,
  hint,
  hasAdditional,
}: Map.PointStyleParams) => {
  const assetFilter = Object.entries(settings?.layerFilters ?? {}).find(([k]) => legendItem.name.endsWith(k))?.[1];
  return ['additional', 'general']
    .map(type => {
      if (type === 'additional' && !hasAdditional) return null;
      const style: Map.Style = {
        source: {
          id: legendItem.name,
          src: {
            type: 'vector',
            bounds: legendItem.bounds,
            minzoom: legendItem.minz,
            maxzoom: MapParams.maxZoom,
            tiles: [
              `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${
                legendItem.type
              }/{z}/{x}/{y}?${getTileTimeStamp(legendItem)}`,
            ],
          },
        },
        layer: {
          legend: {
            id: legendItem.name,
            title: legendItem.title,
            type: 'circle',
            iconClass: `legend-icon ${iconClass}`,
            filters: legendItem.filters,
            hint,
          },
          sublayers: [
            ...(legendItem.name.startsWith('ml__') ? getMaxLoadIcons(legendItem) : []),
            ...(assetFilter?.list ?? [])
              ?.flatMap(voltageFilter => [
                {
                  id: `${legendItem.name}_voltage_${voltageFilter.id}${type === 'additional' ? '_additional' : ''}`,
                  source: legendItem.name,
                  'source-layer': legendItem.table,
                  minzoom:
                    type === 'additional'
                      ? additionalLayersZoom
                      : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz,
                  maxzoom:
                    type === 'additional'
                      ? MapParams.maxZoom
                      : voltageFilter.voltageMaxZoom ?? maxZoom ?? MapParams.maxZoom,
                  filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
                  order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                  showPopup: true,
                  hasData: true,
                  type: color ? 'circle' : 'symbol',
                  paint: color
                    ? {
                        'circle-color': type === 'additional' ? '#918f86' : color,
                        'circle-radius':
                          circleSize && !Array.isArray(circleSize)
                            ? circleSize
                            : [
                                'interpolate',
                                ['exponential', zoomInterpolateRatio],
                                ['zoom'],
                                ...(circleSize ?? [16, 4, 18, 12]),
                              ],
                        'circle-stroke-opacity': assetOpacity,
                        'circle-stroke-color': '#FFF',
                        'circle-stroke-width': 2,
                        'circle-pitch-alignment': 'map',
                        'circle-opacity':
                          type === 'additional'
                            ? additionalAssetOpacity
                            : !legendItem.name.startsWith('asset')
                              ? [
                                  'case',
                                  ['==', ['typeof', ['get', 'in_theme']], 'boolean'],
                                  [
                                    'case',
                                    ['boolean', ['get', 'in_theme'], false],
                                    assetOpacity,
                                    additionalAssetOpacity,
                                  ],
                                  additionalAssetOpacity,
                                ]
                              : assetOpacity,
                      }
                    : {
                        'icon-opacity':
                          type === 'additional'
                            ? additionalAssetOpacity
                            : !legendItem.name.startsWith('asset')
                              ? [
                                  'case',
                                  ['==', ['typeof', ['get', 'in_theme']], 'boolean'],
                                  [
                                    'case',
                                    ['boolean', ['get', 'in_theme'], false],
                                    assetOpacity,
                                    additionalAssetOpacity,
                                  ],
                                  additionalAssetOpacity,
                                ]
                              : assetOpacity,
                      },
                  layout: icon
                    ? {
                        'icon-image': type === 'additional' ? iconAdditional : icon,
                        'icon-size':
                          iconSize && !Array.isArray(iconSize)
                            ? iconSize
                            : [
                                'interpolate',
                                ['exponential', zoomInterpolateRatio],
                                ['zoom'],
                                ...(iconSize ?? [14, 0.8, 16, 1.2, 20, 1.5]),
                              ],
                        'icon-ignore-placement': true,
                        'icon-anchor': iconAlignment === 'viewport' ? 'bottom' : 'center',
                        'icon-pitch-alignment': iconAlignment ?? 'map',
                      }
                    : {},
                },
                hasTextRegex.test(legendItem.name) && {
                  id: `${legendItem.name}_voltage_${voltageFilter.id}_text${
                    type === 'additional' ? '_additional' : ''
                  }`,
                  type: 'symbol',
                  minzoom: Math.max(
                    getVoltageTextZoom(Number(voltageFilter.id)),
                    type === 'additional'
                      ? additionalLayersZoom
                      : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz
                  ),
                  maxzoom: Math.max(
                    getVoltageTextZoom(Number(voltageFilter.id)),
                    type === 'additional'
                      ? MapParams.maxZoom
                      : voltageFilter.voltageMaxZoom ?? maxZoom ?? MapParams.maxZoom
                  ),
                  source: legendItem.name,
                  'source-layer': legendItem.table,
                  filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
                  order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                  layout: {
                    'text-field': ['get', 'name'],
                    'text-size': [
                      'interpolate',
                      ['exponential', zoomInterpolateRatio],
                      ['zoom'],
                      10,
                      10,
                      MapParams.maxZoom,
                      30,
                    ],
                    'text-rotation-alignment': 'viewport',
                    'text-max-width': Infinity,
                    'text-offset': [
                      'interpolate',
                      ['exponential', 1.2],
                      ['zoom'],
                      10,
                      ['literal', [0, 0.4 * Number(voltageFilter.id)]],
                      MapParams.maxZoom,
                      ['literal', [0, 1.8 * Number(voltageFilter.id)]],
                    ],
                  },
                  paint: {
                    'text-color': '#003166',
                    'text-halo-color': '#fff',
                    'text-halo-width': [
                      'interpolate',
                      ['exponential', zoomInterpolateRatio],
                      ['zoom'],
                      10,
                      1,
                      MapParams.maxZoom,
                      3,
                    ],
                  },
                },
              ])
              .filter(Boolean),
          ].filter(Boolean) as Map.StyleSubLayer[],
        },
      };
      return style;
    })
    .filter(Boolean) as Map.Style[];
};

export const getHighlightStyle = ({
  legendItem,
  circleSize,
  minZoom,
  maxZoom,
  hasAdditional,
  settings,
}: Map.HighlightStyleParams) => {
  const assetFilter = Object.entries(settings?.layerFilters ?? {}).find(([k]) => legendItem.name.endsWith(k))?.[1];
  return ['additional', 'general']
    .map(type => {
      if (type === 'additional' && !hasAdditional) return null;
      const style: Map.Style = {
        source: {
          id: `${legendItem.name}_highlight`,
          src: {
            type: 'vector',
            bounds: legendItem.bounds,
            minzoom: legendItem.minz,
            maxzoom: MapParams.maxZoom,
            tiles: [
              `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${
                legendItem.type
              }/{z}/{x}/{y}?${getTileTimeStamp(legendItem)}`,
            ],
          },
        },
        layer: {
          sublayers: (assetFilter?.list ?? [])?.flatMap(voltageFilter => [
            {
              id: `${legendItem.name}_voltage_${voltageFilter.id}${
                type === 'additional' ? '_additional' : ''
              }_highlight`,
              source: legendItem.name,
              'source-layer': legendItem.table,
              minzoom:
                type === 'additional'
                  ? additionalLayersZoom
                  : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz,
              maxzoom:
                type === 'additional'
                  ? MapParams.maxZoom
                  : voltageFilter.voltageMaxZoom ?? maxZoom ?? MapParams.maxZoom,
              order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
              filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id], false],
              type: 'circle',
              paint: {
                'circle-color': '#7345e0',
                'circle-stroke-color': '#7345e0',
                'circle-stroke-width': 3,
                'circle-stroke-opacity': [
                  'case',
                  [
                    'any',
                    ['boolean', ['feature-state', 'select'], false],
                    ['boolean', ['feature-state', 'highlight'], false],
                  ],
                  highlightActiveOpacity,
                  highlightInactiveOpacity,
                ],
                'circle-opacity': [
                  'case',
                  [
                    'any',
                    ['boolean', ['feature-state', 'select'], false],
                    ['boolean', ['feature-state', 'highlight'], false],
                  ],
                  highlightActiveOpacity,
                  highlightInactiveOpacity,
                ],
                'circle-radius':
                  circleSize && !Array.isArray(circleSize)
                    ? circleSize
                    : [
                        'interpolate',
                        ['exponential', zoomInterpolateRatio],
                        ['zoom'],
                        ...(circleSize ?? [8, 14, 12, 20, 18, 24]),
                      ],
                'circle-pitch-alignment': 'map',
              },
            },
          ]),
        },
      };
      return style;
    })
    .filter(Boolean) as Map.Style[];
};

export const getColumnStyle = ({
  legendItem,
  color,
  height,
  transparencyThreshold,
  settings,
}: Map.ColumnStyleParams) => {
  const assetFilter = Object.entries(settings?.layerFilters ?? {}).find(([k]) => legendItem.name.endsWith(k))?.[1];
  const style: Map.Style = {
    source: {
      id: `${legendItem.name}_column`,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        minzoom: columnZoomThreshold,
        maxzoom: MapParams.maxZoom,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${
            legendItem.name
          }/30/{z}/{x}/{y}?${getTileTimeStamp(legendItem)}`,
        ],
      },
    },
    layer: {
      legend: {
        id: `${legendItem.name}_column`,
        title: legendItem.title,
        type: 'fill-extrusion',
      },
      sublayers: (assetFilter?.list ?? [])
        ?.flatMap(voltageFilter => [
          {
            id: `${legendItem.name}_voltage_${voltageFilter.id}_column`,
            showPopup: true,
            type: 'fill-extrusion',
            minzoom: columnZoomThreshold,
            maxzoom: transparencyThreshold ?? MapParams.maxZoom,
            filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
            order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
            source: `${legendItem.name}_column`,
            'source-layer': legendItem.table,
            paint: {
              'fill-extrusion-color': color,
              'fill-extrusion-height': height ?? 5,
              'fill-extrusion-opacity': columnFarOpacity,
            },
          },
          transparencyThreshold
            ? {
                id: `${legendItem.name}_voltage_${voltageFilter.id}_column_2`,
                showPopup: true,
                type: 'fill-extrusion',
                minzoom: transparencyThreshold,
                filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
                order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                source: `${legendItem.name}_column`,
                'source-layer': legendItem.table,
                paint: {
                  'fill-extrusion-color': color,
                  'fill-extrusion-height': height ?? 5,
                  'fill-extrusion-opacity': columnNearOpacity,
                },
              }
            : null,
          hasTextRegex.test(legendItem.name) && {
            id: `${legendItem.name}_voltage_${voltageFilter.id}_column_text`,
            type: 'symbol',
            minzoom: Math.max(getVoltageTextZoom(Number(voltageFilter.id)), columnZoomThreshold),
            maxzoom: Math.max(getVoltageTextZoom(Number(voltageFilter.id)), MapParams.maxZoom),
            source: `${legendItem.name}_column`,
            'source-layer': legendItem.table,
            filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
            order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
            layout: {
              'text-field': ['get', 'name'],
              'text-size': [
                'interpolate',
                ['exponential', zoomInterpolateRatio],
                ['zoom'],
                10,
                10,
                MapParams.maxZoom,
                30,
              ],
              'text-rotation-alignment': 'viewport',
              'text-max-width': Infinity,
              'text-offset': [
                'interpolate',
                ['exponential', 1.5],
                ['zoom'],
                10,
                ['literal', [0, 0.45 * Number(voltageFilter.id)]],
                MapParams.maxZoom,
                ['literal', [0, 8 * Number(voltageFilter.id)]],
              ],
            },
            paint: {
              'text-color': '#003166',
              'text-halo-color': '#fff',
              'text-halo-width': [
                'interpolate',
                ['exponential', zoomInterpolateRatio],
                ['zoom'],
                10,
                1,
                MapParams.maxZoom,
                3,
              ],
            },
          },
        ])
        .filter(Boolean) as Map.StyleSubLayer[],
    },
  };
  return style;
};

export const getLineStyle = ({
  legendItem,
  iconClass,
  color,
  minZoom,
  hasAdditional,
  settings,
}: Map.LineStyleParams) => {
  const assetFilter = Object.entries(settings?.layerFilters ?? {}).find(([k]) => legendItem.name.endsWith(k))?.[1];
  return ['additional', 'general']
    .map(type => {
      if (type === 'additional' && !hasAdditional) return null;
      const style: Map.Style = {
        source: {
          id: legendItem.name,
          src: {
            type: 'vector',
            bounds: legendItem.bounds,
            minzoom: legendItem.minz,
            maxzoom: MapParams.maxZoom,
            tiles: [
              `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${
                legendItem.type
              }/{z}/{x}/{y}?${getTileTimeStamp(legendItem)}`,
            ],
          },
        },
        layer: {
          legend: {
            id: legendItem.name,
            title: legendItem.title,
            type: 'line',
            iconClass: `legend-icon ${iconClass}`,
            filters: legendItem.filters,
          },
          sublayers: [
            ...(legendItem.name.startsWith('ml__') ? getMaxLoadIcons(legendItem, 1, true) : []),
            ...(assetFilter?.list ?? [])?.flatMap(voltageFilter => [
              {
                id: `${legendItem.name}_voltage_${voltageFilter.id}${type === 'additional' ? '_additional' : ''}`,
                source: legendItem.name,
                'source-layer': legendItem.table,
                minzoom:
                  type === 'additional'
                    ? additionalLayersZoom
                    : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz,
                maxzoom: type === 'additional' ? MapParams.maxZoom : voltageFilter.voltageMaxZoom ?? MapParams.maxZoom,
                filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
                order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                hasData: true,
                hoverable: true,
                showPopup: true,
                type: 'line',
                paint: {
                  'line-width': 2,
                  'line-color': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#7345e0',
                    ...(type === 'additional' ? ['#918f86'] : Array.isArray(color) ? color : [color]),
                  ],
                  'line-opacity':
                    type === 'additional'
                      ? additionalAssetOpacity
                      : !legendItem.name.startsWith('asset')
                        ? [
                            'case',
                            ['==', ['typeof', ['get', 'in_theme']], 'boolean'],
                            ['case', ['boolean', ['get', 'in_theme'], false], assetOpacity, additionalAssetOpacity],
                            additionalAssetOpacity,
                          ]
                        : assetOpacity,
                },
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
              },
              {
                id: `${legendItem.name}_voltage_${voltageFilter.id}${
                  type === 'additional' ? '_additional' : ''
                }_background`,
                source: legendItem.name,
                'source-layer': legendItem.table,
                minzoom:
                  type === 'additional'
                    ? additionalLayersZoom
                    : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz,
                maxzoom: type === 'additional' ? MapParams.maxZoom : voltageFilter.voltageMaxZoom ?? MapParams.maxZoom,
                filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id]],
                order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                type: 'line',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-width': 5,
                  'line-color': '#fff',
                  'line-opacity':
                    type === 'additional'
                      ? additionalAssetOpacity
                      : !legendItem.name.startsWith('asset')
                        ? [
                            'case',
                            ['==', ['typeof', ['get', 'in_theme']], 'boolean'],
                            ['case', ['boolean', ['get', 'in_theme'], false], assetOpacity, additionalAssetOpacity],
                            additionalAssetOpacity,
                          ]
                        : assetOpacity,
                },
              },
              {
                id: `${legendItem.name}_voltage_${voltageFilter.id}${
                  type === 'additional' ? '_additional' : ''
                }_highlight`,
                source: legendItem.name,
                'source-layer': legendItem.table,
                minzoom:
                  type === 'additional'
                    ? additionalLayersZoom
                    : voltageFilter.voltageMinzoom ?? minZoom ?? legendItem.minz,
                maxzoom: type === 'additional' ? MapParams.maxZoom : voltageFilter.voltageMaxZoom ?? MapParams.maxZoom,
                order: legendItem.order + (voltageFilter.voltageOrder ?? 0),
                filter: ['all', ['==', ['get', assetFilter?.property], voltageFilter.id], false],
                type: 'line',
                paint: {
                  'line-opacity': [
                    'case',
                    [
                      'any',
                      ['boolean', ['feature-state', 'select'], false],
                      ['boolean', ['feature-state', 'highlight'], false],
                    ],
                    highlightActiveOpacity,
                    highlightInactiveOpacity,
                  ],
                  'line-width': 20,
                  'line-color': '#7345e0',
                },
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
              },
            ]),
          ].filter(Boolean) as Map.StyleSubLayer[],
        },
      };
      return style;
    })
    .filter(Boolean) as Map.Style[];
};
