import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { materialsSelector } from 'modules/tasks/selectors';
import { fetchTaskMaterialsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectTaskMaterials: React.FC<Props> = ({ labelKey = 'Materials', value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const materials = useSelector(materialsSelector);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchTaskMaterialsAction({ skipPagination: true })).then(() => setIsLoading(false));
  }, [dispatch]);

  const { selectOptions, selectValue } = useMemo(() => {
    return materials?.reduce(
      (acc: any, material: Tasks.Material) => {
        const option = { value: material.id, label: material.name };
        if (value === option.value) acc.selectValue = option;
        acc.selectOptions.push(option);
        return acc;
      },
      { selectOptions: [], selectValue: null }
    );
  }, [materials, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      isDisabled={isLoading || props.isDisabled}
      isLoading={isLoading}
      options={selectOptions}
    />
  );
};

export default SelectTaskMaterials;
