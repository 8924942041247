import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.employees;

const employeesHashSelector = createSelector(rootSelector, employees => employees.employeesHash);

export const employeesSelector = createSelector(employeesHashSelector, employeesHash => Object.values(employeesHash));

export const employeesCountSelector = createSelector(rootSelector, employees => employees.employeesCount);

export const employeeSelectorFactory = (id: number | null) =>
  createSelector(employeesHashSelector, employeesHash => employeesHash[`_${id}_`]);

// EMPLOYEES GANTT CHART SELECTORS

export const employeesGanttChartFetchedSelector = createSelector(
  rootSelector,
  employees => employees.employeesGanttChartFetched
);

const employeesGanttChartHashSelector = createSelector(rootSelector, employees => employees.employeesGanttChartHash);

export const employeesGanttChartSelector = createSelector(employeesGanttChartHashSelector, employeesGanttChartHash =>
  Object.values(employeesGanttChartHash)
);

export const employeesGanttChartHoursLeftOverWeekSelector = createSelector(
  rootSelector,
  employees => employees.employeesGanttChartHoursLeftOverWeek
);

export const employeesGanttChartHoursLeftOverMonthSelector = createSelector(
  rootSelector,
  employees => employees.employeesGanttChartHoursLeftOverMonth
);

export const employeesGanttChartTasksHashSelector = createSelector(
  employeesGanttChartSelector,
  (employeesGanttChartItems): Record<string, Tasks.Task> => {
    return employeesGanttChartItems.reduce((acc, item) => {
      item.tasks.forEach(task => {
        (acc as any)[`_${task.id}_`] = task;
      });
      return acc;
    }, {});
  }
);

// TASKS GANTT CHART SELECTORS

export const tasksGanttChartFetchedSelector = createSelector(
  rootSelector,
  employees => employees.tasksGanttChartFetched
);

export const tasksGanttChartHashSelector = createSelector(rootSelector, employees => employees.tasksGanttChartHash);

export const tasksGanttChartSelector = createSelector(tasksGanttChartHashSelector, tasksGanttChartHash =>
  Object.values(tasksGanttChartHash)
);
