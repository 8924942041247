import React from 'react';
import { useSelector } from 'react-redux';
import { PageAppExceptions } from 'pages';
import { AppErrorsTypes } from 'constants/index';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';

const RequiredPortfolioLoadflowEnabledCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isPortfolioLoadflowEnabled = useSelector(isPortfolioLoadflowEnabledSelector);

  if (!isPortfolioLoadflowEnabled) {
    return <PageAppExceptions appError={AppErrorsTypes.NON_LOADFLOW_PORTFOLIO} />;
  }

  return <>{children}</>;
};

export default RequiredPortfolioLoadflowEnabledCheck;
