import styled from 'styled-components';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import SatelliteButton from './SatelliteButton';
import BuildingsButton from './BuildingsButton';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';
import BearingResetButton from './BearingResetButton';
import MapModeButton from './MapModeButton';
import LastUpdateButton from './LastUpdateButton';
import AddressSearchButton from './AddressSearchButton';
import CableAnimationButton from './CableAnimationButton';
import ButtonAddCustomer from './Connections/ButtonAddCustomer';
import LabelsButton from './LabelsButton';
import { isVekselTenant } from 'utils';
import { StorageKeys } from 'constants/index';

const GISLayersButton = React.lazy(() => import('./GISLayers/GISLayersButton'));
const DrawAssetsButton = React.lazy(() => import('./DrawAssets/DrawAssetsButton'));

const Controls: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);
  return (
    <StyledContainer>
      {isVekselTenant && featureToggles[StorageKeys.FEATURE_GIS_LAYERS] && (
        <Suspense>
          <GISLayersButton />
        </Suspense>
      )}

      <ButtonAddCustomer />

      {featureToggles[StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS] && (
        <Suspense>
          <DrawAssetsButton />
        </Suspense>
      )}
      <ZoomInButton />
      <ZoomOutButton />
      <BearingResetButton />
      <AddressSearchButton />
      <SatelliteButton />
      <BuildingsButton />
      <MapModeButton />
      {featureToggles[StorageKeys.FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION] && <CableAnimationButton />}
      <LabelsButton />
      <LastUpdateButton />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  bottom: 45px;
  right: 10px;
  z-index: 1000;
  width: 32px;
`;

export default Controls;
