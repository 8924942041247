import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { fetchNewCustomersCountAction } from 'modules/map/actions';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { DERsProfilesHashSelector } from 'modules/newLoad/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { resetDERsDescriptionOptionsAction } from 'modules/options';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchDERsAction: any = createAction(
  'newLoad/FETCH_DERS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NewLoad.Root, 'DERsHash' | 'DERsCount'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.NEW_LOAD_DERS)(state);
      return AssetLifeAPI.get('scenarios/der_description', {
        params: {
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          year: skipPagination ? null : filters?.year,
          lang: appLangSelector(state).toLowerCase(),
        },
      }).then(res => ({
        DERsCount: res.data.count,
        DERsHash: _keyBy(
          res.data.map((item: NewLoad.DER) => ({
            ...item,
            grid_zones_id: typeof item.grid_zones_id === 'number' ? [item.grid_zones_id] : item.grid_zones_id,
          })),
          (item: NewLoad.DER) => `_${item.id}_`
        ),
        skipStoreUpdate,
      }));
    }
);

export const createDERAction = createAction(
  'newLoad/CREATE_DER',
  (data: {
    description: string;
    der_categories_id: number | null;
    max_per_installation: number | null;
    grid_zones_id: number[] | null;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<number> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      return AssetLifeAPI.post(
        'scenarios/der_description',
        { ...data, portfolio_id: portfolioId, scenario_id: scenarioId },
        { isErrorOnlyForModal: true } as any
      ).then(
        // send id back to formik submit handler
        res => res.data
      );
    }
);

export const updateDERAction = createAction(
  'newLoad/UPDATE_DER',
  (data: {
    id: number;
    description: string;
    der_categories_id: number | null;
    max_per_installation: number | null;
    grid_zones_id: number[] | null;
  }) =>
    (): Promise<void> => {
      return AssetLifeAPI.patch(`scenarios/der_description/${data.id}/`, data, { isErrorOnlyForModal: true } as any);
    }
);

export const deleteDERAction = createAction(
  'newLoad/DELETE_DER',
  (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      return AssetLifeAPI.delete(`scenarios/der_description/${id}/`, {
        params: {
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          id,
        },
      }).then(async () => {
        await dispatch(fetchDERsAction());
        dispatch(setSuccessToastAction('DER has been deleted'));
        dispatch(resetDERsDescriptionOptionsAction());
      });
    }
);

export const uploadDERProfileAction = createAction(
  'newLoad/UPLOAD_DER_PROFILE',
  (description: string, shared: boolean, formData: FormData) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      return AssetLifeAPI.post('scenarios/der_profile_excel', formData, {
        params: { description, shared, portfolio_id: portfolioId },
        ...{ isErrorOnlyForModal: true },
      });
    }
);

export const replaceDERProfileAction = createAction(
  'newLoad/REPLACE_DER_PROFILE',
  (data: { id: number; profileId: number }) => (): Promise<void> => {
    return AssetLifeAPI.put('scenarios/der_profile_excel', null, {
      params: {
        der_description_id: data.id,
        der_profile_info_id: data.profileId,
      },
      ...{ isErrorOnlyForModal: true },
    });
  }
);

export const fetchDERsCategoriesAction = createAction(
  'newLoad/FETCH_DERS_CATEGORIES',
  async ({ skipStoreUpdate } = { skipStoreUpdate: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NewLoad.Root, 'DERsCategoriesHash' | 'DERsCategoriesCount' | 'DERsCategoriesFetched'>> => {
      return AssetLifeAPI.get('scenarios/der_categories', {
        params: { lang: appLangSelector(getState()).toLowerCase() },
      }).then(res => ({
        DERsCategoriesCount: res.data.count,
        DERsCategoriesHash: _keyBy(res.data, (item: NewLoad.DERCategory) => `_${item.id}_`),
        DERsCategoriesFetched: true,
        skipStoreUpdate,
      }));
    }
);

export const createDERsSectionCategoryAction = createAction(
  'newLoad/CREATE_DERS_SECTION_CATEGORY',
  (data: Type.CreateNewLoadSectionCategoryActionProps) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.post('scenarios/der_categories', data).then(async () => {
        await dispatch(fetchDERsCategoriesAction());
        dispatch(setSuccessToastAction('DERs section category has been created'));
      });
    }
);

export const updateDERsSectionCategoryAction = createAction(
  'newLoad/UPDATE_DERS_SECTION_CATEGORY',
  (data: Required<Type.CreateNewLoadSectionCategoryActionProps>) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put(`scenarios/der_categories/${data.id}`, data).then(async () => {
        await dispatch(fetchDERsCategoriesAction());
        dispatch(setSuccessToastAction('DERs section category has been updated'));
      });
    }
);

export const deleteDERsSectionCategoryAction = createAction(
  'newLoad/DELETE_DERS_SECTION_CATEGORY',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.delete(`scenarios/der_categories/${id}`).then(async () => {
        await dispatch(fetchDERsCategoriesAction());
        dispatch(setSuccessToastAction('DERs section category has been deleted'));
      });
    }
);

export const fetchDERsProfilesAction = createAction(
  'newLoad/FETCH_DERS_PROFILES',
  async (portfolioId: Layouts.Root['portfolioId']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NewLoad.Root, 'DERsProfilesHash' | 'DERsProfilesCount'>> => {
      return AssetLifeAPI.get('scenarios/der_profile_info', { params: { portfolio_id: portfolioId } }).then(res => ({
        DERsProfilesCount: res.data.count,
        DERsProfilesHash: {
          ...DERsProfilesHashSelector(getState()),
          [String(portfolioId)]: res.data,
        },
      }));
    }
);

export const deleteDERsProfileAction = createAction(
  'newLoad/DELETE_DERS_PROFILE',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      return AssetLifeAPI.delete(`scenarios/der_profile_info/${id}`, { params: { portfolio_id: portfolioId } }).then(
        async () => {
          await dispatch(fetchDERsProfilesAction(portfolioId));
          dispatch(setSuccessToastAction('DERs profile has been deleted'));
        }
      );
    }
);

export const fetchIndividualCustomersAction = createAction(
  'newLoad/FETCH_INDIVIDUAL_CUSTOMERS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NewLoad.Root, 'individualCustomersHash' | 'individualCustomersCount'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(
        PaginationType.NEW_LOAD_CONNECTION_MANAGER
      )(state);
      return AssetLifeAPI.get('scenarios/individual_customers', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
        },
      }).then(res => ({
        individualCustomersCount: res.data.count,
        individualCustomersHash: _keyBy(res.data.rows, (item: NewLoad.IndividualCustomer) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const deleteIndividualCustomerAction = createAction(
  'newLoad/DELETE_INDIVIDUAL_CUSTOMER',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      return AssetLifeAPI.delete(`scenarios/individual_customers/${id}`, {
        params: { id, scenario_id: scenarioIdSelector(state) },
      }).then(async () => {
        await dispatch(fetchIndividualCustomersAction());
        dispatch(setSuccessToastAction('Individual customer has been deleted'));
      });
    }
);

export const uploadIndividualCustomersExcelAction = createAction(
  'newLoad/UPLOAD_INDIVIDUAL_CUSTOMERS_EXCEL',
  (formData: FormData) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      formData.append('portfolio_id', portfolioId as unknown as string);
      formData.append('scenario_id', scenarioId as unknown as string);
      return AssetLifeAPI.post('/scenarios/individual_customers_excel', formData, {
        isErrorOnlyForModal: true,
      } as any).then(async () => {
        await dispatch(fetchIndividualCustomersAction());
        dispatch(setSuccessToastAction('Individual customers excel has been uploaded'));
      });
    }
);

export const addMapNewCustomerAction = createAction(
  'newLoad/ADD_MAP_NEW_CUSTOMER/(no-store)',
  (data: NewLoad.MapConnection) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return AssetLifeAPI.post('scenarios/individual_customers', {
        ...data,
        portfolio_id: portfolioId,
        scenario_id: scenarioId,
        version_id: versionId,
      }).then(async () => {
        await dispatch(fetchNewCustomersCountAction());
        dispatch(setSuccessToastAction('New customer has been added'));
      });
    }
);

export const updateIndividualCustomerAction = createAction(
  'newLoad/UPDATE_INDIVIDUAL_CUSTOMER',
  (id: number, data: NewLoad.ModalEditIndividualCustomerData) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.patch(`scenarios/individual_customers/${id}`, data).then(async () => {
        await dispatch(fetchIndividualCustomersAction());
        dispatch(setSuccessToastAction('Individual customer has been updated'));
      });
    }
);

export const getGlobalDevelopmentChartData = createAction(
  'newLoad/GET_GLOBAL_DEVELOPMENT_CHART_DATA',
  ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<any> => {
      const state = getState();
      return AssetLifeAPI.get('plots/der_phase_in_plot_global', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId, lang: appLangSelector(state).toLowerCase() },
      }).then(res => res.data);
    }
);

export const getDERProfileChartData = createAction(
  'newLoad/GET_DER_PROFILE_CHART_DATA',
  ({ id }: { id: number }) =>
    (): Promise<any> => {
      return AssetLifeAPI.get('plots/der_profile_plot', { params: { der_profile_info_id: id } }).then(res => res.data);
    }
);

export const getDERPhaseInChartData = createAction(
  'newLoad/GET_DER_PHASE_IN_CHART_DATA',
  ({ id }: { id: number }) =>
    (): Promise<any> =>
      AssetLifeAPI.get('plots/der_phase_in_plot', { params: { der_phase_in_id: id } }).then(res => res.data)
);

export const fetchDERsPhaseInAction = createAction(
  'newLoad/FETCH_DERS_PHASE_IN',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NewLoad.Root, 'DERsPhaseInItems' | 'DERsPhaseInCount'>> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      return AssetLifeAPI.get('scenarios/der_phase_in_info', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId, lang: appLangSelector(state).toLowerCase() },
      }).then(res => ({ DERsPhaseInCount: res.data.count, DERsPhaseInItems: res.data }));
    }
);

export const uploadDERPhaseInAction = createAction(
  'newLoad/UPLOAD_DER_PHASE_IN',
  ({ description, der_description_id }: { description: string; der_description_id: number }, formData: FormData) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      formData.append('description', description as unknown as string);
      formData.append('der_description_id', der_description_id as unknown as string);
      formData.append('portfolio_id', portfolioId as unknown as string);
      formData.append('scenario_id', scenarioId as unknown as string);
      return AssetLifeAPI.post('scenarios/der_phase_in_excel', formData, { isErrorOnlyForModal: true } as any).then(
        async () => {
          await dispatch(fetchDERsPhaseInAction());
          dispatch(setSuccessToastAction('DERs phase-in has been uploaded'));
        }
      );
    }
);

export const deleteDERsPhaseInAction = createAction(
  'newLoad/DELETE_DERS_PHASE_IN',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      return AssetLifeAPI.delete(`scenarios/der_phase_in_info/${id}`, {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(async () => {
        await dispatch(fetchDERsPhaseInAction());
        dispatch(setSuccessToastAction('DERs phase-in has been deleted'));
      });
    }
);
