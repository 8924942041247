import React, { useCallback, useState } from 'react';
import { useLocale } from 'hooks';
import { Button, ButtonProps } from 'components/_common';
import { AssetLifeAPI } from 'constants/index';
import { IconExcel, IconWord, IconDownload } from '@utiligize/shared/resources';

interface Props extends ButtonProps {
  fileType?: 'xlsx' | 'docx' | 'csv';
  fileNameLocaleStr: string;
  link: string;
  dataMarker?: string;
}

const mimeTypes = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  csv: 'text/csv',
};

const ButtonDownload: React.FC<Props> = ({ fileType = 'xlsx', fileNameLocaleStr, link, dataMarker, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getIntl, dateFormat } = useLocale();
  const fileName = getIntl(fileNameLocaleStr);

  const handleDownloadClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    AssetLifeAPI.get(link, { responseType: 'blob' })
      .then(res => {
        const blob = new Blob([res.data], { type: mimeTypes[fileType] });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName} (${dateFormat(new Date().toISOString(), 'L LT')}).${fileType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [fileType, fileName, link, loading, dateFormat]);

  const renderIcon = () => {
    switch (fileType) {
      case 'xlsx':
        return <IconExcel />;
      case 'docx':
        return <IconWord />;
      default:
        return <IconDownload />;
    }
  };

  return (
    <Button dataMarker={dataMarker} icon={renderIcon()} onClick={handleDownloadClick} loading={loading} {...rest} />
  );
};

export default ButtonDownload;
