import { createSelector } from 'reselect';
import { percentilesOptionsSelector, scenariosYearsOptionsSelector } from 'modules/options/selectors';
import { simulationIdSelector } from 'modules/layouts/selectors';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.networkLoading;

export const scenarioRunInfoSelector = createSelector(rootSelector, networkLoading => networkLoading.scenarioRunInfo);

// ------------------------------------
// Transformers
// ------------------------------------

export const transformersCountSelector = createSelector(
  rootSelector,
  networkLoading => networkLoading.transformersCount
);

const transformersHashSelector = createSelector(rootSelector, networkLoading => networkLoading.transformersHash);

export const transformersSelector = createSelector(transformersHashSelector, transformersHash =>
  Object.values(transformersHash)
);

export const transformerSelectorFactory = (uuid: string | null) =>
  createSelector(transformersHashSelector, transformersHash => transformersHash[uuid || '']);

export const tableTransformersCablesCabinetsFusesWaitForDependenciesSelector = createSelector(
  [scenariosYearsOptionsSelector, percentilesOptionsSelector, simulationIdSelector],
  (scenariosYearsOptions, percentilesOptions, simulationId): boolean => {
    return Boolean(!scenariosYearsOptions || !percentilesOptions || !simulationId);
  }
);

// ------------------------------------
// Cables
// ------------------------------------

export const cablesCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.cablesCount
);

const cablesHashSelector = createSelector(rootSelector, networkLoading => networkLoading.cablesHash);

export const cablesSelector = createSelector(cablesHashSelector, hash => Object.values(hash));

export const cableSelectorFactory = (uuid: string | null) =>
  createSelector(cablesHashSelector, hash => hash[uuid || '']);

// ------------------------------------
// Cabinets
// ------------------------------------

export const cabinetsCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.cabinetsCount
);

const cabinetsHashSelector = createSelector(rootSelector, networkLoading => networkLoading.cabinetsHash);

export const cabinetsSelector = createSelector(cabinetsHashSelector, hash => Object.values(hash));

export const cabinetSelectorFactory = (uuid: string | null) =>
  createSelector(cabinetsHashSelector, hash => hash[uuid || '']);

// ------------------------------------
// Fuses
// ------------------------------------

export const fusesCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.fusesCount
);

const fusesHashSelector = createSelector(rootSelector, networkLoading => networkLoading.fusesHash);

export const fusesSelector = createSelector(fusesHashSelector, hash => Object.values(hash));

// ------------------------------------
// Customers
// ------------------------------------

const customersTypesHashSelector = createSelector(rootSelector, networkLoading => networkLoading.customersTypesHash);

export const customersTypesSelector = createSelector(customersTypesHashSelector, customersTypesHash =>
  Object.values(customersTypesHash)
);

export const customersTypesInstallationsSelector = createSelector(
  rootSelector,
  networkLoading => networkLoading.customersTypesInstallations
);

// ------------------------------------
// Comments
// ------------------------------------

export const ignoreMessagesCountSelector = createSelector(
  rootSelector,
  networkLoading => networkLoading.ignoreMessagesCount
);

const ignoreMessagesHashSelector = createSelector(rootSelector, networkLoading => networkLoading.ignoreMessagesHash);

export const ignoreMessagesSelector = createSelector(ignoreMessagesHashSelector, ignoreMessagesHash =>
  Object.values(ignoreMessagesHash)
);

export const futureChartDataHashSelector = createSelector(
  rootSelector,
  networkLoading => networkLoading.futureChartDataHash
);

export const futureChartDataSelectorFactory = (storeKey: string, index: number) =>
  createSelector(
    futureChartDataHashSelector,
    (futureChartDataHash): NetworkLoading.FutureChartData => futureChartDataHash?.[storeKey]?.[index]
  );
