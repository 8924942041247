import React from 'react';
import { useSelector } from 'react-redux';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsCabinets from './TabsCabinets';
import TableFilters from './TableFilters';
import { PaginationType, Routes } from 'constants/index';

const isScenariosTabActiveSelector = isRouteActiveSelectorFactory(Routes.NetworkLoadingCabinets);

const isLoadingDistributionTabActiveSelector = isRouteActiveSelectorFactory(
  `${Routes.NetworkLoadingCabinets}${Routes.HASH.LoadingDistribution}`
);

const isVoltageTabActiveSelector = isRouteActiveSelectorFactory(
  `${Routes.NetworkLoadingCabinets}${Routes.HASH.Voltage}`
);

const isFutureMeanTabActiveSelector = isRouteActiveSelectorFactory(
  `${Routes.NetworkLoadingCabinets}${Routes.HASH.FutureMean}`
);

const isFutureOverloadedTabActiveSelector = isRouteActiveSelectorFactory(
  `${Routes.NetworkLoadingCabinets}${Routes.HASH.FutureOverloaded}`
);

const ViewCabinets: React.FC = () => {
  const isScenariosTabActive = useSelector(isScenariosTabActiveSelector);
  const isLoadingDistributionTabActive = useSelector(isLoadingDistributionTabActiveSelector);
  const isVoltageTabActive = useSelector(isVoltageTabActiveSelector);
  const isFutureMeanTabActive = useSelector(isFutureMeanTabActiveSelector);
  const isFutureOverloadedTabActive = useSelector(isFutureOverloadedTabActiveSelector);

  const type = PaginationType.NETWORK_LOADING_CABINETS;
  return (
    <DataTableTemplate>
      <TableFilters
        type={type}
        hideScenariosYear={isFutureMeanTabActive || isFutureOverloadedTabActive}
        hideVoltage
        showFilterSolved={isScenariosTabActive}
        showPercentile={isScenariosTabActive || isLoadingDistributionTabActive || isVoltageTabActive}
        showOverloadedAssets={isLoadingDistributionTabActive}
      />
      <TabsCabinets type={type} />
    </DataTableTemplate>
  );
};

export default ViewCabinets;
