import React, { useCallback, useState, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { FormReactSelect, Button } from 'components/_common';
import { Routes } from 'constants/index';
import { IconSearch, IconClose } from '@utiligize/shared/resources';

const NavigationSearch: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const toggleSearch = useCallback((): void => setIsOpen(prevState => !prevState), []);

  const options = useMemo(
    () => [
      {
        value: 'Tasks - New table',
        label: (
          <Link to={`${generatePath(Routes.Tasks)}${Routes.HASH.New}`} onClick={toggleSearch}>
            Tasks - New table
          </Link>
        ),
      },
      {
        value: 'Tasks - Complains table',
        label: (
          <Link to={`${generatePath(Routes.Tasks)}${Routes.HASH.Complaints}`} onClick={toggleSearch}>
            Tasks - Complains table
          </Link>
        ),
      },
      {
        value: 'Tasks - Upcoming table',
        label: (
          <Link to={`${generatePath(Routes.Tasks)}${Routes.HASH.Upcoming}`} onClick={toggleSearch}>
            Tasks - Upcoming table
          </Link>
        ),
      },
      {
        value: 'Tasks - Not started/Ongoing table',
        label: (
          <Link to={generatePath(Routes.Tasks)} onClick={toggleSearch}>
            Tasks - Not started/Ongoing table
          </Link>
        ),
      },
      {
        value: 'Tasks - Finished table',
        label: (
          <Link to={`${generatePath(Routes.Tasks)}${Routes.HASH.Finished}`} onClick={toggleSearch}>
            Tasks - Finished table
          </Link>
        ),
      },
      {
        value: 'Tasks - Checklists table',
        label: (
          <Link to={`${generatePath(Routes.Tasks)}${Routes.HASH.Checklists}`} onClick={toggleSearch}>
            Tasks - Checklists table
          </Link>
        ),
      },
    ],
    [toggleSearch]
  );

  return (
    <>
      <Button variant="primary" icon={<IconSearch />} marginLeft onClick={toggleSearch} aria-label="Search" />
      <div
        className="navbar-search-block navbar-search-open flex-row justify-content-center bg-white align-items-center"
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        <FormReactSelect
          className="w-100"
          value={null}
          options={options}
          onInputChange={setInputValue}
          inputValue={inputValue}
          placeholderKey="Type to search"
        />
        <Button className="ml-2" icon={<IconClose />} onClick={toggleSearch} />
      </div>
    </>
  );
};

export default NavigationSearch;
