import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { scenariosYearsOptionsSelector, simulationVersionIdSelector } from 'modules/options/selectors';
import { applyScenarioYearsOptionsAction, fetchScenarioYearsOptionsAction } from 'modules/options';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  labelKey?: string;
  value: number | null;
  onlyFuture?: boolean;
}

const SelectScenarioYears: React.FC<Props> = ({
  labelKey = 'Scenarios year',
  value,
  onlyFuture,
  onChange,
  ...rest
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scenariosYearsOptions = useSelector(scenariosYearsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || !simulationId || !versionId) return;
    if (scenariosYearsOptions) {
      dispatch(applyScenarioYearsOptionsAction(scenariosYearsOptions));
    } else {
      setIsLoading(true);
      dispatch(fetchScenarioYearsOptionsAction({ portfolioId, scenarioId, simulationId, versionId })).finally(() =>
        setIsLoading(false)
      );
    }
  }, [dispatch, portfolioId, scenarioId, simulationId, versionId, scenariosYearsOptions]);

  const selectOptions = useMemo(
    () =>
      (scenariosYearsOptions || []).reduce((acc: Type.SelectOption<number>[], option) => {
        if (onlyFuture && option.value < new Date().getFullYear()) return acc;
        acc.push(option);
        return acc;
      }, []),
    [scenariosYearsOptions, onlyFuture]
  );

  const selectValue = useMemo(
    () => selectOptions.find(option => option.value === value) || null,
    [value, selectOptions]
  );

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={!selectOptions.length || isLoading}
      labelKey={labelKey}
      value={selectValue}
      options={selectOptions}
      isSearchable={false}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SelectScenarioYears;
