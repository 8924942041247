import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CO2eSelector } from 'modules/setup/selectors';
import { fetchSetupCO2eAction, updateSetupCO2eItemAction } from 'modules/setup';
import { DataTable, FormInput } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableSetupCO2e: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(CO2eSelector);
  const count = items.length;

  const sendRequest = useCallback(() => dispatch(fetchSetupCO2eAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.SETUP_CO2E}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName="border-top-0"
    >
      {items.map((item: Setup.CO2e, index: number) => (
        <TableItem key={item.id} {...item} index={index} />
      ))}
    </DataTable>
  );
};

const TableItem: React.FC<Setup.CO2e & { index: number }> = ({
  id,
  der_type,
  index,
  co2e_t_per_unit_per_year_saved_scope_1,
  co2e_t_per_unit_per_year_saved_scope_2,
  co2e_t_per_unit_per_year_saved_scope_3,
  co2e_t_per_unit_per_year_saved_climate_benefit,
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Omit<Setup.CO2e, 'id' | 'der_type'>>({
    co2e_t_per_unit_per_year_saved_scope_1,
    co2e_t_per_unit_per_year_saved_scope_2,
    co2e_t_per_unit_per_year_saved_scope_3,
    co2e_t_per_unit_per_year_saved_climate_benefit,
  });

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const nextValue = value !== '' ? Number(value) : null;
    setState(state => ({ ...state, [name]: nextValue }));
  }, []);

  const handleInputBlur = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (
        state.co2e_t_per_unit_per_year_saved_scope_1 === co2e_t_per_unit_per_year_saved_scope_1 &&
        state.co2e_t_per_unit_per_year_saved_scope_2 === co2e_t_per_unit_per_year_saved_scope_2 &&
        state.co2e_t_per_unit_per_year_saved_scope_3 === co2e_t_per_unit_per_year_saved_scope_3 &&
        state.co2e_t_per_unit_per_year_saved_climate_benefit === co2e_t_per_unit_per_year_saved_climate_benefit
      ) {
        return;
      }
      setLoading(true);
      dispatch(updateSetupCO2eItemAction(id, state)).finally(() => setLoading(false));
    },
    [
      dispatch,
      id,
      state,
      co2e_t_per_unit_per_year_saved_scope_1,
      co2e_t_per_unit_per_year_saved_scope_2,
      co2e_t_per_unit_per_year_saved_scope_3,
      co2e_t_per_unit_per_year_saved_climate_benefit,
    ]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      const rowIndex: number = Number(e.currentTarget.getAttribute('data-row-index'));
      const cellIndex: number = Number(e.currentTarget.getAttribute('data-cell-index'));
      const element: HTMLInputElement | null =
        document.querySelector(`[data-row-index='${rowIndex + 1}'][data-cell-index='${cellIndex}']`) ||
        document.querySelector(`[data-row-index='0'][data-cell-index='${cellIndex + 1}']`);
      if (element) {
        element?.focus();
      } else {
        handleInputBlur();
      }
    },
    [handleInputBlur]
  );

  return (
    <tr>
      <td>{der_type || '-'}</td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={1}
          type="number"
          disabled={loading}
          name="co2e_t_per_unit_per_year_saved_scope_1"
          value={
            state.co2e_t_per_unit_per_year_saved_scope_1 === null ? '' : state.co2e_t_per_unit_per_year_saved_scope_1
          }
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={2}
          type="number"
          disabled={loading}
          name="co2e_t_per_unit_per_year_saved_scope_2"
          value={
            state.co2e_t_per_unit_per_year_saved_scope_2 === null ? '' : state.co2e_t_per_unit_per_year_saved_scope_2
          }
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={3}
          type="number"
          disabled={loading}
          name="co2e_t_per_unit_per_year_saved_scope_3"
          value={
            state.co2e_t_per_unit_per_year_saved_scope_3 === null ? '' : state.co2e_t_per_unit_per_year_saved_scope_3
          }
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={4}
          type="number"
          disabled={loading}
          name="co2e_t_per_unit_per_year_saved_climate_benefit"
          value={
            state.co2e_t_per_unit_per_year_saved_climate_benefit === null
              ? ''
              : state.co2e_t_per_unit_per_year_saved_climate_benefit
          }
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
    </tr>
  );
};

export default TableSetupCO2e;
