import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.forms;

const formsItemsHashSelector = createSelector(rootSelector, forms => forms.itemsHash);

export const formsCountSelector = createSelector(rootSelector, forms => forms.count);

export const formsItemsSelector = createSelector(formsItemsHashSelector, itemsHash => Object.values(itemsHash));

export const formsItemSelectorFactory = (formId: number | null) =>
  createSelector(formsItemsHashSelector, itemsHash => itemsHash[`_${formId}_`]);

export const builderDataSelector = createSelector(rootSelector, forms => forms.builderData);

export const formBuilderFormTypesSelector = createSelector(builderDataSelector, builderData => builderData.formTypes);

const formBuilderTimestampsSelector = createSelector(rootSelector, forms => forms.timestamps);

export const formBuilderTimestampsSelectorFactory = (assetCategoryCode: Type.AssetCategories | null) =>
  createSelector(formBuilderTimestampsSelector, timestamps =>
    assetCategoryCode ? (timestamps as any)[assetCategoryCode] : null
  );
