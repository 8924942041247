export const NewCustomersLegend = {
  name: 'other__new_customers',
  title: 'New customers',
  title_singular: 'New customer',
  fn_render: 'renderOtherNewCustomers',
  bounds: [9.5956777624, 55.8375581438, 10.2816941436, 56.2776597603],
};

export const renderOtherNewCustomers = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'geojson',
        data: `${process.env.REACT_APP_ASSETLIFE_API_URL}/map/new_customers`,
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        type: 'circle',
        iconClass: 'legend-icon legend-filter-icon icon-customer-new',
      },
      sublayers: [
        {
          id: legendItem.name,
          showPopup: true,
          hasData: true,
          type: 'symbol',
          source: legendItem.name,
          order: 50500,
          layout: {
            'icon-image': 'icon-customer-new',
            'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 0.12, 22, 1],
            'icon-anchor': 'center',
            'icon-pitch-alignment': 'map',
            'icon-ignore-placement': true,
            'icon-allow-overlap': true, // icon can overlap other elements
          },
        },
      ],
    },
  };
  return [style];
};
