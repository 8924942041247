import React from 'react';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';

interface Props extends React.HTMLAttributes<HTMLElement> {
  quantity?: number | null;
}

const LayerElementsCount: React.FC<Props> = ({ quantity, children, ...rest }) => {
  const { getIntl, numberFormat } = useLocale();

  if (!quantity) return <>{children}</>;
  return (
    <OverlayTriggerTooltip
      placement="top"
      overlayId="countHint"
      overlayChildren={
        <>
          {getIntl('Quantity of assets')}: {numberFormat(quantity)}
        </>
      }
    >
      <div {...rest}>{children}</div>
    </OverlayTriggerTooltip>
  );
};

export default LayerElementsCount;
