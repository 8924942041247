export const renderOtherZones = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        minzoom: legendItem.minz,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        type: 'fill',
        iconClass: 'legend-filter',
        group: legendItem.group,
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: legendItem.name,
          hasData: true,
          type: 'fill',
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'fill-outline-color': 'rgba(0,0,0,0)',
            'fill-color': [
              'match',
              ['get', 'zone_id'],
              1,
              'rgba(209, 85, 85, 0.5)',
              2,
              'rgba(221, 181, 81, 0.5)',
              3,
              'rgba(165, 0, 165, 0.5)',
              4,
              'rgba(70, 130, 180, 0.5)',
              5,
              'rgba(123, 123, 255, 0.5)',
              // If no type mathed then it will be:
              '#ccc',
            ],
          },
        },
      ],
    },
  };
  return [style];
};
