import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormTypesAction } from 'modules/forms';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { formBuilderFormTypesSelector } from 'modules/forms/selectors';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options' | 'labelKey'> {
  label: string;
}

const SelectFormFormsTypes: React.FC<Props> = ({ label, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reduxFormTypes = useSelector(formBuilderFormTypesSelector);
  const [formTypes, setFormTypes] = useState<Type.FormType[]>(reduxFormTypes);

  useEffect(() => {
    if (formTypes?.length) return;
    dispatch(fetchFormTypesAction() as any).then((action: any) => setFormTypes(action.payload));
  }, [formTypes, dispatch]);

  const { selectValue, selectOptions } = useMemo(
    () =>
      (formTypes || []).reduce(
        (
          acc: { selectValue: Type.SelectOption | null; selectOptions: Type.SelectOption[] },
          formType: Type.FormType
        ) => {
          const option = { value: formType.id, label: formType.name };
          if (option.label === label) acc.selectValue = option;
          acc.selectOptions.push(option);
          return acc;
        },
        { selectValue: null, selectOptions: [] }
      ),
    [formTypes, label]
  );

  return (
    <FormReactSelect {...props} labelKey="Form Type" value={selectValue} options={selectOptions} isSearchable={false} />
  );
};

export default SelectFormFormsTypes;
