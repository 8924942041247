import { handleActions } from 'redux-actions';
import {
  fetchAssetModelsAction,
  fetchAllAssetModelsAction,
  createAssetModelAction,
  updateAssetModelAction,
  deleteAssetModelAction,
  patchAssetModelAction,
} from './actions';

const initialState: AssetModels.Root = {
  count: 0,
  itemsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchAssetModelsAction]: {
    next: (
      state: AssetModels.Root,
      action: Shared.ReduxAction<Pick<AssetModels.Root, 'count' | 'itemsHash'> & { skipStoreUpdate: true | undefined }>
    ): AssetModels.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchAssetModelsAction,
  fetchAllAssetModelsAction,
  createAssetModelAction,
  updateAssetModelAction,
  deleteAssetModelAction,
  patchAssetModelAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
