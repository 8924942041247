import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { customersConnectedCountSelector, customersConnectedSelector } from 'modules/dataQuality/selectors';
import { fetchCustomersConnectedAction } from 'modules/dataQuality';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableCustomersConnected: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { numberFormat } = useLocale();

  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const items = useSelector(customersConnectedSelector);
  const count = useSelector(customersConnectedCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      return dispatch(fetchCustomersConnectedAction({ ...params, portfolioId, versionId })).then(
        (action: Shared.ReduxAction<{ customersConnected: DataQuality.Root['customersConnected'] }>) =>
          action.payload?.customersConnected || []
      );
    },
    [dispatch, portfolioId, versionId]
  );

  return (
    <DataTable
      paginationType={PaginationType.DATA_QUALITY_CUSTOMERS_CONNECTED}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId || !versionId}
      triggerTableUpdateDeps={[portfolioId, versionId]}
      maxHeight="calc(100vh - 220px)"
    >
      {items?.map((item: DataQuality.CustomersConnected) => (
        <tr key={item.index}>
          <td>{item.station || '-'}</td>
          <td>{numberFormat(item.installations_number)}</td>
          <td>{item.installations_connected_pc ?? '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCustomersConnected;
