import React from 'react';
import { ViewCustomers } from 'components/Customers';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { PermissionsTypes } from 'constants/index';

const PageCustomers: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isCustomersEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <ViewCustomers />
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageCustomers;
