import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale, usePaginationHandlers } from 'hooks';
import { rightsSelector, rightsCountSelector } from 'modules/customers/selectors';
import { fetchRightsAction } from 'modules/customers';
import { setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, SolvedOption, Button } from 'components/_common';
import { PaginationType, TableHeaders, TableHeadersKeys } from 'constants/index';

const TableRights: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.CUSTOMERS_RIGHTS;
  const { columns, filters } = usePaginationHandlers({ type });
  const isEVorPV = ['ev', 'pv'].includes(filters.existingDersId);

  const Keys = useMemo(
    () =>
      Object.keys(TableHeadersKeys[type]!).reduce((acc: any, key: any) => {
        if (isEVorPV || ![TableHeadersKeys[type].gis_kw, TableHeadersKeys[type].ai_kw].includes(key)) {
          acc[key] = (TableHeadersKeys[type] as any)![key];
        }
        return acc;
      }, {}),
    [type, isEVorPV]
  );

  const items = useSelector(rightsSelector);
  const count = useSelector(rightsCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchRightsAction(params)).then(
        (action: Shared.ReduxAction<{ rights: Customers.Root['rights'] }>) => action.payload?.rights || []
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const installationsID: string | null = event.currentTarget.getAttribute('data-id');
      if (!installationsID) return;
      dispatch(
        setLayoutAction({
          customersRightsProfileModalId: installationsID,
          // CustomerRightsProfileModal -> AssetTimeSeriesChart must default to the current year
          selectedChartYear: new Date().getFullYear(),
        })
      );
    },
    [dispatch]
  );

  const customHeaders = useMemo(
    () =>
      TableHeaders[type].reduce((acc: Type.DataTableHeader[], header) => {
        acc.push({
          ...header,
          hidden:
            !isEVorPV && (header.key === TableHeadersKeys[type].gis_kw || header.key === TableHeadersKeys[type].ai_kw),
        });
        return acc;
      }, []),
    [type, isEVorPV]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      maxHeight="calc(100vh - 261px)"
      isSettingsButtonEnabled
      customHeaders={customHeaders}
    >
      {(items || []).map((item: Customers.Rights) => (
        <tr key={item.id}>
          {columns?.includes(Keys.installation_number) && <td>{item.installation_number || '-'}</td>}
          {columns?.includes(Keys.meter_number) && <td>{item.meter_number || '-'}</td>}
          {columns?.includes(Keys.metering_point) && <td>{item.metering_point || '-'}</td>}
          {columns?.includes(Keys.address) && <td>{item.address || '-'}</td>}
          {columns?.includes(Keys.substation) && <td>{item.substation || '-'}</td>}
          {columns?.includes(Keys.ignore) && (
            <td className="text-center">
              <SolvedOption uuid={item.id} checked={item.ignore} paginationType={type} />
            </td>
          )}
          {columns?.includes(Keys.explanation) && <td>{item.explanation || '-'}</td>}
          {columns?.includes(Keys.amps_rights) && <td>{numberFormat(item.amps_rights, { fallback: '-' })}</td>}
          {columns?.includes(Keys.amps) && <td>{numberFormat(item.amps, { fallback: '-' })}</td>}
          {columns?.includes(Keys.kva) && <td>{numberFormat(item.kva, { fallback: '-' })}</td>}
          {columns?.includes(Keys.time) && <td>{item.time ? dateFormat(item.time, 'lll') : '-'}</td>}
          {columns?.includes(Keys.utilization) && <td>{numberFormat(item.utilization, { fallback: '-' })}</td>}
          {columns?.includes(Keys.gis_kw) && <td>{numberFormat(item.gis_kw, { fallback: '-' })}</td>}
          {columns?.includes(Keys.ai_kw) && <td>{numberFormat(item.ai_kw, { fallback: '-' })}</td>}
          <td className="text-right">
            <Button
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableRights;
