import React, { useMemo, useEffect } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { fetchFutureChartData } from 'modules/networkLoading';
import { futureChartDataSelectorFactory } from 'modules/networkLoading/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import Chart, { getBaseOptions } from 'components/_charts/Chart';

interface Props {
  asset_class: 'Transformer' | 'Cable' | 'Cabinet' | 'Fuse';
  type: Type.PaginationType;
  index: 0 | 1;
  dataMarker: string;
  skipVoltage?: boolean;
}

const ChartFuture: React.FC<Props> = ({ asset_class, type, index, dataMarker = '', skipVoltage = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const storeKey = `${asset_class}_${filters?.voltage}_${portfolioId}_${scenarioId}_${simulationId}_${versionId}_${filters?.flex}`;
  const futureChartDataSelector = useMemo(() => futureChartDataSelectorFactory(storeKey, index), [storeKey, index]);
  const data = useSelector(futureChartDataSelector);

  useEffect(() => {
    if ((!skipVoltage && !filters?.voltage) || !portfolioId || !scenarioId || !simulationId || !versionId || data) {
      return;
    }

    dispatch(
      fetchFutureChartData({
        asset_class,
        voltage: skipVoltage ? null : filters?.voltage,
        flex: filters?.flex,
        portfolioId,
        scenarioId,
        simulationId,
        versionId,
        storeKey,
      })
    );
  }, [
    dispatch,
    asset_class,
    skipVoltage,
    filters?.voltage,
    filters?.flex,
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    data,
    storeKey,
  ]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, {
      title: data?.title,
      xAxisTitle: data?.xAxisTitle,
      yAxisTitle: data?.yAxisTitle,
    });
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
      },
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `<b>${getIntl(data?.yAxisTitle || '')}:</b> ${this.y}`;
        },
      },
      series: data?.series?.map((s: any) => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: { enabled: false, useHTML: true },
    };
  }, [data, getIntl]) as unknown as Highcharts.Options;

  return <Chart key={storeKey} options={data?.series ? options : null} dataMarker={dataMarker} />;
};

export default ChartFuture;
