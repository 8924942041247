import styled from 'styled-components';
import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import { HighchartsReact } from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { useLocale } from 'hooks';
import { completeActionsChartDataSelector } from 'modules/statistics/selectors';
import { getIntlExporting } from 'components/_charts/Chart';
import { Spinner } from 'components/_common';

if (typeof exporting === 'function') exporting(Highcharts);

interface Props {
  height: string;
  className?: string;
}

const ChartStatisticsCompleteActions: React.FC<Props> = ({ className }) => {
  const { getIntl } = useLocale();
  const chartData = useSelector(completeActionsChartDataSelector);

  const options = useMemo(
    () => ({
      chart: {
        type: 'column',
      },
      title: {
        text: getIntl('Completed forms (actions) per asset type'),
      },
      subtitle: {
        text: `<h6>${getIntl('new meter')} - ${chartData?.smartMeterActions.new}, ${getIntl('replaced meter')} - ${
          chartData?.smartMeterActions.replaced
        }, ${getIntl('deleted meter')} - ${chartData?.smartMeterActions.removed}</h6>`,
        useHTML: true,
      },
      xAxis: {
        categories: chartData?.categories,
        crosshair: true,
      },
      yAxis: { title: { text: '' } },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: chartData?.series.map((item: Statistics.CompleteActionsChartSeries) => ({
        ...item,
        name: `${getIntl(item.name)} (${item.data.reduce((acc: number, i) => acc + i, 0)})`,
      })),

      ...getIntlExporting(getIntl),
    }),
    [getIntl, chartData]
  );

  return !chartData ? (
    <div className={className}>
      <Spinner isInFullHeightContainer />
    </div>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ className, 'data-marker': 'chart_maintenance_statistics_complete_actions' }}
    />
  );
};

const StyledChartStatisticsCompleteActions = styled(ChartStatisticsCompleteActions)`
  ${({ height }) => `height: ${height};`};
  border: 1px solid ${props => props.theme.colors.grey100};

  .highcharts-credits {
    display: none;
  }
`;

export default StyledChartStatisticsCompleteActions;
