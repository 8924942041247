import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { SmartMeterActionBadge } from 'components/_common';

const SmartMetersActionMeta: React.FC<{ item: Meters.SmartMeterAction }> = ({ item }) => {
  const { getIntl, dateFormat } = useLocale();

  const metaData = useMemo(
    () => [
      { labelKey: 'Case', value: item.id || '-' },
      { labelKey: 'Activity', value: <SmartMeterActionBadge action={item.action} /> },

      { labelKey: 'Old reading', value: item.oldReadingKwh || '-' },
      { labelKey: 'Old meter action', value: item.oldMeterAction || '-' },
      { labelKey: 'Old meter type', value: item.oldMaalertype || '-' }, //
      { labelKey: 'Old meter number', value: item.oldMaalernr || '-' },
      { labelKey: 'New reading', value: item.newReadingKwh || '-' },
      { labelKey: 'New meter type', value: getIntl(item.newMeterType || '-') },
      { labelKey: 'New meter number', value: item.newMaalernr || '-' },
      { labelKey: 'Actual meter type', value: getIntl(item.maalertype || '-') },
      { labelKey: 'Actual meter number', value: getIntl(item.maalernr || '-') }, //

      { labelKey: 'Installation number', value: item.installationNumber },
      { labelKey: 'Installation Class', value: item.installationClass || '-' },
      { labelKey: 'Serial number', value: getIntl(item.serialNumber || '-') }, //
      { labelKey: 'Batch number', value: getIntl(item.batchNumber || '-') }, //

      { labelKey: 'Logging date', value: item.timestamp ? dateFormat(item.timestamp) : '-' },
      {
        labelKey: 'Date activated',
        value: item.dateActivated ? dateFormat(item.dateActivated) : '-',
      }, //
      { labelKey: 'Date removed', value: item.dateRemoved ? dateFormat(item.dateRemoved) : '-' }, //
      { labelKey: 'Date disposed', value: item.dateDisposed ? dateFormat(item.dateDisposed) : '-' }, //
      { labelKey: 'Last updated', value: item.lastUpdated ? dateFormat(item.lastUpdated) : '-' }, //
      {
        labelKey: 'Date of work',
        value: item.actionPerformed ? dateFormat(item.actionPerformed) : '-',
      },

      { labelKey: 'User', value: item.user || '-' },
      { labelKey: 'Address', value: item.adresse || '-' },
      { labelKey: 'Metering point', value: item.meteringPoint || '-' },
      { labelKey: 'Supplied by', value: item.stationName || '-' },
      { labelKey: 'Production', value: item.production || '-' },
      { labelKey: 'Reason', value: getIntl(item.reason?.replace('Defective', 'Defect') || '-') },
      { labelKey: 'Ratio', value: item.ratio || '-' },
      { labelKey: 'Longitude', value: item.loc?.x || '-' },
      { labelKey: 'Latitude', value: item.loc?.y || '-' },
    ],
    [item, getIntl, dateFormat]
  );

  return (
    <dl className="row">
      {metaData.map(i => (
        <React.Fragment key={i.labelKey}>
          <dd className="col-sm-5">{getIntl(i.labelKey)}:</dd>
          <dd className="col-sm-7">{i.value}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

export default SmartMetersActionMeta;
