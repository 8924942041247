import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { AddCustomerTypes } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: NewLoad.MapConnection['customer_type'];
}

const SelectCustomerType: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();

  const options = useMemo(
    () => [
      { value: AddCustomerTypes.STANDARD_PROFILE, label: getIntl('Standard profile') },
      { value: AddCustomerTypes.CUSTOM_PROFILE, label: getIntl('Custom profile') },
      { value: AddCustomerTypes.BASE_POWER, label: getIntl('Base power') },
    ],
    [getIntl]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey="Customer type"
      value={value ? options.find(o => o.value === value) || null : null}
      options={options}
    />
  );
};

export default SelectCustomerType;
