import moment from 'moment-timezone';
import { handleActions } from 'redux-actions';
import {
  setPaginationAction,
  setModalConfirmationMetaAction,
  toggleSidebarAction,
  closeSidebarAction,
  setErrorToastAction,
  setSuccessToastAction,
  setInfoToastAction,
  deleteToastAction,
  setLayoutAction,
  setFeatureToggleAction,
} from './actions';
import { getSyncedFilters } from './helpers';
import { _merge, _cloneDeep } from '@utiligize/shared/utils';
import { getStorageItem, setStorageItem } from 'utils';
import {
  AssetCategories,
  TransformerVoltages,
  CableVoltages,
  PaginationType,
  MaxMobileWidth,
  StorageKeys,
  SelectSolvedValues,
  SelectCustomersRightsLoadTypes,
  SelectCustomersRightsMissingTypes,
  TaskStatus,
  TasksRegisteredHoursSourceTypes,
  MapTheme,
  VoltageSide,
  VoltageDisplay,
  BICalculations,
  SelectIssuesStatusValues,
  SelectVoltageUnits,
  SingleLineDiagramTypes,
  MaintenanceAverageTimeSpentByAssetCategories,
  TableHeadersKeys,
  isProduction,
  Currencies,
  SelectLoadingUnitValues,
  TableHeadersKeysInterface,
  SelectMinMaxValues,
} from 'constants/index';
import { InvestmentSelectYearRange } from 'components/Investment/utils';

const basePagination = { limit: 100, offset: 0, sort: 'DESC', column: 'createdAt', query: '' };

// Force local cache automatic clean if necessary
const currentVersion = 20251704;
const cachedVersion = getStorageItem(StorageKeys.PAGINATION_CACHE_VERSION);
if (Number(cachedVersion) !== currentVersion) {
  setStorageItem({ [StorageKeys.PAGINATION]: null });
  setStorageItem({ [StorageKeys.PAGINATION_CACHE_VERSION]: currentVersion });
}

export const initPagination: { [key in PaginationType]: Layouts.Pagination } = {
  [PaginationType.FORMS]: { ...basePagination },
  [PaginationType.MANUALS]: { ...basePagination, filters: { assetCode: '' } },
  [PaginationType.ANSWERS]: { ...basePagination, filters: { assetCode: '' } },
  [PaginationType.HISTORY]: { ...basePagination, filters: { assetCode: '' } },
  [PaginationType.HISTORY_CHECKLISTS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'name',
  },
  [PaginationType.INSTRUCTIONS]: { ...basePagination },
  [PaginationType.TASKS_MOBILE_SUBMITTED]: {
    ...basePagination,
    column: 'name',
    sort: 'ASC',
    filters: { type: null, year: null, appAction: null },
  },
  [PaginationType.UPCOMING_TASKS]: {
    ...basePagination,
    column: 'responsibleUsers',
    sort: 'ASC',
    filters: { type: null, year: null, appAction: null },
  },
  [PaginationType.TASKS]: {
    ...basePagination,
    column: 'responsibleUsers',
    sort: 'ASC',
    limit: 25,
    filters: { type: null, status: null, year: null, userIds: null, appAction: null },
  },
  [PaginationType.TASKS_FINISHED]: {
    ...basePagination,
    column: 'finishDate',
    limit: 25,
    filters: { type: null, year: null, userIds: null, appAction: null },
  },
  [PaginationType.NEW_TASKS]: { ...basePagination, column: 'date' },
  [PaginationType.TASKS_COMPLAINTS]: { ...basePagination, column: 'createdAt' },
  [PaginationType.ASSET_MODELS]: {
    ...basePagination,
    limit: 25,
    column: 'replacementPriority',
    sort: 'ASC',
    filters: { assetcategoryCode: AssetCategories.KS, subComponentTypeId: 6 }, // 6 - equal to 'Default' value for NK.
  },
  [PaginationType.INSPECTIONS]: { ...basePagination },
  [PaginationType.MANUFACTURERS]: { ...basePagination },
  [PaginationType.SMART_METER_ACTIONS]: { ...basePagination, column: 'timestamp' },
  [PaginationType.EMPLOYEES]: {
    ...basePagination,
    sort: 'ASC',
    column: 'fullName',
    filters: {
      year: new Date().getFullYear(),
      electricianIds: null,
      type: null,
      status: [TaskStatus.NotStarted, TaskStatus.Ongoing],
    },
  },
  [PaginationType.ADMIN_ACCESS_LOGS]: {
    ...basePagination,
    column: 'lastAccess',
    filters: { startTime: moment().subtract(6, 'days'), endTime: moment() },
  },
  [PaginationType.REPEATED_TASKS_SECTIONS]: { ...basePagination, sort: 'ASC', column: 'name' },
  [PaginationType.ISSUES]: {
    ...basePagination,
    sort: 'ASC',
    column: 'code',
    filters: { dataQualityIssuesStatus: SelectIssuesStatusValues.open, voltageLevels: null },
  },
  [PaginationType.TRANSFORMERS]: {
    ...basePagination,
    column: 'utilization',
    filters: {
      solvedValue: SelectSolvedValues.False,
      voltage: TransformerVoltages['10/0.4kV transformer'],
      year: null,
      voltageSide: VoltageSide.Primary,
      voltageDisplay: VoltageDisplay['Per-unit'],
      BICalculation: BICalculations.Off,
      percentile: null,
      flex: false,
      showOverloadedAssets: false,
    },
    meta: {
      year: null,
      chartType: 'line',
    },
    columns: [
      TableHeadersKeys[PaginationType.TRANSFORMERS].customer_asset_name,
      TableHeadersKeys[PaginationType.TRANSFORMERS].date_commissioned,
      TableHeadersKeys[PaginationType.TRANSFORMERS].kva,
      TableHeadersKeys[PaginationType.TRANSFORMERS].ignore,
      TableHeadersKeys[PaginationType.TRANSFORMERS].explanation,
      TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading,
      TableHeadersKeys[PaginationType.TRANSFORMERS].max_reactive,
      TableHeadersKeys[PaginationType.TRANSFORMERS].grid_losses,
      TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading_time,
      TableHeadersKeys[PaginationType.TRANSFORMERS].utilization,
      TableHeadersKeys[PaginationType.TRANSFORMERS].annual_consumption,
      TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_min,
      TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_max,
    ],
  },
  [PaginationType.TRANSFORMERS_IGNORE_MESSAGES]: { ...basePagination, column: 'time', limit: 10 },
  [PaginationType.CABLES]: {
    ...basePagination,
    column: 'utilization',
    filters: {
      voltage: CableVoltages['0,4kV'],
      solvedValue: SelectSolvedValues.False,
      year: null,
      voltageDisplay: VoltageDisplay['Per-unit'],
      percentile: null,
      flex: false,
      showOverloadedAssets: false,
      show_current_values: SelectLoadingUnitValues.True,
    },
    meta: {
      year: null,
    },
    columns: [
      TableHeadersKeys[PaginationType.CABLES].customer_asset_name,
      TableHeadersKeys[PaginationType.CABLES].type,
      TableHeadersKeys[PaginationType.CABLES].distribution_feeder,
      TableHeadersKeys[PaginationType.CABLES].length,
      TableHeadersKeys[PaginationType.CABLES].date_commissioned,
      TableHeadersKeys[PaginationType.CABLES].max_capacity,
      TableHeadersKeys[PaginationType.CABLES].ignore,
      TableHeadersKeys[PaginationType.CABLES].explanation,
      TableHeadersKeys[PaginationType.CABLES].max_loading,
      TableHeadersKeys[PaginationType.CABLES].max_reactive,
      TableHeadersKeys[PaginationType.CABLES].grid_losses,
      TableHeadersKeys[PaginationType.CABLES].max_loading_time,
      TableHeadersKeys[PaginationType.CABLES].utilization,
      TableHeadersKeys[PaginationType.CABLES].annual_consumption,
      TableHeadersKeys[PaginationType.CABLES].voltage_min,
      TableHeadersKeys[PaginationType.CABLES].voltage_max,
    ],
  },
  [PaginationType.NETWORK_LOADING_CABINETS]: {
    ...basePagination,
    column: 'utilization',
    filters: {
      year: null,
      percentile: null,
      solvedValue: SelectSolvedValues.False,
      flex: false,
      showOverloadedAssets: false,
    },
    meta: {
      year: null,
    },
    columns: [
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].customer_asset_name,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].date_commissioned,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ignore,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].explanation,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].max_loading_time,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].utilization,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_min,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_max,
    ],
  },
  [PaginationType.NETWORK_LOADING_FUSES]: {
    ...basePagination,
    column: 'utilization',
    filters: {
      year: null,
      voltage: CableVoltages['0,4kV'],
      percentile: null,
      solvedValue: SelectSolvedValues.False,
      flex: false,
    },
    meta: {
      year: null,
    },
    columns: [
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].customer_asset_name,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].date_commissioned,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].amp_rating,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].ignore,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].explanation,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_amps,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_loading_time,
      TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].utilization,
    ],
  },
  [PaginationType.DATA_QUALITY_MISSING_AGE]: {
    ...basePagination,
    sort: 'ASC',
    column: 'customer_asset_name',
    filters: { cnaim_id: null },
  },
  [PaginationType.COMPONENT_PRICES]: {
    ...basePagination,
    sort: 'ASC',
    column: 'price',
    filters: {
      componentPriceScenarioId: null,
      priceTypeId: null,
    },
  },
  [PaginationType.WORK_PRICES]: {
    ...basePagination,
    column: 'price',
    filters: {
      componentPriceScenarioId: null,
    },
  },
  [PaginationType.DETAILED_INVESTMENTS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'asset_name',
    filters: {
      primarySubstationsIds: null,
      gridZones: null,
      voltageLevels: null,
      assetCategories: null,
      replacementReasons: null,
      startYear: InvestmentSelectYearRange[0],
      endYear: InvestmentSelectYearRange[InvestmentSelectYearRange.length - 1],
      flex: false,
    },
    columns: [
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_name,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_category,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].installation_year,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_capacity,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_capacity,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_reason,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_cost,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].suggested_replacement_year,
      TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].co2e_t_per_year,
    ],
  },

  [PaginationType.SUMMARY_PLAN]: {
    ...basePagination,
    sort: 'ASC',
    column: 'suggested_replacement_year',
    filters: {
      assetCategories: null,
      replacementReasons: null,
      gridZones: null,
      voltageLevels: null,
      primarySubstationsIds: null,
      summaryGroupIds: null,
      startYear: InvestmentSelectYearRange[0],
      endYear: InvestmentSelectYearRange[InvestmentSelectYearRange.length - 1],
      flex: false,
    },
  },
  [PaginationType.TASKS_CHECKLISTS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'name',
  },
  [PaginationType.TASKS_MATERIALS]: {
    ...basePagination,
    column: 'createdAt',
  },
  [PaginationType.NK_TASKS_MATERIALS]: { ...basePagination },
  [PaginationType.METERS_MISSING_IN_MDM]: {
    ...basePagination,
    sort: 'ASC',
    column: 'installation_number',
  },
  [PaginationType.METERS_MISSING_IN_GIS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'last_measurement',
  },
  [PaginationType.METERS_POPULATION]: {
    ...basePagination,
    sort: 'ASC',
    column: 'meterNumber',
  },
  [PaginationType.MAINTENANCE_CREATE_PLANS]: {
    ...basePagination,
    column: 'planName',
  },
  [PaginationType.MAINTENANCE_SUMMARY]: {
    ...basePagination,
    column: 'maintenanceYear',
    sort: 'ASC',
    filters: {
      year: new Date().getFullYear(),
    },
  },
  [PaginationType.MAINTENANCE_PLAN]: {
    ...basePagination,
    column: 'name',
    sort: 'ASC',
    filters: {
      year: new Date().getFullYear(),
      assetcategoryCode: AssetCategories.KS,
    },
  },
  [PaginationType.TOTAL_HOURS]: {
    ...basePagination,
    filters: {
      year: new Date().getFullYear(),
      type: null,
      appAction: null,
    },
  },
  [PaginationType.MAINTENANCE_STATISTICS_USERS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'fullName',
    filters: { startTime: moment().subtract(6, 'days'), endTime: moment() },
  },
  [PaginationType.PERMISSIONS_GROUPS]: { ...basePagination, column: 'name' },
  [PaginationType.PERMISSIONS_USERS]: { ...basePagination, column: 'fullName' },
  [PaginationType.CUSTOMERS_RIGHTS]: {
    ...basePagination,
    column: 'utilization',
    filters: {
      percentile: 99.9,
      loadType: SelectCustomersRightsLoadTypes.BOTH,
      missingType: SelectCustomersRightsMissingTypes.ALL,
      existingDersId: null,
      detectionType: null,
      solvedValue: SelectSolvedValues.False,
      outliersRemoved: true,
    },
    columns: [
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].installation_number,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].meter_number,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].metering_point,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].address,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].substation,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].ignore,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].explanation,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps_rights,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].kva,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].time,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].utilization,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].gis_kw,
      TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].ai_kw,
    ],
  },
  [PaginationType.ASSET_TASK_COMPLETIONS]: { ...basePagination },
  [PaginationType.USERS_REGISTERED_HOURS]: {
    ...basePagination,
    column: 'fullName',
    sort: 'ASC',
    filters: {
      startTime: moment().subtract(6, 'days'),
      endTime: moment(),
      source: TasksRegisteredHoursSourceTypes.INTERNAL,
      userIds: null,
      taskId: null,
    },
  },
  [PaginationType.TASKS_REGISTERED_HOURS]: {
    ...basePagination,
    column: 'name',
    sort: 'ASC',
    filters: {
      startTime: moment().subtract(6, 'days'),
      endTime: moment(),
      source: TasksRegisteredHoursSourceTypes.INTERNAL,
      userIds: null,
    },
  },
  [PaginationType.FORECASTED_SCENARIOS]: { ...basePagination, column: 'updated_at' },
  [PaginationType.NETWORK_LOADING_CUSTOMERS_TYPES]: { ...basePagination },
  [PaginationType.REPEATED_TASKS]: { ...basePagination },
  [PaginationType.NEW_LOAD_DERS]: { ...basePagination, filters: { year: null } },
  [PaginationType.NEW_LOAD_DERS_CATEGORIES]: { ...basePagination },
  [PaginationType.NEW_LOAD_DERS_PROFILES]: { ...basePagination },
  [PaginationType.NEW_LOAD_DERS_PHASE_IN]: { ...basePagination },
  [PaginationType.NEW_LOAD_CONNECTION_MANAGER]: { ...basePagination, column: 'connection_year', sort: 'ASC' },
  [PaginationType.SETUP_RAW_DATA]: { ...basePagination },
  [PaginationType.NETWORK_LOADING_VOLTAGE_DROP]: {
    ...basePagination,
    filters: {
      primarySubstationsId: null,
      year: null,
      flex: false,
      min_or_max: true,
      investment: false,
      percentile: null,
    },
  },
  [PaginationType.PERMISSIONS_TENANTS]: {
    ...basePagination,
    column: 'tenantName',
    sort: 'ASC',
  },
  [PaginationType.PERMISSIONS_FEATURES]: {
    ...basePagination,
  },
  [PaginationType.MAINTENANCE_PLAN_CNAIM]: {
    ...basePagination,
    column: 'customer_id',
    sort: 'ASC',
    filters: {
      reinvestmentAssetCategory: '10_04_trafo',
      year: new Date().getFullYear(),
    },
  },
  [PaginationType.UNIFIED_ASSET_VIEW_TASKS]: {
    ...basePagination,
    column: 'name',
    sort: 'ASC',
  },
  [PaginationType.N1]: {
    ...basePagination,
    column: 'outage_asset',
    sort: 'ASC',
    filters: {
      outageType: '',
      route: '',
      primarySubstationsIds: null,
    },
  },
  [PaginationType.INVESTMENT_SCENARIOS]: { ...basePagination, column: 'updated_at' },
  [PaginationType.SETUP_SHORT_CIRCUIT]: {
    ...basePagination,
    column: 'short_name',
    sort: 'ASC',
    filters: { min_max: SelectMinMaxValues.Min },
  },
  [PaginationType.DATA_QUALITY_CUSTOMERS_CONNECTED]: { ...basePagination, column: 'station' },
  [PaginationType.DATA_QUALITY_MISSING_GEOMETRY]: { ...basePagination, column: 'customer_asset_name' },
  [PaginationType.COMPONENT_PRICE_SCENARIOS]: {
    ...basePagination,
    column: 'created_at',
    filters: {
      componentPriceScenarioId: null,
    },
  },
  [PaginationType.INVESTMENT_LIMITATIONS]: {
    ...basePagination,
    filters: {
      voltageUnit: SelectVoltageUnits.False,
    },
  },
  [PaginationType.SETUP_INVESTMENT_BUDGET]: {
    ...basePagination,
    column: 'price',
    filters: {
      componentPriceScenarioId: null,
    },
  },
  [PaginationType.SETUP_SCENARIO_CALCULATION]: { ...basePagination },
  [PaginationType.SETUP_CO2E]: { ...basePagination },
  [PaginationType.ASSETS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'name',
    filters: { assetcategoryCode: AssetCategories.BD },
  },
  [PaginationType.SUB_COMPONENTS]: {
    ...basePagination,
    sort: 'ASC',
    column: 'name',
    filters: { assetcategoryCode: AssetCategories.BD },
  },
};

const cachedPagination: any = getStorageItem(StorageKeys.PAGINATION);
const pagination = _merge(_cloneDeep(initPagination), cachedPagination);

[
  PaginationType.ADMIN_ACCESS_LOGS,
  PaginationType.MAINTENANCE_STATISTICS_USERS,
  PaginationType.USERS_REGISTERED_HOURS,
  PaginationType.TASKS_REGISTERED_HOURS,
].forEach((key: string) => {
  pagination[key].filters = {
    ...pagination[key].filters,
    startTime: moment(pagination[key].filters.startTime),
    endTime: moment(pagination[key].filters.endTime),
  };
});

const PaginationColumnsKeys: (keyof Required<TableHeadersKeysInterface>)[] = [
  PaginationType.DETAILED_INVESTMENTS,
  PaginationType.TRANSFORMERS,
  PaginationType.CABLES,
  PaginationType.NETWORK_LOADING_CABINETS,
  PaginationType.NETWORK_LOADING_FUSES,
  PaginationType.CUSTOMERS_RIGHTS,
];

PaginationColumnsKeys.forEach(key => {
  pagination[key].columns = cachedPagination?.[key]?.columns?.length
    ? cachedPagination?.[key]?.columns
    : pagination[key].columns;
});

const initialState: Layouts.Root = {
  pagination,
  modalConfirmationMeta: null,
  isSidebarOpen: window.innerWidth > MaxMobileWidth,
  formsModalId: null,
  manualsModalId: null,
  taskModalMeta: null,
  toasts: [],
  assetModelsModalId: null,
  assetSubComponentModalId: null,
  employeeModalId: null,
  repeatedTasksSectionModalId: null,
  repeatedTaskModalId: null,
  customersRightsProfileModalId: null,
  profileModalMeta: null,
  tasksChecklistsModalId: null,
  tasksMaterialsModalId: null,
  maintenancePlansModalId: null,
  metersPopulationModalId: null,
  permissionsGroupsModalId: null,
  permissionsUsersModalId: null,
  permissionsTenantsModalId: null,
  permissionsFeaturesModalId: null,
  portfolioId: getStorageItem(StorageKeys.PORTFOLIO_ID) as number,
  scenarioIdHash: getStorageItem(StorageKeys.SCENARIO_ID_HASH) || {},
  simulationId: null,
  investmentScenarioId: getStorageItem(StorageKeys.INVESTMENT_SCENARIO_ID) as number,
  DERModalId: null,
  overviewScenarioModalMeta: null,
  investmentScenarioModalMeta: null,
  componentPriceScenarioModalMeta: null,
  investmentComponentPriceAssetModalMeta: null,
  newLoadDERsCategoriesModalId: null,
  newLoadDERProfileModalId: null,
  newLoadEditIndividualCustomerModalId: null,
  selectedMapTheme: getStorageItem(StorageKeys.SELECTED_MAP_THEME) || MapTheme.Standard,
  muteErrorToastAction:
    // Don't mute error notifications during local development
    getStorageItem(StorageKeys.MUTE_ERROR_TOAST_ACTION) ?? isProduction,
  singleDiagramEnabled: false,
  singleDiagramType: SingleLineDiagramTypes.Tree,
  isSingleDiagramServiceAssets: false,
  isSingleDiagramAssetsWithoutGeometry: false,
  singleDiagramFilterPrimarySubstationsId: null,
  singleDiagramFilterVoltagesIds: null,
  mapPanelEnabled: getStorageItem(StorageKeys.MAP_PANEL_ENABLED) || true,
  selectedChartYear: Number(getStorageItem(StorageKeys.SELECTED_CHART_YEAR) || new Date().getFullYear()),
  selectedChartFlex: getStorageItem(StorageKeys.SELECTED_CHART_FLEX) || false,
  selectedChartAbsolute: getStorageItem(StorageKeys.SELECTED_CHART_ABSOLUTE) || false,
  investmentWorkPriceModalMeta: null,
  createUserOneTimePassword: null,
  isCO2eCumulativeOverview: getStorageItem(StorageKeys.CO2E_CUMULATIVE_OVERVIEW) || false,
  CO2eScenariosYear: getStorageItem(StorageKeys.CO2E_SCENARIO_YEAR) || new Date().getFullYear(),
  maintenanceAverageTimeSpentByAssetCategory:
    getStorageItem(StorageKeys.MAINTENANCE_AVERAGE_TIME_SPENT_BY_ASSET_CATEGORY) ||
    MaintenanceAverageTimeSpentByAssetCategories.Transformers,
  maintenanceTimeByActionTypeAssetCategories:
    getStorageItem(StorageKeys.MAINTENANCE_TIME_BY_ACTION_TYPE_ASSET_CATEGORIES) || [],
  tableSettingsModal: null,
  mapDrawAssetFeatureMeta: null,
  releaseNotificationModalVersion: Number(getStorageItem(StorageKeys.RELEASE_NOTIFICATION_MODAL_VERSION)),
  isExtendedGridEnabled: false,
  isMapConnectionsEnabled: false,
  mapAddConnectionMeta: null,
  taskDetailsModalId: null,
  isMapConfigFetched: false,
  selectedCurrency: Currencies.DKK,
  historyChecklistsModalId: null,

  featureToggles: {
    [StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM]:
      getStorageItem(StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM) || false,
    [StorageKeys.FEATURE_TOGGLE_WORK_PRICES]: getStorageItem(StorageKeys.FEATURE_TOGGLE_WORK_PRICES) || false,
    [StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP]: getStorageItem(StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP) || false,
    [StorageKeys.FEATURE_TOGGLE_FEEDERS_ANIMATION]:
      getStorageItem(StorageKeys.FEATURE_TOGGLE_FEEDERS_ANIMATION) || false,
    [StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS]: getStorageItem(StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS) || false,
    [StorageKeys.FEATURE_GIS_LAYERS]: getStorageItem(StorageKeys.FEATURE_GIS_LAYERS) || false,
    [StorageKeys.SINGLE_LINE_CONTROLS]: getStorageItem(StorageKeys.SINGLE_LINE_CONTROLS) || false,
    [StorageKeys.FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION]:
      getStorageItem(StorageKeys.FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION) || false,
    [StorageKeys.FEATURE_CURRENCIES]: getStorageItem(StorageKeys.FEATURE_CURRENCIES) || false,
    [StorageKeys.FEATURE_MAP_DER_AI_THEMES]: getStorageItem(StorageKeys.FEATURE_MAP_DER_AI_THEMES) || false,
  },
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [setPaginationAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<{ type: Type.PaginationType; modifier: Partial<Layouts.Pagination> }>
  ): Layouts.Root => {
    const pagination = {
      ...state.pagination,
      [action.payload.type]: { ...state.pagination[action.payload.type], ...action.payload.modifier },

      // Combined SUMMARY_PLAN and DETAILED_INVESTMENTS filters
      ...getSyncedFilters({
        types: [PaginationType.SUMMARY_PLAN, PaginationType.DETAILED_INVESTMENTS],
        action,
        state,
      }),
    };
    setStorageItem({ [StorageKeys.PAGINATION]: pagination });
    return { ...state, pagination };
  },

  [setModalConfirmationMetaAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<Shared.ModalConfirmationMeta>
  ): Layouts.Root => ({
    ...state,
    modalConfirmationMeta: action.payload && {
      ...action.payload,
    },
  }),

  [toggleSidebarAction.toString()]: (state: Layouts.Root): Layouts.Root => ({
    ...state,
    isSidebarOpen: !state.isSidebarOpen,
  }),

  [closeSidebarAction.toString()]: (state: Layouts.Root): Layouts.Root => ({
    ...state,
    isSidebarOpen: false,
  }),

  [setErrorToastAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<{ message: string; isErrorOnlyForModal: boolean }>
  ): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'error', ...action.payload }],
  }),

  [setSuccessToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<string>): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'success', message: action.payload }],
  }),

  [setInfoToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<string>): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'info', message: action.payload }],
  }),

  [deleteToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<number>): Layouts.Root => ({
    ...state,
    toasts: state.toasts.filter(toast => toast.id !== action.payload),
  }),

  [setLayoutAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<Partial<Layouts.Root>>
  ): Layouts.Root => {
    if (action.payload.hasOwnProperty('selectedMapTheme')) {
      setStorageItem({ [StorageKeys.SELECTED_MAP_THEME]: action.payload.selectedMapTheme });
    }
    if (action.payload.hasOwnProperty('muteErrorToastAction')) {
      setStorageItem({ [StorageKeys.MUTE_ERROR_TOAST_ACTION]: action.payload.muteErrorToastAction });
    }
    if (action.payload.hasOwnProperty('mapPanelEnabled')) {
      setStorageItem({ [StorageKeys.MAP_PANEL_ENABLED]: action.payload.mapPanelEnabled });
    }
    if (action.payload.hasOwnProperty('portfolioId')) {
      setStorageItem({ [StorageKeys.PORTFOLIO_ID]: action.payload.portfolioId });
    }
    if (action.payload.hasOwnProperty('scenarioIdHash')) {
      setStorageItem({ [StorageKeys.SCENARIO_ID_HASH]: action.payload.scenarioIdHash });
    }
    if (action.payload.hasOwnProperty('investmentScenarioId')) {
      setStorageItem({ [StorageKeys.INVESTMENT_SCENARIO_ID]: action.payload.investmentScenarioId });
    }
    if (action.payload.hasOwnProperty('selectedChartYear')) {
      setStorageItem({ [StorageKeys.SELECTED_CHART_YEAR]: action.payload.selectedChartYear });
    }
    if (action.payload.hasOwnProperty('selectedChartFlex')) {
      setStorageItem({ [StorageKeys.SELECTED_CHART_FLEX]: action.payload.selectedChartFlex });
    }
    if (action.payload.hasOwnProperty('selectedChartAbsolute')) {
      setStorageItem({ [StorageKeys.SELECTED_CHART_ABSOLUTE]: action.payload.selectedChartAbsolute });
    }
    if (action.payload.hasOwnProperty('isCO2eCumulativeOverview')) {
      setStorageItem({ [StorageKeys.CO2E_CUMULATIVE_OVERVIEW]: action.payload.isCO2eCumulativeOverview });
    }
    if (action.payload.hasOwnProperty('CO2eScenariosYear')) {
      setStorageItem({ [StorageKeys.CO2E_SCENARIO_YEAR]: action.payload.CO2eScenariosYear });
    }
    if (action.payload.hasOwnProperty('maintenanceAverageTimeSpentByAssetCategory')) {
      setStorageItem({
        [StorageKeys.MAINTENANCE_AVERAGE_TIME_SPENT_BY_ASSET_CATEGORY]:
          action.payload.maintenanceAverageTimeSpentByAssetCategory,
      });
    }
    if (action.payload.hasOwnProperty('maintenanceTimeByActionTypeAssetCategories')) {
      setStorageItem({
        [StorageKeys.MAINTENANCE_TIME_BY_ACTION_TYPE_ASSET_CATEGORIES]:
          action.payload.maintenanceTimeByActionTypeAssetCategories,
      });
    }
    if (action.payload.hasOwnProperty('releaseNotificationModalVersion')) {
      setStorageItem({
        [StorageKeys.RELEASE_NOTIFICATION_MODAL_VERSION]: action.payload.releaseNotificationModalVersion,
      });
    }

    return {
      ...state,
      ...action.payload,
    };
  },

  [setFeatureToggleAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<Partial<Layouts.Root['featureToggles']>>
  ): Layouts.Root => {
    setStorageItem(action.payload);
    return {
      ...state,
      featureToggles: {
        ...state.featureToggles,
        ...action.payload,
      },
    };
  },
};

export {
  setPaginationAction,
  setModalConfirmationMetaAction,
  toggleSidebarAction,
  closeSidebarAction,
  setErrorToastAction,
  setSuccessToastAction,
  setInfoToastAction,
  deleteToastAction,
  setLayoutAction,
  setFeatureToggleAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
