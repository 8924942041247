import React, { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FormInput, FormInputProps } from 'components/_common';

interface Props extends Omit<FormInputProps, 'value' | 'onChange'> {
  value: Required<Layouts.Filters>['taskId'];
  onChange: Required<FormInputProps>['onChange'];
}

const TaskIdInput: React.FC<Props> = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState<number | null>(value);

  const debounced = useDebouncedCallback(onChange, 1000);

  useEffect(() => {
    return debounced.cancel;
  }, [debounced.cancel]);

  const handleInputChange = useCallback(
    (event: any) => {
      setInputValue(event.target.value || null);
      debounced(event);
    },
    [debounced]
  );

  return <FormInput {...props} type="number" value={inputValue || ''} onChange={handleInputChange} />;
};

export default TaskIdInput;
