import React from 'react';
import { useLocale } from 'hooks';
import { ProfileModal } from 'components/_modals';
import { Tabs, Tab, ContentContainer, ContentCard } from 'components/_common';
import { Routes } from 'constants/index';
import TableFuses from './TableFuses';
import ChartFusesLoadingDistribution from './ChartFusesLoadingDistribution';
import ChartFuture from './ChartFuture';

interface Props {
  type: Type.PaginationType;
}

const TabsFuses: React.FC<Props> = ({ type }) => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter unmountOnExit>
      <Tab id="tab_network_loading_fuses_scenarios" eventKey={Routes.NetworkLoadingFuses} title={getIntl('Scenarios')}>
        <TableFuses />
        <ProfileModal />
      </Tab>
      <Tab
        id="tab_network_loading_fuses_historical_dist"
        eventKey={`${Routes.NetworkLoadingFuses}${Routes.HASH.LoadingDistribution}`}
        title={getIntl('Loading distribution')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartFusesLoadingDistribution type={type} />
          </ContentCard>
        </ContentContainer>
      </Tab>
      <Tab
        id="tab_network_loading_fuses_future_mean"
        eventKey={`${Routes.NetworkLoadingFuses}${Routes.HASH.FutureMean}`}
        title={getIntl('Future (mean)')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartFuture
              type={type}
              asset_class="Fuse"
              index={1}
              dataMarker="chart_network_loading_fuses_future_mean"
            />
          </ContentCard>
        </ContentContainer>
      </Tab>
      <Tab
        id="tab_network_loading_fuses_future_overloaded"
        eventKey={`${Routes.NetworkLoadingFuses}${Routes.HASH.FutureOverloaded}`}
        title={getIntl('Future (overloaded)')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartFuture
              type={type}
              asset_class="Fuse"
              index={0}
              dataMarker="chart_network_loading_fuses_future_overloaded"
            />
          </ContentCard>
        </ContentContainer>
      </Tab>
    </Tabs>
  );
};

export default TabsFuses;
