import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBuilderState } from 'hooks';
import { createFormAction, updateFormAction, copyFormAction } from 'modules/forms';
import { setInfoToastAction } from 'modules/layouts';
import { Button } from 'components/_common';
import { BuilderFieldsTypes } from 'constants/index';

interface Props {
  isFormBuilder: boolean;
  isInstructionBuilder: boolean;
  isToolInspectionBuilder: boolean;
}

const BuilderPublishButton: React.FC<Props> = ({ isFormBuilder, isInstructionBuilder, isToolInspectionBuilder }) => {
  const dispatch: any = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isNew, isDuplicate, selectValue, blocks } = useBuilderState();

  const { isSomeBlockIsEmpty, isToolPhotoAndBinarySelectExistOnEachPage } = useMemo(() => {
    return blocks.reduce(
      (acc, block: Builder.SnapshotBlock) => {
        if (!block.fields?.length) acc.isSomeBlockIsEmpty = true;

        const { isToolPhotoExist, isBinarySelectExist } = block.fields.reduce(
          (fieldAcc: any, field: Builder.Field) => {
            if (field.type === BuilderFieldsTypes.AssetPhoto) {
              fieldAcc.isToolPhotoExist = true;
            }
            if (field.type === BuilderFieldsTypes.BinarySelect) {
              fieldAcc.isBinarySelectExist = true;
            }
            return fieldAcc;
          },
          { isToolPhotoExist: false, isBinarySelectExist: false }
        );

        if (!isToolPhotoExist || !isBinarySelectExist) {
          acc.isToolPhotoAndBinarySelectExistOnEachPage = false;
        }

        return acc;
      },
      { isSomeBlockIsEmpty: false, isToolPhotoAndBinarySelectExistOnEachPage: true }
    );
  }, [blocks]);

  const getSubmitErrorMessage = useCallback(() => {
    if (!blocks?.length) return 'Form must contain one page at least';
    if (isSomeBlockIsEmpty) return 'Each form page should contain one field at least';
    if (isToolInspectionBuilder && !isToolPhotoAndBinarySelectExistOnEachPage) {
      return 'Each form page must contain Tool photo and Binary select fields';
    }
    return '';
  }, [blocks, isSomeBlockIsEmpty, isToolInspectionBuilder, isToolPhotoAndBinarySelectExistOnEachPage]);

  const handleFormPublishClick = useCallback(() => {
    const error: string = getSubmitErrorMessage();
    if (error) return dispatch(setInfoToastAction(error));
    setIsLoading(true);

    const action = (() => {
      switch (true) {
        case isNew:
          return createFormAction;
        case !isNew && !isDuplicate:
          return updateFormAction;
        case !isNew && isDuplicate:
          return copyFormAction;
        default:
          throw Error('Builder action not found');
      }
    })();

    dispatch(
      action({ formOptions: selectValue, blocks }, { isFormBuilder, isInstructionBuilder, isToolInspectionBuilder })
    ).catch(() => setIsLoading(false));
  }, [
    dispatch,
    isFormBuilder,
    isInstructionBuilder,
    isToolInspectionBuilder,
    isNew,
    isDuplicate,
    selectValue,
    blocks,
    getSubmitErrorMessage,
  ]);

  if (!selectValue) {
    console.error("ButtonPublish component, one of prop name, formType, selectValue doesn't exist");
    return null;
  }

  return <Button labelKey="Publish form" onClick={handleFormPublishClick} variant="primary" loading={isLoading} />;
};

export default BuilderPublishButton;
