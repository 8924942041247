import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { selectedCurrencySelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';
import { Currencies } from 'constants/index';

const LabelsMap: { [key: string]: string } = {
  [Currencies.DKK]: 'Danish krone',
  [Currencies.EUR]: 'Euro',
  [Currencies.USD]: 'United States dollar',
};

const SelectCurrency: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(selectedCurrencySelector);

  const getLabel = useCallback(
    (currency: Layouts.Currencies) => (
      <>
        {currency} - {getIntl(LabelsMap[currency])}
      </>
    ),
    [getIntl]
  );

  const options = useMemo(
    () => Object.values(Currencies).map(value => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const handleSelectChange = useCallback(
    (option: { value: Layouts.Currencies }): void => {
      dispatch(setLayoutAction({ selectedCurrency: option.value }));
    },
    [dispatch]
  );

  if (!selectedCurrency) return null;
  return (
    <FormReactSelect
      labelKey="Select currency"
      value={{ value: selectedCurrency, label: getLabel(selectedCurrency) }}
      options={options}
      onChange={handleSelectChange}
      isSearchable={false}
    />
  );
};

export default SelectCurrency;
