import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { maintenancePlansCountSelector, maintenancePlansSelector } from 'modules/maintenance/selectors';
import { fetchMaintenancePlansAction, deleteMaintenancePlanAction } from 'modules/maintenance';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType, TableHeaders } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const TablePlans: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenancePlansCountSelector);
  const items = useSelector(maintenancePlansSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);
  const type = PaginationType.MAINTENANCE_CREATE_PLANS;

  const sendRequest = useCallback(() => dispatch(fetchMaintenancePlansAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ maintenancePlansModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteMaintenancePlanAction(id)) }));
    },
    [dispatch]
  );

  const customHeaders = useMemo(
    () =>
      TableHeaders[type].filter(
        header => isLiveDataConnection || ['Asset category', 'Plan name', 'Actions'].includes(header.titleKey!)
      ),
    [type, isLiveDataConnection]
  );

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_CREATE_PLANS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 220px)"
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
      customHeaders={customHeaders}
    >
      {items.map((item: Maintenance.Plan) => (
        <tr key={item.id}>
          <td>{item.assetCategory || '-'}</td>
          {isLiveDataConnection && (
            <>
              <td>{item.dateSelect || '-'}</td>
              <td>{getIntl(item.date_explicit ? 'Yes' : 'No')}</td>
            </>
          )}
          <td>{item.planName || '-'}</td>
          {isLiveDataConnection && (
            <>
              <td>{item.missingAction ? getIntl(item.missingAction) : '-'}</td>
              <td>{item.intervalYears || '-'}</td>
            </>
          )}
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TablePlans;
