import Highcharts from 'highcharts';
import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import Chart, { getBaseOptions } from '@utiligize/shared/components/Chart';

export interface ChartDataProps {
  series?: Shared.SeriesOptionsType[];
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  categories?: string[];
}

const ChartCustomersConnectedEvolution: React.FC<ChartDataProps> = ({
  title = '',
  xAxisTitle = '',
  yAxisTitle = '',
  series,
  categories,
}) => {
  const { getIntl, dateFormat } = useLocale();

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, { title, xAxisTitle, yAxisTitle });
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        categories,
      },
      tooltip: {
        shared: true,
        formatter(this: Highcharts.Point & { points: Highcharts.Point[] }) {
          return `<b>${dateFormat(String(this.key))}</b><br>${this.points?.map(point => `${getIntl(point.series.name)} <b>${point.y}%</b><br>`).join('')}`;
        },
      },
      plotOptions: {
        series: {
          ...baseOptions.plotOptions.series,
          marker: {
            enabled: false,
          },
        },
      },
      series: (series || [])?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: {
        enabled: series?.length ? series.length > 2 : false,
      },
    };
  }, [title, xAxisTitle, yAxisTitle, categories, series, getIntl, dateFormat]) as unknown as Highcharts.Options;

  return <Chart height="calc((100vh - 208px) / 2)" options={series ? options : null} />;
};

export default ChartCustomersConnectedEvolution;
