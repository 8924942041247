import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateIndividualCustomerAction } from 'modules/newLoad';

const useConfiguredFormik = ({ id, toggleModal }: { id: number; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<NewLoad.ModalEditIndividualCustomerData>({
    initialValues: {
      description: '',
      der_categories_id: null,
      customer_node_from: '',
      der_profile_info_id: null,
      customer_mw: null,
      connection_year: null,
      power_factor: null,
      leading_lagging: '',
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape(
      {
        description: Yup.string().required("Field can't be empty!"),
        der_categories_id: Yup.number().nullable().required("Field can't be empty!"),
        customer_node_from: Yup.string().required("Field can't be empty!"),

        der_profile_info_id: Yup.number()
          .nullable()
          .when('customer_mw', {
            is: customer_mw => customer_mw === null,
            then: Yup.number().nullable().required("One of 'Profile' or 'Power (MW)' fields must be filled!"),
          }),

        customer_mw: Yup.number()
          .nullable()
          .when('der_profile_info_id', {
            is: der_profile_info_id => !der_profile_info_id,
            then: Yup.number().nullable().required("One of 'Profile' or 'Power (MW)' fields must be filled!"),
          }),

        connection_year: Yup.number().nullable().required("Field can't be empty!"),
        power_factor: Yup.number().required("Field can't be empty!"),
        leading_lagging: Yup.string().when(
          'power_factor',
          (power_factor: NewLoad.ModalEditIndividualCustomerData['power_factor'], schema: any) =>
            schema.test({
              test: (leading_lagging: NewLoad.ModalEditIndividualCustomerData['leading_lagging']) =>
                !!leading_lagging || power_factor === 1,
              message: "Field can't be empty!",
            })
        ),
      },
      [['der_profile_info_id', 'customer_mw']]
    ),

    onSubmit: values => {
      return dispatch(updateIndividualCustomerAction(id, values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
