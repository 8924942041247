import { createSelector } from 'reselect';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { sortAlphabeticallyFactory } from 'utils';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.newLoad;

export const DERsCountSelector = createSelector(rootSelector, newLoad => newLoad.DERsCount);

const DERsHashSelector = createSelector(rootSelector, newLoad => newLoad.DERsHash);

type Hash = Record<NewLoad.DER['section'], { items: NewLoad.DER[]; population: number; baseload: number }>;

export const DERsSplitBySectionHashSelector = createSelector(DERsHashSelector, DERsHash => {
  return Object.values(DERsHash)
    .sort(sortAlphabeticallyFactory<NewLoad.DER>('description'))
    .sort(sortAlphabeticallyFactory<NewLoad.DER>('section'))
    .reduce((acc: Hash, der: NewLoad.DER) => {
      if (acc[der.section]) {
        acc[der.section].population += der.n_ders;
        acc[der.section].baseload += der.ders_in_baseload || 0;
        acc[der.section].items.push(der);
      } else {
        acc[der.section] = { items: [der], population: der.n_ders, baseload: der.ders_in_baseload || 0 };
      }
      return acc;
    }, {});
});

export const DERSelectorFactory = (id: number | null) =>
  createSelector(DERsHashSelector, DERsHash => DERsHash[`_${id}_`]);

export const DERsCategoriesHFetchedSelector = createSelector(rootSelector, newLoad => newLoad.DERsCategoriesFetched);

export const DERsCategoriesCountSelector = createSelector(rootSelector, newLoad => newLoad.DERsCategoriesCount);

const DERsCategoriesHashSelector = createSelector(rootSelector, newLoad => newLoad.DERsCategoriesHash);

export const DERsCategoriesSplitBySectionHashSelector = createSelector(
  DERsCategoriesHashSelector,
  DERsCategoriesHash => {
    return Object.values(DERsCategoriesHash).reduce((acc: Record<string, NewLoad.DERCategory[]>, der) => {
      if (acc[der.section]) {
        acc[der.section].push(der);
      } else {
        acc[der.section] = [der];
      }
      return acc;
    }, {});
  }
);

export const DERsCategoryHashSelectorFactory = (id: number | null) =>
  createSelector(DERsCategoriesHashSelector, DERsCategoriesHash => DERsCategoriesHash[`_${id}_`]);

export const DERsProfilesCountSelector = createSelector(rootSelector, newLoad => newLoad.DERsProfilesCount);

export const DERsProfilesHashSelector = createSelector(rootSelector, newLoad => newLoad.DERsProfilesHash);

export const DERsProfilesSelector = createSelector(
  [DERsProfilesHashSelector, portfolioIdSelector],
  (DERsProfilesHash, portfolioId) => DERsProfilesHash[String(portfolioId)] || null
);

export const individualCustomersCountSelector = createSelector(
  rootSelector,
  newLoad => newLoad.individualCustomersCount
);

const individualCustomersHashSelector = createSelector(rootSelector, newLoad => newLoad.individualCustomersHash);

export const individualCustomersSelector = createSelector(individualCustomersHashSelector, individualCustomersHash =>
  Object.values(individualCustomersHash)
);

export const individualCustomersSplitBySectionHashSelector = createSelector(
  individualCustomersHashSelector,
  individualCustomersHash => {
    return Object.values(individualCustomersHash)
      .sort(sortAlphabeticallyFactory<NewLoad.IndividualCustomer>('connection_year'))
      .sort(sortAlphabeticallyFactory<NewLoad.IndividualCustomer>('section'))
      .reduce((acc: Record<string, NewLoad.IndividualCustomer[]>, customer) => {
        if (acc[customer.section]) {
          acc[customer.section].push(customer);
        } else {
          acc[customer.section] = [customer];
        }
        return acc;
      }, {});
  }
);

export const individualCustomersSelectorFactory = (id: number | null) =>
  createSelector(individualCustomersHashSelector, individualCustomersHash => individualCustomersHash[`_${id}_`]);

export const DERsPhaseInCountSelector = createSelector(rootSelector, newLoad => newLoad.DERsPhaseInCount);

export const DERsPhaseInItemsSelector = createSelector(rootSelector, newLoad => newLoad.DERsPhaseInItems);
