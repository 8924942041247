import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.answers;

export const answersItemsSelector = createSelector(rootSelector, answers => answers.rows);

export const answersCountSelector = createSelector(rootSelector, answers => answers.count);
