import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { usePaginationHandlers } from 'hooks';
import { setPaginationAction } from 'modules/layouts';
import TableRights from './TableRights';
import ModalRightsProfile from './ModalRightsProfile';
import { DataTableTemplate, SelectSolved, Checkbox } from 'components/_common';
import { PaginationType } from 'constants/index';
import SelectCustomerPercentile from './SelectCustomerPercentile';
import SelectCustomerLoadType from './SelectCustomerLoadType';
import SelectCustomerMissingType from './SelectCustomerMissingType';
import SelectCustomerDERType from './SelectCustomerDERType';
import SelectCustomerDetectionType from './SelectCustomerDetectionType';

const ViewCustomers: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.CUSTOMERS_RIGHTS;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  const handleOutliersRemovedSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, outliersRemoved: event.currentTarget.checked } },
        })
      );
    },
    [dispatch, filters, type]
  );

  const handleSelectCustomerDERTypeChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      handleSelectChange(option, meta, !option?.value ? { detectionType: null } : {});
    },
    [handleSelectChange]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={1}>
          <SelectCustomerPercentile
            name="percentile"
            mutedTextLabelKey="Percentile"
            value={filters?.percentile}
            onChange={handleSelectChange}
            isSearchable={false}
          />
        </Col>
        <Col xs={2}>
          <SelectCustomerLoadType name="loadType" value={filters?.loadType} onChange={handleSelectChange} />
        </Col>
        <Col xs={2}>
          <SelectCustomerMissingType
            name="missingType"
            mutedTextLabelKey="Missing type"
            value={filters?.missingType}
            onChange={handleSelectChange}
            isSearchable={false}
          />
        </Col>
        <Col xs={2}>
          <SelectCustomerDERType
            name="existingDersId"
            mutedTextLabelKey="DER type"
            value={filters?.existingDersId || null}
            onChange={handleSelectCustomerDERTypeChange}
            isClearable
            isSearchable={false}
          />
        </Col>
        <Col xs={2}>
          <SelectCustomerDetectionType
            name="detectionType"
            mutedTextLabelKey="Detection type"
            isDisabled={!filters.existingDersId}
            value={filters?.detectionType || null}
            onChange={handleSelectChange}
            isClearable
            isSearchable={false}
          />
        </Col>
        <Col xs={1}>
          <SelectSolved
            name="solvedValue"
            mutedTextLabelKey="Filter solved"
            value={filters?.solvedValue}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs="auto">
          <Checkbox
            labelKey="Outliers removed"
            className="icheck-primary"
            checked={filters?.outliersRemoved}
            onChange={handleOutliersRemovedSwitch}
          />
        </Col>
      </Row>
      <>
        <TableRights />
        <ModalRightsProfile outliersRemoved={Boolean(filters?.outliersRemoved)} />
      </>
    </DataTableTemplate>
  );
};

export default ViewCustomers;
