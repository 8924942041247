import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scenarioOptionsSelector, scenarioSelectedOptionSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';
import { portfolioIdSelector, scenarioIdHashSelector } from 'modules/layouts/selectors';

const SelectScenario: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioIdHash = useSelector(scenarioIdHashSelector);
  const options = useSelector(scenarioOptionsSelector);
  const value = useSelector(scenarioSelectedOptionSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      dispatch(
        setLayoutAction({
          scenarioIdHash: { ...scenarioIdHash, [portfolioId!]: Number(option.value) },
          simulationId: null,
        })
      );
    },
    [portfolioId, scenarioIdHash, dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      labelKey="Scenario"
      value={value || null}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
    />
  );
};

export default SelectScenario;
