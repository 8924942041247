import React from 'react';
import { Map } from 'components';
import { useSelector } from 'react-redux';
import MapContextProvider from 'context/map';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import MapPanels from 'components/Panels/MapPanels';
import SingleLineDiagram from 'components/SingleLineDiagram';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { PermissionsTypes } from 'constants/index';

const PageMap: React.FC = () => {
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isMapEnabled}>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <MapContextProvider>
            <MapPanels>
              {singleDiagramEnabled && <SingleLineDiagram />}
              {/* // FixMe. MapPanel must work without map component */}
              <div style={{ display: singleDiagramEnabled ? 'none' : 'initial' }}>
                <Map />
              </div>
            </MapPanels>
          </MapContextProvider>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </PagePermissionsProvider>
  );
};

export default PageMap;
