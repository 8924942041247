// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Root: '/',
  Callback: '/callback',

  // General
  Map: '/map/:uuid?',

  CO2e: '/co2e',
  CO2eEmissionsForecast: '/co2e/emissions-forecast',
  CO2eDetailedEmissions: '/co2e/detailed-emissions',
  CO2eEmissionsByScope: '/co2e/emissions-by-scope',

  // Forecast & Investment
  NetworkLoading: '/network-loading',
  NetworkLoadingCables: '/network-loading/cables',
  NetworkLoadingCabinets: '/network-loading/cabinets',
  NetworkLoadingFuses: '/network-loading/fuses',
  NetworkLoadingTotalLoading: '/network-loading/total-loading',
  NetworkLoadingViewVoltageDrop: '/network-loading/voltage-drop',

  N1: '/n-1',

  Customers: '/customers',

  Investment: '/investment',
  InvestmentDetailedInvestments: '/investment/detailed-investments',

  DataQuality: '/data-quality',
  DataQualityCustomersConnected: '/data-quality/customers-connected',
  DataQualityCustomersConnectedEvolution: '/data-quality/customers-connected/evolution',
  DataQualityMissingGeometry: '/data-quality/missing-geometry',
  DataQualityMeters: '/data-quality/meters',
  DataQualityStatistics: '/data-quality/statistics',

  Setup: '/setup',
  SetupInvestment: '/setup/investment',
  SetupShortCircuit: '/setup/short-circuit',
  SetupInvestmentLimitations: '/setup/investment/limitations',
  SetupInvestmentComponentPriceScenarios: '/setup/investment/component-price-scenarios',
  SetupInvestmentComponentPrices: '/setup/investment/component-prices',
  SetupInvestmentWorkPrices: '/setup/investment/work-prices',
  SetupInvestmentBudget: '/setup/investment/budget',
  SetupCO2e: '/setup/co2e',
  SetupRawData: '/setup/raw-data',
  SetupCalculationSchedule: '/setup/calculation-schedule',

  NewLoad: '/new-load',
  NewLoadCategories: '/new-load/categories',
  NewLoadConnectionManager: '/new-load/connection-manager',
  NewLoadProfiles: '/new-load/profiles',
  NewLoadPhaseIn: '/new-load/phase-in',
  NewLoadGlobalDevelopment: '/new-load/global-development',

  AnalysisTool: '/analysis-tool',

  // Maintenance & Planning
  Tasks: '/tasks/:id?',
  TasksEmployees: '/tasks/employees',
  TasksTimeOverview: '/tasks/time-overview',
  TasksRepeatedTasks: '/tasks/repeated-tasks',

  Meters: '/meters/:id?',
  MetersPopulation: '/meters/population',

  Assets: '/assets',
  AssetsSubComponents: '/assets/sub-components',
  AssetCreate: '/assets/create/:tab(photo|sub-components)?',
  AssetDetails: '/assets/details/:assetCode?/:tab(photo|sub-components|history|answers|task-completions|data-manuals)?',

  Maintenance: '/maintenance',
  MaintenancePlanCNAIM: '/maintenance/plan-cnaim',
  MaintenancePlanSummary: '/maintenance/plan-summary',
  MaintenanceDetailedPlan: '/maintenance/detailed-plan',
  MaintenanceStatistics: '/maintenance/statistics',
  MaintenanceStatisticsUsers: '/maintenance/statistics/users/:id?',
  MaintenanceStatisticsManufacturerComparison: '/maintenance/statistics/manufacturer-comparison',
  MaintenanceStatisticsAssetCategories: '/maintenance/statistics/asset-categories',
  MaintenanceStatisticsConditionAssessment: '/maintenance/statistics/condition-assessment',

  AssetModels: '/asset-models',
  AssetModelsManufacturers: '/asset-models/manufacturers',

  Forms: '/forms',
  FormBuilder: '/forms/builder',
  Instructions: '/forms/instructions',
  InstructionBuilder: '/forms/instructions/builder',
  Inspections: '/forms/tool-inspections',
  InspectionBuilder: '/forms/tool-inspections/builder',

  History: '/history/:id?',
  HistoryAnswers: '/history/answers/:id?',
  HistoryChecklists: '/history/checklists',

  DataManuals: '/data-manuals',

  // Reinvestment
  Reliability: '/reliability',
  MonetizedRisk: '/monetized-risk',

  // Admin
  Admin: '/admin',
  AdminGroups: '/admin/groups',
  AdminAccessLogs: '/admin/access-logs',
  AdminSupervisor: '/admin/supervisor',

  FeatureToggles: '/feature-toggles',

  HASH: {
    Chart: '#chart',
    Profile: '#profile',
    Voltage: '#voltage',
    MissingAge: '#missing-age',
    Checklists: '#checklists',
    Materials: '#materials',
    New: '#new',
    Complaints: '#complaints',
    Upcoming: '#upcoming',
    Finished: '#finished',
    Table: '#table',
    Diagram: '#diagram',
    Tasks: '#tasks',
    MobileSubmitted: '#mobile-submitted',
    LoadingDistribution: '#loading-distribution',
    FutureMean: '#future-mean',
    FutureOverloaded: '#future-overloaded',
    TotalHours: '#total-hours',
    UsersHours: '#users-hours',
    TasksHours: '#tasks-hours',
    Features: '#features',
    LV: '#lv',
    GIS: '#gis',
  },
};
