import { handleActions } from 'redux-actions';
import {
  fetchManualsAction,
  fetchManualAction,
  deleteManualByIdAction,
  createManualAction,
  updateManualAction,
} from './actions';

const initialState: Manuals.Root = {
  count: 0,
  itemsHash: {} as Record<string, Manuals.Item>,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [fetchManualsAction]: {
    next: (
      state: Manuals.Root,
      action: Shared.ReduxAction<Pick<Manuals.Root, 'count' | 'itemsHash'>>
    ): Manuals.Root => ({
      ...state,
      ...action.payload,
    }),
  },
  [fetchManualAction]: {
    next: (state: Manuals.Root, action: Shared.ReduxAction<Record<string, Manuals.Item>>): Manuals.Root => ({
      ...state,
      itemsHash: {
        ...state.itemsHash,
        ...action.payload,
      },
    }),
  },
};

export { fetchManualsAction, fetchManualAction, deleteManualByIdAction, createManualAction, updateManualAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
