import React from 'react';
import { useSelector } from 'react-redux';
import { appErrorSelector } from 'modules/app/selectors';
import { PageAppExceptions } from 'pages';
import { AppErrorsTypes } from 'constants/index';

const RequiredSimulationCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const appError = useSelector(appErrorSelector);

  if (appError === AppErrorsTypes.NO_SIMULATIONS) {
    return <PageAppExceptions appError={appError} />;
  }

  return <>{children}</>;
};

export default RequiredSimulationCheck;
