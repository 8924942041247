import React from 'react';
import { FormsModal } from 'components/_modals';
import { FormsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { PermissionsTypes } from 'constants/index';

const PageForms: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
    <RequiredScenarioCheck>
      <RequiredSimulationCheck>
        <DataTableTemplate paddingBottom>
          <FormsModal />
          <FormsTable />
        </DataTableTemplate>
      </RequiredSimulationCheck>
    </RequiredScenarioCheck>
  </PagePermissionsProvider>
);

export default PageForms;
