import React from 'react';
import { useSelector } from 'react-redux';
import {
  isNetworkLoadingFusesLoadingDistributionTabActiveSelector,
  isRouteActiveSelectorFactory,
} from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsFuses from './TabsFuses';
import TableFilters from './TableFilters';
import { PaginationType, Routes } from 'constants/index';

const ViewFuses: React.FC = () => {
  const isScenariosTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NetworkLoadingFuses));
  const isLoadingDistributionTabActive = useSelector(isNetworkLoadingFusesLoadingDistributionTabActiveSelector);
  const isFutureMeanTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingFuses}${Routes.HASH.FutureMean}`)
  );
  const isFutureOverloadedTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingFuses}${Routes.HASH.FutureOverloaded}`)
  );
  const type = PaginationType.NETWORK_LOADING_FUSES;
  return (
    <DataTableTemplate>
      <TableFilters
        type={type}
        hideScenariosYear={isFutureMeanTabActive || isFutureOverloadedTabActive}
        showFilterSolved={isScenariosTabActive}
        showPercentile={isScenariosTabActive || isLoadingDistributionTabActive}
        showOverloadedAssets={isLoadingDistributionTabActive}
      />
      <TabsFuses type={type} />
    </DataTableTemplate>
  );
};

export default ViewFuses;
