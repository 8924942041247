import React from 'react';
import { Switch, Route } from 'react-router';
import { PageAppExceptions } from 'pages';
import {
  ViewForecastedScenarios,
  ViewInvestment,
  ViewShortCircuit,
  ViewCO2e,
  ViewRawData,
  ViewCalculationSchedule,
} from 'components/Setup';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageSetup: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isSetupEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <Switch>
        <Route exact path={Routes.Setup} component={ViewForecastedScenarios} />
        <Route
          path={Routes.SetupInvestment}
          render={() => (
            <RequiredScenarioCheck>
              <ViewInvestment />
            </RequiredScenarioCheck>
          )}
        />
        <Route path={Routes.SetupShortCircuit} component={ViewShortCircuit} />
        <Route path={Routes.SetupCO2e} component={ViewCO2e} />
        <Route exact path={Routes.SetupRawData} component={ViewRawData} />
        <Route exact path={Routes.SetupCalculationSchedule} component={ViewCalculationSchedule} />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageSetup;
