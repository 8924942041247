import styled from 'styled-components';
import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getReliabilityChartData } from 'modules/networkLoading';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';
import DutyFactor from 'components/DutyFactor';

interface Props {
  uuid: string;
  cnaim_id: number | null;
  className?: string;
}

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartProbabilityOfFailure: React.FC<Props> = ({ uuid, cnaim_id, className }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !versionId || !cnaim_id || !uuid) return;
    dispatch(getReliabilityChartData({ portfolioId, versionId, cnaim_id, uuid }))
      .then(
        (action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x' | 'categories'> | null>) => {
          setChartData({
            series: [
              {
                name: action.payload?.title || '',
                type: 'column' as any,
                data: action.payload?.data,
                color: Colors[0],
              },
            ],
            title: action.payload?.title || '',
            xAxisTitle: action.payload?.xaxis_title || '',
            yAxisTitle: action.payload?.yaxis_title || '',
          });
        }
      )
      .catch(console.error);
  }, [dispatch, portfolioId, versionId, cnaim_id, uuid]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `${this.x}: <b>${this.y}%</b>`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: { enabled: false },
    };
  }, [chartData, getIntl]) as unknown as Highcharts.Options;

  return (
    <>
      <StyledDutyFactor uuid={uuid} />
      <Chart className={className} options={chartData ? options : null} dataMarker="asset_timeseries_chart" />
    </>
  );
};

const StyledDutyFactor = styled(DutyFactor)`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 25px;
  transform: translate(-50%, 0);
`;

export default ChartProbabilityOfFailure;
