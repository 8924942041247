import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsFeaturesCountSelector, permissionsFeaturesSelector } from 'modules/permissions/selectors';
import { fetchPermissionsFeaturesAction } from 'modules/permissions';
import { setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { substringTenantName } from 'utils';
import { PaginationType, PermissionsTypesLocalesMap } from 'constants/index';
import { IconEdit } from '@utiligize/shared/resources';

const TenantTableCell: React.FC<{ tenants: Omit<Permissions.Tenant, 'permissionsTypes'>[] }> = memo(({ tenants }) => (
  <td className="text-nowrap">
    {tenants.length
      ? tenants.map(({ id, tenantName }) => (
          <React.Fragment key={id}>
            - {substringTenantName(tenantName)}
            <br />
          </React.Fragment>
        ))
      : '-'}
  </td>
));

const TableFeatures: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(permissionsFeaturesCountSelector);
  const items = useSelector(permissionsFeaturesSelector);

  const sendRequest = useCallback(() => dispatch(fetchPermissionsFeaturesAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const permissionsFeaturesModalId: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (permissionsFeaturesModalId) dispatch(setLayoutAction({ permissionsFeaturesModalId }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.PERMISSIONS_FEATURES}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 177px)"
    >
      {items?.map((item: Permissions.Feature) => (
        <tr key={item.id}>
          <td>
            - {item.key}
            <br />
            {PermissionsTypesLocalesMap[item.key as Permissions.PermissionsTypes]?.labelKey || item.key}
          </td>
          <td>{item.description || '-'}</td>
          <TenantTableCell tenants={item.tenants} />
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableFeatures;
