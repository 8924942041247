import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MapContext } from 'context/map';
import { addMapNewCustomerAction } from 'modules/newLoad';
import { NewCustomersLegend } from 'components/Map/core/layers/other/NewCustomers';
import { AddCustomerTypes } from 'constants/index';

const useConfiguredFormik = ({
  asset_id,
  asset_type,
  primary_substation_id,
  isTransformer,
  formStep,
  closeModal,
}: Pick<NewLoad.MapConnection, 'asset_id' | 'asset_type' | 'primary_substation_id'> & {
  isTransformer: boolean;
  formStep: 0 | 1;
  closeModal: () => void;
}) => {
  const { map } = useContext(MapContext);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Omit<NewLoad.MapConnection, 'asset_id' | 'asset_type' | 'primary_substation_id'>>({
    initialValues: {
      name: '',
      connection_side: null,
      connection_year: new Date().getFullYear(),
      der_category_id: null,
      customer_type: AddCustomerTypes.STANDARD_PROFILE,
      der_profile_info_id: 3,
      customer_mw: null,
      power_factor: 1,
      leading_lagging: 'leading',
    },

    validationSchema: !formStep
      ? Yup.object().shape({
          der_profile_info_id: Yup.number()
            .nullable()
            .when('customer_type', (customer_type: NewLoad.MapConnection['customer_type'], schema: any) =>
              customer_type === AddCustomerTypes.CUSTOM_PROFILE ? schema.required("Field can't be empty!") : schema
            ),
          customer_mw: Yup.number()
            .nullable()
            .when('customer_type', (customer_type: NewLoad.MapConnection['customer_type'], schema: any) =>
              customer_type === AddCustomerTypes.BASE_POWER
                ? schema.moreThan(0, 'Must be greater than zero').required("Field can't be empty!")
                : schema
            ),
          power_factor: Yup.number()
            .nullable()
            .when('customer_type', (customer_type: NewLoad.MapConnection['customer_type'], schema: any) =>
              customer_type === AddCustomerTypes.BASE_POWER
                ? schema
                    .min(0, 'Value must be at least 0')
                    .max(1, 'Value must be at most 1')
                    .required("Field can't be empty!")
                : schema
            ),
        })
      : Yup.object().shape({
          name: Yup.string().required("Field can't be empty!"),
          connection_side: Yup.string()
            .nullable()
            .when([], {
              is: () => isTransformer,
              then: Yup.string().required("Field can't be empty!"),
              otherwise: Yup.string().nullable(),
            }),
          connection_year: Yup.number().nullable().required("Field can't be empty!"),
        }),

    onSubmit: values => {
      return dispatch(addMapNewCustomerAction({ ...values, asset_id, asset_type, primary_substation_id }))
        .then(() => {
          const source = map?.getSource(NewCustomersLegend.name) as mapboxgl.GeoJSONSource;
          if (source) source.setData((source as any)._data);
          closeModal();
        })
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
