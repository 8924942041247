import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { Routes } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.assets;

export const categoriesSelector = createSelector(rootSelector, assets => assets.categories);

const assetsHashSelector = createSelector(rootSelector, assets => assets.assetsHash);

export const assetsCountSelector = createSelector(rootSelector, assets => assets.assetsCount);

export const assetsSelector = createSelector(assetsHashSelector, assetsHash => Object.values(assetsHash));

const subComponentsHashSelector = createSelector(rootSelector, assets => assets.subComponentsHash);

export const subComponentsCountSelector = createSelector(rootSelector, assets => assets.subComponentsCount);

export const subComponentsSelector = createSelector(subComponentsHashSelector, subComponentsHash =>
  Object.values(subComponentsHash)
);

export const subComponentSelectorFactory = (id: number | null) =>
  createSelector(subComponentsHashSelector, subComponentsHash => subComponentsHash[`_${id}_`]);

export const smartMetersHashFetchedSelector = createSelector(rootSelector, assets => assets.smartMetersHashFetched);

const smartMetersHashSelector = createSelector(rootSelector, assets => assets.smartMetersHash);

export const smartMetersSelector = createSelector(smartMetersHashSelector, smartMetersHash =>
  Object.values(smartMetersHash)
);

export const smartMetersSelectorFactory = (id: number | null) =>
  createSelector(smartMetersHashSelector, smartMetersHash => smartMetersHash[`_${id}_`]);

export const taskCompletionsSelector = createSelector(rootSelector, assets => assets.taskCompletions);

export const assetMetaSelector = createSelector(
  [createMatchSelector({ path: Routes.Map }) as any, rootSelector],
  (match: any, assets) => {
    // UAV, must reset asset meta immediately on uuid change event
    return match?.params?.uuid === assets.assetMeta?.id ? assets.assetMeta : null;
  }
);
