import moment from 'moment';
import { getStorageItem } from './storage';
import {
  TaskTypes,
  TaskAppActions,
  NonAssetTaskAppActions,
  CustomerComplaintTaskAppActions,
  BuilderFieldsTypes,
  TaskStatus,
  PaginationType,
  BuilderFormTypeNames,
  PermissionsTypesLocalesMap,
  StorageKeys,
  TimezoneOptions,
} from 'constants/index';

export { default as i18next, getDaysOfWeek, getMonthNames } from './i18n';

export const getFieldTypesArray = (builderData: Builder.Data, formtypeId: number): Builder.FieldType[] => {
  const { inspectionFieldsTypes, restFieldsTypes } = builderData.fieldTypes.reduce(
    (
      acc: {
        inspectionFieldsTypes: Builder.FieldType[];
        restFieldsTypes: Builder.FieldType[];
      },
      item: Builder.FieldType
    ) => {
      if (item.name === BuilderFieldsTypes.InspectionQuestion) {
        return {
          ...acc,
          inspectionFieldsTypes: [item, ...acc.inspectionFieldsTypes],
        };
      }
      if (item.name === BuilderFieldsTypes.LinkedForm || item.name === BuilderFieldsTypes.LinkedFunctionality) {
        return {
          ...acc,
          inspectionFieldsTypes: [item, ...acc.inspectionFieldsTypes],
          restFieldsTypes: [item, ...acc.restFieldsTypes],
        };
      }
      return { ...acc, restFieldsTypes: [item, ...acc.restFieldsTypes] };
    },
    { inspectionFieldsTypes: [], restFieldsTypes: [] }
  );

  const formType = builderData.formTypes.find((elem: Type.FormType) => elem.id === formtypeId);
  const formTypeName = formType?.name as Builder.FormTypeNames;

  if (formTypeName === BuilderFormTypeNames.Inspection) {
    return inspectionFieldsTypes;
  }

  return restFieldsTypes;
};

export const getGalleryImages = (
  attachments: Type.Attachment[],
  { isAnswersPreviewPage = false }: { isAnswersPreviewPage: boolean } = {} as any
): Type.GalleryImage[] => {
  return attachments.map((attachment: Type.Attachment) => ({
    id: attachment.id,
    gcsFileName: attachment.gcsFileName,
    src: attachment.fileLink || attachment.link || attachment.value || '',
    thumbnail: attachment.fileLink || attachment.link || attachment.value || '',
    width: attachment.width,
    height: attachment.height,
    tags: attachment.createdAt
      ? [
          {
            value: moment(attachment.createdAt).format('L'),
            title: moment(attachment.createdAt).format('L'),
          },
        ]
      : [],
    overlayTags: [
      Boolean(attachment.authorEmail) && {
        value: attachment.authorEmail,
        title: attachment.authorEmail,
      },
      Boolean(attachment.sourceAction) && {
        value: attachment.sourceAction,
        title: attachment.sourceAction,
      },
      Boolean(attachment.sourcePlatform) && {
        value: attachment.sourcePlatform,
        title: attachment.sourcePlatform,
      },
      Boolean(attachment.isHidden) && {
        value: isAnswersPreviewPage ? 'Deleted' : 'Hidden',
        title: isAnswersPreviewPage ? 'Deleted' : 'Hidden',
      },
    ].filter(Boolean) as Type.GalleryTag[],
  }));
};

export const getSelectTaskAppActionExcludedOptions = (type: Type.TaskTypes | null | undefined): TaskAppActions[] => {
  // if (type === TaskTypes.Expected) return [TaskAppActions.NoAsset];
  if (type === TaskTypes.Autogenerated) return [...NonAssetTaskAppActions, TaskAppActions.Repair];
  if (type === TaskTypes.CustomerComplaint) return CustomerComplaintTaskAppActions;
  return [];
};

export const getStaticFiltersMap = (
  key: Type.PaginationType
): { status: Tasks.TaskStatus[] | null; onlyWithoutUsers: boolean | null } => {
  switch (key) {
    case PaginationType.TASKS_MOBILE_SUBMITTED:
      return {
        status: [TaskStatus.MobileSubmitted],
        onlyWithoutUsers: null,
      };
    case PaginationType.UPCOMING_TASKS:
      return {
        status: [TaskStatus.NotStarted, TaskStatus.Ongoing, TaskStatus.Finished],
        onlyWithoutUsers: true,
      };
    case PaginationType.TASKS:
      return { status: [TaskStatus.NotStarted, TaskStatus.Ongoing], onlyWithoutUsers: false };
    case PaginationType.TASKS_FINISHED:
      return { status: [TaskStatus.Finished], onlyWithoutUsers: null };
    default:
      return { status: null, onlyWithoutUsers: null };
  }
};

export const onDigitsKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/^\d+$/.test(event.key)) event.preventDefault();
};

export const sortPermissions = (a: Permissions.PermissionsTypes, b: Permissions.PermissionsTypes) => {
  return (PermissionsTypesLocalesMap[a]?.sort || 100) - (PermissionsTypesLocalesMap[b]?.sort || 100);
};

export const slugify = (text: string) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const sortAlphabeticallyFactory =
  <T>(prop: keyof T) =>
  (a: T, b: T): number => {
    if (a[prop] < b[prop]) return -1;
    if (a[prop] > b[prop]) return 1;
    return 0;
  };

export const stopPropagationFunc = (e: React.SyntheticEvent) => e.stopPropagation();

export const isNKTenant = ((getStorageItem(StorageKeys.SELECTED_TENANT) || '') as string) === 'Tenant NK-FORSYNING A/S';

export const isVekselTenant = Boolean(
  ((getStorageItem(StorageKeys.SELECTED_TENANT) || '') as string) === 'Tenant VEKSEL A/S'
);

export const isNordEnergiTenant =
  ((getStorageItem(StorageKeys.SELECTED_TENANT) || '') as string) === 'Tenant NORD ENERGI NET A/S';

export const isEnelTenant = ((getStorageItem(StorageKeys.SELECTED_TENANT) || '') as string) === 'Tenant Enel S.p.A.';

export const substringTenantName = (tenantName: string) => tenantName.substring(tenantName.indexOf('Tenant ') + 7);

export const getSelectYearRange = (start: number, end: number) => {
  let list = [];
  for (var i = start; i <= end; i++) {
    list.push(i);
  }
  return list;
};

export const getMatchingTimezoneOption = (guessedTimezone: string): Type.SelectOption<string> => {
  const guessedOffset = moment.tz(guessedTimezone).utcOffset();

  const matchingTimezone = TimezoneOptions.find(tz => {
    const tzOffset = moment.tz(tz.value).utcOffset();
    return tzOffset === guessedOffset;
  });

  return matchingTimezone || TimezoneOptions.find(tz => tz.value === 'Europe/London')!;
};

export { initSentry } from './initSentry';
export { transformBlocks, transformFormField } from './form-builder';
export * from './storage';
