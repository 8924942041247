import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getGlobalDevelopmentChartData } from 'modules/newLoad';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import Chart, { getBaseOptions } from 'components/_charts/Chart';
import { YAxisTypes } from 'constants/index';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  rating?: number;
  series: Shared.SeriesOptionsType[];
}

interface Response {
  description_label: string;
  title: string;
  ts_data: Type.GlobalDevelopmentChartDataItem[];
  xlabel: string;
  ylabel: string;
}

const transformChartData = (action: Shared.ReduxAction<Response>): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const seriesHash = action.payload.ts_data?.reduce(
    (acc: Record<string, any>, item: Type.GlobalDevelopmentChartDataItem) => {
      const elem = [item.year, item.n_ders];
      const description = item.description;
      if (!description) return acc;
      if (!acc[description]) {
        acc[description] = {
          name: item.description,
          type: 'line',
          data: [elem],
        };
      } else {
        acc[description].data.push(elem);
      }
      return acc;
    },
    {} as Record<string, Shared.SeriesOptionsType>
  );

  return {
    title: action.payload.title,
    xAxisTitle: action.payload.xlabel,
    yAxisTitle: action.payload.ylabel,
    series: Object.values<Shared.SeriesOptionsType>(seriesHash || {}),
  };
};

const ChartGlobalDevelopment: React.FC<{ yAxisType: YAxisTypes }> = ({ yAxisType }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId) return;
    dispatch(getGlobalDevelopmentChartData({ portfolioId, scenarioId }))
      .then(action => transformChartData(action))
      .then(setChartData)
      .catch(console.error);
  }, [dispatch, portfolioId, scenarioId, getIntl]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      plotOptions: {
        series: {
          ...baseOptions.plotOptions.series,
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
      },
      yAxis: {
        type: yAxisType,
        ...baseOptions.yAxis,
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    };
  }, [chartData, getIntl, yAxisType]) as unknown as Highcharts.Options;

  return (
    <Chart
      options={chartData ? options : null}
      dataMarker="chart_new_load_global_development"
      height="calc(100vh - 167px)"
    />
  );
};

export default ChartGlobalDevelopment;
