import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectCustomersRightsLoadTypes } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['loadType'];
}

const SelectCustomerLoadType: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();

  const LabelsMap = useMemo(
    () => ({
      [SelectCustomersRightsLoadTypes.BOTH]: 'Both',
      [SelectCustomersRightsLoadTypes.CONSUMPTION]: 'Consumption',
      [SelectCustomersRightsLoadTypes.PRODUCTION]: 'Production',
    }),
    []
  );

  const { options, selectValue } = useMemo(
    () =>
      Object.values(SelectCustomersRightsLoadTypes).reduce(
        (acc: any, type: string) => {
          const item = {
            value: type,
            label: getIntl(LabelsMap[type as Customers.SelectLoadTypes]),
          };
          if (type === value) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, LabelsMap, getIntl]
  );

  return (
    <FormReactSelect
      mutedTextLabelKey="Load type"
      value={selectValue}
      options={options}
      isSearchable={false}
      {...props}
    />
  );
};

export default SelectCustomerLoadType;
