import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import {
  DataTableTemplate,
  SelectCNAIMAssetCategory,
  Tabs,
  Tab,
  ContentContainer,
  ContentCard,
} from 'components/_common';
import TableMissingAge from './TableMissingAge';
import { PaginationType, Routes } from 'constants/index';
import ChartAgeDistribution from './ChartAgeDistribution';

const ViewStatistics: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const type = PaginationType.DATA_QUALITY_MISSING_AGE;
  const paginationSelector = useMemo(() => paginationSelectorFactory(type), [type]);
  const { filters }: Layouts.Pagination = useSelector(paginationSelector);

  const handleChangeCategorySelect = useCallback(
    (value: any) => {
      dispatch(setPaginationAction({ type, modifier: { filters: { ...filters, cnaim_id: value.value }, offset: 0 } }));
    },
    [dispatch, filters, type]
  );

  return (
    <DataTableTemplate>
      <Row>
        <Col sm={4}>
          <SelectCNAIMAssetCategory
            labelKey=""
            mutedTextLabelKey="Asset category"
            value={filters?.cnaim_id ?? null}
            onChange={handleChangeCategorySelect}
          />
        </Col>
      </Row>
      <Tabs mountOnEnter>
        <Tab
          id="tab__age_statistics_distribution"
          eventKey={Routes.DataQualityStatistics}
          title={getIntl('Age distribution')}
        >
          <ContentContainer>
            <ContentCard>
              <ChartAgeDistribution cnaim_id={filters?.cnaim_id ?? null} />
            </ContentCard>
          </ContentContainer>
        </Tab>
        <Tab
          id="tab__age_statistics_missing"
          unmountOnExit
          eventKey={`${Routes.DataQualityStatistics}${Routes.HASH.MissingAge}`}
          title={getIntl('Missing age')}
        >
          <TableMissingAge cnaim_id={filters?.cnaim_id} />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewStatistics;
