import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { maintenanceDetailedPlanCountSelector, maintenanceDetailedPlanSelector } from 'modules/maintenance/selectors';
import { fetchMaintenanceDetailedPlanAction, updateMaintenanceDetailedPlanItemAction } from 'modules/maintenance';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, SelectYear } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableDetailedPlan: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenanceDetailedPlanCountSelector);
  const items = useSelector(maintenanceDetailedPlanSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchMaintenanceDetailedPlanAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_PLAN}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 240px)"
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: Maintenance.DetailedPlan) => (
        <MaintenanceDetailedPlanTableItem key={item.id} {...item} />
      ))}
    </DataTable>
  );
};

const MaintenanceDetailedPlanTableItem: React.FC<Maintenance.DetailedPlan> = ({
  id,
  planName,
  name,
  maintenanceYear,
  overrideYear,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [yearInput, setYearInput] = useState<number | null>(overrideYear);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleSelectChange = useCallback(
    (option: Type.SelectOption<number>) => {
      setYearInput(option.value);
      setLoading(true);
      dispatch(updateMaintenanceDetailedPlanItemAction(id, option.value)).finally(() => setLoading(false));
    },
    [dispatch, id]
  );
  return (
    <tr>
      <td>{planName || '-'}</td>
      <td>{name || '-'}</td>
      <td>{maintenanceYear || '-'}</td>
      <td>
        <SelectYear
          labelKey=""
          value={yearInput}
          onChange={handleSelectChange}
          isDisabled={loading}
          isSearchable={false}
          menuPortalTarget={document.body}
        />
      </td>
    </tr>
  );
};

export default TableDetailedPlan;
