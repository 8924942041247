import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTimestampOptionsAction } from 'modules/forms';
import { formBuilderTimestampsSelectorFactory } from 'modules/forms/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  timestampOptions: string[];
  assetCategoryCode: Type.AssetCategories | null;
  setValues: (value: string[]) => void;
}

const SelectTimestampOptions: React.FC<Props> = ({
  isDisabled = false,
  labelKey = 'Timestamp options',
  isMulti,
  timestampOptions = [],
  assetCategoryCode,
  setValues,
  onBlur,
  ...props
}) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const data = useSelector(formBuilderTimestampsSelectorFactory(assetCategoryCode));

  useEffect(() => {
    if (!data && assetCategoryCode) {
      setLoading(true);
      dispatch(fetchTimestampOptionsAction(assetCategoryCode)).finally(() => setLoading(false));
    }
  }, [data, assetCategoryCode, dispatch]);

  const { options, value } = useMemo(
    () =>
      (data || []).reduce(
        (acc: any, option: string) => {
          const item = { value: option, label: option };
          if (timestampOptions.includes(option)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [timestampOptions, data]
  );

  const handleSelectChange = useCallback(
    (options: Type.SelectOption[]) => {
      setValues(isMulti ? options?.map(i => i.value) || [] : [(options as any)?.value]);
    },
    [isMulti, setValues]
  );

  return (
    <FormReactSelect
      labelKey={labelKey}
      {...props}
      isMulti={isMulti}
      isLoading={loading}
      isDisabled={(data && !data.length) || isDisabled}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      onBlur={onBlur}
    />
  );
};

export default SelectTimestampOptions;
