import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import React, { useState, useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { AsyncTypeahead, Menu, MenuItem, TypeaheadResult, TypeaheadMenuProps, Hint } from 'react-bootstrap-typeahead';
import { useLocale } from 'hooks';
import { fetchMapAssetsByCodeAction } from 'modules/assets';
import { setLayoutAction } from 'modules/layouts';
import { settingsSelector } from 'modules/map/selectors';
import {
  singleDiagramFilterPrimarySubstationsIdSelector,
  singleDiagramFilterVoltagesIdsSelector,
} from 'modules/layouts/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import { OverlayTriggerTooltip } from 'components/_common';
import { Routes } from 'constants/index';
import { IconVoltage } from '@utiligize/shared/resources';
import 'react-bootstrap-typeahead/css/Typeahead.css';

interface Props {
  singleDiagramEnabled: boolean;
}

const AssetSearch: React.FC<Props> = ({ singleDiagramEnabled }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const singleDiagramFilterVoltagesIds = useSelector(singleDiagramFilterVoltagesIdsSelector);
  const singleDiagramFilterPrimarySubstationsId = useSelector(singleDiagramFilterPrimarySubstationsIdSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [withGeometry, setWithGeometry] = useState(!settings.isAssetGroupDisabled);
  const [options, setOptions] = useState<Asset.MapAssetCodeItem[]>([]);

  const debouncedSearch = useDebouncedCallback((query: string) => {
    setIsLoading(true);
    dispatch(fetchMapAssetsByCodeAction(query, !singleDiagramEnabled && withGeometry))
      .then((action: Shared.ReduxAction<Asset.MapAssetCodeItem[]>) => setOptions(action.payload))
      .finally(() => setIsLoading(false));
  }, 1000);

  useEffect(() => debouncedSearch.cancel, [debouncedSearch.cancel]);

  const openUnifiedAssetPanel = useCallback(
    async (selected: Asset.MapAssetCodeItem[]) => {
      const { uuid, voltage_level_id, primary_substation_id } = selected[0];

      // Note. Search works with global assets storage, so we have to update single-line automatically
      const mustUpdateVoltages = !singleDiagramFilterVoltagesIds?.includes(voltage_level_id);
      const mustUpdateSubstations = singleDiagramFilterPrimarySubstationsId !== primary_substation_id;
      if (singleDiagramEnabled && (mustUpdateVoltages || mustUpdateSubstations)) {
        await dispatch(
          setLayoutAction({
            ...(mustUpdateVoltages
              ? {
                  singleDiagramFilterVoltagesIds: [...(singleDiagramFilterVoltagesIds || []), voltage_level_id],
                }
              : {}),
            ...(mustUpdateSubstations ? { singleDiagramFilterPrimarySubstationsId: primary_substation_id } : {}),
          })
        );
      }

      return dispatch(push(generatePath(Routes.Map, { uuid }), { keepMapState: singleDiagramEnabled }));
    },
    [dispatch, singleDiagramEnabled, singleDiagramFilterVoltagesIds, singleDiagramFilterPrimarySubstationsId]
  );

  const handlePresentOnTheMapChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setWithGeometry(e.target.checked);
  }, []);

  const renderInput = ({ inputRef, referenceElementRef, ...inputProps }: any) => {
    const FormControl = (
      <div className="position-relative">
        <Hint>
          <>
            <Form.Control
              {...inputProps}
              ref={(node: any) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
            <i className="fas fa-search fa-stack-1x" />
          </>
        </Hint>
      </div>
    );

    return inputProps.disabled ? (
      <OverlayTriggerTooltip
        placement="bottom"
        overlayId="map-asset-search-tooltip"
        overlayChildren={getIntl('There are no assets available on the map')}
      >
        {FormControl}
      </OverlayTriggerTooltip>
    ) : (
      FormControl
    );
  };

  const renderMenu = (
    results: TypeaheadResult<Asset.MapAssetCodeItem>[],
    menuProps: TypeaheadMenuProps<Asset.MapAssetCodeItem>
  ) => (
    <Menu {...menuProps}>
      {results.length ? (
        results.map(
          (result, index: number) =>
            result.code && (
              <MenuItem key={index} option={result} position={index}>
                {menuProps.renderMenuItemChildren?.(result, menuProps, index)}
              </MenuItem>
            )
        )
      ) : (
        <div className="dropdown-item disabled">{menuProps.emptyLabel}</div>
      )}
    </Menu>
  );

  const renderMenuItemChildren = (option: TypeaheadResult<Asset.MapAssetCodeItem>) => (
    <span className="search-option">
      <div>
        {option.code}
        {option.map_asset_name && (
          <span className="inactive">
            &nbsp;•&nbsp;
            {getIntl(option.map_asset_name)}
          </span>
        )}
      </div>
      {option.map_asset_name && (
        <div className="inactive">
          {getIntl(option.voltage_level)}
          <IconVoltage className="voltage-icon" />
        </div>
      )}
    </span>
  );

  return (
    <StyledWrapper>
      <StyledAssetSearch>
        <AsyncTypeahead
          id="map-asset-search"
          filterBy={() => true}
          labelKey={option => option.code}
          isLoading={isLoading}
          minLength={1}
          onSearch={debouncedSearch}
          options={options}
          onChange={openUnifiedAssetPanel}
          placeholder={getIntl(withGeometry ? 'Search for an asset on the map' : 'Search for any asset')}
          emptyLabel={getIntl('Asset is not found')}
          renderInput={renderInput}
          renderMenuItemChildren={renderMenuItemChildren}
          renderMenu={renderMenu}
          maxResults={30}
          useCache={false}
          disabled={settings.isAssetGroupDisabled}
        />
      </StyledAssetSearch>
      {!singleDiagramEnabled && (
        <ControlBox
          type="switch"
          checked={withGeometry}
          labelKey="Only present on the map"
          onChange={handlePresentOnTheMapChange}
          disabled={settings.isAssetGroupDisabled}
          className="mt-3 mb-0"
        />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin: 10px 18px 0px;

  .search-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
  }

  .inactive {
    color: var(--map-text-color-secondary-light);

    path {
      fill: var(--map-text-color-secondary-light);
    }
  }
`;

const StyledAssetSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;

  .dropdown-menu {
    border: 1px solid var(--map-outline-color);
    border-radius: 8px;
    background-color: #f7fafd;
    user-select: none;
    box-shadow: 0px 2px 2px #44366630;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: ${props => `calc(100vh - ${props.theme.heights.topNavigation} - 10px - 35.75px - 15px) !important`};

    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-item {
      padding: 5px 10px;
      color: var(--map-text-color-primary);
      font-style: normal;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.04em;

      &.active {
        background-color: var(--map-active-color);
        color: #fff;

        svg path {
          fill: #fff;
        }
      }

      &:hover:not(.active) {
        background-color: ${props => props.theme.colors.purple50};
      }
    }
  }

  & > div {
    width: 100%;
  }

  .fa-search {
    width: 1rem;
    position: absolute;
    top: 1px;
    left: 16px;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--map-text-color-secondary);
    transition: color 0.2s linear;
  }

  input.form-control {
    width: 100%;
    padding-left: 40px !important;
    background: #ffffff;
    border: 0.5px solid var(--map-outline-color);
    box-shadow: 0px 2px 2px #44366630;
    border-radius: 8px;
    padding: 15px;
    font-size: 12px;
    letter-spacing: 0.02em;

    &[aria-expanded='true'] {
      cursor: default;
    }

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: var(--map-text-color-secondary);
    }

    &:not(:disabled):hover {
      border-color: var(--map-active-color);

      + .fa-search {
        color: var(--map-active-color);
      }
    }

    &:not(:disabled):focus {
      border-color: var(--map-active-color);

      + .fa-search {
        color: var(--map-active-color);
      }
    }

    &:disabled {
      color: var(--map-text-color-secondary);
      cursor: not-allowed;

      + .fa-search {
        opacity: 0.5;
        color: var(--map-text-color-secondary);
      }

      &::placeholder {
        opacity: 0.6;
      }
    }
  }

  .voltage-icon {
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
`;

export default AssetSearch;
