import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { NodeAPI, PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetModelsAction: any = createAction(
  'assetModels/FETCH_ASSET_MODELS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<AssetModels.Root, 'count' | 'itemsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(
        state
      );
      return NodeAPI.get('v1/secure/assetmodels', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          lang: appLangSelector(state).toLowerCase(),
          assetCategoryCode: filters?.assetcategoryCode,
          subComponentTypeId: filters?.subComponentTypeId,
        },
      }).then(res => ({
        count: res.data.count,
        itemsHash: _keyBy(res.data.rows, (item: AssetModels.Item) => `_${item.modelId}_`),
        skipStoreUpdate,
      }));
    }
);

export const fetchAllAssetModelsAction: any = createAction(
  'assetModels/FETCH_ALL_ASSET_MODELS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<AssetModels.Item[]> => {
      const state = getState();
      const { sort, column } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(state);
      return NodeAPI.get('v1/secure/assetmodels/all', { params: { sort, column } }).then(res => res.data.rows);
    }
);

interface Data {
  id?: number;
  name: string;
  assetcategoryId: number;
  manufacturerId: number;
  replacementOption: boolean;
  replacementPriority: number;
  lastReplacementDate: string;
}

export const createAssetModelAction: any = createAction(
  'assetModels/CREATE_ASSET_MODEL',
  async (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.post('v1/secure/assetmodels', data).then(async () => {
        await dispatch(fetchAssetModelsAction());
        dispatch(setSuccessToastAction('Asset model has been created'));
      });
    }
);

export const updateAssetModelAction: any = createAction(
  'assetModels/UPDATE_ASSET_MODEL',
  async (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.put(`v1/secure/assetmodels/${data.id}`, {
        name: data.name,
        assetcategoryId: data.assetcategoryId,
        manufacturerId: data.manufacturerId,
        replacementOption: data.replacementOption,
        replacementPriority: data.replacementPriority,
        lastReplacementDate: data.lastReplacementDate,
      }).then(async () => {
        await dispatch(fetchAssetModelsAction());
        dispatch(setSuccessToastAction('Asset model has been updated'));
      });
    }
);

export const deleteAssetModelAction: any = createAction('assetModels/DELETE_ASSET_MODEL', async (id: string) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    NodeAPI.delete(`v1/secure/assetmodels/${id}`).then(async () => {
      await dispatch(fetchAssetModelsAction());
      dispatch(setSuccessToastAction('Asset model has been deleted'));
    });
});

export const patchAssetModelAction: any = createAction(
  'assetModels/PATCH_ASSET_MODEL',
  async (id: string, data: Partial<{ replacementOption: boolean; replacementPriority: number }>) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(state);
      return NodeAPI.patch(`v1/secure/assetmodels/${id}`, data, {
        params: {
          assetCategoryCode: filters?.assetcategoryCode,
          subComponentTypeId: filters?.subComponentTypeId,
        },
      }).then(async () => dispatch(fetchAssetModelsAction()));
    };
  }
);
