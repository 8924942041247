import styled from 'styled-components';
import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import {
  settingsSelector,
  enabledLayersListSelector,
  mapStateCollapseGroupStateSelector,
  mapStateEnabledLayersSelector,
  mapStateLayerFiltersSelector,
  mapStateHeatmapScenarioSelector,
  newCustomersLayerCountSelector,
} from 'modules/map/selectors';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import RangeSlider from 'components/Map/common/RangeSlider';
import ScaleContainer from 'components/Map/common/ScaleContainer';
import LayerElementsCount from 'components/Map/common/LayerElementsCount';
import { NewCustomersLegend } from 'components/Map/core/layers/other/NewCustomers';
import { Scale } from 'components/Map/common';
import ControlBoxWithCollapseIcon from './CheckboxWithCollapseIcon';
import { showLayer, hideLayer, layerListToRecord, mapColorGradient, getDefaultFilterIds } from 'utils/map';

interface Props {
  map: Map.MapboxMap;
  mapLayers: Map.StyleLayer[];
  item: Map.LegendItem;
}

const GroupAssetList: React.FC<Props> = ({ map, mapLayers, item }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const layerFilters = useSelector(mapStateLayerFiltersSelector);
  const enabledLayers = useSelector(mapStateEnabledLayersSelector);
  const heatmapScenario = useSelector(mapStateHeatmapScenarioSelector);
  const collapseGroupState = useSelector(mapStateCollapseGroupStateSelector);
  const enabledLayersList = useSelector(enabledLayersListSelector);
  const newCustomersLayerCount = useSelector(newCustomersLayerCountSelector);

  const heatmapLayerId = useMemo(() => settings.otherLayers!.find(i => i.includes('heatmap')), [settings.otherLayers]);
  const heatmapFilter = useMemo(() => layerFilters[heatmapLayerId!], [layerFilters, heatmapLayerId]);

  const HeatMapScale = useMemo(() => {
    if (!heatmapFilter?.list) return null;
    const maxValue = settings.heatmapMaxValues?.[heatmapFilter.list[0]] ?? 100;
    const colors = mapColorGradient(selectedMapTheme, 'default', 0, maxValue);
    const values = colors.filter(c => typeof c === 'number') as number[];
    return <Scale theme={selectedMapTheme} values={values} />;
  }, [selectedMapTheme, heatmapFilter?.list]); // eslint-disable-line

  const toggleFilters = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      { name, show_satellite }: Map.LegendItem,
      filterId?: string | number
    ) => {
      // 1. find layer filters using layerId
      const layer = mapLayers.find(k => k.legend?.id === name);
      if (!layer) return;

      const filters = layer?.legend?.filters;
      const checked = event.target.checked;
      const layers = [...settings.assetLayers!, ...settings.cnaimLayers!, ...settings.otherLayers!].filter(i =>
        i.startsWith(name)
      );

      const getMapState = (
        singleState: (string | number)[],
        pluralState: (string | number)[],
        isLayerVisible: boolean
      ) => {
        const nextLayerFilters = filters
          ? ({
              ...filters,
              list: name === 'other__heatmaps' ? singleState : pluralState,
            } as Map.LayerFilter)
          : null;

        // automatically enable / disable satellite layer
        let satelliteVisibility = map.getLayoutProperty('mapbox-satellite', 'visibility') === 'visible';
        if (show_satellite && nextLayerFilters?.list?.length && !satelliteVisibility) {
          satelliteVisibility = true;
          showLayer(map, 'mapbox-satellite');
        } else if (show_satellite && !nextLayerFilters?.list?.length && satelliteVisibility) {
          satelliteVisibility = false;
          hideLayer(map, 'mapbox-satellite');
        }

        return {
          ...(nextLayerFilters ? { layerFilters: { ...layerFilters, [name]: nextLayerFilters } } : {}),
          satelliteVisibility,
          enabledLayers: {
            ...enabledLayers,
            ...layerListToRecord(layers, isLayerVisible),
          },
        };
      };

      // 3. handle filter checkbox click
      if (filterId) {
        const prevFiltersState = layerFilters[name]?.list ?? [];
        const nextFiltersState = checked
          ? [...prevFiltersState, filterId]
          : prevFiltersState.filter(i => i !== filterId);

        // 3.1 set layout map property if first/last checkbox clicked
        const isNextFiltersStateEmpty = !Boolean(nextFiltersState.length);
        layers.forEach(i => (!isNextFiltersStateEmpty ? showLayer(map, i) : hideLayer(map, i)));

        // 3.2 update enabled layers + layer filters in the redux store
        return dispatch(mapStateAction(getMapState([filterId], nextFiltersState, Boolean(nextFiltersState.length))));
      }
      // 4. Root checkbox click handler
      // 4.1 set layout map property if first/last checkbox clicked
      layers.forEach(i => (checked ? showLayer(map, i) : hideLayer(map, i)));
      const filterIds = getDefaultFilterIds(filters?.list || []);
      // 4.2 update enabled layers + layer filters in the redux store
      dispatch(mapStateAction(getMapState(checked ? [filterIds[0]] : [], checked ? filterIds : [], checked)));
    },
    [
      dispatch,
      settings.assetLayers,
      settings.otherLayers,
      settings.cnaimLayers,
      mapLayers,
      layerFilters,
      map,
      enabledLayers,
    ]
  );

  const handleHeatmapScenarioYearChange = useCallback(
    (value: number) => {
      const year = heatmapScenario?.years[Number(value)];
      if (!year) return;
      const id = heatmapScenario?.years0.includes(year) ? 0 : heatmapScenario?.id;
      const key = `${id}_${year}`;
      const s = { ...heatmapScenario, id, year, key } as Map.Scenario;
      dispatch(mapStateAction({ heatmapScenario: s }));
    },
    [heatmapScenario, dispatch]
  );

  const renderHTMLLayer = (item: Map.LegendItem): React.ReactNode => {
    const layer = mapLayers.find(k => k.legend?.id === item.name);
    if (!layer) return null;

    const hasFilters = Boolean(layer?.legend?.filters?.list?.length);
    const isOtherHeatmapsLegendItemName = item.name === 'other__heatmaps';

    const checked = hasFilters
      ? Boolean(layerFilters[item.name]?.list?.length)
      : enabledLayersList.some(i => i.startsWith(item.name));

    const collapsed = collapseGroupState?.[item.name];

    return (
      <>
        <div className="position-relative">
          <ControlBoxWithCollapseIcon
            item={item}
            showIcon={hasFilters}
            checked={checked}
            onControlClick={toggleFilters}
          />
          {layer.legend?.iconClass !== 'legend-filter' && (
            <StyledFilterIcon>
              <LayerElementsCount quantity={item.name === NewCustomersLegend.name ? newCustomersLayerCount : null}>
                <i className={classnames([item.name, layer.legend?.iconClass], { 'd-none': !checked })} />
              </LayerElementsCount>
            </StyledFilterIcon>
          )}
        </div>
        {isOtherHeatmapsLegendItemName && heatmapScenario && checked && collapsed && (
          <div style={{ marginLeft: '26px' }}>
            {heatmapScenario.years.length > 0 ? (
              <>
                <ScaleContainer
                  className="pb-4"
                  title={getIntl(
                    !heatmapScenario?.years0?.includes(heatmapScenario.year) ? 'Forecasted amount' : 'Historical amount'
                  )}
                  scale={HeatMapScale}
                />
                {heatmapScenario.years.length > 1 ? (
                  <RangeSlider
                    min={0}
                    max={heatmapScenario.years.length - 1}
                    value={heatmapScenario.years.findIndex(y => y === heatmapScenario.year)}
                    onChange={handleHeatmapScenarioYearChange}
                    tooltipLabel={i => `${heatmapScenario.years[i] ?? getIntl('Unknown')}`}
                    resetOn={heatmapScenario.id}
                    stopOn={checked}
                    className="w-100 mt-4 pt-1"
                    dataMarker="heatmap_track"
                  />
                ) : (
                  <div className="summary-no-data mb-2">
                    {getIntl('Data on the map is only available for the year')} {heatmapScenario.years[0]}
                  </div>
                )}
              </>
            ) : (
              <div className="summary-no-data">{getIntl('No data available for current scenario')}</div>
            )}
          </div>
        )}
        {hasFilters && collapsed && (
          <ul className="filter-values" data-marker={`${item.name}__filters`}>
            {layer.legend!.filters!.list.map(filter => (
              <li key={filter.id}>
                <ControlBox
                  type={isOtherHeatmapsLegendItemName ? 'radio' : 'checkbox'}
                  id={String(filter.id)}
                  name={String(filter.name)}
                  dataMarker={`filter_${filter.name.replaceAll(' ', '_').toLowerCase()}`}
                  checked={(layerFilters[item.name]?.list?.includes(filter.id) && !filter.disabled) ?? false}
                  labelKey={`${getIntl(filter.name)}${filter.name2 ? ': ' + getIntl(filter.name2!) : ''}`}
                  hint={getIntl(filter.hint ?? '')}
                  disabled={filter.disabled}
                  onChange={e => toggleFilters(e, item, filter.id)}
                />
                {(filter.color || filter.icon) && (
                  <StyledFilterIcon>
                    <LayerElementsCount quantity={filter.cnt!}>
                      {filter.color && (
                        <i className={layer.legend?.iconClass} style={{ backgroundColor: filter.color }} />
                      )}
                      {/* FixMe. Use React icons from the Popup instead of css */}
                      {filter.icon && <i className={classnames([layer.legend?.iconClass, filter.icon])} />}
                    </LayerElementsCount>
                  </StyledFilterIcon>
                )}
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  if (!item.layers) return null;
  return (
    <>
      {item.layers.map(layer => (
        <div key={layer.name} className="position-relative">
          {renderHTMLLayer(layer)}
        </div>
      ))}
    </>
  );
};

const StyledFilterIcon = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  i {
    position: static !important;
    transform: none !important;
  }
`;

export default GroupAssetList;
