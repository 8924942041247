import React from 'react';
import ChartStatisticsCompleteActions from './ChartStatisticsCompleteActions';
import ChartStatisticsFinalChecks from './ChartStatisticsFinalChecks';
import { ContentContainer } from 'components/_common';

const ViewStatisticsActions: React.FC = () => {
  const height = 'calc((100vh - 190px) / 2)';
  return (
    <ContentContainer>
      <ChartStatisticsCompleteActions height={height} />
      <ChartStatisticsFinalChecks height={height} />
    </ContentContainer>
  );
};

export default ViewStatisticsActions;
