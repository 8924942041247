import { createAction } from 'redux-actions';
import queryString from 'query-string';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { setPaginationAction, setSuccessToastAction } from 'modules/layouts';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { appCurrentUserIdSelector } from 'modules/app/selectors';
import { replacementReasonOptionsHashSelector, voltageLevelsOptionsHashSelector } from './selectors';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Detailed Plan Actions
// ------------------------------------
export const fetchSummaryPlanAction: any = createAction(
  'investment/FETCH_SUMMARY_PLAN',
  async ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    skipPagination,
    skipStoreUpdate,
    inputFilters,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    simulationId: Layouts.Root['simulationId'];
    versionId: number;
    skipPagination: boolean;
    skipStoreUpdate: boolean;
    inputFilters: Layouts.Filters;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'summaryPlanCount' | 'summaryPlanCurrency' | 'summaryPlanItems'>> => {
      const state = getState();
      const {
        limit,
        offset,
        sort,
        column,
        query,
        filters: paginationFilters,
      } = paginationSelectorFactory(PaginationType.SUMMARY_PLAN)(state);
      const filters = inputFilters || paginationFilters;
      return AssetLifeAPI.get('/investment/summary_plan', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioId || portfolioIdSelector(state),
          scenario_id: scenarioId || scenarioIdSelector(state),
          simulation_id: simulationId || simulationIdSelector(state),
          version_id: versionId || simulationVersionIdSelector(state),
          start_year: filters?.startYear,
          end_year: filters?.endYear,
          asset_category_ids: filters?.assetCategories || undefined,
          replacement_reasons: filters?.replacementReasons || undefined,
          grid_zone_ids: filters?.gridZones || undefined,
          voltage_level_ids: filters?.voltageLevels || undefined,
          primary_substation_ids: filters?.primarySubstationsIds || undefined,
          summary_group_bys: filters?.summaryGroupIds || undefined,
          flexible: filters?.flex && Boolean(filters?.flex),
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then(res => ({
        summaryPlanCount: res.data.count,
        summaryPlanItems: res.data.rows,
        summaryPlanCurrency: res.data.meta?.currency,
        skipStoreUpdate,
      }));
    }
);

export const fetchDetailedPlanAction: any = createAction(
  'investment/FETCH_DETAILED_PLAN',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'detailedPlanCount' | 'detailedPlanCurrency' | 'detailedPlanItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.DETAILED_INVESTMENTS
      )(state);
      return AssetLifeAPI.get('investment/detailed_plan', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          simulation_id: simulationIdSelector(state),
          version_id: simulationVersionIdSelector(state),
          start_year: filters?.startYear,
          end_year: filters?.endYear,
          asset_category_ids: filters?.assetCategories || undefined,
          replacement_reasons: filters?.replacementReasons || undefined,
          grid_zone_ids: filters?.gridZones || undefined,
          voltage_level_ids: filters?.voltageLevels || undefined,
          primary_substation_ids: filters?.primarySubstationsIds || undefined,
          flexible: Boolean(filters?.flex),
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then(res => ({
        detailedPlanCount: res.data.count,
        detailedPlanCurrency: res.data.meta?.currency,
        detailedPlanItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const updateDetailedPlanAction = createAction(
  'investment/PUT_DETAILED_PLAN',
  async (data: {
    asset_id: Investment.DetailedInvestmentsItem['id'];
    nl_replacement_year: Investment.DetailedInvestmentsItem['suggested_replacement_year'];
    user_replacement_year: number;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      return AssetLifeAPI.put('investment/update_investment_year', {
        ...data,
        portfolio_id: portfolioIdSelector(state),
        scenario_id: scenarioIdSelector(state),
        sso_user_id: appCurrentUserIdSelector(state),
      }).then(async () => {
        await dispatch(fetchDetailedPlanAction());
        dispatch(setSuccessToastAction('Changes has been saved'));
      });
    }
);

export const fetchDetailedPlanItemsAction = createAction(
  'investment/FETCH_DETAILED_PLAN_ITEM',
  async (uuid: string) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    const state = getState();
    return AssetLifeAPI.get<Investment.DetailedInvestmentsItem[]>(`investment/detailed_plan/${uuid}`, {
      params: {
        portfolio_id: portfolioIdSelector(state),
        scenario_id: scenarioIdSelector(state),
        simulation_id: simulationIdSelector(state),
        version_id: simulationVersionIdSelector(state),
      },
      validateStatus: status => [200, 404].includes(status),
    }).then(res => (res.status === 404 ? null : res.data));
  }
);

export const fetchAssetCategoriesOptionsAction = createAction(
  'investment/FETCH_INVESTMENT_ASSET_CATEGORIES',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'assetCategoriesOptionsHash'>> => {
      return AssetLifeAPI.get('/investment/asset_categories_detailed_plan', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: { data: { cnaim_id: number; asset_register_category: string }[] }) => {
        // Reset value on portfolio or scenario change
        [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const ids = res.data?.map(item => item.cnaim_id);
          if (filters?.assetCategories?.some(id => !ids.includes(id))) {
            const modifier = { filters: { ...filters, assetCategories: null }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          assetCategoriesOptionsHash: {
            ...getState().investment.assetCategoriesOptionsHash,
            [`${portfolioId}_${scenarioId}`]: res.data.map(item => ({
              value: item.cnaim_id,
              label: item.asset_register_category,
            })),
          },
        };
      });
    }
);

export const fetchGridZonesOptionsAction = createAction(
  'investment/FETCH_GRID_ZONES',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'gridZonesOptionsHash'>> => {
      return AssetLifeAPI.get('/shared/grid_zones', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: { data: { description: string; id: number }[] }) => {
        // Reset value on portfolio or scenario change
        [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const ids = res.data?.map(item => item.id);
          if (filters?.gridZones?.some(id => !ids.includes(id))) {
            const modifier = { filters: { ...filters, gridZones: null }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          gridZonesOptionsHash: {
            ...getState().investment.gridZonesOptionsHash,
            [`${portfolioId}_${scenarioId}`]: res.data.map(item => ({ value: item.id, label: item.description })),
          },
        };
      });
    }
);

export const fetchVoltageLevelsOptionsAction = createAction(
  'investment/FETCH_VOLTAGE_LEVELS',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'voltageLevelsOptionsHash'>> => {
      return AssetLifeAPI.get('/shared/voltage_levels', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: { data: { voltage_level_text: string; id: number }[] }) => {
        const voltageLevels = res.data?.map(item => item.id);

        // Reset value on portfolio or scenario change
        [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          if (filters?.voltageLevels?.some(id => !voltageLevels.includes(id))) {
            const modifier = { filters: { ...filters, voltageLevels: null }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        [PaginationType.ISSUES].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          if (!filters?.voltageLevels || filters?.voltageLevels?.some(id => !voltageLevels.includes(id))) {
            const modifier = { filters: { ...filters, voltageLevels }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          voltageLevelsOptionsHash: {
            ...voltageLevelsOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: res.data.map(item => ({
              value: item.id,
              label: item.voltage_level_text,
            })),
          },
        };
      });
    }
);

export const applyReplacementReasonFiltersAction = createAction(
  'investment/APPLY_REPLACEMENT_REASON_FILTERS',
  async (options: Type.SelectOption[]) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): void => {
      const ids = options?.map(option => option.value);

      // Reset value on portfolio or scenario change
      [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
        const state = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        if (filters?.replacementReasons?.some(id => !ids.includes(id))) {
          const modifier = { filters: { ...filters, replacementReasons: null }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });
    }
);

export const fetchReplacementReasonOptionsAction = createAction(
  'investment/FETCH_REPLACEMENT_REASON',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'replacementReasonOptionsHash'>> => {
      return AssetLifeAPI.get('/investment/replacement_reason_detailed_plan', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const hash = res.data;
        const replacementReasons = Object.keys(hash);
        const options = replacementReasons.map((value: string) => ({ value, label: hash[value] }));
        dispatch(applyReplacementReasonFiltersAction(options));
        return {
          replacementReasonOptionsHash: {
            ...replacementReasonOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const fetchSummaryPlanSummaryGroupOptionsAction = createAction(
  'investment/FETCH_SUMMARY_PLAN_SUMMARY_GROUP_OPTIONS',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Investment.Root, 'summaryPlanGroupOptionsHash'>> => {
      return AssetLifeAPI.get('/investment/summary_group_by', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const options = Object.keys(res.data);

        // Reset value on portfolio or scenario change
        [PaginationType.SUMMARY_PLAN].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          if (filters?.summaryGroupIds?.some(id => !options.includes(id))) {
            const modifier = { filters: { ...filters, summaryGroupIds: null }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          summaryPlanGroupOptionsHash: {
            ...getState().investment.summaryPlanGroupOptionsHash,
            [`${portfolioId}_${scenarioId}`]: options.map((value: string) => ({
              value,
              label: res.data[value],
            })),
          },
        };
      });
    }
);
