import { createAction } from 'redux-actions';
import { _keyBy } from '@utiligize/shared/utils';
import { NodeAPI } from 'constants/index';
// ------------------------------------
// Actions
// ------------------------------------
export const fetchUsersAction: any = createAction('users/FETCH', async () => {
  return (dispatch: Shared.CustomDispatch): Promise<Record<string, Users.User>> => {
    return NodeAPI.get('v1/secure/users', { params: { extendedInformation: true } }).then(res =>
      _keyBy(res.data, (item: Users.User) => `_${item.id}_`)
    );
  };
});
