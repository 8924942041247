import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFutureChartData, setFutureChartData } from 'modules/networkLoading';
import { futureChartDataHashSelector } from 'modules/networkLoading/selectors';
import Chart, { getBaseOptions } from 'components/_charts/Chart';
import { ChartTypeKeys, ChartTypesOption } from './SelectChartType';
import { IDsState } from './ViewAnalysisTool';

export interface Props {
  voltage: Layouts.TransformerVoltages | Layouts.CableVoltages;
  typeOption: ChartTypesOption;
  ids: IDsState[];
  setLoading: React.Dispatch<boolean>;
}

const ChartAnalysis: React.FC<Props> = ({ ids, setLoading, voltage, typeOption }) => {
  const isPercentageYAxis = [ChartTypeKeys.TR_LOADING, ChartTypeKeys.CABLES_LOADING].includes(typeOption.key);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const futureChartDataHash = useSelector(futureChartDataHashSelector);

  const getStoreKey = useCallback(
    ({ portfolioId, scenarioId, simulationId, versionId, flex }: Omit<IDsState, 'id'>) =>
      `${typeOption.asset_class}_${voltage}_${portfolioId}_${scenarioId}_${simulationId}_${versionId}_${flex}`,
    [typeOption, voltage]
  );

  const emptyDataIds = useMemo(
    () =>
      ids.filter(({ portfolioId, scenarioId, simulationId, versionId, flex }) => {
        const storeKey = getStoreKey({ portfolioId, scenarioId, simulationId, versionId, flex });
        return !futureChartDataHash?.[storeKey];
      }),
    [ids, futureChartDataHash, getStoreKey]
  );

  useEffect(() => {
    if (!emptyDataIds.length) return;
    setLoading(true);
    Promise.all(
      emptyDataIds.map(({ portfolioId, scenarioId, simulationId, versionId, flex }) => {
        const storeKey = getStoreKey({ portfolioId, scenarioId, simulationId, versionId, flex });
        return dispatch(
          fetchFutureChartData({
            asset_class: typeOption.asset_class!,
            voltage,
            portfolioId,
            scenarioId,
            simulationId,
            versionId: versionId!,
            storeKey,
            skipStoreUpdate: true,
            flex,
          })
        ).then(action => action.payload.futureChartDataHash);
      })
    )
      .then(results => {
        dispatch(setFutureChartData(results.reduce((acc, item) => ({ ...acc, ...item }), {})));
      })
      .finally(() => setLoading(false));
  }, [dispatch, emptyDataIds, setLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const chartData = useMemo(() => {
    // Initialize a flag to track missing chart data
    let hasMissingData = false;
    return ids.reduce((acc: any, { portfolioId, scenarioId, simulationId, versionId, flex }) => {
      const storeKey = getStoreKey({ portfolioId, scenarioId, simulationId, versionId, flex });
      const chartData = futureChartDataHash?.[storeKey]?.[typeOption.index!];
      if (!chartData || hasMissingData) {
        hasMissingData = true;
        return null;
      }
      if (!acc) return { ...chartData };
      return { ...acc, series: [...acc.series, ...chartData.series!] };
    }, null);
  }, [futureChartDataHash, ids, typeOption.index, getStoreKey]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, {
      title: chartData?.title,
      xAxisTitle: chartData?.xAxisTitle,
      yAxisTitle: chartData?.yAxisTitle,
    });
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        formatter(this: Highcharts.Point & { points: Highcharts.Point[] }) {
          return `<b>${this.x}</b><br>${this.points?.map(point => `${point.series.name} - <b>${point.y}${isPercentageYAxis ? '%' : ''}</b><br>`).join('')}`;
        },
      },
      series: chartData?.series?.map((s: any) => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: {
        useHTML: true,
      },
    };
  }, [chartData, getIntl, isPercentageYAxis]) as unknown as Highcharts.Options;

  return (
    <Chart
      options={chartData?.series || !ids.length ? options : null}
      dataMarker="chart_analysis_tool"
      height="calc(100vh - 420px)"
    />
  );
};

export default ChartAnalysis;
