import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { portfolioIdSelector, isMapConfigFetchedSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { fetchConfigAction } from 'modules/map';
import MapContainer from 'components/Map/core/MapContainer';
import { ErrorBoundary, MapNotifications, Spinner } from 'components/_common';
import { isPortfolioLoadflowEnabledSelector, simulationVersionIdSelector } from 'modules/options/selectors';

const Map: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const isPortfolioLoadflowEnabled = useSelector(isPortfolioLoadflowEnabledSelector);
  const isMapConfigFetched = useSelector(isMapConfigFetchedSelector);

  useEffect(() => {
    // Note. We have to wait for simulationId and versionId to be fetched before fetching the map config, isPortfolioLoadflowEnabled = true
    if (!portfolioId || (isPortfolioLoadflowEnabled && (!simulationId || !versionId))) return;
    dispatch(
      fetchConfigAction({
        portfolioId,
        versionId: versionId || null,
        simulationId: simulationId || null,
      })
    );
  }, [dispatch, portfolioId, isPortfolioLoadflowEnabled, simulationId, versionId]);

  return (
    <StyledSection>
      <ErrorBoundary>
        <MapNotifications />
        {!isMapConfigFetched ? <Spinner isInFullHeightContainer /> : <MapContainer />}
      </ErrorBoundary>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  position: relative;
  height: calc(100vh - ${props => props.theme.heights.topNavigation});
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Map;
