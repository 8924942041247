import Highcharts from 'highcharts';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart, { getBaseOptions } from 'components/_charts/Chart';
import { timeByActionTypeChartFilteredDataSelector } from 'modules/maintenance/selectors';
import { fetchTimeByActionTypeChartDataAction } from 'modules/maintenance/actions';

const ChartTimeByActionType: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const chartData = useSelector(timeByActionTypeChartFilteredDataSelector);

  useEffect(() => {
    dispatch(fetchTimeByActionTypeChartDataAction());
  }, [dispatch]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        categories: chartData?.categories?.map(c => getIntl(c)),
      },
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `<b>${getIntl('Average time (h)')}:</b> ${numberFormat(this.y)}`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    };
  }, [chartData, getIntl, numberFormat]) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_maintenance_time_by_action_type" />;
};

export default ChartTimeByActionType;
