import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _omit, _keyBy } from '@utiligize/shared/utils';
import { Routes, PaginationType, NodeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFormsAction = createAction(
  'forms/FETCH_FORMS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Forms.Root, 'count' | 'itemsHash' | 'builderData'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.FORMS)(state);
      const lang = appLangSelector(state).toLowerCase();
      return NodeAPI.get('v1/secure/forms', {
        params: { limit, offset, sort, column, query, lang },
      }).then(res => ({
        count: res.data.count,
        itemsHash: _keyBy(res.data.rows, (item: Forms.Item) => `_${item.id}_`),
        builderData: _omit(res.data, ['rows', 'count']) as Builder.Data,
      }));
    }
);

export const fetchFormSnapshotAction = createAction('forms/FETCH_SNAPSHOT', async (formId: number) => {
  return NodeAPI.get('v1/secure/forms/snapshot', { params: { formId } }).then(res => res.data);
});

export const createFormAction = createAction(
  'forms/CREATE_FORM',
  async (
    dataSnapshot: { blocks: Builder.SnapshotBlock[]; formOptions: Builder.SelectValue },
    {
      isFormBuilder,
      isInstructionBuilder,
      isToolInspectionBuilder,
    }: { isFormBuilder: boolean; isInstructionBuilder: boolean; isToolInspectionBuilder: boolean }
  ) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.post('v1/secure/forms/create', dataSnapshot).then(() => {
        switch (true) {
          case isFormBuilder:
            dispatch(push(Routes.Forms));
            dispatch(setSuccessToastAction('Form has been created'));
            return;
          case isInstructionBuilder:
            dispatch(push(Routes.Instructions));
            dispatch(setSuccessToastAction('Instruction has been created'));
            return;
          case isToolInspectionBuilder:
            dispatch(push(Routes.Inspections));
            dispatch(setSuccessToastAction('Tool inspection has been created'));
            return;
        }
      });
    }
);

export const updateFormAction = createAction(
  'forms/UPDATE_FORM',
  async (
    dataSnapshot: { blocks: Builder.SnapshotBlock[]; formOptions: Builder.SelectValue },
    {
      isFormBuilder,
      isInstructionBuilder,
      isToolInspectionBuilder,
    }: { isFormBuilder: boolean; isInstructionBuilder: boolean; isToolInspectionBuilder: boolean }
  ) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.put('v1/secure/forms/update', dataSnapshot).then(() => {
        switch (true) {
          case isFormBuilder:
            dispatch(push(Routes.Forms));
            dispatch(setSuccessToastAction('Form has been saved'));
            return;
          case isInstructionBuilder:
            dispatch(push(Routes.Instructions));
            dispatch(setSuccessToastAction('Instruction has been saved'));
            return;
          case isToolInspectionBuilder:
            dispatch(push(Routes.Inspections));
            dispatch(setSuccessToastAction('Tool inspection has been saved'));
            return;
        }
      });
    }
);

export const copyFormAction = createAction(
  'forms/COPY_FORM',
  async (
    dataSnapshot: { blocks: Builder.SnapshotBlock[]; formOptions: Builder.SelectValue },
    {
      isFormBuilder,
      isInstructionBuilder,
      isToolInspectionBuilder,
    }: { isFormBuilder: boolean; isInstructionBuilder: boolean; isToolInspectionBuilder: boolean }
  ) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.post('v1/secure/forms/copy', dataSnapshot).then(() => {
        switch (true) {
          case isFormBuilder:
            dispatch(push(Routes.Forms));
            dispatch(setSuccessToastAction('Form has been created'));
            return;
          case isInstructionBuilder:
            dispatch(push(Routes.Instructions));
            dispatch(setSuccessToastAction('Instruction has been created'));
            return;
          case isToolInspectionBuilder:
            dispatch(push(Routes.Inspections));
            dispatch(setSuccessToastAction('Tool inspection has been created'));
            return;
        }
      });
    }
);

export const deleteFormAction = createAction(
  'forms/DELETE_FORM',
  (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.delete(`v1/secure/forms/${id}`).then(async res => {
        await dispatch(fetchFormsAction());
        dispatch(setSuccessToastAction('Form has been deleted'));
        return res.data;
      });
    }
);

export const fetchFormCountByTypeAndAssetCategoryCodeAction = createAction(
  'forms/FETCH_FORM_COUNT',
  ({ formtypeId, assetCategoryCode }: { formtypeId: number; assetCategoryCode: Type.AssetCategories }) => {
    return (): Promise<{ count: number }> => {
      const url = `v1/secure/forms/options/formType/${formtypeId}/assetCategory/${assetCategoryCode}`;
      return NodeAPI.get(url).then(res => res.data);
    };
  }
);

export const sendFileAction = createAction('forms/SEND_FORM_FILE', (formData: FormData) => {
  return NodeAPI.post('v1/secure/forms/formbuilder/file', formData).then(res => res.data);
});

export const fetchFormsByTypeAction = createAction(
  'forms/FETCH_FORMS_BY_TYPE',
  (formTypeId: number, assetcategoryId: number) => {
    return NodeAPI.get(`v1/secure/forms/types/${formTypeId}?assetcategoryId=${assetcategoryId}`).then(res => res.data);
  }
);

export const fetchFormTypesAction = createAction('forms/FETCH_FORM_TYPES', () => (): Promise<Type.FormType[]> => {
  return NodeAPI.get('v1/secure/forms/types').then(res => res.data);
});

export const fetchLogMaintenanceFormsAction = createAction(
  'forms/FETCH_LOG_MAINTENANCE',
  (assetCategoryCode: Type.AssetCategories) => (): Promise<Pick<Forms.Item, 'id' | 'name'>[]> => {
    return NodeAPI.get(`v1/secure/forms/types/logMaintenance?assetCategoryCode=${assetCategoryCode}`).then(
      res => res.data
    );
  }
);

export const fetchTimestampOptionsAction = createAction(
  'forms/FETCH_TIMESTAMP_OPTIONS',
  (assetCategoryCode: Type.AssetCategories) => (): Promise<Forms.Timestamps> => {
    return NodeAPI.get(`v1/secure/timestamps/assetCategoryCode/${assetCategoryCode}`).then(res => ({
      [assetCategoryCode]: res.data,
    }));
  }
);
