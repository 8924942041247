import { setStorageItem } from 'utils';
import { StorageKeys, MapThemes } from 'constants/index';

const setUnifiedAssetActiveTabs = (
  theme: Map.Themes,
  TopTabsIndexesMap: Record<string, number>,
  BottomTabsIndexesMap: Record<string, number>
) => {
  switch (theme) {
    case MapThemes.YEARLY_LOSSES:
      setStorageItem({
        [StorageKeys.MAP_PANEL_TOP_TAB_ACTIVE_INDEX]: TopTabsIndexesMap.AssetDetails,
        [StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX]: BottomTabsIndexesMap.Losses,
      });
      return;
    case MapThemes.YEARLY_REPLACEMENTS:
      setStorageItem({
        [StorageKeys.MAP_PANEL_TOP_TAB_ACTIVE_INDEX]: TopTabsIndexesMap.Reinvestment,
        [StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX]: BottomTabsIndexesMap.Power,
      });
      return;
    case MapThemes.TASK:
      setStorageItem({
        [StorageKeys.MAP_PANEL_TOP_TAB_ACTIVE_INDEX]: TopTabsIndexesMap.AssetDetails,
        [StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX]: BottomTabsIndexesMap.Tasks,
      });
      return;
    default:
      setStorageItem({
        [StorageKeys.MAP_PANEL_TOP_TAB_ACTIVE_INDEX]: TopTabsIndexesMap.AssetDetails,
        [StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX]: BottomTabsIndexesMap.Power,
      });
      return;
  }
};

export default setUnifiedAssetActiveTabs;
