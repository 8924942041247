import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { DataTableTemplate } from 'components/_common';
import SelectMinMax from './SelectMinMax';
import TableShortCircuit from './TableShortCircuit';
import { PaginationType } from 'constants/index';

const ViewShortCircuit: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.SETUP_SHORT_CIRCUIT;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, [meta.name]: option?.value }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectMinMax name="min_max" value={filters?.min_max || null} onChange={handleSelectChange} />
        </Col>
      </Row>
      <TableShortCircuit />
    </DataTableTemplate>
  );
};

export default ViewShortCircuit;
