import { createAction } from 'redux-actions';
import { _omit } from '@utiligize/shared/utils';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { PaginationType, NodeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchInspectionsAction: any = createAction(
  'inspections/FETCH_INSPECTIONS',
  async ({ skipPagination, skipFilters } = { skipPagination: false, skipFilters: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Omit<Inspections.Root, 'loading' | 'isFetched'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.INSPECTIONS)(state);
      const lang = appLangSelector(state).toLowerCase();
      return NodeAPI.get('v1/secure/tool-inspections', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipFilters ? null : query,
          lang,
        },
      }).then(res => ({
        count: res.data.count,
        rows: res.data.rows,
        builderData: _omit(res.data, ['rows', 'count']) as Builder.Data,
      }));
    }
);

export const fetchInspectionSnapshotAction: any = createAction('inspections/FETCH_SNAPSHOT', async (id: number) => {
  return NodeAPI.get(`v1/secure/tool-inspections/snapshot?formId=${id}`).then(res => res.data);
});

export const deleteInspectionAction: any = createAction(
  'inspections/DELETE_INSPECTION',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.delete(`v1/secure/tool-inspections/${id}`).then(async () => {
        await dispatch(fetchInspectionsAction());
        dispatch(setSuccessToastAction('Tool inspection has been deleted'));
      })
);
