import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExistingDERsOptionsAction } from 'modules/customers';
import { existingDERsOptionsSelector } from 'modules/customers/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectCustomerDERType: React.FC<Props> = ({ value = null, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const options = useSelector(existingDERsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  useEffect(() => {
    if (!portfolioId) return;
    setLoading(true);
    dispatch(fetchExistingDERsOptionsAction()).finally(() => setLoading(false));
  }, [dispatch, portfolioId]);

  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);

  return (
    <FormReactSelect
      value={selectValue}
      options={options || []}
      isDisabled={!options?.length}
      isLoading={loading}
      {...props}
    />
  );
};

export default SelectCustomerDERType;
