import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { tasksComplaintsCountSelector, tasksComplaintsSelector } from 'modules/tasks/selectors';
import { fetchTasksComplaintsAction, deleteTasksComplaintAction } from 'modules/tasks';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksComplaintsTable: React.FC<Props> = ({ maxTableHeight }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(tasksComplaintsCountSelector);
  const items = useSelector(tasksComplaintsSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksComplaintsAction(params)).then(
        (action: Shared.ReduxAction<Pick<Tasks.Root, 'tasksComplaintsHash'>>) =>
          Object.values(action.payload?.tasksComplaintsHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteTasksComplaintAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.TASKS_COMPLAINTS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.TaskComplaint) => (
        <TaskComplaintTableItem key={item.id} complaint={item} handleDeleteBtnClick={handleDeleteBtnClick} />
      ))}
    </DataTable>
  );
};

const TaskComplaintTableItem: React.FC<{
  complaint: Tasks.TaskComplaint;
  handleDeleteBtnClick: (event: React.SyntheticEvent) => void;
}> = memo(({ complaint, handleDeleteBtnClick }) => {
  const { id, createdAt, name, nameOfRoad, typeOfFault, postcodeAndTown, telephone, email } = complaint;
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleCreateTaskBtnClick = useCallback((): void => {
    dispatch(setLayoutAction({ taskModalMeta: { complaint } }));
  }, [complaint, dispatch]);

  return (
    <tr>
      <td>{dateFormat(createdAt)}</td>
      <td>{name || '-'}</td>
      <td>{nameOfRoad}</td>
      <td>{typeOfFault}</td>
      <td>{postcodeAndTown}</td>
      <td>{telephone}</td>
      <td>{email}</td>
      <td className="text-right">
        <Button
          tooltipKey="Create task"
          icon={<IconPlus />}
          data-id={id}
          onClick={handleCreateTaskBtnClick}
          size="small"
        />
        <Button
          marginLeft
          tooltipKey="Delete"
          icon={<IconDelete />}
          data-id={id}
          onClick={handleDeleteBtnClick}
          size="small"
        />
      </td>
    </tr>
  );
});

export default TasksComplaintsTable;
