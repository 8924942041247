import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Routes } from 'constants/index';

interface Props {
  code: string;
  uuid: string | null;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  state?: any;
}

const AssetCode: React.FC<Props> = ({ uuid, code, children, onClick, state = {} }) => {
  if (!uuid || !code) return <>{children || code || '-'}</>;

  return (
    <Link
      to={{
        pathname: generatePath(Routes.Map, { uuid }),
        state,
      }}
      onClick={onClick}
    >
      {children || code}
    </Link>
  );
};

export default AssetCode;
