import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.dataQuality;

export const issuesCountSelector = createSelector(rootSelector, dataQuality => dataQuality.issuesCount);

export const issuesSelector = createSelector(rootSelector, dataQuality => dataQuality.issues);

export const issuesLastUpdateSelector = createSelector(rootSelector, dataQuality => dataQuality.issuesLastUpdate);

export const customersConnectedCountSelector = createSelector(
  rootSelector,
  dataQuality => dataQuality.customersConnectedCount
);

export const customersConnectedSelector = createSelector(rootSelector, dataQuality => dataQuality.customersConnected);

export const missingGeometryCountSelector = createSelector(
  rootSelector,
  dataQuality => dataQuality.missingGeometryCount
);

export const missingGeometrySelector = createSelector(rootSelector, dataQuality => dataQuality.missingGeometry);

export const metersMissingInMDMSelector = createSelector(rootSelector, meters => meters.missingInMDM);

export const metersMissingInMDMCountSelector = createSelector(rootSelector, meters => meters.missingInMDMCount);

export const metersMissingInGISSelector = createSelector(rootSelector, meters => meters.missingInGIS);

export const metersMissingInGISCountSelector = createSelector(rootSelector, meters => meters.missingInGISCount);

export const missingAgeItemsCountSelector = createSelector(rootSelector, meters => meters.missingAgeItemsCount);

export const missingAgeItemsSelector = createSelector(rootSelector, meters => meters.missingAgeItems);
