import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getProfileChartData } from 'modules/networkLoading';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  selectedChartAbsoluteSelector,
  selectedChartFlexSelector,
} from 'modules/layouts/selectors';
import { selectedAndExistChartYearSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { SelectScenarioYears, Checkbox } from 'components/_common';
import Chart, { ChartDataProps, getBaseOptions } from './Chart';
import ChartControlsContainer from './ChartControlsContainer';

interface Props {
  uuid: string;
  height?: '100%';
  isTooltipOutside?: boolean;
}

const AssetProfileChart: React.FC<Props> = ({ uuid, height, isTooltipOutside = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const year = useSelector(selectedAndExistChartYearSelector);
  const flex = useSelector(selectedChartFlexSelector);
  const absolute = useSelector(selectedChartAbsoluteSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const handleSelectYear = useCallback(
    (option: Type.SelectOption<number>) => dispatch(setLayoutAction({ selectedChartYear: option?.value })),
    [dispatch]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ [event.currentTarget.name]: event.currentTarget.checked })),
    [dispatch]
  );

  const PercentileOpacityMap: { [key: string]: number } = useMemo(
    () => ({
      '100 (max)': 1,
      '90': 0.8,
      '75': 0.7,
      '50': 0.6,
      '25': 0.5,
      '10': 0.4,
      '0 (min)': 0.4,
    }),
    []
  );

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId || !year) return;
    dispatch(getProfileChartData({ portfolioId, scenarioId, uuid, year, flex, absolute }))
      .then((action: Shared.ReduxAction<any>) => {
        const { hash: seriesHash, zIndex } = action.payload.ts_data.reduce(
          (
            acc: { hash: Record<string, number[][]>; zIndex: Record<string, number> },
            item: { hour: number; kVA: number; percentile: string },
            index: number
          ) => {
            const dataItem = [item.hour, item.kVA];
            const name = String(item.percentile);
            if (!acc.hash[name]) {
              acc.hash[name] = [dataItem];
              acc.zIndex = { ...acc.zIndex, [name]: index };
            } else {
              acc.hash[name].push(dataItem);
            }
            return acc;
          },
          { hash: {}, zIndex: {} }
        );

        return {
          seriesHash,
          title: action.payload.title,
          legendTitle: action.payload.legend_title,
          yAxisTitle: action.payload.ylabel,
          xAxisTitle: action.payload.xlabel,
          zIndex,
        };
      })
      .then(setChartData)
      .catch(() => setChartData({ series: [] } as any));
  }, [dispatch, portfolioId, scenarioId, uuid, year, flex, absolute]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);

    const series = Object.keys(chartData?.seriesHash || {})
      .sort((a, b) => chartData?.zIndex[a]! - chartData?.zIndex[b]!)
      .map((key: string) => ({
        name: getIntl(key),
        type: 'line',
        data: chartData?.seriesHash[key],
        opacity: PercentileOpacityMap[key] || 1,
        lineWidth: 2,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      }));

    return {
      ...baseOptions,
      tooltip: {
        shared: false,
        outside: isTooltipOutside,
        formatter(this: Highcharts.Point) {
          return `${getIntl('Hour')}: ${this.x}<br/>kVA: ${this.y}<br/>${getIntl('Percentile')}: ${this.series.name}`;
        },
      },
      legend: {
        title: {
          text: getIntl(chartData?.legendTitle || ''),
        },
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        reversed: true,
      },
      colors: series.map(item => `rgba(45, 76, 147, ${item.opacity})`),
      series,
    };
  }, [chartData, PercentileOpacityMap, getIntl, isTooltipOutside]) as unknown as Highcharts.Options;

  return (
    <>
      <ChartControlsContainer dataMarker="asset_timeseries__buttons_block">
        <SelectScenarioYears labelKey="" value={year} onChange={handleSelectYear} className="mr-2 flex-shrink-0" />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary"
          name="selectedChartFlex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
        <Checkbox
          labelKey="Absolute"
          className="icheck-primary ml-2"
          name="selectedChartAbsolute"
          checked={absolute}
          onChange={handleCheckboxClick}
        />
      </ChartControlsContainer>
      <Chart dataMarker="asset_timeseries_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

export default AssetProfileChart;
