import styled from 'styled-components';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { IconTool, IconVoltage } from '@utiligize/shared/resources';
import { OverlayTriggerTooltip } from 'components/_common';
import { Popup, PopupIcon, PopupItem } from 'components/Map/common/Popup';

export interface SearchPopupProps {
  items: Record<string, Map.SearchPopupItem[]>;
  openUnifiedAssetPanel: (asset: Map.SearchPopupItem) => void;
  onN1RouteClick: (asset: Map.SearchPopupItem) => void;
  onMouseEnter: (asset: Map.SearchPopupItem) => void;
  onMouseLeave: (asset: Map.SearchPopupItem) => void;
  getIntl: (key: string, options?: any) => any;
}

const SearchPopup: React.FC<SearchPopupProps> = ({
  items,
  openUnifiedAssetPanel,
  onN1RouteClick,
  onMouseEnter,
  onMouseLeave,
  getIntl,
}) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(Object.values(items)[0][0].id);
  }, [items]);

  const getItem = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      const key = e.currentTarget.getAttribute('data-key');
      const id = Number(e.currentTarget.getAttribute('data-id'));
      const item = items[key!].find(i => i.id === id);
      return item;
    },
    [items]
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const item = getItem(e);
      if (item) onMouseEnter(item);
    },
    [onMouseEnter, getItem]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const item = getItem(e);
      if (item) onMouseLeave(item);
    },
    [onMouseLeave, getItem]
  );

  const handleN1RouteButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const item = getItem(e);
      if (item) onN1RouteClick(item);
    },
    [onN1RouteClick, getItem]
  );

  const handlePopupItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const item = getItem(e);
      if (!item) return;
      setSelected(item.id);
      openUnifiedAssetPanel(item);
    },
    [openUnifiedAssetPanel, getItem]
  );

  return (
    <Popup $isSearch>
      <StyledOptionsList>
        {Object.keys(items).map(k => (
          <React.Fragment key={k}>
            <LayerTitle>{getIntl(k)}</LayerTitle>
            <PopupBody>
              {items[k].map(item => (
                <StyledPopupItem
                  key={item.id}
                  data-key={k}
                  data-id={item.id}
                  onClick={handlePopupItemClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={classNames({ active: selected === item.id })}
                >
                  <div className="name">{item.name}</div>
                  {item.voltageName && (
                    <StyledItemPart>
                      {getIntl(item.voltageName)} <IconVoltage width={12} height={12} />
                    </StyledItemPart>
                  )}
                  {item.installationNumber && (
                    <OverlayTriggerTooltip
                      trigger={['hover', 'focus']}
                      overlayId={`map-popup-${item.installationNumber}`}
                      overlayChildren={getIntl('Installation number')}
                    >
                      <StyledItemPart>
                        {getIntl(item.installationNumber)} <IconTool width={12} height={12} />
                      </StyledItemPart>
                    </OverlayTriggerTooltip>
                  )}
                  {item.hasN1Route && (
                    <StyledRouteButton data-key={k} data-id={item.id} onClick={handleN1RouteButtonClick}>
                      {getIntl('display routes')}
                    </StyledRouteButton>
                  )}
                  <PopupIcon type={item.type} color={item.color} icon={item.icon as any} />
                </StyledPopupItem>
              ))}
            </PopupBody>
          </React.Fragment>
        ))}
      </StyledOptionsList>
    </Popup>
  );
};

const StyledOptionsList = styled.div`
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  scrollbar-width: thin;
  padding: 0 4px 15px 15px;
  scrollbar-gutter: stable;
`;

export const LayerTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #f6f8fb;
  margin: 15px 0 5px 0;
  text-align: center;
`;

const StyledPopupItem = styled(PopupItem)`
  padding-right: 20px;
  justify-content: center;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto;
  }
`;

export const PopupBody = styled.div`
  font-size: 10px;
`;

const StyledRouteButton = styled.button`
  all: unset;
  background-color: var(--map-active-color-light);
  border-radius: 4px;
  font-weight: 500;
  padding: 0 6px;
  margin: 0 4px;

  &:hover {
    background-color: var(--map-background-color);
  }
`;

const StyledItemPart = styled.div`
  margin-left: auto;
  color: var(--map-outline-color);
  display: flex;
  align-items: center;

  svg {
    margin-left: 3px;

    path {
      fill: var(--map-outline-color);
    }
  }
`;

export default SearchPopup;
