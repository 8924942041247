import moment from 'moment';
import { createAction } from 'redux-actions';
import { setLayoutAction, setPaginationAction } from 'modules/layouts';
import {
  fetchComponentPriceScenariosAction,
  fetchForecastedScenariosAction,
  fetchInvestmentScenariosAction,
} from 'modules/setup';
import { fetchMaintenancePlansAction } from 'modules/maintenance';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  investmentScenarioIdSelector,
  paginationSelectorFactory,
  scenarioIdHashSelector,
  selectedChartYearSelector,
} from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import {
  DERsPointAssetsOptionsHashSelector,
  cableTypeOptionsHashSelector,
  cnaimAssetCategoriesOptionsHashSelector,
  componentPriceScenarioOptionsHashSelector,
  investmentScenariosOptionsHashSelector,
  percentilesOptionsHashSelector,
  pointTypeOptionsHashSelector,
  primarySubstationOptionsHashSelector,
  scenarioOptionsHashSelector,
  scenariosYearsOptionsHashSelector,
  simulationOptionsHashSelector,
  transformerTypeOptionsHashSelector,
} from './selectors';
import { PaginationType, AssetLifeAPI, PrimarySubstationsLabelMapper } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPortfolioOptionsAction = createAction(
  'options/FETCH_PORTFOLIO_OPTIONS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Options.Root, 'portfolioOptions'>> => {
      return AssetLifeAPI.get('shared/portfolios').then(
        (res: { data: { id: number; department: string; loadflow_enabled: boolean }[] }) => {
          const options = res.data.map(item => ({
            value: item.id,
            label: item.department,
            loadflow_enabled: item.loadflow_enabled,
          }));
          const state: State.Root = getState();
          const portfolioId = portfolioIdSelector(state);
          const option = options.find((i: Type.SelectOption) => i.value === portfolioId);

          // select value automatically
          if (!portfolioId || !option) {
            dispatch(setLayoutAction({ portfolioId: options[0]?.value || null }));
          }

          return { portfolioOptions: options };
        }
      );
    }
);

export const fetchScenarioOptionsAction: any = createAction(
  'options/FETCH_SCENARIO_OPTIONS',
  async (portfolioIdInput?: Layouts.Root['portfolioId']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'scenarioOptionsHash'>> => {
      const state: State.Root = getState();
      const portfolioId = portfolioIdInput || portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);

      return dispatch(fetchForecastedScenariosAction({ skipPagination: true, skipStoreUpdate: true })).then(
        (action: Shared.ReduxAction<Setup.Root['forecastedScenariosHash']>) => {
          const options = Object.values(action.payload?.forecastedScenariosHash || {}).map(item => ({
            value: item.id,
            label: item.description,
            default: item.default,
          }));
          const option = options.find(i => i.value === scenarioId);

          // select value automatically
          if (!scenarioId || !option) {
            dispatch(
              setLayoutAction({
                scenarioIdHash: {
                  ...scenarioIdHashSelector(getState()),
                  [portfolioId!]: options.find(i => i.default)?.value || options[0]?.value,
                },
              })
            );
          }

          return {
            scenarioOptionsHash: { ...scenarioOptionsHashSelector(getState()), [String(portfolioId)]: options },
          };
        }
      );
    }
);

export const fetchScenarioYearsPossibleOptionsAction = createAction(
  'options/FETCH_SCENARIO_YEARS_POSSIBLE_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'scenarioYearsOptions'>> =>
      AssetLifeAPI.get('scenarios/scenario_years_possible').then(res => {
        const state = getState();
        const { filters } = paginationSelectorFactory(PaginationType.NEW_LOAD_DERS)(state);

        const currentYear = new Date().getFullYear();
        const year = res.data.includes(currentYear) ? currentYear : res.data[0];

        if (!res.data.includes(filters?.year)) {
          dispatch(
            setPaginationAction({
              type: PaginationType.NEW_LOAD_DERS,
              modifier: { filters: { ...filters, year } },
            })
          );
        }
        return { scenarioYearsOptions: res.data || [] };
      })
);

export const fetchSimulationOptionsAction = createAction(
  'options/FETCH_SIMULATION_OPTIONS',
  async (portfolio_id: Layouts.Root['portfolioId'], scenario_id: Layouts.ScenarioId) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'simulationOptionsHash'>> => {
      return AssetLifeAPI.get('shared/simulation', { params: { portfolio_id, scenario_id } }).then(res => {
        const options = res.data.map(
          (item: { id: number; date: string; version_id: number; is_map_updated: boolean }) => ({
            value: item.id,
            label: `${item.date ? moment(item.date).format('L LT') : 'Unknown date'} • v.${item.version_id} • s.${item.id}`,
            versionId: item.version_id,
          })
        );
        return {
          simulationOptionsHash: {
            ...simulationOptionsHashSelector(getState()),
            [`${portfolio_id}_${scenario_id}`]: options,
          },
        };
      });
    }
);

interface FetchPercentileOptionsActionResponse {
  id: number;
  label: number;
  default?: boolean;
}

export const fetchPercentileOptionsAction = createAction(
  'options/FETCH_PERCENTILE_OPTIONS',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'percentilesOptionsHash'>> => {
      return AssetLifeAPI.get('load/percentile', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const options = res.data.map((item: FetchPercentileOptionsActionResponse) => ({
          value: item.id,
          label: item.label,
        }));
        const state: State.Root = getState();

        // Automatically apply default values for necessary table filters
        [
          PaginationType.TRANSFORMERS,
          PaginationType.CABLES,
          PaginationType.NETWORK_LOADING_CABINETS,
          PaginationType.NETWORK_LOADING_FUSES,
          PaginationType.NETWORK_LOADING_VOLTAGE_DROP,
        ].forEach(type => {
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.percentile);

          if (!filters?.percentile || !option) {
            const index = res.data.findIndex((o: FetchPercentileOptionsActionResponse) => o.default);
            const modifier = {
              filters: { ...filters, percentile: options?.[Math.max(index, 0)]?.value || null },
              offset: 0,
            };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          percentilesOptionsHash: {
            ...percentilesOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const applyScenarioYearsOptionsAction = createAction(
  'options/APPLY_SCENARIO_YEARS_OPTIONS',
  async (options: Type.SelectOption<number>[]) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): void => {
      const types = [
        PaginationType.TRANSFORMERS,
        PaginationType.CABLES,
        PaginationType.NETWORK_LOADING_CABINETS,
        PaginationType.NETWORK_LOADING_FUSES,
        PaginationType.NETWORK_LOADING_VOLTAGE_DROP,
      ];

      // Automatically apply default values for necessary table filters
      types.forEach(type => {
        const state: State.Root = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        const option = options.find((i: Type.SelectOption) => i.value === filters?.year);
        if (!filters?.year || !option) {
          const currentYear = new Date().getFullYear();
          const year = options.find((i: Type.SelectOption) => i.value === currentYear)?.value || options?.[0]?.value;
          const modifier = { filters: { ...filters, year }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });

      const value = selectedChartYearSelector(getState());

      // Note. ChartYear - Step.3 - Automatically apply year value if doesn't exist
      if (!options.some(option => option.value === value)) {
        const currentYear = new Date().getFullYear();
        const year = options.some(option => option.value === currentYear) ? currentYear : options?.[0]?.value;
        dispatch(setLayoutAction({ selectedChartYear: year }));
      }
    }
);

export const fetchScenarioYearsOptionsAction = createAction(
  'options/FETCH_SCENARIO_YEARS_OPTIONS',
  async ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    simulationId: Layouts.Root['simulationId'];
    versionId: number;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'scenariosYearsOptionsHash'>> => {
      return AssetLifeAPI.get('scenarios/scenario_years', {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
        },
      }).then(res => {
        const options = res.data.map((item: number) => ({ value: item, label: item }));
        dispatch(applyScenarioYearsOptionsAction(options));

        return {
          scenariosYearsOptionsHash: {
            ...scenariosYearsOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}_${simulationId}_${versionId}`]: options,
          },
        };
      });
    }
);

export const fetchOutageTypeOptionsAction = createAction(
  'options/FETCH_OUTAGE_TYPE_OPTIONS',
  () =>
    async (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'outageTypeOptions'>> => {
      return AssetLifeAPI.get('n_1/outage_types').then(res => {
        const state = getState();
        const type = PaginationType.N1;
        const { filters } = paginationSelectorFactory(type)(state);

        const outageTypeOptions = [{ value: '', label: 'All' }, ...res.data];

        // Set first value by default
        if (!outageTypeOptions.some(o => o.value === filters?.outageType)) {
          const modifier = { filters: { ...filters, outageType: outageTypeOptions[0].value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }

        return { outageTypeOptions };
      });
    }
);

export const fetchRoutesOptionsAction = createAction(
  'options/FETCH_ROUTES_OPTIONS',
  () =>
    async (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'routesOptions'>> => {
      return AssetLifeAPI.get('n_1/routes').then(res => {
        const state = getState();
        const type = PaginationType.N1;
        const { filters } = paginationSelectorFactory(type)(state);

        const routesOptions = [{ value: '', label: 'All' }, ...res.data];

        if (!routesOptions.some(o => o.value === filters?.route)) {
          const modifier = { filters: { ...filters, route: routesOptions[0].value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }

        return { routesOptions };
      });
    }
);

export const applyInvestmentScenariosOptionsAction = createAction(
  'options/APPLY_INVESTMENT_SCENARIOS_OPTIONS',
  async (options: Type.SelectOption<number>[]) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): void => {
      const state = getState();
      const investmentScenarioId = investmentScenarioIdSelector(state);
      const option = options.find((i: Type.SelectOption) => i.value === investmentScenarioId);
      if (options?.[0]?.value && (!investmentScenarioId || !option)) {
        dispatch(setLayoutAction({ investmentScenarioId: options[0].value }));
      }
    }
);

export const fetchInvestmentScenariosOptionsAction = createAction(
  'options/FETCH_INVESTMENT_SCENARIOS_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'investmentScenariosOptionsHash'>> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      return dispatch(
        fetchInvestmentScenariosAction({
          skipFilters: true,
          skipStoreUpdate: true,
          returnAllItems: true,
          scenarioId,
        })
      ).then((action: Shared.ReduxAction<{ investmentScenariosHash: Setup.Root['investmentScenariosHash'] }>) => {
        const items = Object.values(action.payload?.investmentScenariosHash || {});
        const options = items.map((item: Setup.InvestmentScenario) => ({
          value: item.id,
          label: item.investment_description,
        }));

        dispatch(applyInvestmentScenariosOptionsAction(options));

        return {
          investmentScenariosOptionsHash: {
            ...investmentScenariosOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const fetchComponentPriceScenarioOptionsAction: any = createAction(
  'options/FETCH_COMPONENT_PRICE_SCENARIO_OPTIONS',
  async (portfolioId: Layouts.Root['portfolioId']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'componentPriceScenarioOptionsHash'>> => {
      return dispatch(
        fetchComponentPriceScenariosAction({ skipPagination: true, skipFilters: true, skipStoreUpdate: true })
      ).then(action => {
        const types = [PaginationType.WORK_PRICES];

        const options = Object.values<Setup.ComponentPriceScenario>(
          action.payload?.componentPriceScenariosHash || []
        ).map((i: { id: number; description: string }) => ({ value: i.id, label: i.description }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.componentPriceScenarioId);
          if (!option) {
            const modifier = { filters: { ...filters, componentPriceScenarioId: options[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });
        return {
          componentPriceScenarioOptionsHash: {
            ...componentPriceScenarioOptionsHashSelector(getState()),
            [String(portfolioId)]: options,
          },
        };
      });
    }
);

export const fetchComponentPriceTypeOptionsAction = createAction(
  'options/FETCH_COMPONENT_PRICE_TYPE_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'componentPriceTypeOptions'>> =>
      AssetLifeAPI.get('investment/price_type').then(res => {
        const types = [PaginationType.COMPONENT_PRICES];
        const options = res.data.map((i: { price_type_id: number; price_type: string }) => ({
          value: i.price_type_id,
          label: i.price_type,
        }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.priceTypeId);
          if (!option) {
            const modifier = { filters: { ...filters, priceTypeId: options[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return { componentPriceTypeOptions: options };
      })
);

interface CnaimIDsResponse {
  cnaim_id: number;
  asset_register_category: string;
  price_unit: string;
  size_unit: string;
  co2e_unit: string;
  investment: boolean;
}

export const fetchCnaimAssetCategoriesOptionsAction = createAction(
  'options/FETCH_CNAIM_ASSET_CATEGORIES_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'cnaimAssetCategoriesOptionsHash'>> => {
      const lang = appLangSelector(getState());
      return AssetLifeAPI.get('shared/all_cnaim_ids', { params: { lang: lang.toLocaleLowerCase() } }).then(res => {
        const options = res.data.rows
          .filter((i: CnaimIDsResponse) => i.investment)
          .map((i: CnaimIDsResponse) => ({
            value: i.cnaim_id,
            label: i.asset_register_category,
            priceUnit: res.data.meta.currency + i.price_unit,
            sizeUnit: i.size_unit,
            co2eUnit: i.co2e_unit,
          }));

        // Automatically apply default values for necessary table filters
        [PaginationType.DATA_QUALITY_MISSING_AGE].forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.cnaim_id);
          if (!filters?.cnaim_id || !option) {
            const modifier = { filters: { ...filters, cnaim_id: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          cnaimAssetCategoriesOptionsHash: {
            ...(cnaimAssetCategoriesOptionsHashSelector(getState()) || {}),
            [lang]: options,
          },
        };
      });
    }
);

export const fetchDERsDescriptionOptionsAction = createAction(
  'options/FETCH_DERS_DESCRIPTION_OPTIONS',
  () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'DERsDescriptionOptionsHash'>> => {
      const state: State.Root = getState();
      // Note. We don't need to pass portfolioId, scenarioId as props,
      // since SelectDERsDescription is in use only on modal windows
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);

      return AssetLifeAPI.get('scenarios/available_der_descriptions', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const options = res.data.map((item: NewLoad.DER) => ({ value: item.id, label: item.description }));
        return {
          DERsDescriptionOptionsHash: {
            ...DERsPointAssetsOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const resetDERsDescriptionOptionsAction = createAction(
  'options/RESET_DERS_DESCRIPTION_OPTIONS_HASH_ITEM',
  () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Pick<Options.Root, 'DERsDescriptionOptionsHash'> => {
      const state: State.Root = getState();
      // Note. We don't need to pass portfolioId, scenarioId as props,
      // since SelectDERsDescription is in use only on modal windows
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);

      return {
        DERsDescriptionOptionsHash: {
          ...DERsPointAssetsOptionsHashSelector(getState()),
          [`${portfolioId}_${scenarioId}`]: null,
        },
      };
    }
);

export const fetchDERsPointAssetsOptionsAction = createAction(
  'options/FETCH_DERS_POINT_ASSETS_OPTIONS',
  async (version_id: number) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'DERsPointAssetsOptionsHash'>> =>
      AssetLifeAPI.get('assets/point_asset', { params: { version_id } }).then(res => ({
        DERsPointAssetsOptionsHash: {
          ...DERsPointAssetsOptionsHashSelector(getState()),
          [version_id!]: res.data.map((i: { id: number; label: string }) => ({ value: i.id, label: i.label })),
        },
      }))
);

export const fetchWorkTypeOptionsAction = createAction(
  'options/FETCH_WORK_TYPE_OPTIONS',
  async () => (): Promise<Pick<Options.Root, 'workTypeOptions'>> =>
    AssetLifeAPI.get('work_type/work_type').then(res => ({
      workTypeOptions: res.data.rows.map((i: { id: number; description: string; price_unit: string }) => ({
        value: i.id,
        label: i.description,
        priceUnit: i.price_unit,
      })),
    }))
);

export const applyPrimarySubstationsFiltersAction = createAction(
  'options/APPLY_PRIMARY_SUBSTATIONS_FILTERS',
  async (options: Type.SelectOption[]) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): void => {
      const ids = options?.map(option => option.value);

      // Reset value on portfolio or scenario change
      [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
        const state: State.Root = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        if (filters?.primarySubstationsIds?.some(id => !ids.includes(id))) {
          const modifier = { filters: { ...filters, primarySubstationsIds: null }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });

      // Automatically apply default values for necessary table filters
      [PaginationType.NETWORK_LOADING_VOLTAGE_DROP].forEach(type => {
        const state: State.Root = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        const option = options.find((i: Type.SelectOption) => i.value === filters?.primarySubstationsId);
        if (!filters?.primarySubstationsId || !option) {
          const modifier = { filters: { ...filters, primarySubstationsId: options?.[0]?.value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });

      // Automatically apply all values for N-1 table
      [PaginationType.N1].forEach(type => {
        const state: State.Root = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        if (!filters?.primarySubstationsIds || filters?.primarySubstationsIds?.some(id => !ids.includes(id))) {
          const modifier = { filters: { ...filters, primarySubstationsIds: ids }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });
    }
);

export const fetchPrimarySubstationsOptionsAction = createAction(
  'options/FETCH_PRIMARY_SUBSTATIONS',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'primarySubstationOptionsHash'>> => {
      return AssetLifeAPI.get('shared/primary_substations', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: { data: { name: string; id: number }[] }) => {
        const options = res.data.map(item => ({
          value: item.id,
          label: PrimarySubstationsLabelMapper[item.name] || item.name,
        }));
        dispatch(applyPrimarySubstationsFiltersAction(options));
        return {
          primarySubstationOptionsHash: {
            ...primarySubstationOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const fetchPointTypeOptionsAction = createAction(
  'options/FETCH_POINT_TYPES',
  async ({ voltage_level_id }: Pick<Map.MapDrawAssetFeatureProperties, 'voltage_level_id'>) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'pointTypeOptionsHash'>> =>
      AssetLifeAPI.get('investment/new_point_asset_type', {
        params: { voltage_id: voltage_level_id },
      }).then((res: { data: { id: number; sorting_order: number; type: string }[] }) => {
        const options = res.data.map(item => ({ value: item.id, label: item.type }));
        return {
          pointTypeOptionsHash: {
            ...pointTypeOptionsHashSelector(getState()),
            [String(voltage_level_id)]: options,
          },
        };
      })
);

export const fetchTransformerTypeOptionsAction = createAction(
  'options/FETCH_TRANSFORMER_TYPES',
  async ({
    componentPriceScenarioId,
    voltage_level_id,
  }: Pick<Map.MapDrawAssetFeatureProperties, 'componentPriceScenarioId' | 'voltage_level_id'>) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'transformerTypeOptionsHash'>> =>
      AssetLifeAPI.get('investment/new_transformer_type', {
        params: { price_scenario_id: componentPriceScenarioId, voltage_id: voltage_level_id },
      }).then(
        (res: { data: { id: number; sorting_order: number; type: string; asset_register_category: string }[] }) => {
          const options = res.data.map(item => ({
            value: item.id,
            label: item.type,
            asset_category: item.asset_register_category,
          }));
          return {
            transformerTypeOptionsHash: {
              ...transformerTypeOptionsHashSelector(getState()),
              [`${componentPriceScenarioId}_${voltage_level_id}`]: options,
            },
          };
        }
      )
);

export const fetchCableTypeOptionsAction = createAction(
  'options/FETCH_CABLE_TYPES',
  async ({
    componentPriceScenarioId,
    voltage_level_id,
  }: Pick<Map.MapDrawAssetFeatureProperties, 'componentPriceScenarioId' | 'voltage_level_id'>) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'cableTypeOptionsHash'>> =>
      AssetLifeAPI.get('investment/new_cable_type', {
        params: { price_scenario_id: componentPriceScenarioId, voltage_id: voltage_level_id },
      }).then(
        (res: { data: { id: number; sorting_order: number; type: string; asset_register_category: string }[] }) => {
          const options = res.data.map(item => ({
            value: item.id,
            label: item.type,
            asset_category: item.asset_register_category,
          }));
          return {
            cableTypeOptionsHash: {
              ...cableTypeOptionsHashSelector(getState()),
              [`${componentPriceScenarioId}_${voltage_level_id}`]: options,
            },
          };
        }
      )
);

export const fetchCustomerDetectionTypeOptionsAction = createAction(
  'options/FETCH_CUSTOMER_DETECTION_TYPE_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'customerDetectionTypeOptions'>> => {
      const portfolioId = portfolioIdSelector(getState());
      return AssetLifeAPI.get('customers/customer_existing_ders_detection_type', {
        params: { portfolio_id: portfolioId },
      }).then(res => {
        const customerDetectionTypeOptions = res.data.map((i: { id: number; labels: string }) => ({
          value: i.id,
          label: i.labels,
        }));
        return { customerDetectionTypeOptions };
      });
    }
);

export const fetchMaintenancePlansOptionsAction = createAction(
  'options/FETCH_MAINTENANCE_PLANS_OPTIONS',
  async () =>
    (dispatch: Shared.CustomDispatch): Promise<Pick<Options.Root, 'maintenancePlansOptions'>> => {
      return dispatch(fetchMaintenancePlansAction({ skipPagination: true, skipStoreUpdate: true })).then(
        (action: Shared.ReduxAction<Maintenance.Root['maintenancePlansHash']>) => {
          const maintenancePlansOptions = Object.values(action.payload.maintenancePlansHash).map(item => ({
            value: item.id,
            label: `${item.planName} (${item.assetCategory})`,
            assetCategoryCode: item.assetCategory,
          }));

          return { maintenancePlansOptions };
        }
      );
    }
);
