import styled from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { createMapStateSelectorFactory } from 'modules/map/selectors';
import { useSelector } from 'react-redux';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  title: string;
  name: keyof Map.MapState;
  className?: string;
  options: Map.MultiSelectValue[];
  showDescription?: boolean;
  isSelectAllNodeEnabled?: boolean;
  disabled?: boolean;
  onChange: (state: Partial<Map.MapState>) => void;
}

const MultiSelect: React.FC<Props> = ({
  title,
  name,
  className = '',
  options,
  showDescription = true,
  isSelectAllNodeEnabled = false,
  disabled = false,
  onChange,
}) => {
  const { getIntl } = useLocale();
  const values: any = useSelector(createMapStateSelectorFactory(name)) || null;
  const activeOptions = options.filter(i => !i.disabled);
  const descriptions = activeOptions.map(v => v.labelShort);
  const isSelectAllChecked = activeOptions.length === values?.length;

  useEffect(() => {
    const nextValues = activeOptions.map(o => o.value);
    const filteredValues = values?.filter((value: string) => nextValues.includes(value));
    if (values && filteredValues.length === values.length) return;
    // Set default filters if don't exist
    // Automatically update filters if after fetchTasksFeatureCollection there are less available options
    onChange({ [name]: activeOptions.map(o => o.value) });
  }, [values, name, activeOptions, disabled, onChange]);

  const handleControlBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.id;
      const isChecked = event.target.checked;
      const nextValues = isChecked ? [...(values || []), value] : values.filter((v: any) => v !== value);
      onChange({ [name]: nextValues });
    },
    [name, values, onChange]
  );

  const handleSelectAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValues = event.target.checked ? activeOptions.map(option => option.value) : [];
      onChange({ [name]: nextValues });
    },
    [name, activeOptions, onChange]
  );

  const renderSelectAllNode = () => {
    if (!isSelectAllNodeEnabled) return null;
    return (
      <OverlayTriggerTooltip
        placement="left"
        overlayId={`multiselect_${title}_select_all_tooltip`}
        overlayChildren={getIntl(isSelectAllChecked ? 'Deselect all' : 'Select all')}
      >
        <ControlBox
          type="checkbox"
          id={`multiselect_${title}_select_all_checkbox`}
          checked={isSelectAllChecked}
          onChange={handleSelectAllChange}
          className="m-0"
        />
      </OverlayTriggerTooltip>
    );
  };

  return (
    <StyledContainer className={className}>
      <CollapseGroup
        id={`multiselect_${title}`}
        titleKey={title}
        disabled={!activeOptions.length || disabled}
        description={
          showDescription && <GroupHiddenDescription values={descriptions} defaultValue="No active filters" />
        }
        selectAllNode={renderSelectAllNode()}
      >
        <StyledOptionsList>
          {options.map(option => (
            <div className="item" key={option.value}>
              <ControlBox
                type="checkbox"
                id={option.value}
                name={option.value}
                checked={Boolean(!option.disabled && values?.includes(option.value))}
                labelKey={option.label}
                onChange={handleControlBoxChange}
                disabled={option.disabled || disabled}
              />
            </div>
          ))}
        </StyledOptionsList>
      </CollapseGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid var(--map-outline-color);
  box-shadow: 0px 2px 2px #44366630;
  border-radius: 8px;

  .os-host {
    margin: 0 -18px;
  }

  .item {
    display: flex;
    align-items: center;
  }
`;

const StyledOptionsList = styled.div`
  position: relative;
  max-height: 140px;
  margin-left: 18px;
  overflow-x: hidden;
  ${props => props.theme.mixins.scroll};
`;

export default MultiSelect;
