import React from 'react';
import { Switch, Route } from 'react-router';
import { PageAppExceptions } from 'pages';
import { ViewOverview, ViewEmissionsForecast, ViewDetailedEmissions, ViewEmissionsByScope } from 'components/CO2e';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageCO2e: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isCO2eEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <Switch>
            <Route exact path={Routes.CO2e} component={ViewOverview} />
            <Route exact path={Routes.CO2eEmissionsForecast} component={ViewEmissionsForecast} />
            <Route exact path={Routes.CO2eDetailedEmissions} component={ViewDetailedEmissions} />
            <Route exact path={Routes.CO2eEmissionsByScope} component={ViewEmissionsByScope} />
            <Route path="*" component={PageAppExceptions} />
          </Switch>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageCO2e;
