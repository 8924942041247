import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createMaintenancePlanAction, updateMaintenancePlanAction } from 'modules/maintenance';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { MaintenanceMissingActions } from 'constants/index';

type Values = Omit<
  Maintenance.Plan,
  'id' | 'assetCategory' | 'dateSelect' | 'date_explicit' | 'missingAction' | 'intervalYears'
> & {
  assetCategory: Maintenance.Plan['assetCategory'] | null;
  dateSelect: Maintenance.Plan['dateSelect'] | null;
  date_explicit: Maintenance.Plan['date_explicit'] | null;
  missingAction: Maintenance.Plan['missingAction'] | null;
  intervalYears: Maintenance.Plan['intervalYears'] | null;
};

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  const formik = useFormik<Values>({
    initialValues: {
      planName: '',
      assetCategory: null,
      dateSelect: isLiveDataConnection ? '' : null,
      date_explicit: isLiveDataConnection ? false : null,
      missingAction: isLiveDataConnection ? MaintenanceMissingActions.START : null,
      intervalYears: isLiveDataConnection ? 1 : null,
    },

    validationSchema: Yup.object().shape({
      planName: Yup.string().required("Field can't be empty!"),
      assetCategory: Yup.string().nullable().required("Field can't be empty!"),
      ...(isLiveDataConnection && {
        dateSelect: Yup.string().required("Field can't be empty!"),
        intervalYears: Yup.number().nullable().required("Field can't be empty!"),
      }),
    }),

    onSubmit: values => {
      const action = id ? updateMaintenancePlanAction : createMaintenancePlanAction;
      return dispatch(id ? action(id, values) : action(values))
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
