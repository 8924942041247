import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType, NodeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchManufacturersAction: any = createAction(
  'manufacturers/FETCH_MANUFACTURERS',
  async ({ skipPagination, skipFilters } = { skipPagination: false, skipFilters: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Manufacturers.Root, 'count' | 'items'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.MANUFACTURERS)(state);
      return NodeAPI.get('v1/secure/manufacturers', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipFilters ? null : query,
          lang: appLangSelector(state).toLowerCase(),
        },
      }).then(res => ({ count: res.data.count, items: res.data.manufacturers }));
    }
);

export const createManufacturerAction: any = createAction(
  'manufacturers/POST_MANUFACTURER',
  async (name: string) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.post('v1/secure/assetmanufacturers', { name }).then(() => dispatch(fetchManufacturersAction()));
    }
);

export const deleteManufacturerAction: any = createAction('manufacturers/DELETE_MANUFACTURER', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    NodeAPI.delete(`v1/secure/assetmanufacturers/${id}`).then(async () => {
      await dispatch(fetchManufacturersAction());
      dispatch(setSuccessToastAction('Manufacturer has been deleted'));
    });
});
