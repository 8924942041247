import { Action, handleActions } from 'redux-actions';
import { fetchUsersAction } from './actions';

const initialState: Users.Root = {
  isFetched: false,
  usersHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchUsersAction]: {
    next: (state: Users.Root, action: Action<Record<string, Users.User>>): Users.Root => ({
      ...state,
      isFetched: true,
      usersHash: action.payload,
    }),
  },
};

export { fetchUsersAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
