import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { TableN1, SelectOutageType, SelectRoute } from 'components/N1';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { DataTableTemplate, SelectPrimarySubstations } from 'components/_common';
import { PaginationType, PermissionsTypes } from 'constants/index';

const PageN1: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.N1;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, [meta.name]: option?.value }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isN1Available}>
      <RequiredPortfolioLoadflowEnabledCheck>
        <RequiredScenarioCheck>
          <RequiredSimulationCheck>
            <DataTableTemplate paddingBottom>
              <Row>
                <Col xs={3}>
                  <SelectOutageType
                    name="outageType"
                    value={filters?.outageType}
                    onChange={handleSelectChange}
                    isSearchable={false}
                  />
                </Col>
                <Col xs={3}>
                  <SelectRoute
                    name="route"
                    value={filters?.route!}
                    onChange={handleSelectChange}
                    isSearchable={false}
                  />
                </Col>
                <Col xs={4}>
                  <SelectPrimarySubstations
                    name="primarySubstationsIds"
                    value={filters?.primarySubstationsIds!}
                    onChange={handleSelectChange}
                    isMulti
                  />
                </Col>
              </Row>
              <TableN1 />
            </DataTableTemplate>
          </RequiredSimulationCheck>
        </RequiredScenarioCheck>
      </RequiredPortfolioLoadflowEnabledCheck>
    </PagePermissionsProvider>
  );
};

export default PageN1;
