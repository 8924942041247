import styled from 'styled-components';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { releaseNotificationModalVersionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';
import { currentAppReleaseVersionSelector } from 'modules/app/selectors';

const ModalReleaseNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const releaseNotificationModalVersion = useSelector(releaseNotificationModalVersionSelector);
  const currentAppReleaseVersion = useSelector(currentAppReleaseVersionSelector);
  const isShow = currentAppReleaseVersion > Number(releaseNotificationModalVersion);

  useEffect(() => {
    if (isShow) setShow(true);
  }, [isShow]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ releaseNotificationModalVersion: currentAppReleaseVersion })), 500);
  }, [dispatch, currentAppReleaseVersion]);

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      title={
        <span>
          &#127881; {getIntl("What's new in version {{version}}", { version: process.env.REACT_APP_VERSION! })}
        </span>
      }
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <>
        <p>{getIntl('Discover the latest enhancements and features in our web app')}:</p>
        {/* <ReleaseNotesList>
          <figcaption>&#128640; {getIntl('Features')}:</figcaption>
          <ul>
            <li>Forecast & Investment:</li>
            <ul>
              <li>"Setup" - Primary substation short-circuit values.</li>
              <li>"Network loading" - Short-circuit results for transformers, cables & cabinets.</li>
            </ul>
          </ul>
        </ReleaseNotesList> */}

        <ReleaseNotesList>
          <figcaption>&#128736; {getIntl('Improvements')}:</figcaption>
          <ul>
            <li>"Investment &gt; Detailed investments" - Ability to modify the investment year.</li>
            <li>"Map" - Rename top node to HV grid.</li>
            <li>"Map &gt; Single-line diagram" - Ability to filter out service assets.</li>
            <li>"Map &gt; Single-line diagram" - Display the HV grid.</li>
            <li>"Download network development plan" - Make download portfolio-dependent.</li>
            <li>"Download table excel" - Logic optimisation.</li>
            <li>"Maintenance & Planning &gt; History &gt; Asset Details" - Child asset changes are now visible.</li>
          </ul>
        </ReleaseNotesList>
        {/* <ReleaseNotesList>
          <figcaption>&#128030; {getIntl('Fixes')}:</figcaption>
          <ul>
            <li>"Primary substations" - HV Grid substation name.</li>
          </ul>
        </ReleaseNotesList> */}
        <p>{getIntl('Thank You for Your Collaboration')}! &#128578;</p>
        <p>
          {getIntl(
            'We appreciate your continued feedback, which help us improve and grow. Stay tuned for more exciting updates and features. Together, we’re making great things happen'
          )}
          !
        </p>
      </>
    </Modal>
  );
};

const ReleaseNotesList = styled.figure`
  margin-left: 20px;
  > ul {
    line-height: 2;
    margin-bottom: 0;
  }
`;

export default ModalReleaseNotification;
