import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';
import { getAverageTimeSpentByTransformersChartData } from 'modules/maintenance/actions';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
  categories: string[];
}

const ChartAverageTimeSpentByTransformers: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  useEffect(() => {
    setChartData(null);
    dispatch(getAverageTimeSpentByTransformersChartData())
      .then((action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null>) => {
        setChartData({
          title: action.payload?.title || '',
          xAxisTitle: action.payload?.xaxis_title || '',
          yAxisTitle: action.payload?.yaxis_title || '',
          series:
            action.payload?.series.map((i, index: number) => ({
              name: i.group || '',
              type: 'column' as any,
              data: i.data,
              color: Colors[index],
            })) || [],
          categories: action.payload?.categories!,
        });
      })
      .catch(console.error);
  }, [dispatch]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        categories: chartData?.categories?.map(c => getIntl(c)),
      },
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `<b>${getIntl('Average time spend per transformer (h)')}:</b> ${numberFormat(this.y)}`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    };
  }, [chartData, getIntl, numberFormat]) as unknown as Highcharts.Options;

  return (
    <Chart options={chartData ? options : null} dataMarker="chart_maintenance_average_time_spent_by_transformers" />
  );
};

export default ChartAverageTimeSpentByTransformers;
