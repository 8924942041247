import { layersState, ColorThemes, initLayers } from 'constants/index';
import { startAnimation, stopAnimation } from 'components/Map/core/layers/deck/animation';
import { _mergeWith, _pick, numberFormat, _isUndefined } from '@utiligize/shared/utils';
import { NewCustomersLegend } from 'components/Map/core/layers/other/NewCustomers';

export async function loadImages(map: Map.MapboxMap, assetImages: { id: string; source: string }[]) {
  const images = await Promise.all(assetImages.map(i => loadImage(map, i.source, i.id)));

  for (const img of images) {
    map.addImage(img.id, img.image);
  }
}

export const loadImage = (
  map: Map.MapboxMap,
  source: string,
  id: string
): Promise<{ id: string; image: ImageBitmap }> => {
  return new Promise((resolve, reject) => {
    map.loadImage(source, (err?: Error, image?: ImageBitmap | HTMLImageElement) => {
      if (err) return reject(err);
      if (image) resolve({ id, image: image as ImageBitmap });
    });
  });
};

export const getLayer = (layerId: string) => {
  return layersState.layers.find(l => l.id === layerId);
};

export const isLayerExist = (map: Map.MapboxMap, layerId: string) => {
  const layer = map.getStyle()?.layers?.find(layer => layer.id === layerId);
  return !!layer;
};

export const isSourceExist = (map: Map.MapboxMap, sourceId: string) => {
  return !!map.getStyle()?.sources?.[sourceId];
};

export const addSource = (source: Map.StyleSource) => {
  if (!layersState.sources.find(s => s.id === source.id)) {
    layersState.sources.push(source);
  }
};

export const addLayer = (id: string, isActive: boolean, source?: string, style?: Map.StyleSubLayer) => {
  if (!layersState.layers.find(l => l.id === id)) {
    layersState.layers.push({ id, source, style, isActive, filter: style?.filter, paint: {}, layout: {}, events: [] });
  }
};

export const addEvent = (id: string, type: keyof mapboxgl.MapLayerEventType, handler: (e: any) => void) => {
  const layer = layersState.layers.find(l => l.id === id);
  layer?.events.push({ type, handler });
};

export const showLayer = (map: Map.MapboxMap, layerId: string): void => {
  const layerIndex = layersState.layers.findIndex(l => l.id === layerId);
  const layerInState = layersState.layers[layerIndex];
  const layersBefore = layersState.layers.slice(layerIndex + 1).filter(l => l.isActive);
  const drawLayer = map.getStyle()?.layers?.find(l => l.id.startsWith('gl-draw'));
  const beforeId = layersBefore[0]?.id || drawLayer?.id;

  if (layerInState) {
    if (!isLayerExist(map, layerId)) {
      const source = layersState.sources.find(s => s.id === layerInState.source);
      if (source && !isSourceExist(map, source.id)) {
        map.addSource(source.id, source.src as mapboxgl.AnySourceData);
        if (!map.getSource(source.id)) console.warn('Source was not added', source);
      }
      if (layerInState.style) {
        const { style } = layerInState;
        const layout = { ...style.layout, ...layerInState.layout, visibility: 'visible' };
        const paint = { ...style.paint, ...layerInState.paint };
        const filter = layerInState.filter ?? layerInState.style.filter ?? true;
        const layer = { ...style, layout, paint, filter } as mapboxgl.AnyLayer;
        map.addLayer(layer, beforeId);
        if (map.getLayer('mapbox-buildings')) map.moveLayer('mapbox-buildings');
        for (const e of layerInState.events) map.on(e.type, layerInState.id, e.handler);
        if (!map.getLayer(layer.id)) console.warn('Layer was not added', layer);
      }
    }
    if (isLayerExist(map, layerId)) layerInState.isActive = true;
  } else if (!layerInState && isLayerExist(map, layerId)) {
    map.setLayoutProperty(layerId, 'visibility', 'visible');
  }
};

export const hideLayer = (map: Map.MapboxMap, layerId: string): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      const layerInState = layersState.layers.find(l => l.id === layerId);
      const source = layerInState?.source;
      const canRemoveSource = layersState.layers.filter(l => l.isActive && l.source === source).length <= 1;
      if (layerInState) {
        if (isLayerExist(map, layerId)) {
          for (const e of layerInState.events) map.off(e.type, layerInState.id, e.handler);
          map.removeLayer(layerInState.id);
          if (canRemoveSource && source && isSourceExist(map, source)) map.removeSource(source);
        }
        if (!isLayerExist(map, layerId)) {
          layerInState.isActive = false;
        }
      } else if (!layerInState && isLayerExist(map, layerId)) {
        map.setLayoutProperty(layerId, 'visibility', 'none');
      }
      resolve();
    }, 300);
  });
};

export const showTheme = ({
  map,
  layers,
  enabledLayersList,
  showCableAnimation = false,
}: {
  map: Map.MapboxMap;
  layers: string[];
  enabledLayersList: string[];
  showCableAnimation: boolean;
}) => {
  const enabledAssetsLayersList = enabledLayersList.filter(layer => layer.startsWith('asset__'));

  layers.forEach(async layer => {
    const isLayerEnabled = enabledAssetsLayersList.some(l => layer.includes(l));
    const isHighVoltageCableLayer = layer.includes('asset__cables_voltage_3');
    await (isLayerEnabled ? showLayer(map, layer) : hideLayer(map, layer));
    showCableAnimation && isLayerEnabled && isHighVoltageCableLayer
      ? startAnimation(map, layer)
      : stopAnimation(map, layer);
  });
};

export const hideTheme = (map: Map.MapboxMap, layers: string[]) => {
  layers.forEach(async i => {
    await hideLayer(map, i);
    stopAnimation(map, i);
  });
};

export const paintLayer = (map: Map.MapboxMap, layerId: string, prop: string, value: any) => {
  const layerInState = layersState.layers.find(l => l.id === layerId);
  if (layerInState) layerInState.paint[prop] = value;
  if (isLayerExist(map, layerId)) map.setPaintProperty(layerId, prop, value);
};

export const layoutLayer = (map: Map.MapboxMap, layerId: string, prop: string, value: any) => {
  const layerInState = layersState.layers.find(l => l.id === layerId);
  if (layerInState) layerInState.layout[prop] = value;
  if (isLayerExist(map, layerId)) map.setLayoutProperty(layerId, prop, value);
};

export const filterLayer = (map: Map.MapboxMap, layerId: string, filter?: boolean | null | any[]) => {
  const layerInState = layersState.layers.find(l => l.id === layerId);
  if (layerInState) layerInState.filter = filter;
  if (isLayerExist(map, layerId)) map.setFilter(layerId, filter);
};

export const getLayerFilter = (layerId: string): null | any => {
  const layerInState = layersState.layers.find(l => l.id === layerId);
  return layerInState?.filter;
};

export const isLayerVisible = (map: Map.MapboxMap, layerId: string) => {
  if (isLayerExist(map, layerId) && map.getLayoutProperty(layerId, 'visibility') === 'visible') return true;
  return false;
};

export const setFeatureState = (map: Map.MapboxMap, target: mapboxgl.FeatureIdentifier, state: Record<string, any>) => {
  if (isSourceExist(map, target.source)) map.setFeatureState(target, state);
};

export const removeFeatureState = (map: Map.MapboxMap, target: mapboxgl.FeatureIdentifier) => {
  if (isSourceExist(map, target.source)) map.removeFeatureState(target);
};

export const getMapSourceField = (sourceId: string, field: string) => {
  const source = layersState.sources.find(s => s.id === sourceId);
  const tile = source?.src.tiles?.[0];
  if (!tile) return null;
  return new URL(tile).searchParams.get(field)?.replace(/\s+/g, ' ').trim();
};

export const setMapSourceField = (map: Map.MapboxMap, layerIds: string[], values: Record<string, string>) => {
  const updateUrl = (url: string) => {
    const u = new URL(url);
    Object.entries(values).forEach(([k, v]) => u.searchParams.set(k, v));
    return decodeURI(u.toString());
  };

  layerIds.forEach(layerId => {
    const id = layerId.replace(/_voltage_\d*/gi, '');
    const sourceInState = layersState.sources.find(s => s.id === id);
    const tile = sourceInState?.src.tiles?.[0];
    if (sourceInState && tile) sourceInState.src.tiles = [updateUrl(tile)];

    if (isLayerExist(map, layerId)) {
      const mapSource = map.getSource(id);
      const styleSource = map.getStyle()?.sources?.[id];
      const source = { ...mapSource, ...styleSource } as mapboxgl.VectorSourceImpl & { id: string };
      const tile = source?.tiles?.[0];
      const layers = map.getStyle()?.layers;
      if (source.id && tile && layers) {
        const newSource = _pick(source, ['id', 'type', 'bounds', 'minzoom', 'maxzoom', 'tiles']);
        const newTile = updateUrl(tile);

        if (tile === newTile) return;

        newSource.tiles = [newTile];

        const sourceLayers = layers
          .filter((layer: mapboxgl.Layer) => layer.source === id)
          .map(layer => {
            const layerIndex = layers.findIndex(l => l.id === layer.id);
            const before = layers[layerIndex + 1] && layers[layerIndex + 1].id;
            return { layer, before };
          });

        sourceLayers.forEach(({ layer }) => map.removeLayer(layer.id));
        map.removeSource(source.id);
        map.addSource(newSource.id, newSource);

        sourceLayers.reverse().forEach(({ layer, before }) => {
          (layer as mapboxgl.Layer).source = newSource.id;
          map.addLayer(layer, before);
        });
      }
    }
  });
};

export const parseLegendItem = (item?: Map.LegendItem): any => {
  if (!item) return null;
  return item.layers ? item.layers.map(k => parseLegendItem(k)).flat(Infinity) : item.fn_render ? item : null;
};

export const parseLegendToItems = (legend: Map.LegendData): Map.LegendItem[] => {
  return Object.keys(legend)
    .map(k => parseLegendItem(legend[k]))
    .flat(Infinity)
    .filter(Boolean);
};

export const isGroupDisabled = (legend: Map.LegendData, legendKey: string) => {
  const prefix = (legendKey === 'assets' ? 'asset' : legendKey) + '__';
  const items = parseLegendToItems(legend);
  const disabled = legend[legendKey]?.settings?.disabled;
  const noData = items.filter(i => i.name.startsWith(prefix)).every(i => i.settings?.no_data);
  return disabled || noData;
};

export const keyToReadableProp = (key: string, toSnake?: boolean, upper: boolean = true) => {
  const toSnakeCase = (str: string) =>
    str
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  const toUpper = (str: string) => (upper ? str.charAt(0).toUpperCase() + str.slice(1) : str);
  return toUpper((toSnake ? toSnakeCase(key) : key).replace(/_/g, ' '));
};

export const getPopupCoords = (feature: mapboxgl.MapboxGeoJSONFeature, layer: Map.StyleLayer, mousePos?: number[]) => {
  type Coords = number[] | null;
  const props = feature.properties;
  const isPolygon = layer?.legend?.type === 'fill' || layer?.legend?.type === 'fill-extrusion';
  const isLine = layer?.legend?.type === 'line';
  const isPoint = layer?.legend?.type === 'circle';
  // Note. for polygons we use geom_center from the server response
  const polygonCoords: Coords = isPolygon ? JSON.parse(props?.geom_center || props?.geom_center_json || '[]') : null;
  const lineCoords: Coords = isLine ? mousePos ?? (feature.geometry as any).coordinates[0].slice() : null;
  const pointCoords: Coords = isPoint ? (feature.geometry as any).coordinates.slice() : null;
  const coords = polygonCoords ?? lineCoords ?? pointCoords;
  return coords && coords.length > 0 ? coords : null;
};

export const formatPopupProps = (
  feature: mapboxgl.MapboxGeoJSONFeature,
  layer: Map.StyleLayer,
  r: Map.Root & { investmentScenariosOption?: Type.SelectOption | null }
): Map.PopupData => {
  const scenarioKey = r.mapState.scenario?.key;
  const heatmapScenarioKey = r.mapState.heatmapScenario?.key;
  const consumptionKey = r.mapState.consumptionScenario?.key;
  const lossesKey = r.mapState.lossesScenario?.key;
  const n1MaxLoadKey = r.mapState.n1MaxLoadScenario?.key;
  const heatmapLayerId = r.settings.otherLayers!.find(i => i.includes('heatmap'));
  const heatmapFilter = r.mapState.layerFilters[heatmapLayerId!];

  const id = layer?.legend?.id.replace(/(_voltage|_column).*/gi, '') ?? '';
  const title = r.settings?.layerSingularTitle?.[id ?? ''] ?? '';
  const properties = feature.properties;

  const meta: Map.PopupData['meta'] = {
    id,
    title: id.includes('heatmap') ? (heatmapFilter?.list[0] as string) : title,
    type: feature?.layer.type,
    icon: (feature?.layer.layout as any)?.['icon-image']?.toString(),
    ...(() => {
      const voltage = properties?.voltage_level_id;
      const filterKey = Object.keys(r.settings.layerFilters ?? {}).find(k => id.includes(k));
      const filter = r.settings.layerFilters?.[filterKey!]?.list.find(f => f.id === voltage);
      return _pick(filter ?? {}, ['color', 'icon']);
    })(),
  };

  let theme: Map.PopupData['theme'] = null;

  const fieldsFilterFn = ([k]: [string, any]) =>
    ['name', 'primary_substation', 'primary_substation_from', 'primary_substation_to', 'voltage_level'].includes(k);

  const substationFilterFn = ([k]: [string, any]) => {
    const name = 'primary_substation';
    const nameFrom = name + '_from';
    const nameTo = name + '_to';
    if (k === name) return false;
    if (k === nameTo && properties?.[nameFrom] === properties?.[nameTo]) return false;
    return true;
  };

  const substationRenameFn = (k: string) => {
    const name = 'primary_substation';
    const nameFrom = name + '_from';
    const nameTo = name + '_to';
    if (k === nameFrom && properties?.[nameFrom] === properties?.[nameTo]) return name;
    return k;
  };

  const hasMaxLoadData = typeof properties?.[`l_${scenarioKey}`] !== 'undefined';
  const hasMaxLoadVoltageData = typeof properties?.[`v_${scenarioKey}`] !== 'undefined';
  const hasMaxLoadRawData = typeof properties?.[`r_${scenarioKey}`] !== 'undefined';
  const hasHeatmapData = typeof properties?.[`v_${heatmapScenarioKey}`] !== 'undefined';
  const hasConsumptionData = typeof properties?.[`e_${consumptionKey}`] !== 'undefined';
  const hasConsumptionPercentData = typeof properties?.[`p_${consumptionKey}`] !== 'undefined';
  const hasLossesData = typeof properties?.[`s_${lossesKey}`] !== 'undefined';
  const hasLossesPercentData = typeof properties?.[`t_${lossesKey}`] !== 'undefined';
  const hasDataQualityData = typeof properties?.['issue_name'] !== 'undefined';
  const hasN1Data = typeof properties?.['route_count'] !== 'undefined';
  const hasReplacementsData = typeof properties?.['replacement_reason'] !== 'undefined';

  const percentile = typeof properties?.percentile !== 'undefined' ? numberFormat(Number(properties.percentile)) : '—';

  if (layer && id.includes('ml__') && (hasMaxLoadData || hasMaxLoadVoltageData || hasMaxLoadRawData)) {
    const loading = properties[`l_${scenarioKey}`];
    const voltage = properties[`v_${scenarioKey}`];
    const raw = properties[`r_${scenarioKey}`];
    const showLoading = !/no investment/i.test(String(r.investmentScenariosOption?.label));
    const maxLoading = hasMaxLoadData ? numberFormat(loading) + ' %' : '—';
    const maxLoadingRaw = hasMaxLoadRawData ? numberFormat(raw) + ' %' : '—';
    const maxVoltageDeviation = hasMaxLoadVoltageData ? numberFormat(voltage) + ' p.u.' : '—';
    theme = {
      title: r.settings.themeTitles?.ml ?? '',
      year: scenarioKey?.split('_')[1],
      fields: Object.fromEntries(
        Object.entries({
          max_load: showLoading ? maxLoading : maxLoadingRaw,
          max_voltage_deviation: maxVoltageDeviation,
          percentile,
        }).filter(([_, v]) => v)
      ),
    };
  }

  if (layer && id.includes('ec__') && (hasConsumptionData || hasConsumptionPercentData)) {
    theme = {
      title: r.settings.themeTitles?.ec ?? '',
      year: consumptionKey?.split('_')[1],
      fields: {
        consumption: hasConsumptionData ? numberFormat(properties[`e_${consumptionKey}`]) + ' GWh' : '—',
        'consumption ': hasConsumptionPercentData ? numberFormat(properties[`p_${consumptionKey}`]) + ' %' : '—',
        percentile,
      },
    };
  }

  if (layer && id.includes('yl__') && (hasLossesData || hasLossesPercentData)) {
    theme = {
      title: r.settings.themeTitles?.yl ?? '',
      year: lossesKey?.split('_')[1],
      fields: {
        losses: hasLossesData ? numberFormat(properties[`s_${lossesKey}`]) + ' MWh' : '—',
        'losses ': hasLossesPercentData ? numberFormat(properties[`t_${lossesKey}`]) + ' %' : '—',
        percentile,
      },
    };
  }

  if (layer && id.includes('dq__') && hasDataQualityData) {
    theme = {
      title: r.settings.themeTitles?.dq ?? '',
      fields: {
        issue_type: properties.issue_type ?? '',
      },
    };
  }

  if (layer && id.includes('n_1__') && hasN1Data) {
    theme = {
      title: r.settings.themeTitles?.n_1 ?? '',
      fields: {
        available_routes: numberFormat(properties['route_count']) ?? '',
        to_primary_substation: properties['to_primary_substations'] ?? '',
      },
    };
  }

  if (layer && id.includes('n_1_ml__')) {
    const outageAssetName = properties?.[`o_${n1MaxLoadKey}`];
    const maxLoading = properties?.[`p_${n1MaxLoadKey}`];
    const isMaxLoadingDefined = !_isUndefined(maxLoading);

    const maxSpareCapacity = properties?.[`c_${n1MaxLoadKey}`];
    const isMaxSpareCapacityDefined = !_isUndefined(maxSpareCapacity);

    const maxVoltageDeviation = properties?.[`v_${n1MaxLoadKey}`];
    const isMaxVoltageDeviationDefined = !_isUndefined(maxVoltageDeviation);

    if (isMaxLoadingDefined || isMaxSpareCapacityDefined || isMaxVoltageDeviationDefined) {
      theme = {
        title: r.settings.themeTitles?.n_1_ml ?? '',
        year: n1MaxLoadKey?.split('_')[1],
        fields: {
          outage_asset_name: outageAssetName ?? '-',
          max_loading: isMaxLoadingDefined ? numberFormat(maxLoading) + ' %' : '—',
          max_spare_capacity: isMaxSpareCapacityDefined ? numberFormat(maxSpareCapacity) + ' kVA' : '—',
          max_voltage_deviation: isMaxVoltageDeviationDefined ? numberFormat(maxVoltageDeviation) + ' p.u.' : '—',
          percentile,
        },
      };
    }
  }

  if (layer && id.includes('yr__') && hasReplacementsData) {
    theme = {
      title: r.settings.themeTitles?.yr ?? '',
      fields: {
        installation_year: properties['installation_year'],
        suggested_replacement_year: properties['suggested_replacement_year'],
        replacement_reason: properties['replacement_reason'],
        replacement_cost: `${numberFormat(properties['replacement_cost'])} ${properties['currency']}`,
      },
    };
  }

  if (layer && id.includes('heatmap') && hasHeatmapData) {
    properties.name = parseFloat(properties[`v_${heatmapScenarioKey}`]);
  }

  const fields: Map.PopupData['fields'] = Object.fromEntries(
    Object.entries(properties ?? {})
      .filter(fieldsFilterFn)
      .filter(substationFilterFn)
      .map(([k, v]) => [substationRenameFn(k), v])
  );

  if (layer && id.includes('other__customers_ico') && properties) {
    fields.name = '';
    fields.installation_number = properties.installation_number;
    fields.meter_number = properties.meter_number;
    fields.metering_point = properties.metering_point;
    fields.address = properties.name;
  }

  if (layer && id.includes('other__customers_der') && properties) {
    fields[properties.der_value_name] = properties.der_value;
  }

  if (layer && id.includes(NewCustomersLegend.name) && properties) {
    fields.name = properties.name;
    fields.connected_at = properties.node_from;
    delete fields.voltage_level;
    fields.voltage = properties.voltage_level;
    fields.section = properties.section;
    fields.category = properties.category;
    fields.connection_year = properties.connection_year;
    if (properties.profile) fields.profile = properties.profile;
    if (properties.power_mw) fields['power_(MW)'] = properties.power_mw;
    if (properties.power_mw) fields.power_factor = properties.power_factor;
  }

  return { meta, fields, theme };
};

export const layerListToRecord = (layers: string[], value: boolean) => {
  return layers.reduce(
    (record, layer) => {
      record[layer] = value;
      return record;
    },
    {} as Record<string, boolean>
  );
};

export const sortLayers = (layers: Map.StyleSubLayer[]): Map.StyleSubLayer[] => {
  const rules = {
    outline: (s: Map.StyleSubLayer) => (s.id.includes('__outline') ? 1 : 0),
    highlight: (s: Map.StyleSubLayer) => (s.id.endsWith('highlight') ? 1 : 0),
    maxLoadInvestment: (s: Map.StyleSubLayer) => (s.id.endsWith('investment') ? 1 : 0),
    maxLoadFlexibility: (s: Map.StyleSubLayer) => (s.id.endsWith('flexibility') ? 1 : 0),
    lineBg: (s: Map.StyleSubLayer) => (s.id.includes('_background') && s.type === 'line' ? 1 : 0),
    pointBg: (s: Map.StyleSubLayer) => (s.id.endsWith('_background') && ['symbol', 'circle'].includes(s.type) ? 1 : 0),
    line: (s: Map.StyleSubLayer) => (s.type === 'line' ? 1 : 0),
    polygon: (s: Map.StyleSubLayer) => (s.type === 'fill' ? 1 : 0),
    column: (s: Map.StyleSubLayer) => (s.type === 'fill-extrusion' ? 1 : 0),
    point: (s: Map.StyleSubLayer) => (['symbol', 'circle'].includes(s.type) ? 1 : 0),
  };

  const getPriorities = (s: Map.StyleSubLayer, r: (keyof typeof rules)[]) =>
    Object.keys(rules)
      .filter(key => r.includes(key as keyof typeof rules))
      .map(key => {
        const i = r.findIndex(x => x === key);
        const index = i === -1 ? 0 : i + 1;
        const priority = rules[key as keyof typeof rules](s) << index;
        return index ? priority : 0;
      });

  const compare = (r: (keyof typeof rules)[], a: Map.StyleSubLayer, b: Map.StyleSubLayer) => {
    const priorityA = getPriorities(a, r).find(x => x > 0) ?? 0;
    const priorityB = getPriorities(b, r).find(x => x > 0) ?? 0;
    return priorityA - priorityB;
  };

  return layers
    .sort((a, b) => a.order - b.order)
    .sort((a, b) =>
      compare(
        [
          'polygon',
          'outline',
          'highlight',
          'lineBg',
          'line',
          'pointBg',
          'point',
          'column',
          'maxLoadFlexibility',
          'maxLoadInvestment',
        ],
        a,
        b
      )
    );
};

export const parseMaplegendToSettings = (legend: Map.LegendData): Map.Settings => {
  const s: Map.Settings = {};

  s.hasAssets = Boolean(legend.assets);
  s.hasDataQuality = Boolean(legend.dq);
  s.hasMaxLoad = Boolean(legend['ml']);
  s.hasMaxLoadIcons = s.hasMaxLoad ? legend.ml?.settings?.icons ?? true : false;
  s.hasMaxLoadVoltage = s.hasMaxLoad ? legend.ml?.settings?.voltage ?? true : false;
  s.hasMaxLoadSummary = s.hasMaxLoad ? legend.ml?.settings?.summary ?? true : false;

  s.hasConsumption = Boolean(legend['ec']);
  s.hasLosses = Boolean(legend['yl']);
  s.hasReplacements = Boolean(legend['yr']);

  s.scenarios = legend.ml?.settings?.yearlyScenarios ?? {};
  s.consumptionScenarios = legend['ec']?.settings?.yearlyScenarios ?? {};
  s.lossesScenarios = legend['yl']?.settings?.yearlyScenarios ?? {};
  s.n1MaxLoadScenarios = legend['n_1_ml']?.settings?.yearlyScenarios ?? {};
  s.replacementsScenarios = legend['yr']?.settings?.yearlyScenarios ?? {};
  s.heatmapScenarios = (() => {
    const items = parseLegendToItems(legend);
    for (const item of items) {
      const scenarios = item.settings?.yearlyScenarios;
      if (scenarios) return scenarios;
    }
    return {};
  })();

  s.hasScenarios = Object.keys(s.scenarios!).length > 0;
  s.hasConsumptionScenarios = Object.keys(s.consumptionScenarios!).length > 0;
  s.hasLossesScenarios = Object.keys(s.lossesScenarios!).length > 0;
  s.hasN1MaxLoadScenarios = Object.keys(s.n1MaxLoadScenarios!).length > 0;
  s.hasReplacementsScenarios = Object.keys(s.replacementsScenarios!).length > 0;
  s.hasHeatmapScenarios = Object.keys(s.heatmapScenarios!).length > 0;

  s.consumptionInvestmentScenarios = legend['ec']?.settings?.investmentScenarios;
  s.maxLoadInvestmentScenarios = legend['ml']?.settings?.investmentScenarios;
  s.lossesInvestmentScenarios = legend['yl']?.settings?.investmentScenarios;
  s.replacementsInvestmentScenarios = legend['yr']?.settings?.investmentScenarios;

  s.consumptionMaxValues = legend['ec']?.settings?.maxValues ?? {};
  s.lossesMaxValues = legend['yl']?.settings?.maxValues ?? {};
  s.replacementsMaxValues = legend['yr']?.settings?.maxValues ?? {};
  s.heatmapMaxValues = (() => {
    const items = parseLegendToItems(legend);
    for (const item of items) {
      const values = item.settings?.otherHeatmapsMaxVals;
      if (values) {
        return Object.keys(values).reduce((acc: any, k) => {
          acc[k] = Math.round(values[k] / 5);
          return acc;
        }, {});
      }
    }
  })();

  s.globalFilters = (() => {
    const filters = (legend.assets?.filters as any)?.global_filters as Record<string, Map.StyleLayerFilter>;
    const filtered = Object.fromEntries(Object.entries(filters ?? {}).filter(([_, v]) => v.list));
    return filters ? filtered : undefined;
  })();
  s.dataQualityFilters = legend['dq']?.filters;
  s.replacementsFilters = legend['yr']?.filters;
  s.themeActiveFilters = (() => {
    return Object.keys(legend).reduce(
      (acc, k) => {
        const item = legend[k];
        const name = item.name === 'ml' ? 'max_load' : item.name === 'dq' ? 'data_quality' : item.name;
        if (item.contains_data_for) acc[name] = item.contains_data_for;
        return acc;
      },
      {} as Record<string, Map.ThemeActiveFilter>
    );
  })();
  s.layerFilters = (() => {
    const items = parseLegendToItems(legend);
    return items.reduce(
      (acc, el) => {
        if (el.filters) acc[el.name] = el.filters;
        return acc;
      },
      {} as Record<string, Map.StyleLayerFilter>
    );
  })();

  s.mapCenter = legend['assets']?.settings?.center ?? { lng: 0, lat: 0 };
  s.layerTitle = Object.fromEntries(parseLegendToItems(legend).map(i => [i.name, i.title]));
  s.layerSingularTitle = Object.fromEntries(parseLegendToItems(legend).map(i => [i.name, i.title_singular]));
  s.themeTitles = Object.fromEntries(
    Object.entries(legend)
      .map(([k, v]) => [k, v.title])
      .concat([['ts', 'Tasks']])
  );
  s.layerTable = Object.fromEntries(parseLegendToItems(legend).map(i => [i.name, i.table]));
  s.layerUpdate = (() => {
    const updates = Object.keys(legend)
      .map(key =>
        _pick(legend[key], ['title', 'last_update', 'last_update_map', 'last_version_id', 'last_simulation_id'])
      )
      .flat(Infinity) as Map.LegendItem[];
    return {
      map: updates.find(u => u.last_update_map)?.last_update_map,
      version: updates.find(u => u.last_update_map)?.last_version_id,
      simulation: updates.find(u => u.last_update_map)?.last_simulation_id,
      layers: updates.filter(u => u.last_update),
    };
  })();
  s.satelliteLayers = parseLegendToItems(legend)
    .filter(i => i.show_satellite)
    .map(i => i.name);

  s.isAssetGroupDisabled = !s.hasAssets || isGroupDisabled(legend, 'assets');
  s.isOtherGroupDisabled = isGroupDisabled(legend, 'other');
  s.isCNAIMGroupDisabled = isGroupDisabled(legend, 'cnaim');

  s.isDataQualityThemeDisabled = !s.hasDataQuality || isGroupDisabled(legend, 'dq') || s.isAssetGroupDisabled;
  s.isMaxLoadThemeDisabled =
    !s.hasMaxLoad || !s.hasScenarios || isGroupDisabled(legend, 'ml') || s.isAssetGroupDisabled;
  s.isConsumptionThemeDisabled =
    !s.hasConsumption || !s.hasConsumptionScenarios || isGroupDisabled(legend, 'ec') || s.isAssetGroupDisabled;
  s.isLossesThemeDisabled =
    !s.hasLosses || !s.hasLossesScenarios || isGroupDisabled(legend, 'yl') || s.isAssetGroupDisabled;
  s.isN1ThemeDisabled = isGroupDisabled(legend, 'n_1') || s.isAssetGroupDisabled;
  s.isN1MaxLoadThemeDisabled = !s.hasN1MaxLoadScenarios || isGroupDisabled(legend, 'n_1_ml') || s.isAssetGroupDisabled;
  s.isTasksThemeDisabled = s.isAssetGroupDisabled;
  s.isReplacementsThemeDisabled =
    !s.hasReplacements || !s.hasReplacementsScenarios || isGroupDisabled(legend, 'yr') || s.isAssetGroupDisabled;

  return s;
};

export const parseStylesToSettings = (legend: Map.LegendData, styles: Map.StyleLayer[]): Map.Settings => {
  const layers = styles
    .map(s => s.sublayers)
    .flat()
    .filter((v, i, a) => a.findIndex(e => e.id === v.id) === i);
  const l = (key: string) => ((parseLegendItem(legend[key]) ?? []) as Map.LegendItem[]).filter(Boolean);
  return {
    initLayers: legend['assets']?.settings?.initLayers,
    assetLayers: layers
      .filter(s => l('assets').some(x => s.id.startsWith(x.name) && !x.settings?.no_data))
      .map(x => x.id),
    cnaimLayers: layers.filter(s => l('cnaim').some(x => s.id.startsWith(x.name))).map(x => x.id),
    otherLayers: layers.filter(s => l('other').some(x => s.id.startsWith(x.name))).map(x => x.id),
    dataQualityLayers: layers
      .filter(s => l('dq').some(x => s.id.startsWith(x.name) && !s.id.endsWith('warning')))
      .map(x => x.id),
    maxLoadLayers: layers
      .filter(s => s.id.startsWith('ml__') && !/(investment|flexibility)$/.test(s.id))
      .map(x => x.id),
    maxLoadIconsLayers: layers
      .filter(s => s.id.startsWith('ml__') && /(investment|flexibility)$/.test(s.id))
      .map(x => x.id),
    taskLayers: layers.filter(s => /^task|ts__/.test(s.id)).map(x => x.id),
    infoLayers: layers.filter(s => s.hasData).map(x => x.id),
    textLayers: layers.filter(s => /_text/.test(s.id)).map(x => x.id),
    highlightLayers: layers.filter(s => s.id.endsWith('highlight')).map(x => x.id),
    consumptionLayers: layers.filter(s => s.id.startsWith('ec__')).map(x => x.id),
    lossesLayers: layers.filter(s => s.id.startsWith('yl__')).map(x => x.id),
    n1Layers: layers.filter(s => s.id.startsWith('n_1__')).map(x => x.id),
    n1MaxLoadLayers: layers.filter(s => s.id.startsWith('n_1_ml__')).map(x => x.id),
    replacementsLayers: layers.filter(s => s.id.startsWith('yr__')).map(x => x.id),
  };
};

export const mapColorGradient = (theme: Map.ColorTheme, type: string, min: number, max: number, baseColor?: string) => {
  const colors = ColorThemes.list[5][type].map(c => c[theme]);
  const range = max - min;
  const step1 = range / (colors.length - 1);
  return colors.map((color, i) => [min + step1 * i, baseColor && i === 0 ? baseColor : color]).flat();
};

export const getInitMapState = (
  settings: Map.Settings,
  filters: Record<string, Map.LayerFilter>,
  resetStations: boolean,
  themeLayers?: string[] | null,
  dataQualityFilters?: Map.LayerFilter | null
) => {
  const layers = (themeLayers ?? settings.initLayers ?? initLayers).filter(i => filters[i]);
  const dataQualityFilter = dataQualityFilters
    ? ({ list: dataQualityFilters?.initList.map(f => f.id) } as Map.LayerFilter)
    : null;
  const layersToShow = settings.assetLayers!.filter(i => layers.some(l => i.startsWith(l)));
  const enabledLayers = layerListToRecord(layersToShow, true);
  const substations = 'filter_primary_substations';
  const substationsState = settings.globalFilters?.[substations];
  const globalFilters = { [substations]: { list: substationsState?.list.map(i => i.id) ?? [] } as Map.LayerFilter };
  const layerFilter = Object.fromEntries(
    layers.map(i => [i, { list: filters[i]?.initList.map(i => i.id) ?? [] } as Map.LayerFilter])
  );
  return Object.fromEntries(
    Object.entries({
      enabledLayers,
      globalFilters: resetStations ? globalFilters : null,
      layerFilters: layerFilter,
      dataQualityFilters: dataQualityFilter,
    }).filter(([_, v]) => v)
  ) as Partial<Map.MapState>;
};

export const filterMapState = (state: Map.MapState): Partial<Map.MapState> => {
  const keys: (keyof Map.MapState)[] = ['enabledLayers', 'collapseGroupState'];
  return Object.fromEntries(
    Object.entries(state).filter(([k, v]) => {
      const key = k as keyof Map.MapState;
      if (keys.includes(key)) {
        Object.keys(v ?? {}).forEach(k => {
          if (!/^other|cnaim/.test(k)) delete v[k];
        });
      }
      return keys.includes(key);
    })
  );
};

export const mergeMapState = (
  cachedState: Map.MapState,
  nextState: Map.Root,
  mapDefaultState: Map.MapInitialState<Partial<Map.MapState>>
): Map.MapState => {
  return Object.fromEntries(
    Object.entries(mapDefaultState).map(([k, v]) => {
      const key = k as keyof Map.MapState;
      const nextStateValue = v(nextState) as Map.MapState[typeof key];
      const cachedStateValue = cachedState[key];
      const value =
        typeof cachedStateValue !== 'undefined'
          ? typeof cachedStateValue === 'object' && cachedStateValue !== null
            ? _mergeWith({}, nextStateValue, cachedStateValue, (_, b) =>
                !Array.isArray(b) && typeof b === 'object' ? undefined : b
              )
            : cachedStateValue
          : nextStateValue;
      return [k, value];
    })
  ) as any;
};

export const parseObject = <T>(object: T) =>
  (typeof object === 'string' ? JSON.parse(object) : object) as Exclude<T, null>;

export const getAssetLayerFilters = (layerId: string) => {
  const layer = getLayer(layerId);
  const voltageFilter = layer?.filter?.[1] ?? true;
  const layerFilter = layer?.filter?.[2] ?? true;
  const assetFilter = layer?.filter?.[3] ?? true;
  const textFilter = layer?.filter?.[4] ?? true;
  return { voltageFilter, layerFilter, assetFilter, textFilter };
};

export const getTileTimeStamp = (legendItem: Map.LegendItem) => {
  return new URLSearchParams(
    Object.fromEntries(
      Object.entries({
        updateTimestamp: legendItem.last_update!,
      }).filter(([_, v]) => Boolean(v))
    )
  );
};

export const getDefaultFilterIds = (list: Map.StyleLayerFilterItem[]) => {
  const allFilterIds = list.map(l => l.id) || [];
  const defaultFilterIds = list.filter(i => i.default_state ?? !i.disabled).map(l => l.id);
  return defaultFilterIds?.length ? defaultFilterIds : allFilterIds;
};

export const removeLayerSafely = (map: mapboxgl.Map, id: string) => {
  try {
    if (map?.getLayer(id)) map?.removeLayer(id);
  } catch {}
};

export const removeSourceSafely = (map: mapboxgl.Map, id: string) => {
  try {
    if (map?.getSource(id)) map?.removeSource(id);
  } catch {}
};
