import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { checklistsSelector } from 'modules/tasks/selectors';
import { fetchTaskChecklistsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { NonAssetTaskAppActions } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  appAction: Type.TaskAppActions | null;
  assetCategoryCode: Type.AssetCategories | null;
  taskCheckListName: Tasks.Task['taskCheckListName'];
}

const SelectTaskChecklist: React.FC<Props> = ({
  labelKey = 'Checklist',
  value,
  appAction,
  assetCategoryCode,
  taskCheckListName,
  ...props
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const checklists = useSelector(checklistsSelector);
  const isDisabled = useMemo(
    () => !appAction || (!assetCategoryCode && !NonAssetTaskAppActions.includes(appAction)),
    [appAction, assetCategoryCode]
  );

  useEffect(() => {
    if (isDisabled) return;
    setIsLoading(true);
    dispatch(fetchTaskChecklistsAction({ appAction, assetCategoryCode })).then(() => setIsLoading(false));
  }, [isDisabled, appAction, assetCategoryCode, dispatch]);

  const selectOptions = useMemo(() => checklists.map(item => ({ value: item.id, label: item.name })), [checklists]);

  const selectValue = useMemo(() => selectOptions.find(item => item.value === value) || null, [selectOptions, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={
        !selectValue && taskCheckListName && value
          ? { value, label: `${taskCheckListName} (${getIntl('deleted')})` }
          : selectValue
      }
      options={selectOptions}
      isDisabled={isLoading || isDisabled || props.isDisabled}
      isLoading={isLoading}
      isClearable
    />
  );
};

export default SelectTaskChecklist;
