import { createSelector } from 'reselect';

// ------------------------------------
// SMART_METER_ACTIONS Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.meters;

const smartMeterActionsHashSelector = createSelector(rootSelector, meters => meters.smartMeterActionsHash);

export const smartMeterActionsSelector = createSelector(smartMeterActionsHashSelector, smartMeterActionsHash =>
  Object.values(smartMeterActionsHash)
);

export const smartMeterActionsCountSelector = createSelector(rootSelector, meters => meters.smartMeterActionsCount);

// ------------------------------------
// METERS_POPULATION Selectors
// ------------------------------------
const metersPopulationHashSelector = createSelector(rootSelector, meters => meters.populationHash);

export const metersPopulationSelector = createSelector(metersPopulationHashSelector, populationHash =>
  Object.values(populationHash)
);

export const metersPopulationCountSelector = createSelector(rootSelector, meters => meters.populationCount);

export const metersPopulationItemSelectorFactory = (id: number | null) =>
  createSelector(metersPopulationHashSelector, populationHash => populationHash[`_${id}_`]);

export const smartMeterActionSelector = createSelector(rootSelector, meters => meters.smartMeterAction);
