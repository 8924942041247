import styled from 'styled-components';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocale, useToggle } from 'hooks';
import { useDispatch } from 'react-redux';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { FieldControls, BuilderFieldsRenderer } from 'components/_builder';
import { Gallery } from 'components/_common';
import { BuilderFieldsTypes } from 'constants/index';
import './BuilderField.scss';

export interface BuilderFieldProps {
  field: Builder.Field;
  fieldTypes: Builder.FieldType[];
  addField: (formField: Builder.Field) => void;
  changeField: (field: Builder.Field) => void;
  moveField: (index: number, offset: number) => void;
  delField: (index: number) => void;
  children?: React.ReactNode;
}

const BuilderField: React.FC<BuilderFieldProps> = ({ field, addField, changeField, moveField, delField, children }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [showModal, toggleModal] = useToggle();

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      if (showModal) toggleModal(event);
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => delField(field.index),
        })
      );
    },
    [showModal, toggleModal, dispatch, delField, field.index]
  );

  const onTop = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      moveField(field.index, -1);
    },
    [moveField, field.index]
  );

  const onBottom = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      moveField(field.index, 1);
    },
    [moveField, field.index]
  );

  const renderMoveFieldButton = (position: 'top' | 'bottom') => {
    const isTopButton: boolean = position === 'top';
    return (
      <button type="button" onClick={isTopButton ? onTop : onBottom}>
        <i className={`fa ${isTopButton ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </button>
    );
  };

  const { Photo, AssetPhoto, Image } = BuilderFieldsTypes;
  const isPhotoField = [Photo, AssetPhoto, Image].includes(field.type);
  return (
    <>
      <Row className="mx-0 w-100 d-flex my-1 flex-grow-1">
        <StyledCol sm={1} className="d-flex flex-column justify-content-around align-items-center p-0 rounded-left">
          {renderMoveFieldButton('top')}
          {renderMoveFieldButton('bottom')}
        </StyledCol>
        <Col className="bg-white border p-0 rounded-right">
          <FieldControls onEdit={toggleModal} onDelete={handleDeleteBtnClick} />
          <div className="mx-3 my-4 d-flex align-items-center">
            <span className="field-text font-weight-bold align-self-start">{getIntl(field.type)} </span>
            {field.title && <span className="field-text pl-1 text-break"> - {getIntl(field.title)}</span>}
            {isPhotoField && field.params?.[1]?.values[0].valuealt && (
              <div style={{ minWidth: 50, minHeight: 50, position: 'absolute', right: 100 }}>
                <Gallery
                  rowHeight={50}
                  images={
                    [
                      {
                        id: 0,
                        src: field.params[1].values[0].valuealt,
                      },
                    ] as any
                  }
                />
              </div>
            )}
          </div>
          {children}
        </Col>
      </Row>
      {showModal && (
        <BuilderFieldsRenderer
          type={field.type}
          onCloseModal={toggleModal}
          addField={addField}
          formField={field}
          onChangeModal={changeField}
        />
      )}
    </>
  );
};

const StyledCol = styled(Col)`
  background-color: ${props => props.theme.colors.purple50};
  max-width: 25px;

  > button {
    color: inherit !important;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }

    > i {
      font-size: 14px;
    }
  }
`;

export default BuilderField;
