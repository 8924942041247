import { createAction } from 'redux-actions';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchCablesAction: any = createAction(
  'network-loading/FETCH_CABLES',
  async (
    { skipPagination, skipStoreUpdate, skipPaginationLimit, skipPaginationOffset } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
      skipPaginationLimit: undefined,
      skipPaginationOffset: 0,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'cablesHash' | 'cablesCount'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.CABLES)(state);
      return AssetLifeAPI.get('load/load_cables', {
        params: {
          limit: skipPagination ? skipPaginationLimit : limit,
          offset: skipPagination ? skipPaginationOffset : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          simulation_id: simulationIdSelector(state),
          version_id: simulationVersionIdSelector(state),
          voltage: filters?.voltage,
          hide_solved: filters?.solvedValue,
          year: filters?.year,
          voltage_display: filters?.voltageDisplay,
          percentile: filters?.percentile,
          show_current_values: filters?.show_current_values,
          flex: filters?.flex,
        },
      }).then(res => ({
        cablesCount: res.data.count,
        cablesHash: _keyBy(res.data.rows, (item: NetworkLoading.Cable) => item.id),
        skipStoreUpdate,
      }));
    }
);

interface GetChartDataParams {
  portfolioId: Layouts.Root['portfolioId'];
  scenarioId: Layouts.ScenarioId;
  simulationId: Layouts.Root['simulationId'];
  versionId: number;
  voltage: Layouts.Filters['voltage'];
  year: Layouts.Filters['year'];
  percentile: Layouts.Filters['percentile'];
  flex: Layouts.Filters['flex'];
  showOverloadedAssets: Layouts.Filters['showOverloadedAssets'];
}

export const getCablesLoadingChartData = createAction(
  'network-loading/GET_CABLES_LOADING_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    voltage,
    year,
    percentile,
    flex,
    showOverloadedAssets,
  }: GetChartDataParams) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null> => {
      return AssetLifeAPI.get('load/load_cables_hist_plot', {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
          voltage,
          year,
          percentile,
          flex,
          show_overloaded_assets: showOverloadedAssets,
        },
      }).then(res => res.data);
    }
);

export const getCablesVoltageChartData = createAction(
  'network-loading/GET_CABLES_VOLTAGE_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    voltage,
    year,
    percentile,
    flex,
  }: Omit<GetChartDataParams, 'showOverloadedAssets'>) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null> => {
      return AssetLifeAPI.get('load/voltage_cables_hist_plot', {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
          voltage,
          year,
          percentile,
          flex,
        },
      }).then(res => res.data);
    }
);
