import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { investmentBudgetMetaSelector } from 'modules/setup/selectors';
import { SelectComponentPriceScenario } from 'components/_common';
import { PaginationType } from 'constants/index';

const FiltersInvestmentBudget: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.SETUP_INVESTMENT_BUDGET;
  const { getIntl, numberFormat } = useLocale();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const meta = useSelector(investmentBudgetMetaSelector);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, [meta.name]: option?.value }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <Row>
      <Col xs={2}>
        <SelectComponentPriceScenario
          name="componentPriceScenarioId"
          value={filters?.componentPriceScenarioId || null}
          onChange={handleSelectChange}
          isClearable
          labelKey=""
        />
      </Col>
      <Col xs="auto" className="d-flex flex-column ml-auto">
        <small className="text-muted">
          {getIntl('Total yearly budget')}:{' '}
          {meta?.total_yearly_budget ? `${numberFormat(meta?.total_yearly_budget)} DKK` : '-'}
        </small>
        <small className="text-muted">
          {getIntl('Total grid value')}: {meta?.total_grid_value ? `${numberFormat(meta?.total_grid_value)} DKK` : '-'}
        </small>
      </Col>
    </Row>
  );
};

export default FiltersInvestmentBudget;
