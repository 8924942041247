import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { timeByActionTypeChartDataSelector } from 'modules/maintenance/selectors';
import { maintenanceTimeByActionTypeAssetCategoriesSelector } from 'modules/layouts/selectors';
import { FormReactSelect } from 'components/_common';
import { setLayoutAction } from 'modules/layouts';

const SelectChartTimeByActionTypeAssetCategories: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const assetCategories = useSelector(maintenanceTimeByActionTypeAssetCategoriesSelector);
  const chartData = useSelector(timeByActionTypeChartDataSelector);

  const handleAssetsSelectChange = useCallback(
    (option: Type.SelectOption[] | null) => {
      dispatch(
        setLayoutAction({
          maintenanceTimeByActionTypeAssetCategories: (option?.map(item => String(item.value)) ||
            []) as Type.AssetCategories[],
        })
      );
    },
    [dispatch]
  );

  const { options, value } = useMemo(
    () =>
      (chartData?.categories || []).reduce(
        (acc: any, category: string) => {
          const item = { value: category, label: getIntl(category) };
          if (assetCategories.includes(item.value as Type.AssetCategories)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [chartData?.categories, assetCategories, getIntl]
  );

  return (
    <FormReactSelect
      labelKey=""
      mutedTextLabelKey="Asset categories"
      value={value}
      options={options}
      onChange={handleAssetsSelectChange}
      isSearchable={false}
      isMulti
    />
  );
};

export default SelectChartTimeByActionTypeAssetCategories;
