import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { metersPopulationSelector, metersPopulationCountSelector } from 'modules/meters/selectors';
import { fetchMetersPopulationAction } from 'modules/meters';
import { setLayoutAction } from 'modules/layouts';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';

const MetersPopulationTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { dateFormat } = useLocale();

  const count = useSelector(metersPopulationCountSelector);
  const items = useSelector(metersPopulationSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchMetersPopulationAction(params)).then(
        (action: Shared.ReduxAction<Pick<Meters.Root, 'populationHash'>>) =>
          Object.values(action.payload?.populationHash || {})
      ),
    [dispatch]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ metersPopulationModalId: Number(id) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.METERS_POPULATION}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      containerClassName="border-top-0"
      maxHeight="calc(100vh - 170px)"
    >
      {items.map((item: Meters.Population) => (
        <tr key={item.id}>
          <td>{item.meterNumber}</td>
          <td>{item.batchNumber}</td>
          <td>{item.type}</td>
          <td>{item.installation || '-'}</td>
          <td>{item.address || '-'}</td>
          <td>{item.connection || '-'}</td>
          <td>{item.serialNumber || '-'}</td>
          <td>{item.dateActivated ? dateFormat(item.dateActivated) : '-'}</td>
          <td>{item.dateDiscarded ? dateFormat(item.dateDiscarded) : '-'}</td>
          <td>{item.dateRemoved ? dateFormat(item.dateRemoved) : '-'}</td>
          <td>{item.controlDate ? dateFormat(item.controlDate) : '-'}</td>
          <td className="text-right">
            <Button
              tooltipKey="Details"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleDetailsBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default MetersPopulationTable;
