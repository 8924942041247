import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapIsLoadingSelector } from 'modules/map/selectors';

const MapNotifications: React.FC = () => {
  const { getIntl } = useLocale();
  const mapIsLoading = useSelector(mapIsLoadingSelector);

  if (!mapIsLoading) return null;
  return (
    <StyledContainer>
      {mapIsLoading && (
        <StyledMessageContainer>
          <span className="mr-2">{getIntl('Loading, please wait')}</span>
          <i className="fas fa-spinner fa-pulse" />
        </StyledMessageContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
  user-select: none;
  opacity: 0.9;

  > div:not(:last-child) {
    margin-bottom: 10px;
    display: inline-block;
  }
`;

const StyledMessageContainer = styled.div`
  padding: 7px 10px;
  box-shadow:
    0px 4px 4px rgb(50 50 71 / 8%),
    0px 4px 8px rgb(50 50 71 / 6%);
  border: 0.5px solid #d4d4d4;
  border-radius: 8px;
  background: #fff;
  color: var(--map-text-color-primary);
  font-size: 12px;
`;

export default MapNotifications;
