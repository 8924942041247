import moment from 'moment';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, TaskTypes, TaskMonths, DateFormats, NodeAPI } from 'constants/index';
import { calcBusinessDaysInMonths, getTaskMonthsNumbers } from './helpers';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchEmployeesAction: any = createAction(
  'employees/FETCH_EMPLOYEES',
  async (
    { skipPagination, skipFilters, skipStoreUpdate } = {
      skipPagination: false,
      skipFilters: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Employees.Root, 'employeesCount' | 'employeesHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.EMPLOYEES)(
        state
      );
      return NodeAPI.get('v1/secure/electricians', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipFilters ? null : query,
          lang: appLangSelector(state).toLowerCase(),
          filters: { year: filters?.year },
        },
      }).then(res => ({
        employeesCount: res.data.count,
        employeesHash: _keyBy(res.data.rows, (item: Employees.Item) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

interface Data {
  id: number;
  startDate: string;
  endDate: string;
  minutes: number;
}

export const createEmployeeAction = createAction(
  'employees/CREATE_EMPLOYEE',
  (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.post('v1/secure/electricians', data).then(async () => {
        await dispatch(fetchEmployeesAction());
        dispatch(setSuccessToastAction('Employee has been created'));
      });
    }
);

export const updateEmployeeAction: any = createAction(
  'employees/UPDATE_EMPLOYEE',
  (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.put('v1/secure/electricians', data).then(async () => {
        await dispatch(fetchEmployeesAction());
        dispatch(setSuccessToastAction('Employee has been updated'));
      });
    }
);

export const deleteEmployeeAction: any = createAction('employees/DELETE_EMPLOYEE', async (id: string) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return NodeAPI.delete(`v1/secure/electricians/${id}`).then(async () => {
      await dispatch(fetchEmployeesAction());
      dispatch(setSuccessToastAction('Employee has been deleted'));
    });
  };
});

export const copyFromPreviousYearAction = createAction(
  'employees/COPY_FROM_PREVIOUS_YEAR',
  (year: number) =>
    async (dispatch: Shared.CustomDispatch): Promise<void> => {
      return NodeAPI.put(`v1/secure/electricians/copy?originYear=${year - 1}&targetYear=${year}`).then(async () => {
        await dispatch(fetchEmployeesAction());
        dispatch(setSuccessToastAction('Employees has been copied'));
      });
    }
);

export const fetchEmployeesGanttChartAction = createAction(
  'employees/FETCH_EMPLOYEES_GANTT_CHART',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<
      Pick<
        Employees.Root,
        | 'employeesGanttChartHash'
        | 'employeesGanttChartFetched'
        | 'employeesGanttChartHoursLeftOverWeek'
        | 'employeesGanttChartHoursLeftOverMonth'
      >
    > => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.EMPLOYEES)(state);

      return NodeAPI.get('v1/secure/electricians/ganttChart', {
        params: { filters: { ...filters, userIds: filters?.electricianIds, electricianIds: undefined } },
      }).then(res => {
        const year = filters?.year!;
        const totalNumberOfEmployees = res.data.electricians.length;
        const isLeapYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
        const daysInAYear = isLeapYear ? 366 : 365;
        const weeksAmount = 53;
        const oneDayAvailableEffort = 5.6; // 28h per week for each employee
        const workingDaysAmount = 5;
        const daysFromPreviousYear = (new Date(year, 0, 1).getDay() || 7) - 1; // 0 === Sunday
        const daysFromNextYear = weeksAmount * 7 - daysFromPreviousYear - daysInAYear;
        const workingDaysHash = calcBusinessDaysInMonths(`${filters?.year}-01-01`, `${filters?.year}-12-31`, {
          onlyBusinessDays: true,
        });
        const workingDays = [].concat.apply([], Object.values(workingDaysHash));

        // Initial hours week availability calculation
        const employeesGanttChartHoursLeftOverWeek = Array.from(Array(weeksAmount).keys()).map(
          (weekArrIndex: number) => {
            if (weekArrIndex === 0) {
              return (
                totalNumberOfEmployees *
                oneDayAvailableEffort *
                (daysFromPreviousYear < workingDaysAmount ? workingDaysAmount - daysFromPreviousYear : 0)
              );
            }
            if (weekArrIndex === 52) {
              const daysInLastWeek = 7 - daysFromNextYear;
              return (
                totalNumberOfEmployees *
                oneDayAvailableEffort *
                (daysInLastWeek > workingDaysAmount ? workingDaysAmount : daysInLastWeek)
              );
            }
            return totalNumberOfEmployees * oneDayAvailableEffort * workingDaysAmount;
          }
        );

        // Initial hours month availability calculation
        const employeesGanttChartHoursLeftOverMonth = Array.from(Array(12).keys()).map((monthArrIndex: number) => {
          return totalNumberOfEmployees * oneDayAvailableEffort * workingDaysHash[monthArrIndex].length;
        });

        const employeesGanttChartHash = res.data.electricians.reduce(
          (acc: Record<string, Employees.GanttChartItem>, item: Employees.GanttChartItem) => {
            // Initial employee hours year availability
            let hoursLeftOver = oneDayAvailableEffort * workingDays.length;

            const tasks = item.tasks.map(task => {
              const businessDaysNumbers = [].concat.apply(
                [],
                Object.values(
                  calcBusinessDaysInMonths(task.startDate, task.endDate, {
                    onlyBusinessDays: true,
                  })
                )
              );

              const businessDaysInCurrentYearNumbers = ((): number[] => {
                if (task.type !== TaskTypes.Autogenerated) {
                  return [].concat.apply(
                    [],
                    Object.values(
                      calcBusinessDaysInMonths(
                        moment(task.startDate).isBefore(`${filters?.year}-01-01`)
                          ? `${filters?.year}-01-01`
                          : task.startDate,
                        moment(task.endDate).isAfter(`${filters?.year}-12-31`)
                          ? `${filters?.year}-12-31`
                          : task.endDate,
                        {
                          onlyBusinessDays: true,
                        }
                      )
                    )
                  );
                }

                return [].concat.apply(
                  [],
                  Object.values(
                    task.taskMonths?.reduce((acc: any, month) => {
                      const index = TaskMonths.indexOf(month);
                      const firstDay = moment(new Date(year, index, 1)).format(DateFormats.SERVER);
                      const lastDay = moment(new Date(year, index + 1, 0)).format(DateFormats.SERVER);
                      const a = calcBusinessDaysInMonths(firstDay, lastDay, { onlyBusinessDays: true });
                      acc[index] = a[index];
                      return acc;
                    }, {})
                  )
                );
              })();

              const totalAmountOfDays =
                task.type === TaskTypes.Autogenerated
                  ? businessDaysInCurrentYearNumbers.length
                  : businessDaysNumbers.length;

              const oneDayEffort =
                totalAmountOfDays &&
                task.totalExpectedTime /
                  ((task.assignedUsers.length || 1) + (task.responsibleUsers.length || 1)) /
                  totalAmountOfDays;

              // Employee hours left over year calculation
              hoursLeftOver = hoursLeftOver - oneDayEffort * businessDaysInCurrentYearNumbers.length;

              // Hours left over week calculation
              businessDaysInCurrentYearNumbers.forEach(num => {
                const weekNumber = Math.ceil((num + daysFromPreviousYear) / 7);
                employeesGanttChartHoursLeftOverWeek[weekNumber - 1] =
                  employeesGanttChartHoursLeftOverWeek[weekNumber - 1] - oneDayEffort;

                const monthNumber = moment().dayOfYear(num).month();
                employeesGanttChartHoursLeftOverMonth[monthNumber] =
                  employeesGanttChartHoursLeftOverMonth[monthNumber] - oneDayEffort;
              });

              return {
                ...task,
                oneDayEffort,
                businessDaysInCurrentYearNumbers,
                taskMonthsNumbers: getTaskMonthsNumbers(task, year),
              };
            });

            acc[item.email] = { ...item, tasks, hoursLeftOver };
            return acc;
          },
          {}
        );

        return {
          employeesGanttChartFetched: true,
          employeesGanttChartHash,
          employeesGanttChartHoursLeftOverWeek,
          employeesGanttChartHoursLeftOverMonth,
        };
      });
    }
);

export const fetchTasksGanttChartAction = createAction(
  'employees/FETCH_TASKS_GANTT_CHART',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Employees.Root, 'tasksGanttChartFetched' | 'tasksGanttChartHash'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.EMPLOYEES)(state);
      return NodeAPI.get('v2/secure/tasks', {
        params: {
          limit: null,
          offset: null,
          sort: 'ASC',
          column: 'name',
          query: '',
          lang: appLangSelector(state).toLowerCase(),
          year: filters?.year,
          type: filters?.type && JSON.stringify(filters?.type),
          users: filters?.electricianIds && JSON.stringify(filters?.electricianIds),
          status: filters?.status && JSON.stringify(filters?.status),
          includeExpectedTimeFields: true,
        },
      }).then(res => ({
        tasksGanttChartFetched: true,
        tasksGanttChartHash: res.data.rows.reduce((acc: any, task: Tasks.Task) => {
          acc[`_${task.id}_`] = { ...task, taskMonthsNumbers: getTaskMonthsNumbers(task, filters?.year!) };
          return acc;
        }, {}),
      }));
    }
);
