import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { PageAppExceptions } from 'pages';
import {
  ViewCreatePlans,
  ViewPlanCNAIM,
  ViewPlanSummary,
  ViewDetailedPlan,
  ViewStatistics,
} from 'components/Maintenance';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes, StorageKeys } from 'constants/index';

const PageMaintenance: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isMaintenanceEnabled}>
      {/* RequiredScenarioCheck & RequiredSimulationCheck free views */}
      <Switch>
        <Route exact path={Routes.Maintenance} component={ViewCreatePlans} />
        {featureToggles[StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM] && (
          <Route exact path={Routes.MaintenancePlanCNAIM} component={ViewPlanCNAIM} />
        )}
        <Route exact path={Routes.MaintenancePlanSummary} component={ViewPlanSummary} />
        <Route exact path={Routes.MaintenanceDetailedPlan} component={ViewDetailedPlan} />
        <Route path={Routes.MaintenanceStatistics} component={ViewStatistics} />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    </PagePermissionsProvider>
  );
};

export default PageMaintenance;
