export const renderOtherBuildings = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        minzoom: legendItem.minz,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        type: 'fill',
        group: legendItem.group,
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: legendItem.name,
          type: 'fill',
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'fill-color': 'rgba(229, 137, 255, 0.7)',
          },
        },
      ],
    },
  };
  return [style];
};
