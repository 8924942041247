import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewSaifiCMLplots } from 'components/Reliability';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageReliability: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isReliabilityEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <Switch>
            <Route exact path={Routes.Reliability} component={ViewSaifiCMLplots} />
          </Switch>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageReliability;
