import React from 'react';
import { Switch, Route } from 'react-router';
import {
  ViewIssues,
  ViewCustomersConnected,
  ViewMissingGeometry,
  ViewMeters,
  ViewStatistics,
} from 'components/DataQuality';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { PageAppExceptions } from 'pages';
import { Routes, PermissionsTypes } from 'constants/index';

const PageDataQuality: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isDataQualityEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <Switch>
            <Route exact path={Routes.DataQuality} component={ViewIssues} />
            <Route path={Routes.DataQualityCustomersConnected} component={ViewCustomersConnected} />
            <Route path={Routes.DataQualityMissingGeometry} component={ViewMissingGeometry} />
            <Route exact path={Routes.DataQualityMeters} component={ViewMeters} />
            <Route exact path={Routes.DataQualityStatistics} component={ViewStatistics} />
            <Route path="*" component={PageAppExceptions} />
          </Switch>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageDataQuality;
