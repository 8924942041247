import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portfolioOptionsSelector, portfolioOptionSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectPortfolio: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(portfolioOptionsSelector);
  const value = useSelector(portfolioOptionSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      dispatch(
        setLayoutAction({
          portfolioId: Number(option.value),
          simulationId: null,
          isMapConfigFetched: false,
          singleDiagramFilterPrimarySubstationsId: null,
          singleDiagramFilterVoltagesIds: null,
        })
      );
    },
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!options?.length}
      isDisabled={!options?.length}
      labelKey="Portfolio"
      value={value || null}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
    />
  );
};

export default SelectPortfolio;
