import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import SelectVoltageUnit from './SelectVoltageUnit';
import { PaginationType } from 'constants/index';

const FiltersInvestmentLimitations: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.INVESTMENT_LIMITATIONS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, [meta.name]: option?.value }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <Row>
      <Col xs={2}>
        <SelectVoltageUnit
          name="voltageUnit"
          value={filters?.voltageUnit || null}
          onChange={handleSelectChange}
          isSearchable={false}
          labelKey=""
          mutedTextLabelKey="Voltage unit"
        />
      </Col>
    </Row>
  );
};

export default FiltersInvestmentLimitations;
