import axios from 'axios';
import { generatePath } from 'react-router';
import Routes from './routes';
export * from './tasks';
export * from './storage';
export * from './map';
export { MaxMobileWidth, isProduction, isDevelopment, isDevelop } from '@utiligize/shared/constants';

export enum Languages {
  EN = 'EN',
  DA = 'DA',
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  NO = 'NO',
  SV = 'SV',
}

export const DateFormats = {
  CLIENT: 'DD/MM/YYYY',
  SERVER: 'YYYY-MM-DD',
};

// Each table pagination and controls meta stored to redux separately
export enum PaginationType {
  FORMS = 'forms',
  MANUALS = 'manuals',
  ANSWERS = 'answers',
  HISTORY = 'history',
  INSTRUCTIONS = 'instructions',
  UPCOMING_TASKS = 'upcomingTasks',
  TASKS = 'tasks',
  TASKS_FINISHED = 'tasksFinished',
  NEW_TASKS = 'newTasks',
  TASKS_COMPLAINTS = 'tasksComplaints',
  ASSET_MODELS = 'assetModels',
  INSPECTIONS = 'inspections',
  MANUFACTURERS = 'manufacturers',
  SMART_METER_ACTIONS = 'smartMeterActions',
  METERS_MISSING_IN_MDM = 'metersMissingInMDM',
  METERS_MISSING_IN_GIS = 'metersMissingInGIS',
  METERS_POPULATION = 'metersPopulation',
  ASSETS = 'ASSETS',
  SUB_COMPONENTS = 'SUB_COMPONENTS',
  EMPLOYEES = 'EMPLOYEES',
  ADMIN_ACCESS_LOGS = 'ADMIN_ACCESS_LOGS',
  REPEATED_TASKS_SECTIONS = 'repeatedTasksSections',
  ISSUES = 'ISSUES',
  TRANSFORMERS = 'TRANSFORMERS',
  CABLES = 'CABLES',
  DATA_QUALITY_MISSING_AGE = 'DATA_QUALITY_MISSING_AGE',
  COMPONENT_PRICES = 'COMPONENT_PRICES',
  WORK_PRICES = 'WORK_PRICES',
  DETAILED_INVESTMENTS = 'DETAILED_INVESTMENTS',
  TASKS_CHECKLISTS = 'TASKS_CHECKLISTS',
  TASKS_MATERIALS = 'TASKS_MATERIALS',
  NK_TASKS_MATERIALS = 'NK_TASKS_MATERIALS',
  MAINTENANCE_CREATE_PLANS = 'MAINTENANCE_CREATE_PLANS',
  MAINTENANCE_SUMMARY = 'maintenanceSummary',
  MAINTENANCE_PLAN = 'maintenancePlan',
  TOTAL_HOURS = 'totalHours',
  MAINTENANCE_STATISTICS_USERS = 'MAINTENANCE_STATISTICS_USERS',
  PERMISSIONS_GROUPS = 'permissionsGroups',
  PERMISSIONS_USERS = 'permissionsUsers',
  CUSTOMERS_RIGHTS = 'CUSTOMERS_RIGHTS',
  TRANSFORMERS_IGNORE_MESSAGES = 'transformersIgnoreMessages',
  ASSET_TASK_COMPLETIONS = 'ASSET_TASK_COMPLETIONS',
  USERS_REGISTERED_HOURS = 'usersRegisteredHours',
  TASKS_REGISTERED_HOURS = 'tasksRegisteredHours',
  FORECASTED_SCENARIOS = 'FORECASTED_SCENARIOS',
  NETWORK_LOADING_CUSTOMERS_TYPES = 'NETWORK_LOADING_CUSTOMERS_TYPES',
  TASKS_MOBILE_SUBMITTED = 'TASKS_MOBILE_SUBMITTED',
  REPEATED_TASKS = 'REPEATED_TASKS',
  NEW_LOAD_DERS = 'NEW_LOAD_DERS',
  NEW_LOAD_DERS_CATEGORIES = 'NEW_LOAD_DERS_CATEGORIES',
  NEW_LOAD_DERS_PROFILES = 'NEW_LOAD_DERS_PROFILES',
  NEW_LOAD_DERS_PHASE_IN = 'NEW_LOAD_DERS_PHASE_IN',
  NEW_LOAD_CONNECTION_MANAGER = 'NEW_LOAD_CONNECTION_MANAGER',
  NETWORK_LOADING_VOLTAGE_DROP = 'NETWORK_LOADING_VOLTAGE_DROP',
  PERMISSIONS_TENANTS = 'PERMISSIONS_TENANTS',
  PERMISSIONS_FEATURES = 'PERMISSIONS_FEATURES',
  MAINTENANCE_PLAN_CNAIM = 'MAINTENANCE_PLAN_CNAIM',
  UNIFIED_ASSET_VIEW_TASKS = 'UNIFIED_ASSET_VIEW_TASKS',
  N1 = 'N1',
  INVESTMENT_SCENARIOS = 'INVESTMENT_SCENARIOS',
  SETUP_SHORT_CIRCUIT = 'SETUP_SHORT_CIRCUIT',
  DATA_QUALITY_CUSTOMERS_CONNECTED = 'DATA_QUALITY_CUSTOMERS_CONNECTED',
  DATA_QUALITY_MISSING_GEOMETRY = 'DATA_QUALITY_MISSING_GEOMETRY',
  SUMMARY_PLAN = 'SUMMARY_PLAN',
  COMPONENT_PRICE_SCENARIOS = 'COMPONENT_PRICE_SCENARIOS',
  INVESTMENT_LIMITATIONS = 'INVESTMENT_LIMITATIONS',
  SETUP_INVESTMENT_BUDGET = 'SETUP_INVESTMENT_BUDGET',
  SETUP_RAW_DATA = 'SETUP_RAW_DATA',
  SETUP_SCENARIO_CALCULATION = 'SETUP_SCENARIO_CALCULATION',
  SETUP_CO2E = 'SETUP_CO2E',
  HISTORY_CHECKLISTS = 'HISTORY_CHECKLISTS',
  NETWORK_LOADING_CABINETS = 'NETWORK_LOADING_CABINETS',
  NETWORK_LOADING_FUSES = 'NETWORK_LOADING_FUSES',
}

export interface TableHeadersKeysInterface {
  [PaginationType.DETAILED_INVESTMENTS]: Omit<
    Record<keyof Investment.DetailedInvestmentsItem, keyof Investment.DetailedInvestmentsItem>,
    'id' | 'cnaim_id'
  >;
  [PaginationType.TRANSFORMERS]: Omit<
    Record<keyof NetworkLoading.Transformer, keyof NetworkLoading.Transformer>,
    | 'id'
    | 'cnaim_id'
    | 'reactive_losses'
    | 'real_losses'
    | 'max_consumption'
    | 'max_production'
    | 'vn_hv_kv'
    | 'vn_lv_kv'
  >;
  [PaginationType.CABLES]: Omit<
    Record<keyof NetworkLoading.Cable, keyof NetworkLoading.Cable>,
    'id' | 'cnaim_id' | 'reactive_losses' | 'real_losses'
  >;
  [PaginationType.NETWORK_LOADING_CABINETS]: Omit<
    Record<keyof NetworkLoading.Cabinet, keyof NetworkLoading.Cabinet>,
    'id' | 'cnaim_id'
  >;
  [PaginationType.NETWORK_LOADING_FUSES]: Omit<Record<keyof NetworkLoading.Fuse, keyof NetworkLoading.Fuse>, 'id'>;
  [PaginationType.CUSTOMERS_RIGHTS]: Omit<
    Record<keyof Customers.Rights, keyof Customers.Rights>,
    'id' | 'type' | 'asset__installations_id' | 'index' | 'outliers_removed' | 'percentile'
  >;
}

export const TableHeadersKeys: TableHeadersKeysInterface = {
  [PaginationType.DETAILED_INVESTMENTS]: {
    asset_name: 'asset_name',
    asset_category: 'asset_category',
    installation_year: 'installation_year',
    current_type: 'current_type',
    future_type: 'future_type',
    current_capacity: 'current_capacity',
    future_capacity: 'future_capacity',
    replacement_reason: 'replacement_reason',
    replacement_cost: 'replacement_cost',
    suggested_replacement_year: 'suggested_replacement_year',
    latest_replacement_year: 'latest_replacement_year',
    savings_over_remaining_lifetime_current_asset: 'savings_over_remaining_lifetime_current_asset',
    primary_substation_name: 'primary_substation_name',
    grid_zone: 'grid_zone',
    voltage_level: 'voltage_level',
    department: 'department',
    co2e_t_per_year: 'co2e_t_per_year',
  },
  [PaginationType.TRANSFORMERS]: {
    customer_asset_name: 'customer_asset_name',
    date_commissioned: 'date_commissioned',
    kva: 'kva',
    ignore: 'ignore',
    explanation: 'explanation',
    max_loading: 'max_loading',
    max_reactive: 'max_reactive',
    grid_losses: 'grid_losses',
    max_loading_time: 'max_loading_time',
    utilization: 'utilization',
    annual_consumption: 'annual_consumption',
    voltage_min: 'voltage_min',
    voltage_max: 'voltage_max',
    replacement_year: 'replacement_year',
    kva_future: 'kva_future',
    replacement_reason: 'replacement_reason',
    replacement_cost: 'replacement_cost',
    pof_sum: 'pof_sum',
    ikss_ka_min: 'ikss_ka_min',
    ikss_ka_max: 'ikss_ka_max',
    skss_mva_min: 'skss_mva_min',
    skss_mva_max: 'skss_mva_max',
    sc_angle_degrees_min: 'sc_angle_degrees_min',
    sc_angle_degrees_max: 'sc_angle_degrees_max',
    skss_mva: 'skss_mva',
  },
  [PaginationType.CABLES]: {
    customer_asset_name: 'customer_asset_name',
    type: 'type',
    distribution_feeder: 'distribution_feeder',
    length: 'length',
    date_commissioned: 'date_commissioned',
    max_capacity: 'max_capacity',
    ignore: 'ignore',
    explanation: 'explanation',
    max_loading: 'max_loading',
    max_reactive: 'max_reactive',
    grid_losses: 'grid_losses',
    max_loading_time: 'max_loading_time',
    utilization: 'utilization',
    annual_consumption: 'annual_consumption',
    voltage_min: 'voltage_min',
    voltage_max: 'voltage_max',
    replacement_year: 'replacement_year',
    max_capacity_future: 'max_capacity_future',
    type_future: 'type_future',
    replacement_reason: 'replacement_reason',
    replacement_cost: 'replacement_cost',
    pof_sum: 'pof_sum',
    ikss_ka_min: 'ikss_ka_min',
    ikss_ka_max: 'ikss_ka_max',
    skss_mva_min: 'skss_mva_min',
    skss_mva_max: 'skss_mva_max',
    sc_angle_from_degrees_min: 'sc_angle_from_degrees_min',
    sc_angle_to_degrees_min: 'sc_angle_to_degrees_min',
    sc_angle_from_degrees_max: 'sc_angle_from_degrees_max',
    sc_angle_to_degrees_max: 'sc_angle_to_degrees_max',
    skss_mva: 'skss_mva',
  },
  [PaginationType.NETWORK_LOADING_CABINETS]: {
    customer_asset_name: 'customer_asset_name',
    date_commissioned: 'date_commissioned',
    ignore: 'ignore',
    explanation: 'explanation',
    max_loading_time: 'max_loading_time',
    utilization: 'utilization',
    voltage_min: 'voltage_min',
    voltage_max: 'voltage_max',
    ikss_ka_min: 'ikss_ka_min',
    ikss_ka_max: 'ikss_ka_max',
    skss_mva_min: 'skss_mva_min',
    skss_mva_max: 'skss_mva_max',
    sc_angle_degrees_min: 'sc_angle_degrees_min',
    sc_angle_degrees_max: 'sc_angle_degrees_max',
    skss_mva: 'skss_mva',
  },
  [PaginationType.NETWORK_LOADING_FUSES]: {
    amp_rating: 'amp_rating',
    annual_p_kwh: 'annual_p_kwh',
    utilization: 'utilization',
    customer_asset_name: 'customer_asset_name',
    date_commissioned: 'date_commissioned',
    explanation: 'explanation',
    ignore: 'ignore',
    max_amps: 'max_amps',
    max_loading_time: 'max_loading_time',
  },
  [PaginationType.CUSTOMERS_RIGHTS]: {
    installation_number: 'installation_number',
    meter_number: 'meter_number',
    metering_point: 'metering_point',
    address: 'address',
    substation: 'substation',
    ignore: 'ignore',
    explanation: 'explanation',
    amps_rights: 'amps_rights',
    amps: 'amps',
    kva: 'kva',
    time: 'time',
    utilization: 'utilization',
    gis_kw: 'gis_kw',
    ai_kw: 'ai_kw',
  },
};

export const TableHeaders: { [key in PaginationType]: Type.DataTableHeader[] } = {
  [PaginationType.FORMS]: [
    { sortKey: 'name', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Assigned to' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MANUALS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Date', sortKey: 'createdAt' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ANSWERS]: [
    { sortKey: 'formName', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date of answer' },
    { titleKey: 'Type' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Username' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.HISTORY]: [
    { titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date of answer' },
    { titleKey: 'Type' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Username' },
    { titleKey: 'Tasks' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.HISTORY_CHECKLISTS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Asset category', sortKey: 'assetCategoryCode' },
    { titleKey: 'Questions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INSTRUCTIONS]: [
    { sortKey: 'name', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Assigned to' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.UPCOMING_TASKS]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    {
      titleKey: 'Expected time',
      className: 'text-center',
      subTitles: [
        { titleKey: '-', className: 'text-center' },
        { titleKey: 'Total', className: 'text-center' },
      ],
    },
    { sortKey: 'status', titleKey: 'Status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    { sortKey: 'createdBy', titleKey: 'Created by' },
    { sortKey: 'responsibleUsers', titleKey: 'Responsible users' },
    {
      titleKey: 'Registered time',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Internal', className: 'text-center' },
        { titleKey: 'External', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_FINISHED]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    { sortKey: 'createdBy', titleKey: 'Created by' },
    { sortKey: 'finishDate', titleKey: 'Finish date' },
    { sortKey: 'finishedBy', titleKey: 'Finished by' },
    {
      titleKey: 'Registered time',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Internal', className: 'text-center' },
        { titleKey: 'External', className: 'text-center' },
      ],
    },
    { titleKey: 'Checklist status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_TASKS]: [
    { titleKey: 'Date', sortKey: 'date' },
    { titleKey: 'Department', sortKey: 'department' },
    { titleKey: 'Description' },
    { titleKey: 'Main activity', sortKey: 'mainActivity' },
    { titleKey: 'Project number', sortKey: 'projectNumber' },
    { titleKey: 'Task number', sortKey: 'taskNumber' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_COMPLAINTS]: [
    { titleKey: 'Date', sortKey: 'createdAt' },
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Name of road', sortKey: 'nameOfRoad' },
    { titleKey: 'Type of fault', sortKey: 'typeOfFault' },
    { titleKey: 'Post code and town', sortKey: 'postcodeAndTown' },
    { titleKey: 'Telephone', sortKey: 'telephone' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSET_MODELS]: [
    { titleKey: 'Date' },
    { titleKey: 'Manufacturer' },
    { titleKey: 'Model' },
    {
      titleKey: 'Counts',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Assets', className: 'text-center' },
        { titleKey: 'Instructions', className: 'text-center' },
        { titleKey: 'Data manuals', className: 'text-center' },
      ],
    },
    {
      titleKey: 'Replacement',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Date', className: 'text-center' },
        { titleKey: 'Option', className: 'text-center' },
        { titleKey: 'Priority', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INSPECTIONS]: [
    { sortKey: 'name', titleKey: 'Inspection name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MANUFACTURERS]: [{ sortKey: 'name', titleKey: 'Manufacturer' }, { titleKey: 'Actions' }],
  [PaginationType.SMART_METER_ACTIONS]: [
    { titleKey: 'Old meter number' },
    { titleKey: 'Logging date', sortKey: 'timestamp' },
    { titleKey: 'New meter number' },
    { titleKey: 'Activity', className: 'text-center', sortKey: 'action' },
    { titleKey: 'User' },
    { titleKey: 'Installation number' },
    { titleKey: 'Date of work', className: 'text-center' },
    { titleKey: 'Checklist status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.EMPLOYEES]: [
    { titleKey: 'Name', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Start date', sortKey: 'startDate' },
    { titleKey: 'End date', sortKey: 'endDate' },
    { titleKey: 'Billable hours', sortKey: 'minutes' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_ACCESS_LOGS]: [
    { titleKey: 'User', sortKey: 'user' },
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Last access', sortKey: 'lastAccess' },
    { titleKey: 'Operating system', sortKey: 'operatingSystem' },
    { titleKey: 'App opened', sortKey: 'appOpened' },
  ],
  [PaginationType.ASSETS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Asset code', sortKey: 'code' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SUB_COMPONENTS]: [
    { titleKey: 'Sub-component type name', sortKey: 'name' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.REPEATED_TASKS_SECTIONS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Department' },
    { titleKey: 'Asset category' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ISSUES]: [
    { titleKey: 'ID', sortKey: 'id' },
    { titleKey: 'Date of discovery', sortKey: 'opened_time' },
    { titleKey: 'Code', sortKey: 'code' },
    { titleKey: 'Ignore', className: 'text-center' },
    { titleKey: 'Explanation' },
    { titleKey: 'Description' },
    {
      titleKey: 'Switches',
      descriptionKey:
        'Open switches connecting islands to the rest of the network or closed switches in meshed networks',
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TRANSFORMERS]: [
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].customer_asset_name,
      titleKey: 'Name',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].customer_asset_name,
    },
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].date_commissioned,
      titleKey: 'Date deployed',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].date_commissioned,
    },
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].kva,
      titleKey: 'Rating (kVA)',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].kva,
    },
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].ignore,
      titleKey: 'Solved',
      className: 'text-center',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].ignore,
    },
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].explanation,
      titleKey: 'Explanation',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].explanation,
    },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading,
          titleKey: 'Loading (kW)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].max_reactive,
          titleKey: 'Reactive (kVAR)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].max_reactive,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].grid_losses,
          titleKey: 'Losses (kVA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].grid_losses,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading_time,
          titleKey: 'Loading time',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].max_loading_time,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].utilization,
          titleKey: 'Utilization (%)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].utilization,
        },
      ],
    },
    {
      key: TableHeadersKeys[PaginationType.TRANSFORMERS].annual_consumption,
      titleKey: 'Annual consumption',
      sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].annual_consumption,
    },
    {
      titleKey: 'Voltage',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_min,
          titleKey: 'Min',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_min,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_max,
          titleKey: 'Max',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].voltage_max,
        },
      ],
    },
    {
      titleKey: 'Investment',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_year,
          titleKey: 'Replacement year',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_year,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].kva_future,
          titleKey: 'Future kVA',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].kva_future,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_reason,
          titleKey: 'Replacement reason',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_reason,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_cost,
          titleKey: 'Replacement cost',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].replacement_cost,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].pof_sum,
          titleKey: 'Probability of failure',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].pof_sum,
        },
      ],
    },
    {
      titleKey: 'Short circuit',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].ikss_ka_min,
          titleKey: 'Ikss min (kA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].ikss_ka_min,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].ikss_ka_max,
          titleKey: 'Ikss max (kA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].ikss_ka_max,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva_min,
          titleKey: 'Skss min (MVA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva_min,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva_max,
          titleKey: 'Skss max (MVA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva_max,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].sc_angle_degrees_min,
          titleKey: 'Angle min (°)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].sc_angle_degrees_min,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].sc_angle_degrees_max,
          titleKey: 'Angle max (°)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].sc_angle_degrees_max,
        },
        {
          key: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva,
          titleKey: 'Skss mean (MVA)',
          sortKey: TableHeadersKeys[PaginationType.TRANSFORMERS].skss_mva,
        },
      ],
    },
    { key: 'actions', titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TRANSFORMERS_IGNORE_MESSAGES]: [
    { titleKey: 'Time', sortKey: 'time' },
    { titleKey: 'Name', sortKey: 'username' },
    { titleKey: 'Explanation' },
  ],
  [PaginationType.CABLES]: [
    {
      key: TableHeadersKeys[PaginationType.CABLES].customer_asset_name,
      titleKey: 'Name',
      sortKey: TableHeadersKeys[PaginationType.CABLES].customer_asset_name,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].type,
      titleKey: 'Type',
      sortKey: TableHeadersKeys[PaginationType.CABLES].type,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].distribution_feeder,
      titleKey: 'Distribution feeder',
      sortKey: TableHeadersKeys[PaginationType.CABLES].distribution_feeder,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].length,
      titleKey: 'Length (m)',
      sortKey: TableHeadersKeys[PaginationType.CABLES].length,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].date_commissioned,
      titleKey: 'Date deployed',
      sortKey: TableHeadersKeys[PaginationType.CABLES].date_commissioned,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].max_capacity,
      titleKey: 'Current (A)',
      sortKey: TableHeadersKeys[PaginationType.CABLES].max_capacity,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].ignore,
      titleKey: 'Solved',
      className: 'text-center',
      sortKey: TableHeadersKeys[PaginationType.CABLES].ignore,
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].explanation,
      titleKey: 'Explanation',
      sortKey: TableHeadersKeys[PaginationType.CABLES].explanation,
    },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.CABLES].max_loading,
          titleKey: 'Loading (kW)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].max_loading,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].max_reactive,
          titleKey: 'Reactive (kVAR)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].max_reactive,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].grid_losses,
          titleKey: 'Losses (kVA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].grid_losses,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].max_loading_time,
          titleKey: 'Loading time',
          sortKey: TableHeadersKeys[PaginationType.CABLES].max_loading_time,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].utilization,
          titleKey: 'Utilization (%)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].utilization,
        },
      ],
    },
    {
      key: TableHeadersKeys[PaginationType.CABLES].annual_consumption,
      titleKey: 'Annual consumption',
      sortKey: TableHeadersKeys[PaginationType.CABLES].annual_consumption,
    },
    {
      titleKey: 'Voltage',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.CABLES].voltage_min,
          titleKey: 'Min',
          sortKey: TableHeadersKeys[PaginationType.CABLES].voltage_min,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].voltage_max,
          titleKey: 'Max',
          sortKey: TableHeadersKeys[PaginationType.CABLES].voltage_max,
        },
      ],
    },
    {
      titleKey: 'Investment',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.CABLES].replacement_year,
          titleKey: 'Replacement year',
          sortKey: TableHeadersKeys[PaginationType.CABLES].replacement_year,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].max_capacity_future,
          titleKey: 'Future current (A)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].max_capacity_future,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].type_future,
          titleKey: 'Future type',
          sortKey: TableHeadersKeys[PaginationType.CABLES].type_future,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].replacement_reason,
          titleKey: 'Replacement reason',
          sortKey: TableHeadersKeys[PaginationType.CABLES].replacement_reason,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].replacement_cost,
          titleKey: 'Replacement cost',
          sortKey: TableHeadersKeys[PaginationType.CABLES].replacement_cost,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].pof_sum,
          titleKey: 'Probability of failure',
          sortKey: TableHeadersKeys[PaginationType.CABLES].pof_sum,
        },
      ],
    },
    {
      titleKey: 'Short circuit',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.CABLES].ikss_ka_min,
          titleKey: 'Ikss min (kA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].ikss_ka_min,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].ikss_ka_max,
          titleKey: 'Ikss max (kA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].ikss_ka_max,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].skss_mva_min,
          titleKey: 'Skss min (MVA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].skss_mva_min,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].skss_mva_max,
          titleKey: 'Skss max (MVA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].skss_mva_max,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].sc_angle_from_degrees_min,
          titleKey: 'Angle from min (°)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].sc_angle_from_degrees_min,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].sc_angle_to_degrees_min,
          titleKey: 'Angle to min (°)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].sc_angle_to_degrees_min,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].sc_angle_from_degrees_max,
          titleKey: 'Angle from max (°)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].sc_angle_from_degrees_max,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].sc_angle_to_degrees_max,
          titleKey: 'Angle to max (°)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].sc_angle_to_degrees_max,
        },
        {
          key: TableHeadersKeys[PaginationType.CABLES].skss_mva,
          titleKey: 'Skss mean (MVA)',
          sortKey: TableHeadersKeys[PaginationType.CABLES].skss_mva,
        },
      ],
    },
    { key: 'actions', titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_CABINETS]: [
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].customer_asset_name,
      titleKey: 'Name',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].customer_asset_name,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].date_commissioned,
      titleKey: 'Date deployed',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].date_commissioned,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ignore,
      titleKey: 'Solved',
      className: 'text-center',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ignore,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].explanation,
      titleKey: 'Explanation',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].explanation,
    },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].max_loading_time,
          titleKey: 'Loading time',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].max_loading_time,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].utilization,
          titleKey: 'Utilization (%)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].utilization,
          descriptionKey: 'Max utilization of connected cables',
        },
      ],
    },
    {
      titleKey: 'Voltage',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_min,
          titleKey: 'Min',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_min,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_max,
          titleKey: 'Max',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].voltage_max,
        },
      ],
    },
    {
      titleKey: 'Short circuit',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ikss_ka_min,
          titleKey: 'Ikss min (kA)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ikss_ka_min,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ikss_ka_max,
          titleKey: 'Ikss max (kA)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].ikss_ka_max,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva_min,
          titleKey: 'Skss min (MVA)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva_min,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva_max,
          titleKey: 'Skss max (MVA)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva_max,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].sc_angle_degrees_min,
          titleKey: 'Angle min (°)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].sc_angle_degrees_min,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].sc_angle_degrees_max,
          titleKey: 'Angle max (°)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].sc_angle_degrees_max,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva,
          titleKey: 'Skss mean (MVA)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_CABINETS].skss_mva,
        },
      ],
    },
    { key: 'actions', titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_FUSES]: [
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].customer_asset_name,
      titleKey: 'Name',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].customer_asset_name,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].date_commissioned,
      titleKey: 'Date deployed',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].date_commissioned,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].amp_rating,
      titleKey: 'Current (A)',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].amp_rating,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].ignore,
      titleKey: 'Solved',
      className: 'text-center',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].ignore,
    },
    {
      key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].explanation,
      titleKey: 'Explanation',
      sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].explanation,
    },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_amps,
          titleKey: 'Loading (A)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_amps,
          descriptionKey: 'Calculated by DC load flow',
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_loading_time,
          titleKey: 'Loading time',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].max_loading_time,
        },
        {
          key: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].utilization,
          titleKey: 'Utilization (%)',
          sortKey: TableHeadersKeys[PaginationType.NETWORK_LOADING_FUSES].utilization,
          descriptionKey: 'Calculated by DC load flow',
        },
      ],
    },
    { key: 'actions', titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.DATA_QUALITY_MISSING_AGE]: [
    { titleKey: 'Name', sortKey: 'customer_asset_name' },
    { titleKey: 'Type', sortKey: 'type' },
    { titleKey: 'Manufacturer', sortKey: 'manufacturer' },
  ],
  [PaginationType.COMPONENT_PRICES]: [
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Size', sortKey: 'size' },
    { titleKey: 'Size unit', sortKey: 'size_unit' },
    { titleKey: 'Price', sortKey: 'price' },
    { titleKey: 'Price unit', sortKey: 'price_unit' },
    { titleKey: 'Price type', sortKey: 'price_type' },
    { titleKey: 'Component price scenario', sortKey: 'component_price_scenario' },
    {
      titleKey: 'CO₂e',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Scope 1', sortKey: 'co2e_scope_1', className: 'text-center text-nowrap' },
        { titleKey: 'Scope 2', sortKey: 'co2e_scope_2', className: 'text-center text-nowrap' },
        { titleKey: 'Scope 3', sortKey: 'co2e_scope_3', className: 'text-center text-nowrap' },
        { titleKey: 'Unit', sortKey: 'co2e_unit', className: 'text-center text-nowrap' },
      ],
    },
    { titleKey: 'Grid zone', sortKey: 'grid_zone' },
    { titleKey: 'Lifetime', sortKey: 'lifetime' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.WORK_PRICES]: [
    { titleKey: 'Work type', sortKey: 'description' },
    { titleKey: 'Grid zone', sortKey: 'grid_zone_description' },
    { titleKey: 'Price', sortKey: 'price' },
    { titleKey: 'Price unit', sortKey: 'price_per_unit' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SETUP_INVESTMENT_BUDGET]: [
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Size', sortKey: 'size_asset' },
    { titleKey: 'Size unit', sortKey: 'size_unit' },
    { titleKey: 'Lifetime', sortKey: 'lifetime' },
    { titleKey: 'Grid zone', sortKey: 'grid_zone' },
    { titleKey: 'Price (DKK)', sortKey: 'price' },
    { titleKey: 'Count', sortKey: 'count_asset' },
    { titleKey: 'Length (km)', sortKey: 'total_length_km' },
    { titleKey: 'Total asset value', sortKey: 'total_assets_value' },
    { titleKey: 'Yearly budget', sortKey: 'yearly_budget' },
  ],
  [PaginationType.DETAILED_INVESTMENTS]: [
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_name,
      titleKey: 'Customer asset name',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_name,
    },
    { key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_category, titleKey: 'Asset category' },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].installation_year,
      titleKey: 'Installation year',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].installation_year,
    },
    {
      titleKey: 'Type',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_type,
          titleKey: 'Current',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_type,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_type,
          titleKey: 'Future',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_type,
        },
      ],
    },
    {
      titleKey: 'Capacity',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_capacity,
          titleKey: 'Current',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_capacity,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_capacity,
          titleKey: 'Future',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_capacity,
        },
      ],
    },
    {
      titleKey: 'Investment',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_reason,
          titleKey: 'Reason',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_reason,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_cost,
          titleKey: 'Cost ({{currency}})',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_cost,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].suggested_replacement_year,
          titleKey: 'Suggested year',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].suggested_replacement_year,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].latest_replacement_year,
          titleKey: 'Latest year',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].latest_replacement_year,
        },
      ],
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].savings_over_remaining_lifetime_current_asset,
      titleKey: 'Savings over the remaining lifetime ({{currency}})',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].savings_over_remaining_lifetime_current_asset,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].primary_substation_name,
      titleKey: 'Primary substation',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].primary_substation_name,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].grid_zone,
      titleKey: 'Grid zone',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].grid_zone,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].voltage_level,
      titleKey: 'Voltage level',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].voltage_level,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].department,
      titleKey: 'Portfolio',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].department,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].co2e_t_per_year,
      titleKey: 'CO₂e (t/year)',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].co2e_t_per_year,
    },
  ],
  [PaginationType.SUMMARY_PLAN]: [
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Investment year', sortKey: 'suggested_replacement_year' },
    { titleKey: 'Investment reason', sortKey: 'replacement_reason' },
    { titleKey: 'Grid zone', sortKey: 'description_en' },
    { titleKey: 'Voltage level', sortKey: 'voltage_level_text' },
    { titleKey: 'Portfolio', sortKey: 'department' },
    { titleKey: 'Primary substation', sortKey: 'name' },
    { titleKey: 'Investment cost ({{currency}})', sortKey: 'replacement_cost' },
    { titleKey: 'CO₂e (t/year)', sortKey: 'co2e_t_per_year' },
  ],
  [PaginationType.TASKS_CHECKLISTS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Asset category', sortKey: 'assetCategoryCode' },
    { titleKey: 'App action', sortKey: 'appAction' },
    { titleKey: 'Questions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_MATERIALS]: [
    { titleKey: 'Date', sortKey: 'createdAt' },
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Code', sortKey: 'code' },
    { titleKey: 'Unit', sortKey: 'unit' },
    { titleKey: 'Group', sortKey: 'group' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NK_TASKS_MATERIALS]: [
    { titleKey: 'Date' },
    { titleKey: 'Quantity' },
    { titleKey: 'Cost', className: 'text-right' },
    { titleKey: 'Type' },
    { titleKey: 'Unit' },
    { titleKey: 'Description' },
  ],
  [PaginationType.METERS_MISSING_IN_MDM]: [
    { titleKey: 'Installation number', sortKey: 'installation_number' },
    { titleKey: 'Metering point', sortKey: 'metering_point' },
    { titleKey: 'Meter number', sortKey: 'meter_number' },
    { titleKey: 'Address', sortKey: 'address' },
  ],
  [PaginationType.METERS_MISSING_IN_GIS]: [
    { titleKey: 'Last measurement', sortKey: 'last_measurement' },
    { titleKey: 'Installation number', sortKey: 'installation_number' },
    { titleKey: 'Metering point', sortKey: 'metering_point' },
    { titleKey: 'Meter number', sortKey: 'meter_number' },
    { titleKey: 'Address', sortKey: 'address' },
  ],
  [PaginationType.METERS_POPULATION]: [
    { titleKey: 'Meter number', sortKey: 'meterNumber' },
    { titleKey: 'Batch number', sortKey: 'batchNumber' },
    { titleKey: 'Type', sortKey: 'type' },
    { titleKey: 'Installation number', sortKey: 'installation' },
    { titleKey: 'Address', sortKey: 'address' },
    { titleKey: 'Connection', sortKey: 'connection' },
    { titleKey: 'Serial number', sortKey: 'serialNumber' },
    {
      titleKey: 'Date',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Activated', sortKey: 'dateActivated', className: 'text-center' },
        { titleKey: 'Discarded', sortKey: 'dateDiscarded', className: 'text-center' },
        { titleKey: 'Removed', sortKey: 'dateRemoved', className: 'text-center' },
        { titleKey: 'Control', sortKey: 'controlDate', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MAINTENANCE_CREATE_PLANS]: [
    { titleKey: 'Asset category', sortKey: 'assetCategory' },
    { titleKey: 'Date select' },
    { titleKey: 'Date explicit' },
    { titleKey: 'Plan name', sortKey: 'planName' },
    { titleKey: 'Missing action' },
    { titleKey: 'Intervals (years)' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MAINTENANCE_SUMMARY]: [
    { titleKey: 'Asset category' },
    { titleKey: 'Year' },
    { titleKey: 'Number' },
    { titleKey: 'Plan name' },
  ],
  [PaginationType.MAINTENANCE_PLAN]: [
    { titleKey: 'Plan name' },
    { titleKey: 'Name' },
    { titleKey: 'Maintenance year' },
    { titleKey: 'Override year' },
  ],
  [PaginationType.TOTAL_HOURS]: [
    { titleKey: 'Name' },
    { titleKey: 'App action' },
    { titleKey: 'Type' },
    { titleKey: 'Expected time' },
  ],
  [PaginationType.MAINTENANCE_STATISTICS_USERS]: [
    { titleKey: 'User', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Maintenance' },
    { titleKey: 'Repair' },
    { titleKey: 'Inspections' },
    { titleKey: 'Final check' },
    { titleKey: 'Task related forms' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_GROUPS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Permissions' },
    { titleKey: 'Default', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_USERS]: [
    { titleKey: 'Name', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Groups' },
    { titleKey: 'Permissions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.CUSTOMERS_RIGHTS]: [
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].installation_number,
      titleKey: 'Installation number',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].installation_number,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].meter_number,
      titleKey: 'Meter number',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].meter_number,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].metering_point,
      titleKey: 'Metering point',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].metering_point,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].address,
      titleKey: 'Address',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].address,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].substation,
      titleKey: 'Substation',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].substation,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].ignore,
      titleKey: 'Solved',
      className: 'text-center',
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].explanation,
      titleKey: 'Explanation',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].explanation,
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps_rights,
      titleKey: 'Current rights (A)',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps_rights,
    },
    {
      titleKey: 'Max measured',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps,
          titleKey: 'Current (A)',
          className: 'text-nowrap',
          descriptionKey: 'Assumes PF = 0.95',
          sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].amps,
        },
        {
          key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].kva,
          titleKey: 'Consumption (kVA)',
          sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].kva,
        },
        {
          key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].time,
          titleKey: 'Time',
          sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].time,
        },
        {
          key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].utilization,
          titleKey: 'Utilization (%)',
          sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].utilization,
        },
      ],
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].gis_kw,
      titleKey: 'GIS (kW)',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].gis_kw,
      explanationKey: "Only for 'Electric vehicles' and 'Solar' DER types",
    },
    {
      key: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].ai_kw,
      titleKey: 'AI (kW)',
      sortKey: TableHeadersKeys[PaginationType.CUSTOMERS_RIGHTS].ai_kw,
      explanationKey: "Only for 'Electric vehicles' and 'Solar' DER types",
    },
    { key: 'actions', titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSET_TASK_COMPLETIONS]: [
    { titleKey: 'Date' },
    { titleKey: 'Name' },
    { titleKey: 'Email' },
    { titleKey: 'Task' },
    { titleKey: 'Answer' },
  ],
  [PaginationType.USERS_REGISTERED_HOURS]: [
    { titleKey: 'User (Σ)', sortKey: 'fullName' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_REGISTERED_HOURS]: [
    { titleKey: 'Task (Σ)', sortKey: 'name' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_CUSTOMERS_TYPES]: [{ titleKey: 'Type' }, { titleKey: 'Number' }],
  [PaginationType.FORECASTED_SCENARIOS]: [
    { titleKey: 'Default' },
    { titleKey: 'Scenario', sortKey: 'description' },
    { titleKey: 'User', sortKey: 'user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Years', sortKey: 'years' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_MOBILE_SUBMITTED]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Date created', sortKey: 'createdAt' },
    { titleKey: 'Created by', sortKey: 'createdBy' },
    { titleKey: 'Department' },
    { titleKey: 'Assets' },
    { titleKey: 'App action', sortKey: 'appAction' },
    { titleKey: 'Status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.REPEATED_TASKS]: [
    { titleKey: 'Task number' },
    { titleKey: 'Status' },
    { titleKey: 'Description' },
    { titleKey: 'Project number' },
    { titleKey: 'App action' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS]: [
    { titleKey: 'Description' },
    { titleKey: 'Category' },
    { titleKey: 'Population' },
    { titleKey: 'Baseload' },
    { titleKey: 'Max number per installation' },
    { titleKey: 'Grid zones' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_CATEGORIES]: [
    { titleKey: 'Category' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_PROFILES]: [
    { titleKey: 'Date' },
    { titleKey: 'Company' },
    { titleKey: 'Description' },
    { titleKey: 'User' },
    { titleKey: 'Shared', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_PHASE_IN]: [
    { titleKey: 'Date' },
    { titleKey: 'Company' },
    { titleKey: 'Description' },
    { titleKey: 'User' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_CONNECTION_MANAGER]: [
    { titleKey: 'Connection year' },
    { titleKey: 'Description' },
    { titleKey: 'Point asset' },
    { titleKey: 'Customer (MW)' },
    { titleKey: 'Power factor' },
    { titleKey: 'Capacitive / Inductive' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SETUP_RAW_DATA]: [{ titleKey: 'Name' }, { titleKey: 'Actions', className: 'text-right' }],
  [PaginationType.NETWORK_LOADING_VOLTAGE_DROP]: [
    /* this view has filters for chart */
  ],
  [PaginationType.PERMISSIONS_TENANTS]: [
    { titleKey: 'Name', sortKey: 'tenantName' },
    { titleKey: 'Permissions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_FEATURES]: [
    { titleKey: 'Feature' },
    { titleKey: 'Description' },
    { titleKey: 'Tenants' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MAINTENANCE_PLAN_CNAIM]: [
    { titleKey: 'Name', sortKey: 'customer_id' },
    { titleKey: 'Probability of failure', sortKey: 'pof' },
    { titleKey: 'Consequence of failure', sortKey: 'cof' },
    { titleKey: 'Monetary risk', sortKey: 'monetary_risk' },
    { titleKey: 'Inspection interval', sortKey: 'inspection' },
  ],
  [PaginationType.UNIFIED_ASSET_VIEW_TASKS]: [
    /* this view has custom headers */
  ],
  [PaginationType.N1]: [
    {
      titleKey: 'Outage',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Asset', sortKey: 'outage_asset' },
        { titleKey: 'Asset type', sortKey: 'outage_asset_type' },
        { titleKey: 'Type', sortKey: 'outage_type_text' },
      ],
    },
    { titleKey: 'Supply primary substation', sortKey: 'primary_substation' },
    { titleKey: 'Route' },
    { titleKey: 'Switches to close', sortKey: 'switches_to_close' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INVESTMENT_SCENARIOS]: [
    { titleKey: 'Scenario', sortKey: 'investment_description' },
    { titleKey: 'User', sortKey: 'sso_user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Forecasted scenario', sortKey: 'forecasted_scenario' },
    { titleKey: 'Component price scenario', sortKey: 'component_price_scenario' },
    { titleKey: 'Interest rate (%)', sortKey: 'interest_rate' },
    { titleKey: 'Electricity price ({{currency}}/MWh)' },
    { titleKey: 'Loss optimisation', sortKey: 'loss_optimisation', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SETUP_SHORT_CIRCUIT]: [
    { titleKey: 'Primary substation', sortKey: 'short_name' },
    { titleKey: 'Skss (MVA)', sortKey: 'skss_mva' },
    { titleKey: 'Ikss (MVA)', sortKey: 'ikss_ka' },
    { titleKey: 'X/R', sortKey: 'xtor' },
    { titleKey: 'Φ (°)', sortKey: 'phii_deg' },
    { titleKey: 'R (Ω)', sortKey: 'r_ohm' },
    { titleKey: 'X (Ω)', sortKey: 'x_ohm' },
  ],
  [PaginationType.DATA_QUALITY_CUSTOMERS_CONNECTED]: [
    { titleKey: 'Station', sortKey: 'station' },
    {
      titleKey: 'Installations',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Number', sortKey: 'installations_number' },
        { titleKey: 'Connected (%)', sortKey: 'installations_connected_pc' },
      ],
    },
  ],
  [PaginationType.DATA_QUALITY_MISSING_GEOMETRY]: [
    { titleKey: 'Name', sortKey: 'customer_asset_name' },
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Asset type', sortKey: 'asset_type' },
  ],
  [PaginationType.COMPONENT_PRICE_SCENARIOS]: [
    { titleKey: 'Scenario', sortKey: 'description' },
    { titleKey: 'Price evolution (% per year)', sortKey: 'price_evolution_percent' },
    { titleKey: 'User', sortKey: 'sso_user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INVESTMENT_LIMITATIONS]: [
    { titleKey: '' },
    { titleKey: '' },
    { titleKey: 'Cables max loading (%)' },
    { titleKey: 'Transformers max loading (%)' },
    { titleKey: 'Min voltage ({{unit}})' },
    { titleKey: 'Max voltage ({{unit}})' },
  ],
  [PaginationType.SETUP_SCENARIO_CALCULATION]: [
    { titleKey: 'Name' },
    { titleKey: 'Monday', className: 'text-center' },
    { titleKey: 'Tuesday', className: 'text-center' },
    { titleKey: 'Wednesday', className: 'text-center' },
    { titleKey: 'Thursday', className: 'text-center' },
    { titleKey: 'Friday', className: 'text-center' },
    { titleKey: 'Saturday', className: 'text-center' },
    { titleKey: 'Sunday', className: 'text-center' },
  ],
  [PaginationType.SETUP_CO2E]: [
    { titleKey: 'DER' },
    {
      titleKey: 'CO₂e reduction (t/unit/year)',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Scope 1', className: 'text-center' },
        { titleKey: 'Scope 2', className: 'text-center' },
        { titleKey: 'Scope 3', className: 'text-center' },
      ],
    },
    { titleKey: 'Climate benefit (t/unit/year)', className: 'text-center' },
  ],
};

export const MAX_FIELDS = 10;

export const TwoOptionDefaultIndexes = {
  Child1: 1000,
  Child2: 4000,
};

export enum BuilderFieldsTypes {
  Date = 'Date',
  PhotoCollection = 'Photo collection',
  SingleSelect = 'Single select',
  MultipleSelect = 'Multiple select',
  TextInput = 'Text input',
  NumberInput = 'Number input',
  LargeTextInput = 'Large text input',
  TwoOption = 'Two-option',
  InspectionQuestion = 'Inspection question',
  Text = 'Text',
  Photo = 'Photo',
  NumberInputInspection = 'Number input inspection',
  AssetPhoto = 'Asset photo',
  BinarySelect = 'Binary select',
  LargeText = 'Large Text',
  YouTubeVideo = 'YouTube Video',
  URL = 'URL',
  Image = 'Image',
  LinkedForm = 'Linked Form',
  LinkedFunctionality = 'Linked Functionality',
  Signature = 'Signature',
}

export const LegacyBuilderFieldsTypesMap = {
  [BuilderFieldsTypes.InspectionQuestion]: 'Inspectionquestion',
  [BuilderFieldsTypes.AssetPhoto]: 'Assetphoto',
  [BuilderFieldsTypes.BinarySelect]: 'Binaryselect',
  [BuilderFieldsTypes.TwoOption]: BuilderFieldsTypes.TwoOption,
  [BuilderFieldsTypes.LargeTextInput]: 'Largetextinput',
  [BuilderFieldsTypes.Text]: BuilderFieldsTypes.Text,
  [BuilderFieldsTypes.PhotoCollection]: 'Photocollection',
  [BuilderFieldsTypes.LinkedForm]: 'LinkedForm',
  [BuilderFieldsTypes.NumberInput]: 'Numberinput',
  [BuilderFieldsTypes.MultipleSelect]: 'Multipleselect',
  [BuilderFieldsTypes.Photo]: BuilderFieldsTypes.Photo,
  [BuilderFieldsTypes.Date]: BuilderFieldsTypes.Date,
  [BuilderFieldsTypes.TextInput]: 'Textinput',
  [BuilderFieldsTypes.LinkedFunctionality]: 'LinkedFunctionality',
  [BuilderFieldsTypes.SingleSelect]: 'Singleselect',
  [BuilderFieldsTypes.NumberInputInspection]: 'Numberinputinspection',
  [BuilderFieldsTypes.Signature]: BuilderFieldsTypes.Signature,
};

export const BuilderFieldsTypesWithoutAnswers: BuilderFieldsTypes[] = [
  BuilderFieldsTypes.Text,
  BuilderFieldsTypes.LargeText,
  BuilderFieldsTypes.YouTubeVideo,
  BuilderFieldsTypes.URL,
  BuilderFieldsTypes.Photo,
  BuilderFieldsTypes.Image,
  BuilderFieldsTypes.AssetPhoto,
  BuilderFieldsTypes.NumberInputInspection,
];

export const BuilderFieldsTypesValues = Object.values(BuilderFieldsTypes);

export enum FunctionalityTypes {
  ReplaceAsset = 'Replace Asset',
  EditMetaData = 'Edit Meta Data',
}

const baseProps: Pick<Builder.BaseField, 'id' | 'indexBlock' | 'index' | 'checked' | 'title'> = {
  id: 0,
  indexBlock: 0,
  index: 0,
  checked: false,
  title: '',
};

export const FIELDS: Record<string, Builder.BaseField> = {
  [BuilderFieldsTypes.Text]: {
    ...baseProps,
    type: BuilderFieldsTypes.Text,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.Signature]: {
    ...baseProps,
    type: BuilderFieldsTypes.Signature,
    params: [{ name: 'Label', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.TextInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.TextInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'commentsAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
    ],
  },
  [BuilderFieldsTypes.LargeTextInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.LargeTextInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'commentsAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
    ],
  },
  [BuilderFieldsTypes.LargeText]: {
    ...baseProps,
    type: BuilderFieldsTypes.LargeText,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.NumberInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.NumberInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Min Value', type: 'number', num: 1, values: [{ value: 0 }] },
      { name: 'Max Value', type: 'number', num: 2, values: [{ value: 0 }] },
      { name: 'inputName', type: 'string', num: 3, values: [{ value: '' }] },
      { name: 'Unit', type: 'string', num: 4, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 5, values: [{ value: '' }] },
    ],
  },
  [BuilderFieldsTypes.NumberInputInspection]: {
    ...baseProps,
    type: BuilderFieldsTypes.NumberInputInspection,
    params: [
      { name: 'Text', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Number', type: 'number', num: 1, values: [{ value: 0 }] },
    ],
  },
  [BuilderFieldsTypes.Date]: {
    ...baseProps,
    type: BuilderFieldsTypes.Date,
    params: [{ name: 'Label', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.URL]: {
    ...baseProps,
    type: BuilderFieldsTypes.URL,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.YouTubeVideo]: {
    ...baseProps,
    type: BuilderFieldsTypes.YouTubeVideo,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.Photo]: {
    ...baseProps,
    type: BuilderFieldsTypes.Photo,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.AssetPhoto]: {
    ...baseProps,
    type: BuilderFieldsTypes.AssetPhoto,
    params: [
      { name: 'Description', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.Image]: {
    ...baseProps,
    type: BuilderFieldsTypes.Image,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.PhotoCollection]: {
    ...baseProps,
    type: BuilderFieldsTypes.PhotoCollection,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Min Photo', type: 'number', num: 1, values: [{ value: 1 }] },
      { name: 'Max Photo', type: 'number', num: 2, values: [{ value: 2 }] },
    ],
  },
  [BuilderFieldsTypes.SingleSelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.SingleSelect,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.MultipleSelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.MultipleSelect,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.InspectionQuestion]: {
    ...baseProps,
    type: BuilderFieldsTypes.InspectionQuestion,
    params: [
      { name: 'Title', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', explanation: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.BinarySelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.BinarySelect,
    params: [
      {
        name: 'Variants',
        type: 'string',
        num: 0,
        values: [{ value: '' }],
        variants: [
          { index: 0, value: '', field: [] },
          { index: 1, value: '', field: [] },
        ],
      },
    ],
  },
  [BuilderFieldsTypes.TwoOption]: {
    ...baseProps,
    type: BuilderFieldsTypes.TwoOption,
    fieldtype: { name: BuilderFieldsTypes.TwoOption }, // FixMe.
    checked: [false, false],
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [
          { index: 0, value: '', field: [] },
          { index: 1, value: '', field: [] },
        ],
      },
    ],
  },
  [BuilderFieldsTypes.LinkedForm]: {
    ...baseProps,
    type: BuilderFieldsTypes.LinkedForm,
    params: [
      { name: 'Form type', type: 'number', num: 0, values: [{ value: null as any, valuealt: '' }] },
      { name: 'Selected form', type: 'number', num: 1, values: [{ value: null as any, valuealt: '' }] },
      { name: 'scheduleAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
      { name: 'Label', type: 'string', num: 3, values: [{ value: '' }] },
    ],
  },
  [BuilderFieldsTypes.LinkedFunctionality]: {
    ...baseProps,
    type: BuilderFieldsTypes.LinkedFunctionality,
    params: [
      { name: 'Functionality type', type: 'number', num: 0, values: [{ value: null as any, valuealt: '' }] },
      { name: 'Label', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'scheduleAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
      { name: 'subComponentTypeId', type: 'number', num: 3, values: [{ value: null }] },
    ],
  },
};

export enum AssetCategories {
  TH = 'TH', // TH === ST
  ST = 'ST',
  TR = 'TR',
  MV = 'MV',
  LV = 'LV',
  RT = 'RT',
  KS = 'KS',
  BD = 'BD',
  TS = 'TS',
  SS = 'SS',
  GL = 'GL',
  LE = 'LE',
  LL = 'LL',
  LH = 'LH',
  SM = 'SM',
}

export const SyntheticAssetCategories = [AssetCategories.LL, AssetCategories.LH, AssetCategories.SM];

export enum PageAssetTabs {
  ROOT = '',
  PHOTO = 'photo',
  SUB_COMPONENTS = 'sub-components',
  HISTORY = 'history',
  ANSWERS = 'answers',
  TASK_COMPLETIONS = 'task-completions',
  DATA_MANUALS = 'data-manuals',
}

export const MapParams = {
  minZoom: 4,
  maxZoom: 22,
  maxClusterZoom: 14,
};

export enum TransformerVoltages {
  '10/0.4kV transformer' = '10/0.4kV transformer',
  '60/10kV transformer' = '60/10kV transformer',
  '150/60kV transformer' = '150/60kV transformer',
}

export enum CableVoltages {
  '0,4kV' = '0,4kV',
  '10kV' = '10kV',
  '60kV' = '60kV',
}

export enum VoltageSide {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum VoltageDisplay {
  'Per-unit' = 'Per-unit',
  Volts = 'Volts',
}

export enum BICalculations {
  Off = 'Off',
  On = 'On',
}

export enum BuilderFormTypeNames {
  LogMaintenance = 'Log Maintenance',
  Repair = 'Repair',
  Inspection = 'Inspection',
  FinalCheck = 'Final Check',
  TaskRelatedForm = 'Task related form',
  // custom values
  ToolInspection = 'Tool inspection',
  Instructions = 'Instructions',
}

export enum SmartMeterActionNames {
  NEW = 'new',
  REPLACE = 'replace',
  REMOVE = 'remove',
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const NodeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/admin/`,
});

export const AssetLifeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_ASSETLIFE_API_URL}/`,
});

export enum DataTableFilterKeys {
  YEAR = 'year',
  TYPE = 'type',
  APP_ACTION = 'appAction',
  STATUS = 'status',
  USER_IDS = 'userIds',
  EMPLOYEES_IDS = 'electricianIds',
  ASSET_CODE = 'assetCode',
  ASSET_CATEGORY_CODE = 'assetCategoryCode',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export enum MaintenanceMissingActions {
  START = 'Start',
  END = 'End',
  DISTRIBUTE = 'Distribute',
}

export enum PermissionsTypes {
  // GENERAL
  isMapEnabled = 'isMapEnabled',
  isSingleLineDiagramEnabled = 'isSingleLineDiagramEnabled',
  isCO2eEnabled = 'isCO2eEnabled',

  // Forecast & Investment
  isNetworkLoadingEnabled = 'isNetworkLoadingEnabled',
  isNetworkLoadingFusesEnabled = 'isNetworkLoadingFusesEnabled',
  isN1Available = 'isN1Available',
  isCustomersEnabled = 'isCustomersEnabled',
  isInvestmentEnabled = 'isInvestmentEnabled',
  isDataQualityEnabled = 'isDataQualityEnabled',
  isSetupEnabled = 'isSetupEnabled',
  isNewLoadEnabled = 'isNewLoadEnabled',
  isAnalysisToolEnabled = 'isAnalysisToolEnabled',

  // Maintenance & Planning
  isTasksEnabled = 'isTasksEnabled',
  isSmartMetersEnabled = 'isSmartMetersEnabled',
  isAssetsEnabled = 'isAssetsEnabled',
  isMaintenanceEnabled = 'isMaintenanceEnabled',
  isAssetModelsEnabled = 'isAssetModelsEnabled',
  isFormsEnabled = 'isFormsEnabled',
  isHistoryEnabled = 'isHistoryEnabled',
  isDataManualsEnabled = 'isDataManualsEnabled',
  isMaterialsEnabled = 'isMaterialsEnabled',

  // REINVESTMENT
  isReliabilityEnabled = 'isReliabilityEnabled',
  isMonetizedRiskEnabled = 'isMonetizedRiskEnabled',

  // EXTRA
  isFeatureToggleAvailable = 'isFeatureToggleAvailable',

  // Maintenance & Planning - TASKS
  isTaskTypeImmediate = 'isTaskTypeImmediate',
  isTaskTypeExpected = 'isTaskTypeExpected',
  isTaskTypeAutogenerated = 'isTaskTypeAutogenerated',
  isTaskTypeCustomerComplaint = 'isTaskTypeCustomerComplaint',
  isAssignedTasksOnly = 'isAssignedTasksOnly',

  // WRITE PERMISSIONS
  isAdminPanelReadOnly = 'isAdminPanelReadOnly',
  isMobileApplicationsReadOnly = 'isMobileApplicationsReadOnly',

  // MOBILE
  isTimeRegistrationEnabled = 'isTimeRegistrationEnabled',

  // NOT SURE IF WE NEED CATEGORY PERMISSIONS
  isAssetcategoryCodeBD = 'isAssetcategoryCodeBD',
  isAssetcategoryCodeKS = 'isAssetcategoryCodeKS',
  isAssetcategoryCodeLE = 'isAssetcategoryCodeLE',
  isAssetcategoryCodeLV = 'isAssetcategoryCodeLV',
  isAssetcategoryCodeMV = 'isAssetcategoryCodeMV',
  isAssetcategoryCodeRT = 'isAssetcategoryCodeRT',
  isAssetcategoryCodeSM = 'isAssetcategoryCodeSM',
  isAssetcategoryCodeTH = 'isAssetcategoryCodeTH',
  isAssetcategoryCodeTS = 'isAssetcategoryCodeTS',
  isAssetcategoryCodeGL = 'isAssetcategoryCodeGL',
  isAssetcategoryCodeSS = 'isAssetcategoryCodeSS',
  isAssetcategoryCodeTR = 'isAssetcategoryCodeTR',
}

export enum PermissionGroups {
  GENERAL = 'General',
  FORECAST_INVESTMENTS = 'Forecast & Investment',
  MAINTENANCE_PLANNING = 'Maintenance & Planning',
  REINVESTMENT = 'Reinvestment',
  TASK_TYPES = 'Task types',
  ASSET_CATEGORIES = 'Asset categories',
  READ_WRITE = 'Read / Write',
  MOBILE = 'Mobile',
}

export const PermissionsTypesLocalesMap: {
  [key in PermissionsTypes]: { labelKey: string; sort: number; group: PermissionGroups };
} = {
  // GENERAL
  [PermissionsTypes.isMapEnabled]: { labelKey: 'Map', sort: 1, group: PermissionGroups.GENERAL },
  [PermissionsTypes.isSingleLineDiagramEnabled]: {
    labelKey: 'Single line diagram',
    sort: 2,
    group: PermissionGroups.GENERAL,
  },
  [PermissionsTypes.isCO2eEnabled]: {
    labelKey: 'CO₂e',
    sort: 3,
    group: PermissionGroups.GENERAL,
  },
  [PermissionsTypes.isFeatureToggleAvailable]: {
    labelKey: 'Feature toggles',
    sort: 4,
    group: PermissionGroups.GENERAL,
  },
  // Forecast & Investment
  [PermissionsTypes.isNetworkLoadingEnabled]: {
    labelKey: 'Network loading',
    sort: 5,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isNetworkLoadingFusesEnabled]: {
    labelKey: 'Network loading - Fuses',
    sort: 6,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isN1Available]: {
    labelKey: 'N-1',
    sort: 7,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isCustomersEnabled]: {
    labelKey: 'Customers',
    sort: 8,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isInvestmentEnabled]: {
    labelKey: 'Investment',
    sort: 9,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isDataQualityEnabled]: {
    labelKey: 'Data quality',
    sort: 10,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isSetupEnabled]: {
    labelKey: 'Setup',
    sort: 11,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isNewLoadEnabled]: {
    labelKey: 'New load',
    sort: 12,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },
  [PermissionsTypes.isAnalysisToolEnabled]: {
    labelKey: 'Analysis tool',
    sort: 13,
    group: PermissionGroups.FORECAST_INVESTMENTS,
  },

  // Maintenance & Planning
  [PermissionsTypes.isTasksEnabled]: {
    labelKey: 'Tasks ',
    sort: 14,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isSmartMetersEnabled]: {
    labelKey: 'Meters',
    sort: 15,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isAssetsEnabled]: {
    labelKey: 'Assets',
    sort: 16,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isMaintenanceEnabled]: {
    labelKey: 'Maintenance',
    sort: 17,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isAssetModelsEnabled]: {
    labelKey: 'Asset models',
    sort: 18,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isFormsEnabled]: {
    labelKey: 'Forms',
    sort: 19,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isHistoryEnabled]: {
    labelKey: 'History',
    sort: 20,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isDataManualsEnabled]: {
    labelKey: 'Data manuals',
    sort: 21,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },
  [PermissionsTypes.isMaterialsEnabled]: {
    labelKey: 'Materials',
    sort: 22,
    group: PermissionGroups.MAINTENANCE_PLANNING,
  },

  // REINVESTMENT
  [PermissionsTypes.isReliabilityEnabled]: {
    labelKey: 'Reliability',
    sort: 23,
    group: PermissionGroups.REINVESTMENT,
  },
  [PermissionsTypes.isMonetizedRiskEnabled]: {
    labelKey: 'Monetized risk',
    sort: 24,
    group: PermissionGroups.REINVESTMENT,
  },

  // TASK TYPES
  [PermissionsTypes.isTaskTypeImmediate]: {
    labelKey: 'Immediate',
    sort: 25,
    group: PermissionGroups.TASK_TYPES,
  },
  [PermissionsTypes.isTaskTypeExpected]: {
    labelKey: 'Expected',
    sort: 26,
    group: PermissionGroups.TASK_TYPES,
  },
  [PermissionsTypes.isTaskTypeAutogenerated]: {
    labelKey: 'Autogenerated',
    sort: 27,
    group: PermissionGroups.TASK_TYPES,
  },
  [PermissionsTypes.isTaskTypeCustomerComplaint]: {
    labelKey: 'Customer complaint',
    sort: 28,
    group: PermissionGroups.TASK_TYPES,
  },
  [PermissionsTypes.isAssignedTasksOnly]: {
    labelKey: 'Show only assigned',
    sort: 29,
    group: PermissionGroups.TASK_TYPES,
  },

  // CATEGORIES
  [PermissionsTypes.isAssetcategoryCodeBD]: {
    labelKey: 'Buildings',
    sort: 30,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeKS]: {
    labelKey: 'Cabinets',
    sort: 31,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeLE]: {
    labelKey: 'Cables',
    sort: 32,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeLV]: {
    labelKey: 'LV board',
    sort: 33,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeMV]: {
    labelKey: 'MV board',
    sort: 34,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeRT]: {
    labelKey: 'RTU',
    sort: 35,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeSM]: {
    labelKey: 'Smart meter',
    sort: 36,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeTH]: {
    labelKey: 'Stations',
    sort: 37,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeTS]: {
    labelKey: 'Street light cabinets',
    sort: 38,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeGL]: {
    labelKey: 'Street lights',
    sort: 39,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeSS]: {
    labelKey: 'Traffic light control box',
    sort: 40,
    group: PermissionGroups.ASSET_CATEGORIES,
  },
  [PermissionsTypes.isAssetcategoryCodeTR]: {
    labelKey: 'Transformers',
    sort: 41,
    group: PermissionGroups.ASSET_CATEGORIES,
  },

  // READ / WRITE
  [PermissionsTypes.isAdminPanelReadOnly]: {
    labelKey: 'Dashboard read-only access',
    sort: 42,
    group: PermissionGroups.READ_WRITE,
  },
  [PermissionsTypes.isMobileApplicationsReadOnly]: {
    labelKey: 'Mobile read-only access',
    sort: 43,
    group: PermissionGroups.READ_WRITE,
  },

  // MOBILE
  [PermissionsTypes.isTimeRegistrationEnabled]: {
    labelKey: 'Time registration',
    sort: 44,
    group: PermissionGroups.MOBILE,
  },
};

export enum SelectCustomersRightsLoadTypes {
  BOTH = 'both',
  CONSUMPTION = 'consumption',
  PRODUCTION = 'production',
}

export enum SelectCustomersRightsMissingTypes {
  ALL = 'All',
  CONSUMPTION = 'Consumption',
  PRODUCTION = 'Production',
}

export enum SelectSolvedValues {
  False = 'False',
  True = 'True',
  Solved = 'Solved',
}

export enum SelectLoadingUnitValues {
  True = 'True',
  False = 'False',
}

export enum SelectVoltageUnits {
  False = 'False',
  True = 'True',
}

export enum SelectMinMaxValues {
  Min = 'Min',
  Max = 'Max',
}

export enum SelectIssuesStatusValues {
  open = 'open',
  ignored = 'ignored',
  solved = 'solved',
}

export enum SelectMissingGeometryAssetTypes {
  Transformers = 'Transformers',
  Cables = 'Cables',
  Cabinets = 'Cabinets',
}

export enum ProfileModalTypes {
  Transformer = 'Transformer',
  Cable = 'Cable',
  Cabinet = 'Cabinet',
  Fuse = 'Fuse',
}

export enum TimeSeriesTypes {
  POWER = 'power',
  VOLTAGE = 'voltage',
  LOSSES = 'losses',
}

export enum TasksRegisteredHoursSourceTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export const DefaultRoute = generatePath(Routes.Map);

export enum AppErrorsTypes {
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  FETCH_USER_ERROR = 'FETCH_USER_ERROR',
  TENANT_NOT_FOUND = 'TENANT_NOT_FOUND',
  NO_ASSIGNED_TENANTS = 'NO_ASSIGNED_TENANTS',
  NO_SCENARIOS = 'NO_SCENARIOS',
  NO_SIMULATIONS = 'NO_SIMULATIONS',
  NON_LOADFLOW_PORTFOLIO = 'NON_LOADFLOW_PORTFOLIO',
  UPGRADE_YOUR_PLAN = 'UPGRADE_YOUR_PLAN',
}

export enum MapTheme {
  Standard = 'Standard',
  Responsive = 'Responsive',
  Monochrome = 'Monochrome',
}

export enum SingleLineDiagramTypes {
  Tree = 'Tree',
  Geospatial = 'Geospatial',
}

export const GriZoneNamesLocalesMap: { [key: string]: string } = {
  'Høj bebyggelse': 'High buildings',
  'Lav bebyggelse': 'Low buildings',
  Opland: 'Countryside',
  Sommerhuse: 'Summer houses',
  Industri: 'Industry',
};

export enum MaintenanceAverageTimeSpentByAssetCategories {
  Transformers = 'Transformers',
  Cabinets = 'Cabinets',
}

export enum Currencies {
  DKK = 'DKK',
  EUR = 'EUR',
  USD = 'USD',
}

export const CurrenciesRatesMap: { [key: string]: number } = {
  [Currencies.DKK]: 1,
  [Currencies.EUR]: 7.5,
  [Currencies.USD]: 7,
};

export const MAX_FORECASTED_SCENARIOS = 4;

export enum YAxisTypes {
  LINEAR = 'linear',
  LOGARITHMIC = 'logarithmic',
}

export enum AddCustomerTypes {
  STANDARD_PROFILE = 'STANDARD_PROFILE',
  CUSTOM_PROFILE = 'CUSTOM_PROFILE',
  BASE_POWER = 'BASE_POWER',
}

export const TimezoneOptions = [
  { value: 'Etc/GMT+12', label: 'International Date Line West' },
  { value: 'Pacific/Midway', label: 'Midway Island, Samoa' },
  { value: 'Pacific/Honolulu', label: 'Hawaii' },
  { value: 'America/Anchorage', label: 'Alaska' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)' },
  { value: 'America/Phoenix', label: 'Arizona (No DST)' },
  { value: 'America/Denver', label: 'Mountain Time (US & Canada)' },
  { value: 'America/Chicago', label: 'Central Time (US & Canada)' },
  { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
  { value: 'America/Halifax', label: 'Atlantic Time (Canada)' },
  { value: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires' },
  { value: 'America/Nuuk', label: 'Greenland' },
  { value: 'Atlantic/Azores', label: 'Azores, Cabo Verde Is.' },
  { value: 'Europe/London', label: 'Dublin, Edinburgh, Lisbon, London' },
  { value: 'Europe/Berlin', label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { value: 'Europe/Paris', label: 'Brussels, Copenhagen, Madrid, Paris' },
  { value: 'Europe/Helsinki', label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { value: 'Europe/Istanbul', label: 'Istanbul' },
  // { value: 'Asia/Riyadh', label: 'Kuwait, Riyadh' },
  { value: 'Asia/Baku', label: 'Baku, Tbilisi, Yerevan' },
  { value: 'Asia/Tashkent', label: 'Tashkent, Ashgabat' },
  // { value: 'Asia/Almaty', label: 'Astana (Kazakhstan)' },
  { value: 'Asia/Kolkata', label: 'India Standard Time' },
  { value: 'Asia/Dhaka', label: 'Dhaka' },
  { value: 'Asia/Bangkok', label: 'Bangkok, Hanoi, Jakarta' },
  { value: 'Asia/Shanghai', label: 'Beijing, Chongqing, Hong Kong, Urumqi' },
  // { value: 'Asia/Seoul', label: 'Seoul' },
  { value: 'Asia/Tokyo', label: 'Osaka, Sapporo, Tokyo' },
  { value: 'Australia/Brisbane', label: 'Brisbane (No DST)' },
  { value: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney' },
  // { value: 'Pacific/Noumea', label: 'Solomon Is., New Caledonia' },
  { value: 'Pacific/Fiji', label: 'Fiji' },
  { value: 'Pacific/Auckland', label: 'Auckland, Wellington' },
  // { value: 'Pacific/Tongatapu', label: "Nuku'alofa, Samoa" },
  { value: 'Pacific/Kiritimati', label: 'Kiritimati Island' },
];

export const PrimarySubstationsLabelMapper: { [key: string]: string } = {
  '150_top_node': 'HV grid',
};

export { Routes };
