import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { mapAddConnectionMetaSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import ModalUploadProfile from 'components/NewLoad/ModalUploadProfile';
import {
  Modal,
  HiddenFormSubmit,
  FormInput,
  SelectDERsCategory,
  SelectDERsProfile,
  Button,
  SelectScenarioYears,
  ButtonDownload,
} from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import SelectConnectionSide from './SelectConnectionSide';
import SelectCapacitiveInductive from './SelectCapacitiveInductive';
import SelectCustomerType from './SelectCustomerType';
import { AddCustomerTypes } from 'constants/index';
import { IconElectricVehicle, IconSolar, IconHeatPump } from '@utiligize/shared/resources';

const ModalAddCustomer: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const meta = useSelector(mapAddConnectionMetaSelector);
  const [show, setShow] = useState(false);
  const [isUploadProfile, setIsUploadProfile] = useState(false);
  const isTransformer = [29, 79].includes(meta?.properties!.cnaim_id);
  const isCabinet = meta?.properties?.cnaim_id === 104;
  const isCustomer = !meta?.properties?.cnaim_id;

  const [formStep, setFormStep] = useState<0 | 1>(0);

  const asset_type = useMemo(() => {
    if (isTransformer) return 'transformer';
    if (isCabinet) return 'cabinet';
    if (isCustomer) return 'customer';
    return null;
  }, [isTransformer, isCabinet, isCustomer]);

  const closeModal = useCallback((): void => {
    setShow(false);
    if (meta) dispatch(setLayoutAction({ mapAddConnectionMeta: null }));
  }, [meta, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setFieldValue,
    validateForm,
    setFieldTouched,
  } = useConfiguredFormik({
    asset_id: meta?.properties!.asset_id,
    asset_type,
    primary_substation_id: meta?.properties?.primary_substation_id_from || null,
    isTransformer,
    formStep,
    closeModal,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (meta) setShow(true);
  }, [meta]);

  const handleSelectCustomerType = useCallback(
    (option: Type.SelectOption) => {
      setFieldValue('customer_type', option.value);
      setFieldValue('der_profile_info_id', option.value === AddCustomerTypes.STANDARD_PROFILE ? 3 : null);
    },
    [setFieldValue]
  );

  const handleSelectDERProfile = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget;
      setFieldValue('der_profile_info_id', Number(value));
    },
    [setFieldValue]
  );

  const handleBack = useCallback(() => setFormStep(0), []);

  const handleContinue = useCallback(() => {
    validateForm().then(res => {
      const keys = Object.keys(res);
      if (keys.length === 0) return setFormStep(1);
      keys.forEach(key => setFieldTouched(key));
    });
  }, [validateForm, setFieldTouched]);

  return (
    <>
      <StyledModal
        show={show}
        onHide={closeModal}
        titleKey="Add customer"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: formStep ? handleBack : closeModal,
          labelKey: formStep ? 'Back' : 'Cancel',
        }}
        submitButtonProps={{
          labelKey: formStep ? 'Add customer' : 'Continue',
          variant: formStep ? 'primary' : 'primary-outline',
          onClick: formStep ? handleFormSubmit : handleContinue,
        }}
        $isUploadProfile={isUploadProfile}
      >
        <Form onSubmit={handleFormSubmit}>
          {formStep === 0 && (
            <>
              <Form.Group>
                <SelectCustomerType
                  value={values.customer_type}
                  onChange={handleSelectCustomerType}
                  errorKey={
                    Boolean(touched.customer_type && errors.customer_type) ? (errors.customer_type as string) : ''
                  }
                />
              </Form.Group>
              {values.customer_type === AddCustomerTypes.STANDARD_PROFILE && (
                <div className="d-flex justify-content-center">
                  <Button
                    value={3}
                    className={classNames({ active: values.der_profile_info_id === 3 })}
                    onClick={handleSelectDERProfile}
                    icon={<IconElectricVehicle />}
                    labelKey="Electric vehicle"
                  />
                  <Button
                    value={6}
                    className={classNames({ active: values.der_profile_info_id === 6 })}
                    onClick={handleSelectDERProfile}
                    icon={<IconSolar />}
                    labelKey="Solar"
                    marginLeft
                  />
                  <Button
                    value={4}
                    className={classNames({ active: values.der_profile_info_id === 4 })}
                    onClick={handleSelectDERProfile}
                    icon={<IconHeatPump />}
                    labelKey="Heat pump"
                    marginLeft
                  />
                </div>
              )}
              {values.customer_type === AddCustomerTypes.CUSTOM_PROFILE && (
                <>
                  <Form.Group>
                    <SelectDERsProfile
                      name="der_profile_info_id"
                      value={values.der_profile_info_id}
                      onChange={(value: number | null) => setFieldValue('der_profile_info_id', value)}
                      onBlur={() => setFieldTouched('der_profile_info_id')}
                      errorKey={
                        Boolean(touched.der_profile_info_id && errors.der_profile_info_id)
                          ? (errors.der_profile_info_id as string)
                          : ''
                      }
                    />
                  </Form.Group>
                  <ModalUploadProfile setIsUploadProfile={setIsUploadProfile} />
                  <ButtonDownload
                    labelKey="Download template"
                    fileNameLocaleStr="Profile template"
                    link="scenarios/der_profile_excel"
                    marginLeft
                  />
                </>
              )}
              {values.customer_type === AddCustomerTypes.BASE_POWER && (
                <>
                  <Form.Group>
                    <FormInput
                      labelKey="Power (MW)"
                      type="number"
                      name="customer_mw"
                      value={values.customer_mw ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorKey={
                        Boolean(touched.customer_mw && errors.customer_mw) ? (errors.customer_mw as string) : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <FormInput
                      labelKey="Power factor"
                      type="number"
                      name="power_factor"
                      value={values.power_factor ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorKey={
                        Boolean(touched.power_factor && errors.power_factor) ? (errors.power_factor as string) : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <SelectCapacitiveInductive
                      value={values.leading_lagging}
                      onChange={value => setFieldValue('leading_lagging', value?.value)}
                      onBlur={handleBlur}
                      errorKey={
                        Boolean(touched.leading_lagging && errors.leading_lagging)
                          ? (errors.leading_lagging as string)
                          : ''
                      }
                    />
                  </Form.Group>
                </>
              )}
            </>
          )}

          {formStep === 1 && (
            <>
              <Form.Group>
                <FormInput
                  labelKey="Name"
                  name="name"
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
                />
              </Form.Group>
              {isTransformer && (
                <Form.Group>
                  <SelectConnectionSide
                    value={values.connection_side}
                    onChange={value => setFieldValue('connection_side', value?.value)}
                    onBlur={() => setFieldTouched('connection_side')}
                    errorKey={
                      Boolean(touched.connection_side && errors.connection_side)
                        ? (errors.connection_side as string)
                        : ''
                    }
                  />
                </Form.Group>
              )}
              <Form.Group>
                <SelectScenarioYears
                  name="connection_year"
                  labelKey="Connection year"
                  value={values.connection_year}
                  onChange={value => setFieldValue('connection_year', value?.value)}
                  onBlur={() => setFieldTouched('connection_year')}
                  onlyFuture
                  errorKey={
                    Boolean(touched.connection_year && errors.connection_year) ? (errors.connection_year as string) : ''
                  }
                />
              </Form.Group>
              <SelectDERsCategory
                name="der_category_id"
                value={values.der_category_id}
                setFieldValue={setFieldValue}
                errorKey={
                  Boolean(touched.der_category_id && errors.der_category_id) ? (errors.der_category_id as string) : ''
                }
                show={show}
              />
            </>
          )}
          <HiddenFormSubmit />
        </Form>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)<{ $isUploadProfile: boolean }>`
  .modal-content {
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => (props.$isUploadProfile ? 0 : 1)};
  }
`;

export default ModalAddCustomer;
