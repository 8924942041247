import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { Assets, AssetsSubComponents, AssetDetails } from 'components/Assets';
import PagePermissionsProvider from './PagePermissionsProvider';
import PageAppExceptions from './PageAppExceptions';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageAssets: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isAnalysisToolEnabled}>
    <RequiredScenarioCheck>
      <RequiredSimulationCheck>
        <Switch>
          <Route exact path={Routes.Assets} component={Assets} />
          <Route exact path={Routes.AssetsSubComponents} component={AssetsSubComponents} />
          <Route
            exact
            path={Routes.AssetCreate}
            render={(props: RouteComponentProps) => <AssetDetails isCreateMode {...props} />}
          />
          <Route exact path={Routes.AssetDetails} component={AssetDetails} />
          <Route path="*" component={PageAppExceptions} />
        </Switch>
      </RequiredSimulationCheck>
    </RequiredScenarioCheck>
  </PagePermissionsProvider>
);

export default PageAssets;
