import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { BICalculations } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['voltageDisplay'];
}

const SelectBICalculation: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const selectOptions = useMemo(
    () => Object.keys(BICalculations).map(key => ({ value: key, label: getIntl(key) })),
    [getIntl]
  );

  const selectValue = useMemo(
    () => selectOptions.find(option => option.value === value) || null,
    [value, selectOptions]
  );

  return <FormReactSelect value={selectValue} options={selectOptions} isSearchable={false} {...props} />;
};

export default SelectBICalculation;
