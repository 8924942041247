import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useEventPreventedExec, useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory, assetModelModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { assetModelsItemSelectorFactory } from 'modules/assetModels/selectors';
import {
  Modal,
  Button,
  FormInput,
  SelectFormManufacturers,
  SelectAssetCategories,
  HiddenFormSubmit,
  Checkbox,
  DateRangePicker,
} from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { PaginationType } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

const AssetModelsModal: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(assetModelModalIdSelector);
  const assetModel: AssetModels.Item | undefined = useSelector(assetModelsItemSelectorFactory(id));
  const { filters } = useSelector(paginationSelectorFactory(PaginationType.ASSET_MODELS));
  const isEditMode: boolean = Boolean(id && assetModel);
  const [show, setShow] = useState(false);
  const isReplacementPriorityDisabled =
    !isEditMode || !filters?.subComponentTypeId || filters?.subComponentTypeId === 'unassigned';

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ assetModelsModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldTouched,
    setValues,
    setFieldValue,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal, isReplacementPriorityDisabled });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!assetModel) return;
    setValues({
      name: assetModel.modelName,
      assetCategoryId: assetModel.assetcategoryId,
      manufacturerId: assetModel.manufacturerId,
      replacementOption: assetModel.replacementOption,
      replacementPriority: assetModel.replacementPriority,
      lastReplacementDate: (assetModel.lastReplacementDate as null) && moment(assetModel.lastReplacementDate),
    });
    setShow(true);
  }, [assetModel, setValues]);

  const handleDateRangePickerApply = useCallback(
    (event: React.SyntheticEvent, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      setFieldValue('lastReplacementDate', picker.startDate);
    },
    [setFieldValue]
  );

  const handleReplacementOptionClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked: status } = event.currentTarget;
      setFieldValue('replacementOption', status);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create asset model" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit asset model' : 'Create asset model'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <SelectAssetCategories
                value={values.assetCategoryId}
                isIdValue
                onChange={(v: any) => setFieldValue('assetCategoryId', v.value)}
                onBlur={() => setFieldTouched('assetCategoryId')}
                errorKey={
                  Boolean(touched.assetCategoryId && errors.assetCategoryId) ? (errors.assetCategoryId as string) : ''
                }
                excludeSyntheticOptions
              />
            </Form.Group>
            <Form.Group as={Col}>
              <SelectFormManufacturers
                name="manufacturerId"
                value={values.manufacturerId}
                onChange={v => setFieldValue('manufacturerId', v.value)}
                onBlur={() => setFieldTouched('manufacturerId')}
                errorKey={
                  Boolean(touched.manufacturerId && errors.manufacturerId) ? (errors.manufacturerId as string) : ''
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <FormInput
                min={1}
                type="number"
                labelKey="Replacement priority"
                name="replacementPriority"
                value={values.replacementPriority || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isReplacementPriorityDisabled}
                errorKey={
                  Boolean(touched.replacementPriority && errors.replacementPriority)
                    ? (errors.replacementPriority as string)
                    : ''
                }
              />
              <Form.Text className="text-muted" hidden={!isReplacementPriorityDisabled}>
                {getIntl('Please assign asset model to sub-component first')}
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col}>
              <DateRangePicker
                labelKey="Replacement date"
                initialSettings={{
                  singleDatePicker: true,
                  startDate: values.lastReplacementDate || undefined,
                }}
                onApply={handleDateRangePickerApply}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Checkbox
                labelKey="Replacement option"
                className="icheck-primary d-inline"
                checked={values.replacementOption}
                onChange={handleReplacementOptionClick}
              />
            </Form.Group>
          </Form.Row>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default AssetModelsModal;
