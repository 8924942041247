import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useEventPreventedExec, useLocale } from 'hooks';
import { Modal, Button, FormInput, Checkbox } from 'components/_common';
import { IconUpload } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalUploadProfile: React.FC<{ setIsUploadProfile?: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setIsUploadProfile,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const { getIntl } = useLocale();

  const toggleModal = useCallback((): void => {
    setShow(show => {
      setIsUploadProfile?.(!show);
      return !show;
    });
  }, [setIsUploadProfile]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, resetForm, setFieldValue } =
    useConfiguredFormik({ toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { name, checked: shared } = event.target as HTMLInputElement;
      setFieldValue(name, shared);
    },
    [setFieldValue]
  );

  const handleUploadButtonClick = useCallback(() => {
    inputRef.current!.value = '';
    inputRef.current?.click();
  }, []);

  const handleUploadInputChange = useCallback(() => {
    const files = inputRef.current?.files;
    if (files?.[0]) setFieldValue('file', files?.[0]);
  }, [setFieldValue]);

  return (
    <>
      <Button icon={<IconUpload />} labelKey="Upload profile" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Upload profile"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Upload',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              placeholderKey="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>

          <Row>
            <Col xs="auto">
              <Button icon={<IconUpload />} labelKey="Upload profile" onClick={handleUploadButtonClick} />
              <input ref={inputRef} onChange={handleUploadInputChange} className="d-none" type="file" />
            </Col>
            <Col xs="auto">
              <Checkbox
                className="pl-0 icheck-primary"
                name="shared"
                checked={values.shared}
                labelKey="Shared"
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>

          {values.file && <Form.Text className="text-muted">{values.file.name}</Form.Text>}
          {Boolean(touched.file && errors.file) && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {getIntl(errors.file as string)}
            </Form.Control.Feedback>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalUploadProfile;
