import { handleActions, combineActions } from 'redux-actions';
import { fetchStatisticsActions, fetchCompletedActionsByUsers, fetchUserChartActions, resetModule } from './actions';

const initialState: Statistics.Root = {
  completeActionsChartData: null,
  finalChecksChartData: null,
  completedActionsByUsersHash: {} as Record<string, Statistics.UserAction>,
  completedActionsByUsersCount: 0,
  isFetched: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(fetchStatisticsActions, fetchUserChartActions, fetchCompletedActionsByUsers).toString()]: {
    next: (state: Statistics.Root, action: Shared.ReduxAction<Partial<Statistics.Root>>): Statistics.Root => ({
      ...state,
      ...action.payload,
    }),
  },

  [resetModule]: (): Statistics.Root => initialState,
};

export { fetchCompletedActionsByUsers, fetchUserChartActions, fetchStatisticsActions, resetModule };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
