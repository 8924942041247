import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { shortCircuitCountSelector, shortCircuitItemsSelector } from 'modules/setup/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { fetchShortCircuitAction } from 'modules/setup';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableShortCircuit: React.FC = () => {
  const { numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const portfolioId = useSelector(portfolioIdSelector);
  const items = useSelector(shortCircuitItemsSelector);
  const count = useSelector(shortCircuitCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchShortCircuitAction(params)).then(
        (action: Shared.ReduxAction<{ shortCircuitItems: Setup.Root['shortCircuitItems'] }>) =>
          action.payload?.shortCircuitItems || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.SETUP_SHORT_CIRCUIT}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map?.((item: Setup.ShortCircuit) => (
        <tr key={item.short_name}>
          <td>{item.short_name}</td>
          <td>{numberFormat(item.skss_mva, { fallback: '-' })}</td>
          <td>{numberFormat(item.ikss_ka, { fallback: '-' })}</td>
          <td>{numberFormat(item.xtor, { fallback: '-' })}</td>
          <td>{numberFormat(item.phii_deg, { fallback: '-' })}</td>
          <td>{numberFormat(item.r_ohm, { fallback: '-' })}</td>
          <td>{numberFormat(item.x_ohm, { fallback: '-' })}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableShortCircuit;
