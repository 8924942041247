import { Action, handleActions, ReducerMap } from 'redux-actions';
import { parseMaplegendToSettings, layerListToRecord } from 'utils/map';
import { StorageKeys, initLayers } from 'constants/index';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { filterMapState, mergeMapState } from 'utils/map';
import { _cloneDeep } from '@utiligize/shared/utils';
import {
  setMapStateAction,
  fetchConfigAction,
  mapStateAction,
  fetchMaxLoadSummaryAction,
  fetchTasksFeatureCollection,
  fetchTaskFiltersAction,
  setSettingsAction,
  setMapLayersAction,
  setDataQualityWarningAction,
  fetchPopupAssetInfoAction,
  fetchN1RouteAction,
  setAddressSearchAction,
  fetchDataQualityWarningAction,
  setLabelsAction,
  fetchSingleLineDiagramAction,
  setMapDrawAssetFeaturesAction,
  updateMapDrawAssetFeaturePropertiesAction,
  changeFilterVoltagesAction,
  fetchNewCustomersCountAction,
} from './actions';

const initialState: Map.Root = {
  isLoading: true,
  legendData: {},
  newCustomersLayerCount: null,
  mapState: {} as Map.MapState,
  tasksFeatureCollection: null,
  taskFilters: null,
  settings: {
    assetLayers: [],
    cnaimLayers: [],
    otherLayers: [],
    dataQualityLayers: [],
    maxLoadLayers: [],
    maxLoadIconsLayers: [],
    n1Layers: [],
    n1MaxLoadLayers: [],
    lossesLayers: [],
    consumptionLayers: [],
    taskLayers: [],
    replacementsLayers: [],
    satelliteLayers: [],
    infoLayers: [],
    highlightLayers: [],
    themeActiveFilters: {},
  },
  dataQualityWarning: null,
  n1Route: null,
  selectedN1RoutesIds: [],
  highlightedN1RouteId: null,
  showAddressSearch: false,
  showCableAnimation: false,
  showLabels: true,
  mapDrawAssetFeatures: getStorageItem(StorageKeys.MAP_DRAWN_FEATURES) || null,
};

export const mapDefaultState: Map.MapInitialState<Partial<Map.MapState>> = {
  theme: () => null,
  themeGroup: () => null,
  satelliteVisibility: () => false,
  buildingsVisibility: () => false,
  showMaxLoadVoltage: () => false,
  enabledLayers: state =>
    layerListToRecord(
      state.settings.assetLayers!.filter(l => (state.settings.initLayers ?? initLayers).some(x => l.startsWith(x))),
      true
    ),
  layerFilters: () => ({}),
  globalFilters: state => {
    const filters = state.settings.globalFilters;
    if (!filters) return {};
    return Object.keys(filters).reduce(
      (acc, key) => {
        const list = filters[key].list.filter(i => i.default_state ?? true).map(i => i.id);
        acc[key] = { ...filters[key], list, initList: filters[key].list };
        return acc;
      },
      {} as Record<string, any>
    );
  },
  dataQualityFilters: state => {
    const f = state.settings.dataQualityFilters;
    if (!f) return null;
    return { property: f.property!, list: f.list.map(i => i.id), initList: f.list };
  },
  replacementsFilters: state => {
    const f = state.settings.replacementsFilters;
    if (!f) return null;
    return { property: f.property!, list: f.list.map(i => i.id), initList: f.list };
  },
  taskDepartments: () => null,
  taskActions: () => null,
  taskCategories: () => null,
  taskTypes: () => null,
  taskUserEmails: () => null,
  taskDateRange: () => ({ startYear: new Date().getFullYear(), endYear: new Date().getFullYear() }),
  collapseGroupState: () => ({}),
  showConsumptionPercents: () => false,
  showLossesPercents: () => false,
  n1MaxLoadValueType: () => 'percent',
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: ReducerMap<Map.Root, any> = {
  [setMapStateAction.toString()]: (state: Map.Root, action: Action<Partial<Map.Root>>): Map.Root => ({
    ...state,
    ...action.payload,
  }),

  [fetchConfigAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.LegendData>): Map.Root => ({
      ...state,
      legendData: action.payload,
      settings: { ...state.settings, ...parseMaplegendToSettings(action.payload) },
    }),
  },

  [setAddressSearchAction.toString()]: (state: Map.Root, action: Action<Map.Root['showAddressSearch']>): Map.Root => ({
    ...state,
    showAddressSearch: action.payload,
  }),

  [setLabelsAction.toString()]: (state: Map.Root, action: Action<Map.Root['showLabels']>): Map.Root => ({
    ...state,
    showLabels: action.payload,
  }),

  [mapStateAction.toString()]: (state: Map.Root, action: Action<Map.MapState>): Map.Root => {
    setStorageItem({ [StorageKeys.MAP_CACHE]: filterMapState(_cloneDeep(action.payload)) });
    return {
      ...state,
      mapState: action.payload,
    };
  },

  [fetchTasksFeatureCollection.toString()]: {
    next: (state: Map.Root, action: Action<Map.Root['tasksFeatureCollection']>): Map.Root => ({
      ...state,
      tasksFeatureCollection: action.payload,
      isLoading: false,
    }),
  },

  [fetchTaskFiltersAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.TaskFilters>): Map.Root => ({
      ...state,
      taskFilters: action.payload,
    }),
  },

  [setSettingsAction.toString()]: {
    next: (state: Map.Root, action: Action<Partial<Map.Settings>>): Map.Root => {
      const nextState = { ...state, settings: { ...state.settings, ...action.payload } };
      const cachedState = getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || ({} as Map.MapState);
      nextState.mapState = mergeMapState(cachedState, nextState, mapDefaultState);
      return nextState;
    },
  },

  [setMapLayersAction.toString()]: (state: Map.Root, action: Action<Map.StyleLayer[] | null>): Map.Root => ({
    ...state,
    mapLayers: action.payload,
  }),

  [setDataQualityWarningAction.toString()]: (
    state: Map.Root,
    action: Action<Map.DataQualityWarning | null>
  ): Map.Root => ({
    ...state,
    dataQualityWarning: action.payload,
  }),

  [fetchDataQualityWarningAction.toString()]: (
    state: Map.Root,
    action: Action<Map.DataQualityWarning | null>
  ): Map.Root => ({
    ...state,
    dataQualityWarning: action.payload,
  }),

  [fetchN1RouteAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.N1Route | null>): Map.Root => ({
      ...state,
      n1Route: action.payload,
      selectedN1RoutesIds: action.payload?.routes?.map(p => p.id) ?? [],
    }),
  },

  [setMapDrawAssetFeaturesAction.toString()]: (
    state: Map.Root,
    action: Action<Map.Root['mapDrawAssetFeatures']>
  ): Map.Root => {
    setStorageItem({ [StorageKeys.MAP_DRAWN_FEATURES]: action.payload });
    return {
      ...state,
      mapDrawAssetFeatures: action.payload,
    };
  },

  [updateMapDrawAssetFeaturePropertiesAction.toString()]: (
    state: Map.Root,
    action: Action<{ id: string; properties: Map.MapDrawAssetFeatureProperties }>
  ): Map.Root => {
    const features =
      state.mapDrawAssetFeatures?.reduce(
        (acc: GeoJSON.Feature<GeoJSON.Geometry, Map.MapDrawAssetFeatureProperties>[], feature) => {
          if (feature.id === action.payload.id) {
            acc.push({ ...feature, properties: action.payload.properties });
          } else {
            acc.push(feature);
          }
          return acc;
        },
        []
      ) || null;
    setStorageItem({ [StorageKeys.MAP_DRAWN_FEATURES]: features });
    return {
      ...state,
      mapDrawAssetFeatures: features,
    };
  },

  [fetchNewCustomersCountAction.toString()]: {
    next: (state: Map.Root, action: Action<Map.Root['newCustomersLayerCount']>): Map.Root => ({
      ...state,
      newCustomersLayerCount: action.payload,
    }),
  },
};

export {
  setMapStateAction,
  fetchConfigAction,
  mapStateAction,
  fetchMaxLoadSummaryAction,
  fetchTasksFeatureCollection,
  fetchTaskFiltersAction,
  setSettingsAction,
  setMapLayersAction,
  setDataQualityWarningAction,
  fetchPopupAssetInfoAction,
  fetchN1RouteAction,
  setAddressSearchAction,
  fetchDataQualityWarningAction,
  setLabelsAction,
  fetchSingleLineDiagramAction,
  changeFilterVoltagesAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions<Map.Root>(ACTION_HANDLERS, initialState);
