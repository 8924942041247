import * as Yup from 'yup';
import { useFormik } from 'formik';
import { push } from 'connected-react-router';
import { useBuilderState } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { instructionsBuilderDataSelector } from 'modules/instructions/selectors';
import { BuilderFormTypeNames, Routes } from 'constants/index';

interface Values {
  name: string;
  assetCategory: Asset.Category | null;
  assetCodes: string[];
  modelIds: number[];
}

const useConfiguredFormik = ({
  isEditMode,
  toggleModal,
}: {
  isEditMode: boolean;
  usersIds: number[];
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const builderData: Builder.Data = useSelector(instructionsBuilderDataSelector);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectValue, setLocationState } = isEditMode ? useBuilderState() : ({} as any);

  const formik = useFormik<Values>({
    initialValues: {
      name: isEditMode ? selectValue.name : '',
      assetCategory: isEditMode ? selectValue.assetCategories : null,
      assetCodes: isEditMode ? selectValue.assetCodes : [],
      modelIds: isEditMode ? selectValue.modelIds : [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      assetCategory: Yup.object().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      if (isEditMode) {
        setLocationState({ selectValue: { ...selectValue, ...values } });
        toggleModal();
      } else {
        const formtype = builderData.formTypes.find((i: Type.FormType) => i.name === BuilderFormTypeNames.Instructions);
        if (!formtype) throw Error('formtype not found');

        const selectValue: Builder.SelectValue = {
          name: values.name,
          formtype,
          assetCategories: values.assetCategory!,
          formSubcomponents: null,
          assetCodes: values.assetCodes,
          modelIds: values.modelIds,
        };

        dispatch(
          push(Routes.InstructionBuilder, {
            isNew: true,
            isDuplicate: false,
            selectValue,
            blocks: [],
            snapshots: [],
            fieldTypes: builderData.fieldTypes,
            formTypes: builderData.formTypes,
          } as Builder.LocationState)
        );
      }
    },
  });

  return formik;
};

export default useConfiguredFormik;
