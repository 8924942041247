import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale, usePaginationHandlers } from 'hooks';
import { externalTimeSystemSelector } from 'modules/app/selectors';
import {
  isPageTasksTimeOverviewUsersHoursTabActiveSelector,
  isPageTasksTimeOverviewTasksHoursTabActiveSelector,
} from 'modules/router/selectors';
import { setPaginationAction } from 'modules/layouts';
import { TotalHoursTable, UsersRegisteredHoursTable, TasksRegisteredHoursTable } from 'components/_tables';
import { Tabs, Tab, DataTableTemplate, DataTableHeaderFilters, Checkbox, SelectYear } from 'components/_common';
import {
  Routes,
  PaginationType,
  DataTableFilterKeys,
  TasksRegisteredHoursSourceTypes,
  TableHeaders,
} from 'constants/index';
import TabAssetServiceTime from './TabAssetServiceTime';
import TaskIdInput from './TaskIdInput';

const ViewTimeOverview: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const externalTimeSystem = useSelector(externalTimeSystemSelector);
  const isPageTasksTimeOverviewUsersHoursTabActive = useSelector(isPageTasksTimeOverviewUsersHoursTabActiveSelector);
  const isPageTasksTimeOverviewTasksHoursTabActive = useSelector(isPageTasksTimeOverviewTasksHoursTabActiveSelector);

  const isRegisteredHoursTabsActive =
    isPageTasksTimeOverviewUsersHoursTabActive || isPageTasksTimeOverviewTasksHoursTabActive;

  const type = (() => {
    if (isPageTasksTimeOverviewUsersHoursTabActive) {
      return PaginationType.USERS_REGISTERED_HOURS;
    }
    if (isPageTasksTimeOverviewTasksHoursTabActive) {
      return PaginationType.TASKS_REGISTERED_HOURS;
    }
    return PaginationType.TOTAL_HOURS;
  })();
  const { filters, handleSelectChange, handleInputChange } = usePaginationHandlers({ type });

  const showKeys = [DataTableFilterKeys.START_TIME, DataTableFilterKeys.END_TIME, DataTableFilterKeys.USER_IDS];

  const handleSwitchButtonClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const modifier = {
        filters: {
          ...filters,
          source: checked ? TasksRegisteredHoursSourceTypes.EXTERNAL : TasksRegisteredHoursSourceTypes.INTERNAL,
          // reset task filter on source change
          ...(type === PaginationType.USERS_REGISTERED_HOURS && {
            taskId: null,
          }),
        },
        // force column name change for external request
        ...(type === PaginationType.TASKS_REGISTERED_HOURS && {
          column: checked ? 'caseNumber' : TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][0].sortKey,
        }),
        offset: 0,
      };
      dispatch(setPaginationAction({ type, modifier }));
    },
    [dispatch, filters, type]
  );

  const handleDetailsButtonClick = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const id = Number(event.currentTarget.getAttribute('data-id'));

      const modifier = {
        filters: {
          ...filters,
          taskId: isPageTasksTimeOverviewTasksHoursTabActive ? id : null,
          userIds: isPageTasksTimeOverviewTasksHoursTabActive ? null : [id],
        },
        offset: 0,
        query: '',
        ...(!isPageTasksTimeOverviewTasksHoursTabActive && {
          column:
            filters?.source === TasksRegisteredHoursSourceTypes.EXTERNAL
              ? 'caseNumber'
              : TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][0].sortKey,
        }),
      };

      dispatch(
        setPaginationAction({
          type: isPageTasksTimeOverviewTasksHoursTabActive
            ? PaginationType.USERS_REGISTERED_HOURS
            : PaginationType.TASKS_REGISTERED_HOURS,
          modifier,
        })
      );

      dispatch(
        push(
          isPageTasksTimeOverviewTasksHoursTabActive
            ? `${Routes.TasksTimeOverview}${Routes.HASH.UsersHours}`
            : `${Routes.TasksTimeOverview}${Routes.HASH.TasksHours}`
        )
      );
    },
    [dispatch, filters, isPageTasksTimeOverviewTasksHoursTabActive]
  );

  return (
    <DataTableTemplate>
      {isRegisteredHoursTabsActive ? (
        <Row>
          <DataTableHeaderFilters key={type} paginationType={type} showKeys={showKeys} />
          {isPageTasksTimeOverviewUsersHoursTabActive && (
            <Col xs={2}>
              <TaskIdInput
                mutedTextLabelKey={
                  filters?.source === TasksRegisteredHoursSourceTypes.EXTERNAL ? 'External task id' : 'Task id'
                }
                name="taskId"
                value={filters.taskId}
                onChange={handleInputChange}
              />
            </Col>
          )}
          {externalTimeSystem && (
            <Col xs="auto">
              <Checkbox
                labelKey="External"
                className="icheck-primary"
                checked={filters?.source === TasksRegisteredHoursSourceTypes.EXTERNAL}
                onChange={handleSwitchButtonClick}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col xs={2}>
            <SelectYear
              labelKey=""
              mutedTextLabelKey="Year"
              name="year"
              value={filters.year}
              onChange={handleSelectChange}
              isSearchable={false}
            />
          </Col>
        </Row>
      )}
      <Tabs mountOnEnter unmountOnExit>
        <Tab
          id="tab_time_overview_asset_service_time"
          eventKey={Routes.TasksTimeOverview}
          title={getIntl('Asset service time')}
        >
          <TabAssetServiceTime />
        </Tab>
        <Tab
          id="tab_time_overview_total_hours"
          eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.TotalHours}`}
          title={getIntl('Total hours')}
        >
          <TotalHoursTable />
        </Tab>
        <Tab
          id="tab_time_overview_users_hours"
          eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.UsersHours}`}
          title={getIntl('Users hours')}
        >
          <UsersRegisteredHoursTable handleDetailsButtonClick={handleDetailsButtonClick} />
        </Tab>
        <Tab
          id="tab_time_overview_tasks_hours"
          eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.TasksHours}`}
          title={getIntl('Tasks hours')}
        >
          <TasksRegisteredHoursTable
            source={filters?.source as Tasks.RegisteredHoursSourceTypes}
            handleDetailsButtonClick={handleDetailsButtonClick}
          />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewTimeOverview;
