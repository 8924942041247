import React from 'react';
import { useSelector } from 'react-redux';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import PageAppExceptions from './PageAppExceptions';
import { AppErrorsTypes } from 'constants/index';

const PagePermissionsProvider: React.FC<{
  children: React.ReactNode;
  permissionKey: Permissions.PermissionsTypes;
}> = ({ permissionKey, children }) => {
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  return appCurrentUserPermissions[permissionKey] ? (
    <>{children}</>
  ) : (
    <PageAppExceptions appError={AppErrorsTypes.UPGRADE_YOUR_PLAN} />
  );
};

export default PagePermissionsProvider;
