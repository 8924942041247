import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { missingGeometryCountSelector, missingGeometrySelector } from 'modules/dataQuality/selectors';
import { fetchMissingGeometryAction } from 'modules/dataQuality';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableMissingGeometry: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.DATA_QUALITY_MISSING_GEOMETRY;

  const items = useSelector(missingGeometrySelector);
  const count = useSelector(missingGeometryCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      return dispatch(fetchMissingGeometryAction(params)).then(
        (action: Shared.ReduxAction<Pick<DataQuality.Root, 'missingGeometry'>>) => action.payload?.missingGeometry || []
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 255px)"
      waitForDependencies={!portfolioId || !versionId}
      triggerTableUpdateDeps={[portfolioId, versionId]}
    >
      {items?.map((item: DataQuality.MissingGeometry) => (
        <tr key={item.id}>
          <td>{item.customer_asset_name || '-'}</td>
          <td>{item.asset_register_category || '-'}</td>
          <td>{item.asset_type || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMissingGeometry;
