import React from 'react';
import { InspectionsModal } from 'components/_modals';
import { InspectionsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { PermissionsTypes } from 'constants/index';

const PageInspections: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
    <RequiredScenarioCheck>
      <RequiredSimulationCheck>
        <DataTableTemplate paddingBottom>
          <InspectionsModal />
          <InspectionsTable />
        </DataTableTemplate>
      </RequiredSimulationCheck>
    </RequiredScenarioCheck>
  </PagePermissionsProvider>
);

export default PageInspections;
