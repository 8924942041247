import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isSelectSimulationEnabledSelector,
  simulationOptionSelector,
  simulationOptionsSelector,
} from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';
import { simulationIdSelector } from 'modules/layouts/selectors';

const SelectSimulation: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(simulationOptionsSelector);
  const simulationId = useSelector(simulationIdSelector);
  const value = useSelector(simulationOptionSelector);
  const isSelectSimulationEnabled = useSelector(isSelectSimulationEnabledSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      if (simulationId === Number(option.value)) return;
      dispatch(
        setLayoutAction({
          simulationId: Number(option.value),
          isMapConfigFetched: false,
          singleDiagramFilterPrimarySubstationsId: null,
          singleDiagramFilterVoltagesIds: null,
        })
      );
    },
    [dispatch, simulationId]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options?.length || !isSelectSimulationEnabled}
      labelKey="Simulation"
      value={isSelectSimulationEnabled ? value : options?.[0]}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      placeholder={options?.length ? undefined : 'No simulations available'}
    />
  );
};

export default SelectSimulation;
