import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerDetectionTypeOptionsAction } from 'modules/options';
import { customerDetectionTypeOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectCustomerDetectionType: React.FC<Props> = ({ value = null, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const options = useSelector(customerDetectionTypeOptionsSelector);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCustomerDetectionTypeOptionsAction()).finally(() => setLoading(false));
  }, [dispatch]);

  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);

  return (
    <FormReactSelect
      value={selectValue}
      options={options || []}
      isDisabled={!options?.length}
      isLoading={loading}
      {...props}
    />
  );
};

export default SelectCustomerDetectionType;
