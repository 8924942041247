import styled from 'styled-components';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { deleteAssetPhotosAction } from 'modules/assets';
import { Alert, Gallery, Button } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { IconDelete } from '@utiligize/shared/resources';

interface Props {
  uuid: string;
  images: Asset.AssetMeta['images'];
}

const TabPhoto: React.FC<Props> = ({ uuid, images }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [imagesState, setImages] = useState<Type.GalleryImage[]>(_cloneDeep(images || []));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDeletePhotosButtonVisible = useMemo(() => imagesState.some(image => image.isSelected), [imagesState]);

  const handleSelectImage = useCallback((index: number, image: Type.GalleryImage) => {
    setImages(prevImages => {
      const nextImages = prevImages.slice();
      nextImages[index].isSelected = !image.isSelected;
      return nextImages;
    });
  }, []);

  const handleCancelButtonClick = useCallback(() => setImages(_cloneDeep(images || [])), [images]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsSubmitting(true);
    const attachmentKeys = imagesState.reduce((acc: string[], image: Type.GalleryImage) => {
      if (image.isSelected) acc.push(image.gcsFileName);
      return acc;
    }, []);

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () =>
          dispatch(deleteAssetPhotosAction({ uuid, attachmentKeys }))
            .then(action => setImages(action.payload))
            .finally(() => setIsSubmitting(false)),
      })
    );
  }, [uuid, imagesState, dispatch]);

  if (!imagesState.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <StyledContainer data-button-visible={isDeletePhotosButtonVisible}>
      <Gallery rowHeight={220} images={imagesState} enableImageSelection onSelectImage={handleSelectImage} />
      {isDeletePhotosButtonVisible && (
        <StyledControlsContainer>
          <Button
            variant="primary-outline"
            labelKey="Cancel"
            onClick={handleCancelButtonClick}
            disabled={isSubmitting}
          />
          <Button
            labelKey="Delete photos"
            marginLeft
            icon={<IconDelete />}
            onClick={handleDeleteButtonClick}
            disabled={!images.length}
            variant="danger"
          />
        </StyledControlsContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ 'data-button-visible': boolean }>`
  margin-bottom: ${props => (props['data-button-visible'] ? 63 : 0)}px;
`;

const StyledControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px;
  background: ${props => props.theme.colors.white};
  border-top: solid 1px ${props => props.theme.colors.grey100};
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.05);

  > button {
    width: 100%;
  }
`;

export default TabPhoto;
