import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { setPaginationAction } from 'modules/layouts';
import { ScenarioRunInfo } from 'components';
import { SelectSolved, Checkbox, SelectScenarioYears } from 'components/_common';
import SelectVoltage from './SelectVoltage';
import SelectVoltageSide from './SelectVoltageSide';
import SelectVoltageDisplay from './SelectVoltageDisplay';
import SelectBICalculation from './SelectBICalculation';
import SelectPercentile from './SelectPercentile';
import SelectLoadingUnit from './SelectLoadingUnit';
import { PaginationType, TransformerVoltages, CableVoltages } from 'constants/index';

interface Props {
  type: Type.PaginationType;
  hideScenariosYear?: boolean;
  hideVoltage?: boolean;
  showFilterSolved?: boolean;
  showLoadingUnit?: boolean;
  showVoltageSide?: boolean;
  showVoltageDisplay?: boolean;
  showBICalculation?: boolean;
  showPercentile?: boolean;
  showOverloadedAssets?: boolean;
}

const TableFilters: React.FC<Props> = ({
  type,
  hideScenariosYear,
  hideVoltage,
  showFilterSolved = false,
  showLoadingUnit = false,
  showVoltageSide = false,
  showVoltageDisplay = false,
  showBICalculation = false,
  showPercentile = false,
  showOverloadedAssets = false,
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters, handleSelectChange } = usePaginationHandlers({ type });
  const voltages = [PaginationType.TRANSFORMERS].includes(type) ? TransformerVoltages : CableVoltages;

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [event.currentTarget.name]: event.currentTarget.checked }, offset: 0 },
        })
      );
    },
    [type, dispatch, filters]
  );

  return (
    <>
      <Row>
        <Col sm={9}>
          <Row style={{ rowGap: '0.5rem' }}>
            {!hideScenariosYear && (
              <Col xs={3}>
                <SelectScenarioYears
                  name="year"
                  value={filters?.year as number}
                  onChange={handleSelectChange}
                  labelKey=""
                  mutedTextLabelKey="Scenarios year"
                />
              </Col>
            )}
            {!hideVoltage && (
              <Col xs={3}>
                <SelectVoltage
                  name="voltage"
                  labelKey=""
                  mutedTextLabelKey="Voltage"
                  value={filters?.voltage}
                  voltages={voltages}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            {showVoltageSide && (
              <Col xs={3}>
                <SelectVoltageSide
                  name="voltageSide"
                  labelKey=""
                  mutedTextLabelKey="Voltage side"
                  value={filters?.voltageSide}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            {showVoltageDisplay && (
              <Col xs={3}>
                <SelectVoltageDisplay
                  name="voltageDisplay"
                  labelKey=""
                  mutedTextLabelKey="Voltage display"
                  value={filters?.voltageDisplay}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            {showPercentile && (
              <Col xs={3}>
                <SelectPercentile
                  name="percentile"
                  labelKey=""
                  value={filters?.percentile || null}
                  onChange={handleSelectChange}
                  isSearchable={false}
                  mutedTextLabelKey="Percentile"
                />
              </Col>
            )}
            {showBICalculation && (
              <Col xs={3}>
                <SelectBICalculation
                  name="BICalculation"
                  labelKey=""
                  mutedTextLabelKey="BI calculation"
                  value={filters?.BICalculation}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            {showFilterSolved && (
              <Col xs={3}>
                <SelectSolved
                  name="solvedValue"
                  mutedTextLabelKey="Filter solved"
                  value={filters?.solvedValue}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            {showLoadingUnit && (
              <Col xs={3}>
                <SelectLoadingUnit
                  name="show_current_values"
                  mutedTextLabelKey="Loading unit"
                  value={filters?.show_current_values}
                  onChange={handleSelectChange}
                />
              </Col>
            )}
            <Col xs="auto">
              <Checkbox
                labelKey="Flex"
                className="icheck-primary"
                name="flex"
                checked={filters?.flex}
                onChange={handleCheckboxClick}
              />
            </Col>
            {showOverloadedAssets && (
              <Col xs="auto">
                <Checkbox
                  labelKey="Show overloaded assets"
                  className="icheck-primary"
                  name="showOverloadedAssets"
                  checked={filters?.showOverloadedAssets}
                  onChange={handleCheckboxClick}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col sm={3} className="d-flex flex-column align-items-end">
          <ScenarioRunInfo />
        </Col>
      </Row>
    </>
  );
};

export default TableFilters;
