import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { Alert, Spinner, OverlayTriggerTooltip } from 'components/_common';
import { fetchDetailedPlanItemsAction } from 'modules/investment';
import { detailedPlanCurrencySelector } from 'modules/investment/selectors';
import TextOverflow from 'components/Map/common/AssetDetails/TextOverflow';
import { IconList } from '@utiligize/shared/resources';

interface Props {
  uuid: string;
}

const TabReinvestment: React.FC<Props> = ({ uuid }) => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Investment.DetailedInvestmentsItem[] | null>(null);
  const [activeIndex, setActiveIndex] = useState<any>(0);

  const { currency, rate } = useSelector(detailedPlanCurrencySelector);

  useEffect(() => {
    dispatch(fetchDetailedPlanItemsAction(uuid))
      .then(action => setItems(action.payload))
      .finally(() => setLoading(false));
  }, [dispatch, uuid]);

  const handleHeaderControlClick = useCallback((event: React.MouseEvent) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    setActiveIndex(index);
  }, []);

  if (loading) return <Spinner isInFullHeightContainer />;
  if (!items?.length) {
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;
  }

  return (
    <StyledContainer>
      {items?.length > 1 && (
        <StyledHeaderControls>
          {items.map(({ replacement_reason }, index) => (
            <OverlayTriggerTooltip
              key={replacement_reason}
              placement="top"
              overlayId={replacement_reason}
              overlayChildren={getIntl(replacement_reason)}
            >
              <StyledIconList
                width={18}
                height={18}
                $active={index === activeIndex}
                data-index={index}
                onClick={handleHeaderControlClick}
              />
            </OverlayTriggerTooltip>
          ))}
        </StyledHeaderControls>
      )}
      <StyledContent>
        {Object.entries({
          asset_name: 'Customer asset name',
          asset_category: 'Asset category',
          installation_year: 'Installation year',
          current_type: 'Current type',
          future_type: 'Future type',
          current_capacity: 'Current capacity',
          future_capacity: 'Future capacity',
          replacement_reason: 'Reason',
          replacement_cost: getIntl('Cost ({{currency}})', { currency }),
          suggested_replacement_year: 'Suggested year',
          savings_over_remaining_lifetime_current_asset: getIntl('Savings over the remaining lifetime ({{currency}})', {
            currency,
          }),
          primary_substation_name: 'Primary substation',
          grid_zone: 'Grid zone',
          voltage_level: 'Voltage level',
          department: 'Portfolio',
          co2e_t_per_year: 'CO₂e (t/year)',
        }).map(([key, labelKey]: [string, string]) => {
          const value = items[activeIndex][key as keyof Investment.DetailedInvestmentsItem] ?? '-';
          const label = getIntl(labelKey);
          return (
            <React.Fragment key={key}>
              <TextOverflow data-text={label}>{label}:</TextOverflow>
              <TextOverflow data-text={value}>
                {['replacement_cost', 'savings_over_remaining_lifetime_current_asset'].includes(key) &&
                typeof value === 'number'
                  ? numberFormat(value / rate, { minimumFractionDigits: 2, fallback: '-' })
                  : 'co2e_t_per_year' === key && typeof value === 'number'
                    ? numberFormat(value, { fallback: '-' })
                    : value}
              </TextOverflow>
            </React.Fragment>
          );
        })}
      </StyledContent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  font-size: 12px;
`;

const StyledHeaderControls = styled.div`
  margin-bottom: 7.5px;
`;

const StyledIconList = styled(IconList)<{ $active: boolean }>`
  fill: ${({ $active }) => ($active ? 'var(--map-active-color)' : 'var(--map-text-color-inactive)')};

  &:hover {
    cursor: pointer;
    fill: var(--map-active-color);
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  row-gap: 2px;

  > div:nth-child(2n) {
    text-align: right;
  }
`;

export default TabReinvestment;
