import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { customersRightsProfileModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { TableIgnoreMessages } from 'components/_tables';
import { AssetTimeSeriesChart } from 'components/_charts';
import { Modal, Tabs, Tab } from 'components/_common';
import { TimeSeriesTypes } from 'constants/index';

interface Props {
  outliersRemoved: boolean;
}

const ModalRightsProfile: React.FC<Props> = ({ outliersRemoved }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const id = useSelector(customersRightsProfileModalIdSelector);
  const [uuid, setUUID] = useState<string | null>(null);

  useEffect(() => {
    if (id) return setUUID(id);
    setTimeout(() => setUUID(null), 500);
  }, [id]);

  const handleCancelBtnClick = useCallback(
    () => dispatch(setLayoutAction({ customersRightsProfileModalId: null })),
    [dispatch]
  );

  return (
    <Modal
      size="xl"
      show={Boolean(id)}
      backdrop
      onHide={handleCancelBtnClick}
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCancelBtnClick }}
    >
      <>
        {!!uuid && (
          <Tabs mountOnEnter defaultActiveKey="electricityConsumption">
            <Tab eventKey="electricityConsumption" title={getIntl('Electricity consumption')}>
              <AssetTimeSeriesChart uuid={uuid!} type={TimeSeriesTypes.POWER} isFiltersDisabled />
            </Tab>
            <Tab eventKey="ignoreMessages" title={getIntl('Comments')}>
              <TableIgnoreMessages uuid={uuid} />
            </Tab>
          </Tabs>
        )}
      </>
    </Modal>
  );
};

export default ModalRightsProfile;
