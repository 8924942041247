import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, DateFormats, NodeAPI } from 'constants/index';

const responseTransformation = (res: any) => {
  const {
    maintenanceActionsByAssetCategories,
    inspectionsByAssetCategories,
    repairsByAssetCategories,
    finalChecks,
    finalTaskRelatedForm,
    toolInspectionPerYear,
    smartMeterActions,
  } = res.data;

  const completeActionsChartData = {
    categories: maintenanceActionsByAssetCategories.map(({ name }: { name: Type.AssetCategories }) => name),
    series: [
      {
        name: 'Maintenance',
        data: maintenanceActionsByAssetCategories.map((i: any) => i.actions),
      },
      {
        name: 'Inspections',
        data: inspectionsByAssetCategories.map((i: any) => i.actions),
      },
      {
        name: 'Repair',
        data: repairsByAssetCategories.map((i: any) => i.actions),
      },
    ],
    smartMeterActions,
  };

  const finalChecksUniqueCategoriesCodes = [
    ...new Set<Type.AssetCategories>(
      finalChecks.map(({ assetCategory }: { assetCategory: Type.AssetCategories }) => assetCategory)
    ),
  ];

  const finalChecksChartData = finalChecksUniqueCategoriesCodes.reduce(
    (acc: any, code: Type.AssetCategories) => {
      const { data, y } = finalChecks.reduce(
        (acc: { data: [string, number][]; y: number }, { actions, assetCategory, assetCode }: any) => {
          if (assetCategory === code) {
            acc.data.push([assetCode, Number(actions)]);
            acc.y += Number(actions);
          }
          return acc;
        },
        { data: [], y: 0 }
      );

      acc.seriesData.push({ name: code, y, drilldown: code });
      acc.drilldownSeries.push({ name: code, id: code, data });
      return acc;
    },
    { seriesData: [], drilldownSeries: [], finalTaskRelatedForm, toolInspectionPerYear }
  );

  return { completeActionsChartData, finalChecksChartData, isFetched: true };
};

export const fetchStatisticsActions: any = createAction(
  'statistics/FETCH_ACTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Statistics.Root, 'completeActionsChartData' | 'finalChecksChartData' | 'isFetched'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.MAINTENANCE_STATISTICS_USERS)(state);
      return NodeAPI.get('v1/secure/statistics', {
        params: {
          startDate: filters?.startTime?.format(DateFormats.SERVER),
          endDate: filters?.endTime?.format(DateFormats.SERVER),
        },
      }).then(responseTransformation);
    }
);

export const fetchCompletedActionsByUsers = createAction(
  'statistics/FETCH_USERS_ACTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Statistics.Root, 'completedActionsByUsersHash' | 'completedActionsByUsersCount'>> => {
      const state = getState();
      const { limit, offset, sort, query, filters } = paginationSelectorFactory(
        PaginationType.MAINTENANCE_STATISTICS_USERS
      )(state);
      return NodeAPI.get('v1/secure/statistics/users', {
        params: {
          startDate: filters?.startTime?.format(DateFormats.SERVER),
          endDate: filters?.endTime?.format(DateFormats.SERVER),
          limit,
          offset,
          sort,
          query,
        },
      }).then(res => ({
        completedActionsByUsersHash: _keyBy(res.data.rows, (item: Statistics.UserAction) => `_${item.id}_`),
        completedActionsByUsersCount: res.data.count,
      }));
    }
);

export const fetchUserChartActions: any = createAction(
  'statistics/FETCH_USER_ACTIONS',
  async (id: number) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Statistics.Root, 'completeActionsChartData' | 'finalChecksChartData' | 'isFetched'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.MAINTENANCE_STATISTICS_USERS)(state);
      return NodeAPI.get(`v1/secure/statistics/users/${id}`, {
        params: {
          startDate: filters?.startTime?.format(DateFormats.SERVER),
          endDate: filters?.endTime?.format(DateFormats.SERVER),
        },
      }).then(responseTransformation);
    }
);

export const resetModule: any = createAction('statistics/RESET_MODULE');
