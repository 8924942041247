import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale, useDebouncedCallback } from 'hooks';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import {
  detailedPlanCountSelector,
  detailedPlanCurrencySelector,
  detailedPlanItemsSelector,
} from 'modules/investment/selectors';
import { fetchDetailedPlanAction, updateDetailedPlanAction } from 'modules/investment';
import { AssetCode, DataTable, SelectScenarioYearsPossible } from 'components/_common';
import { getStorageItem, setStorageItem } from 'utils';
import {
  PaginationType,
  TableHeaders,
  StorageKeys,
  TableHeadersKeys,
  MapThemes,
  MapThemeGroups,
} from 'constants/index';

const TableDetailedInvestments: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const type = PaginationType.DETAILED_INVESTMENTS;

  const items = useSelector(detailedPlanItemsSelector);
  const count = useSelector(detailedPlanCountSelector);
  const { currency, rate } = useSelector(detailedPlanCurrencySelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  const sendRequest = useDebouncedCallback((params = { skipPagination: false, skipStoreUpdate: false }) =>
    dispatch(fetchDetailedPlanAction(params)).then(
      (action: Shared.ReduxAction<{ detailedPlanItems: Investment.Root['detailedPlanItems'] }>) =>
        action.payload?.detailedPlanItems
    )
  );

  const customHeaders = useMemo(() => {
    const isCurrencyExist = (key: string) => (key || '').includes('{{currency}}');
    return [
      ...TableHeaders[type].map(header => ({
        ...header,
        ...(isCurrencyExist(header.titleKey!) ? { title: getIntl(header.titleKey!, { currency }) } : {}),
        subTitles: header.subTitles?.map(i => ({
          ...i,
          ...(isCurrencyExist(i.titleKey!) ? { title: getIntl(i.titleKey!, { currency }) } : {}),
        })),
      })),
    ];
  }, [type, currency, getIntl]);

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      customHeaders={customHeaders}
      waitForDependencies={!portfolioId || !scenarioId || !simulationId}
      triggerTableUpdateDeps={[portfolioId, scenarioId, simulationId]}
      maxHeight="calc(100vh - 342px)"
      isResetFiltersButtonEnabled
      isSettingsButtonEnabled
    >
      {items?.map?.((item: Investment.DetailedInvestmentsItem, index: number) => (
        <TableItem key={item.asset_name} {...item} type={type} rate={rate} index={index} />
      ))}
    </DataTable>
  );
};

const TableItem: React.FC<
  Investment.DetailedInvestmentsItem & { type: PaginationType.DETAILED_INVESTMENTS; rate: number; index: number }
> = ({
  type,
  rate,
  index,
  id,
  asset_name,
  suggested_replacement_year,
  asset_category,
  installation_year,
  current_type,
  future_type,
  current_capacity,
  future_capacity,
  replacement_reason,
  replacement_cost,
  latest_replacement_year,
  savings_over_remaining_lifetime_current_asset,
  primary_substation_name,
  grid_zone,
  voltage_level,
  department,
  co2e_t_per_year,
}) => {
  const { getIntl, numberFormat } = useLocale();
  const Keys = TableHeadersKeys[type]!;
  const { columns }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleMapLinkClick = useCallback(() => {
    setStorageItem({
      [StorageKeys.MAP_CACHE]: {
        ...(getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || {}),
        theme: MapThemes.YEARLY_REPLACEMENTS,
        themeGroup: MapThemeGroups.REINVESTMENT,
      },
    });
  }, []);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption<number>) => {
      setLoading(true);
      dispatch(
        updateDetailedPlanAction({
          asset_id: id,
          nl_replacement_year: suggested_replacement_year,
          user_replacement_year: option.value,
        })
      ).finally(() => setLoading(false));
    },
    [dispatch, id, suggested_replacement_year]
  );

  return (
    <tr>
      {columns?.includes(Keys.asset_name) && (
        <td>
          <AssetCode
            uuid={id}
            code={asset_name}
            onClick={handleMapLinkClick}
            state={{
              keepMapTheme: true,
              year: suggested_replacement_year,
              theme: MapThemes.YEARLY_REPLACEMENTS,
            }}
          />
        </td>
      )}
      {columns?.includes(Keys.asset_category) && <td>{asset_category || '-'}</td>}
      {columns?.includes(Keys.installation_year) && <td>{installation_year || '-'}</td>}
      {columns?.includes(Keys.current_type) && <td>{current_type || '-'}</td>}
      {columns?.includes(Keys.future_type) && <td>{future_type || '-'}</td>}
      {columns?.includes(Keys.current_capacity) && <td>{current_capacity || '-'}</td>}
      {columns?.includes(Keys.future_capacity) && <td>{future_capacity || '-'}</td>}
      {columns?.includes(Keys.replacement_reason) && <td>{replacement_reason ? getIntl(replacement_reason) : '-'}</td>}
      {columns?.includes(Keys.replacement_cost) && (
        <td className="text-right">
          {numberFormat(replacement_cost && replacement_cost / rate, {
            minimumFractionDigits: 2,
            fallback: '-',
          })}
        </td>
      )}
      {columns?.includes(Keys.suggested_replacement_year) && (
        <td>
          <SelectScenarioYearsPossible
            name="years"
            labelKey=""
            value={suggested_replacement_year}
            onChange={handleSelectChange}
            isDisabled={loading}
            onlyFuture
            skipFetch={index !== 0}
          />
        </td>
      )}
      {columns?.includes(Keys.latest_replacement_year) && <td>{latest_replacement_year || '-'}</td>}
      {columns?.includes(Keys.savings_over_remaining_lifetime_current_asset) && (
        <td>
          {numberFormat(
            savings_over_remaining_lifetime_current_asset && savings_over_remaining_lifetime_current_asset / rate,
            {
              minimumFractionDigits: 2,
              fallback: '-',
            }
          )}
        </td>
      )}
      {columns?.includes(Keys.primary_substation_name) && <td>{primary_substation_name || '-'}</td>}
      {columns?.includes(Keys.grid_zone) && <td>{grid_zone || '-'}</td>}
      {columns?.includes(Keys.voltage_level) && <td>{voltage_level || '-'}</td>}
      {columns?.includes(Keys.department) && <td>{department || '-'}</td>}
      {columns?.includes(Keys.co2e_t_per_year) && <td>{numberFormat(co2e_t_per_year, { fallback: '-' })}</td>}
    </tr>
  );
};

export default TableDetailedInvestments;
