import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale, usePaginationHandlers } from 'hooks';
import {
  cabinetsSelector,
  cabinetsCountSelector,
  tableTransformersCablesCabinetsFusesWaitForDependenciesSelector,
} from 'modules/networkLoading/selectors';
import { fetchCabinetsAction } from 'modules/networkLoading';
import { setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button, SolvedOption, AssetCode } from 'components/_common';
import { PaginationType, ProfileModalTypes, TableHeadersKeys } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableCabinets: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.NETWORK_LOADING_CABINETS;
  const { columns, filters } = usePaginationHandlers({ type });
  const Keys = TableHeadersKeys[type];
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  const items = useSelector(cabinetsSelector);
  const count = useSelector(cabinetsCountSelector);
  const waitForDependencies = useSelector(tableTransformersCablesCabinetsFusesWaitForDependenciesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchCabinetsAction(params)).then(
        (action: Shared.ReduxAction<Pick<NetworkLoading.Root, 'cabinetsHash'>>) =>
          Object.values(action.payload?.cabinetsHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(
        setLayoutAction({
          profileModalMeta: { id: assetName, type: ProfileModalTypes.Cabinet },
          ...(filters?.year ? { selectedChartYear: filters?.year } : {}),
        })
      );
    },
    [dispatch, filters?.year]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={waitForDependencies}
      triggerTableUpdateDeps={[portfolioId, scenarioId, simulationId]}
      maxHeight="calc(100vh - 282px)"
      isSettingsButtonEnabled
    >
      {items?.map((item: NetworkLoading.Cabinet) => (
        <tr key={item.id}>
          {columns?.includes(Keys.customer_asset_name) && (
            <td>
              <AssetCode uuid={item.id} code={item.customer_asset_name} />
            </td>
          )}
          {columns?.includes(Keys.date_commissioned) && (
            <td>{item.date_commissioned ? dateFormat(item.date_commissioned) : '-'}</td>
          )}
          {columns?.includes(Keys.ignore) && (
            <td className="text-center">
              <SolvedOption uuid={item.id} checked={item.ignore} paginationType={type} />
            </td>
          )}
          {columns?.includes(Keys.explanation) && <td>{item.explanation || '-'}</td>}
          {columns?.includes(Keys.max_loading_time) && <td>{dateFormat(item.max_loading_time, 'L LT')}</td>}
          {columns?.includes(Keys.utilization) && <td>{numberFormat(item.utilization)}</td>}
          {columns?.includes(Keys.voltage_min) && <td>{item.voltage_min ?? '-'}</td>}
          {columns?.includes(Keys.voltage_max) && <td>{item.voltage_max ?? '-'}</td>}
          {columns?.includes(Keys.ikss_ka_min) && <td>{item.ikss_ka_min ?? '-'}</td>}
          {columns?.includes(Keys.ikss_ka_max) && <td>{item.ikss_ka_max ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva_min) && <td>{item.skss_mva_min ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva_max) && <td>{item.skss_mva_max ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_degrees_min) && <td>{item.sc_angle_degrees_min ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_degrees_max) && <td>{item.sc_angle_degrees_max ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva) && <td>{item.skss_mva ?? '-'}</td>}
          <td className="text-right">
            <Button
              dataMarker="actions_profile"
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCabinets;
