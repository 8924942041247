import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { PageSmartMeterDetails } from 'pages';
import { SmartMeterActionsTable, MetersPopulationTable } from 'components/_tables';
import { MetersPopulationModal } from 'components/_modals';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageMeters: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isSmartMetersEnabled}>
    <RequiredScenarioCheck>
      <RequiredSimulationCheck>
        <Switch>
          <Route path={Routes.MetersPopulation}>
            <>
              <MetersPopulationModal />
              <MetersPopulationTable />
            </>
          </Route>
          {/* Routes.Meters route must be specified last  */}
          <Route
            exact
            path={Routes.Meters}
            render={
              ((props: RouteComponentProps<{ id: string }>) =>
                props.match.params.id ? <PageSmartMeterDetails {...props} /> : <SmartMeterActionsTable />) as any
            }
          />
        </Switch>
      </RequiredSimulationCheck>
    </RequiredScenarioCheck>
  </PagePermissionsProvider>
);

export default PageMeters;
