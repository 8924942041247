import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { isNKTenant } from 'utils';
import { PaginationType, NodeAPI } from 'constants/index';

export const fetchTaskMaterialsAction: any = createAction(
  'task-materials/FETCH_MATERIALS',
  async (
    {
      skipPagination,
      skipStoreUpdate,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'materialsCount' | 'materialsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.TASKS_MATERIALS)(state);
      return NodeAPI.get('v1/secure/materials', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
          lang: appLangSelector(state).toLowerCase(),
        },
      }).then(res => ({
        materialsCount: res.data.count,
        materialsHash: _keyBy(res.data.rows, (item: Tasks.Material) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const createTaskMaterialAction: any = createAction(
  'task-materials/CREATE_MATERIAL',
  async (data: Tasks.Material) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.post('v1/secure/materials', data).then(async () => {
        await dispatch(fetchTaskMaterialsAction());
        dispatch(setSuccessToastAction('Material has been created'));
      });
  }
);

export const editTaskMaterialAction: any = createAction(
  'task-materials/EDIT_MATERIAL',
  async (id: number, data: Type.TaskChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.put(`v1/secure/materials/${id}`, data).then(async () => {
        await dispatch(fetchTaskMaterialsAction());
        dispatch(setSuccessToastAction('Material has been saved'));
      });
  }
);

export const deleteTaskMaterialAction: any = createAction('task-materials/DELETE_MATERIAL', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    NodeAPI.delete(`v1/secure/materials/${id}`).then(async () => {
      await dispatch(fetchTaskMaterialsAction());
      dispatch(setSuccessToastAction('Material has been deleted'));
    });
});

const groupAndSummarize = (data: Required<Tasks.Material>[]) => {
  return Object.values(
    data.reduce((acc: { [key: string]: Omit<Tasks.Material, 'name' | 'code'> }, item) => {
      const key = `${item.date}|${item.type}|${item.unit}|${item.description}`;

      if (!acc[key]) acc[key] = { ...item, quantity: 0, cost: 0 };
      acc[key].quantity! += item.quantity!;
      acc[key].cost! += item.cost!;

      return acc;
    }, {})
  ).sort((a, b) => (b.date && a.date ? new Date(b.date).getTime() - new Date(a.date).getTime() : 0));
};

export const fetchMaterialsByTaskIDAction = createAction(
  'task-materials/FETCH_TASK_MATERIALS_BY_TASK_ID',
  async (id: number) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'taskMaterialsCount' | 'taskMaterials'>> => {
      return NodeAPI.get(`v2/secure/tasks/${id}/materials`).then(res => ({
        taskMaterialsCount: res.data.count,
        taskMaterials: isNKTenant ? groupAndSummarize(res.data.rows) : res.data.rows,
      }));
    }
);

export const uploadMaterialsExcelAction = createAction(
  'task-materials/UPLOAD_EXCEL',
  (values: Type.ModalUploadMaterialsValues) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      const formData: FormData = new FormData();
      formData.append('file', values.file as Blob);
      return NodeAPI.post(`v1/secure/materials/excel?replace=${values.replace}`, formData, {
        isErrorOnlyForModal: true,
      } as any).then(async () => {
        await dispatch(fetchTaskMaterialsAction());
        dispatch(setSuccessToastAction('Materials excel has been uploaded'));
      });
    }
);
