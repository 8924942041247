import React, { useCallback, useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useLocale, useToggle, useUsers, useBuilderState, useEventPreventedExec } from 'hooks';
import {
  Button,
  SelectAssetCategories,
  FormInput,
  Modal,
  HiddenFormSubmit,
  SelectAssetCodes,
  SelectModels,
} from 'components/_common';
import { IconEdit, IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

interface Props {
  isEditMode?: boolean;
}

const InstructionsModal: React.FC<Props> = ({ isEditMode = false }) => {
  const { getIntl, dateFormat } = useLocale();
  const { usersIds } = useUsers();
  const [show, toggleModal] = useToggle();
  const { snapshots } = useBuilderState();
  const formik = useConfiguredFormik({ isEditMode, toggleModal, usersIds });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleCancelButtonClick = useCallback(() => {
    toggleModal();
    resetForm();
  }, [toggleModal, resetForm]);

  const handleAssetCategoriesSelectChange = useCallback(
    (assetCategory: Asset.Category) => {
      setValues({
        ...values,
        assetCategory,
        assetCodes: [],
        modelIds: [],
      });
    },
    [values, setValues]
  );

  const revisions = useMemo(() => {
    if (!snapshots) return null;
    return snapshots.splice(0, 1).map((item: Builder.Snapshot) => <p key={item.id}>{dateFormat(item.createdAt)}</p>);
  }, [snapshots, dateFormat]);

  const handleAssetCodesSelectChange = useCallback(
    (options: Type.SelectOption[] | null) =>
      setFieldValue(
        'assetCodes',
        options?.map(o => o.value)
      ),
    [setFieldValue]
  );

  return (
    <>
      <Button
        className={isEditMode ? 'ml-auto mr-2' : ''}
        variant={isEditMode ? undefined : 'primary'}
        icon={isEditMode ? <IconEdit /> : <IconPlus />}
        labelKey={isEditMode ? '' : 'Create instruction'}
        onClick={toggleModal}
      />
      <Modal
        show={show}
        onHide={handleCancelButtonClick}
        titleKey={getIntl(isEditMode ? 'Instruction Options' : 'Create instruction')}
        cancelButtonProps={{
          onClick: handleCancelButtonClick,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Row>
            {isEditMode && (
              <Form.Group as={Col} sm={2} className="border-right">
                <Form.Text>{getIntl('Revisions')}</Form.Text>
                {revisions}
              </Form.Group>
            )}
            <Col sm={isEditMode ? 10 : 12} className={isEditMode ? 'pl-4' : ''}>
              <Form.Group>
                <FormInput
                  autoFocus
                  labelKey="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <SelectAssetCategories
                    value={values.assetCategory?.code || ''}
                    fullObject
                    onChange={handleAssetCategoriesSelectChange as any}
                    onBlur={() => setFieldTouched('assetCategory')}
                    errorKey={
                      Boolean(touched.assetCategory && errors.assetCategory) ? (errors.assetCategory as string) : ''
                    }
                    isClearable
                    excludeSyntheticOptions
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <SelectAssetCodes
                    values={values.assetCodes}
                    onChange={handleAssetCodesSelectChange}
                    onBlur={() => setFieldTouched('assetCodes')}
                    errorKey={Boolean(touched.assetCodes && errors.assetCodes) ? (errors.assetCodes as string) : ''}
                    isDisabled={Boolean(!values.assetCategory?.code || values.modelIds.length)}
                    isMulti
                    assetCategoryCode={values.assetCategory?.code || null}
                  />
                </Form.Group>
              </Form.Row>
              <SelectModels
                isDisabled={Boolean(!values.assetCategory?.code || values.assetCodes.length)}
                modelIds={values.modelIds}
                setValue={modelIds => setFieldValue('modelIds', modelIds)}
                assetCategoryCode={values.assetCategory?.code || null}
              />
            </Col>
          </Form.Row>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default InstructionsModal;
