import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale, usePaginationHandlers } from 'hooks';
import { issuesLastUpdateSelector } from 'modules/dataQuality/selectors';
import { ScenarioRunInfo } from 'components';
import SelectIssuesStatus from './SelectIssuesStatus';
import TableIssues from './TableIssues';
import { DataTableTemplate, SelectVoltageLevel } from 'components/_common';
import { PaginationType } from 'constants/index';

const ViewIssues: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const type = PaginationType.ISSUES;
  const dataQualityLastUpdate: string | null = useSelector(issuesLastUpdateSelector);
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectIssuesStatus
            name="dataQualityIssuesStatus"
            mutedTextLabelKey="Status"
            value={filters?.dataQualityIssuesStatus}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs={4}>
          <SelectVoltageLevel
            value={filters?.voltageLevels ?? null}
            onChange={handleSelectChange}
            mutedTextLabelKey="Voltage level"
            isMulti
            isClearable={false}
            disableMultiValueRemoveForLastOption
          />
        </Col>
        <Col xs="auto" className="d-flex ml-auto">
          <ScenarioRunInfo>
            <span className="text-muted">{getIntl('Last update')}:</span>{' '}
            <span className="text-bold">{dataQualityLastUpdate ? dateFormat(dataQualityLastUpdate, 'L LT') : '-'}</span>
          </ScenarioRunInfo>
        </Col>
      </Row>
      <TableIssues />
    </DataTableTemplate>
  );
};

export default ViewIssues;
