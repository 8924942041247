import mapboxgl from 'mapbox-gl';
import React, { useEffect, useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { MapContext } from 'context/map';
import { useSelector, useDispatch } from 'react-redux';
import { mapStateAction } from 'modules/map';
import { mapStateEnabledLayersSelector } from 'modules/map/selectors';
import { setLayoutAction } from 'modules/layouts';
import { isMapConnectionsEnabledSelector } from 'modules/layouts/selectors';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import { NewCustomersLegend } from 'components/Map/core/layers/other/NewCustomers';
import { Button } from 'components/_common';
import { IconUsers } from '@utiligize/shared/resources';
import { setCursor } from './helpers';
import ModalAddCustomer from './ModalAddCustomer';
import { hideLayer, showLayer } from 'utils/map';

const ButtonAddCustomer: React.FC = () => {
  const { map } = useContext(MapContext);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  const isMapConnectionsEnabled = useSelector(isMapConnectionsEnabledSelector);
  const enabledLayers = useSelector(mapStateEnabledLayersSelector);
  const isPermissionsEnabled =
    appCurrentUserPermissions.isNewLoadEnabled && !appCurrentUserPermissions.isAdminPanelReadOnly;

  const hoverPopup = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false }).setText(
      getIntl('Please select a transformer, cabinet or customer')
    )
  );

  const allowedLayersList = useMemo(
    () => [
      'asset__primary__transformers_voltage_3',
      'asset__secondary__transformers_voltage_2',
      'asset__cabinets_voltage_1',
      'other__customers_ico',
    ],
    []
  );

  const handleMapMouseEnterLeaveClickEvents = useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => {
      if (!map) return;
      const isMouseEnterEvent = e.type === 'mouseenter';
      const isMouseLeaveEvent = e.type === 'mouseleave';
      const isMouseClickEvent = e.type === 'click';
      setCursor(map, isMouseEnterEvent ? 'pointer' : 'not-allowed');

      if (isMouseEnterEvent) hoverPopup.current.remove();
      if (isMouseLeaveEvent && !hoverPopup.current.isOpen()) {
        hoverPopup.current.setLngLat(e.lngLat).addTo(map);
      }

      if (isMouseClickEvent) {
        // Check if layers exist in the map style
        const layers = allowedLayersList.filter(layer => map.getLayer(layer));

        if (layers.length === 0) {
          console.warn('No valid layers found for querying features.');
          return;
        }

        const [feature] = map.queryRenderedFeatures(e.point, { layers });
        dispatch(setLayoutAction({ mapAddConnectionMeta: feature, isMapConnectionsEnabled: false }));
      }
    },
    [map, dispatch, allowedLayersList, hoverPopup]
  );

  const handleMapMouseMoveEvents = useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => {
      if (hoverPopup.current.isOpen()) hoverPopup.current.setLngLat(e.lngLat);
    },
    [hoverPopup]
  );

  useEffect(() => {
    if (!map || !isMapConnectionsEnabled) return;
    const popup = hoverPopup.current;

    setCursor(map, 'not-allowed');
    popup.addTo(map);

    map.on('mouseenter', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
    map.on('mouseleave', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
    map.on('click', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
    map.on('mousemove', handleMapMouseMoveEvents);

    return () => {
      map.off('mouseenter', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
      map.off('mouseleave', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
      map.off('click', allowedLayersList, handleMapMouseEnterLeaveClickEvents);
      map.off('mousemove', handleMapMouseMoveEvents);

      setCursor(map, 'pointer');
      popup.setLngLat([0, 0]).remove();
    };
  }, [map, isMapConnectionsEnabled, handleMapMouseEnterLeaveClickEvents, handleMapMouseMoveEvents, allowedLayersList]);

  const handleButtonClick = useCallback(() => {
    dispatch(setLayoutAction({ isMapConnectionsEnabled: !isMapConnectionsEnabled, isExtendedGridEnabled: false }));
    const action = !isMapConnectionsEnabled ? showLayer : hideLayer;
    if (map) action(map, NewCustomersLegend.name);
    dispatch(
      mapStateAction({
        enabledLayers: {
          ...enabledLayers,
          // Automatically enable new_customers layer
          ...(!enabledLayers?.[NewCustomersLegend.name] &&
            !isMapConnectionsEnabled && {
              [NewCustomersLegend.name]: !isMapConnectionsEnabled,
            }),
        },
      })
    );
  }, [dispatch, isMapConnectionsEnabled, enabledLayers, map]);

  return (
    <>
      <Button
        variant="light"
        className={classNames('mb-1', { active: isMapConnectionsEnabled, 'cursor-not-allowed': !isPermissionsEnabled })}
        onClick={handleButtonClick}
        icon={<IconUsers />}
        tooltipKey={isPermissionsEnabled ? 'Add customer' : 'You do not have permission to add customers'}
        tooltipPlacement="left"
        disabled={!isPermissionsEnabled}
      />
      {isPermissionsEnabled && <ModalAddCustomer />}
    </>
  );
};

export default ButtonAddCustomer;
