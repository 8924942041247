import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setInfoToastAction } from 'modules/layouts';
import { Checkbox, Alert, Spinner } from 'components/_common';

interface Props<ChecklistType> {
  labelKey?: string;
  actionDisabledMessage?: string;
  isTaskChecklist?: boolean;
  isHistoryChecklist?: boolean;
  id: number;
  fetchChecklistAction: (id: number) => Promise<Shared.ReduxAction<Record<string, ChecklistType>>>;
  updateChecklistAction: (id: number, checklist: ChecklistType[]) => Promise<void>;
}

const Checklist = <ChecklistType,>({
  labelKey = 'Checklist',
  actionDisabledMessage = '',
  id,
  isTaskChecklist = false,
  isHistoryChecklist = false,
  fetchChecklistAction,
  updateChecklistAction,
}: Props<ChecklistType>) => {
  const dispatch = useDispatch();
  const { getIntl } = useLocale();
  const [fetched, setFetched] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [checklistHash, setChecklistHash] = useState<Record<string, ChecklistType>>({});

  useEffect(() => {
    dispatch(fetchChecklistAction(id)).then((action: Shared.ReduxAction<Record<string, ChecklistType>>) => {
      setChecklistHash(action.payload || {});
      setFetched(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChecklistCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (actionDisabledMessage) return dispatch(setInfoToastAction("You can't update checklist for unfinished task"));
      const itemId: number = Number(event.currentTarget.getAttribute('data-index'));
      const { checked } = event.currentTarget;
      const updatedChecklistHash = {
        ...checklistHash,
        [itemId]: {
          ...(checklistHash as any)[itemId],
          [isTaskChecklist || isHistoryChecklist ? 'value' : 'status']: checked,
        },
      };
      setUpdating(true);
      dispatch(updateChecklistAction(id, Object.values(updatedChecklistHash)))
        .then((): void => setChecklistHash(updatedChecklistHash))
        .catch(console.error)
        .finally((): void => setUpdating(false));
    },
    [id, isTaskChecklist, isHistoryChecklist, dispatch, checklistHash, updateChecklistAction, actionDisabledMessage]
  );

  const renderCheckboxes = (): React.ReactNode => {
    if (!fetched) return <Spinner inline />;
    const checklist = Object.values(checklistHash || {});

    if (!checklist.length) {
      return (
        <Alert className="mb-0 text-center h-100" variant="light">
          {getIntl('There is no assigned checklist')}
        </Alert>
      );
    }

    return checklist.map((item: any) => (
      <li
        key={
          isTaskChecklist ? item.taskCheckListQuestionId : isHistoryChecklist ? item.assetCheckListQuestionId : item.id
        }
        className={classNames('d-flex align-items-center', {
          done: isTaskChecklist || isHistoryChecklist ? item.value : item.status,
        })}
      >
        <Checkbox
          dataIndex={
            isTaskChecklist
              ? item.taskCheckListQuestionId
              : isHistoryChecklist
                ? item.assetCheckListQuestionId
                : item.id
          }
          className="icheck-primary d-inline"
          checked={Boolean(isTaskChecklist || isHistoryChecklist ? item.value : item.status)}
          onChange={handleChecklistCheckboxClick}
          disabled={updating}
        />
        <span className="text">{isTaskChecklist || isHistoryChecklist ? item.question : getIntl(item.key)}</span>
      </li>
    ));
  };

  return (
    <>
      {labelKey && (
        <>
          <h6 className="text-uppercase">{getIntl(labelKey)}</h6>
          <hr className="mt-0" />
        </>
      )}
      <ul className="todo-list position-relative">{renderCheckboxes()}</ul>
    </>
  );
};

export default Checklist;
