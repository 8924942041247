import styled from 'styled-components';
import React, { useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value'> {
  value: string | null;
}

const SelectEvolutionType: React.FC<Props> = ({ value = null, options, ...props }) => {
  const selectValue = useMemo(
    () => options.find((option: Type.SelectOption<string>) => option.value === value),
    [options, value]
  );

  return <StyledFormReactSelect value={selectValue} options={options} isSearchable={false} {...props} />;
};

const StyledFormReactSelect = styled(FormReactSelect)`
  min-width: 110px;
`;

export default SelectEvolutionType;
