import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { useLocale } from 'hooks';
import { setLangAction } from 'modules/app';
import { FormReactSelect } from 'components/_common';
import { Languages, Routes } from 'constants/index';
import {
  IconDenmarkFlag,
  IconBritishFlag,
  IconFrenchFlag,
  IconGermanFlag,
  IconItalianFlag,
  IconNorwegianFlag,
  IconSwedishFlag,
} from '@utiligize/shared/resources';

const LanguageLabelMap: { [key: string]: React.ReactNode } = {
  [Languages.EN]: 'English',
  [Languages.DA]: 'Danish',
  [Languages.DE]: 'German',
  [Languages.FR]: 'French',
  [Languages.IT]: 'Italian',
  [Languages.NO]: 'Norwegian',
  [Languages.SV]: 'Swedish',
};

const LanguageIconMap: { [key: string]: React.ReactNode } = {
  [Languages.EN]: <IconBritishFlag width={19} height={19} />,
  [Languages.DA]: <IconDenmarkFlag width={19} height={19} />,
  [Languages.DE]: <IconGermanFlag width={19} height={19} />,
  [Languages.FR]: <IconFrenchFlag width={19} height={19} />,
  [Languages.IT]: <IconItalianFlag width={19} height={19} />,
  [Languages.NO]: <IconNorwegianFlag width={19} height={19} />,
  [Languages.SV]: <IconSwedishFlag width={19} height={19} />,
};

const SelectLanguage: React.FC = () => {
  const { lng, getIntl } = useLocale();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const getLabel = useCallback(
    (lng: App.Languages) => (
      <span className="d-flex align-items-center">
        {LanguageIconMap[lng]}&nbsp;&nbsp;{getIntl(LanguageLabelMap[lng] as string)}
      </span>
    ),
    [getIntl]
  );

  const options = useMemo(
    () => Object.values(Languages).map(lng => ({ value: lng, label: getLabel(lng) })),
    [getLabel]
  );

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      dispatch(setLangAction(option.value as App.Languages));
      if (matchPath(pathname, Routes.Map)) window.location.replace(window.location.origin);
    },
    [dispatch, pathname]
  );

  return (
    <FormReactSelect
      labelKey="Select language"
      value={{ value: lng, label: getLabel(lng) }}
      options={options}
      onChange={handleSelectChange}
      isSearchable={false}
    />
  );
};

export default SelectLanguage;
