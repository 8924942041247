import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { NodeAPI, PaginationType } from 'constants/index';
import { setSuccessToastAction } from 'modules/layouts';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchHistoryAction: any = createAction(
  'history/FETCH_HISTORY',
  async (
    {
      uuid,
      assetCode,
      portfolioId,
      versionId,
      skipPagination,
      queryInput,
    }: {
      uuid: string;
      assetCode: string;
      portfolioId: Layouts.Root['portfolioId'];
      versionId: number;
      skipPagination: boolean;
      queryInput: string;
    } = {} as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<History.Root, 'historyCount' | 'historyItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.HISTORY)(state);
      return NodeAPI.get('v2/secure/histories', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? queryInput || null : query,
          lang: appLangSelector(state).toLowerCase(),
          filters: (uuid && { uuid }) || (assetCode && { assetCode }) || filters,
          portfolioId,
          versionId,
        },
      }).then(res => ({ historyCount: res.data.total, historyItems: res.data.items }));
    }
);

export const fetchHistoryDetailsAction = createAction(
  'history/FETCH_HISTORY_DETAILS',
  ({ id, portfolioId, versionId }: { id: number; portfolioId: number; versionId: number }) =>
    (): Promise<History.Item> => {
      return NodeAPI.get(`v1/secure/histories/${id}`, { params: { portfolioId, versionId } }).then(res => res.data);
    }
);

export const fetchHistoryChecklistsAction: any = createAction(
  'history/FETCH_CHECKLISTS',
  async (
    {
      skipPagination,
      skipStoreUpdate,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<History.Root, 'checklistsCount' | 'checklistsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.HISTORY_CHECKLISTS)(
        state
      );
      return NodeAPI.get('v1/secure/histories/checkLists', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
        },
      }).then(res => ({
        checklistsCount: res.data.count,
        checklistsHash: _keyBy(res.data.rows, (item: History.Checklist) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const createHistoryChecklistAction: any = createAction(
  'history/CREATE_CHECKLIST',
  (data: Type.HistoryChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.post('v1/secure/histories/checkLists', data).then(async () => {
        await dispatch(fetchHistoryChecklistsAction());
        dispatch(setSuccessToastAction('Checklist has been created'));
      });
  }
);

export const editHistoryChecklistAction: any = createAction(
  'history/EDIT_CHECKLIST',
  (id: number, data: Type.HistoryChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      NodeAPI.put(`v1/secure/histories/checkLists/${id}`, data).then(async () => {
        await dispatch(fetchHistoryChecklistsAction());
        dispatch(setSuccessToastAction('Checklist has been saved'));
      });
  }
);

export const deleteHistoryChecklistAction: any = createAction('history/DELETE_CHECKLIST', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    NodeAPI.delete(`v1/secure/histories/checkLists/${id}`).then(async () => {
      await dispatch(fetchHistoryChecklistsAction());
      dispatch(setSuccessToastAction('Checklist has been deleted'));
    });
});

export const fetchHistoryChecklistAction: any = createAction('history/FETCH_CHECKLIST', async (id: number) => {
  return (): Promise<Record<History.ChecklistAnswer['assetCheckListQuestionId'], History.ChecklistAnswer>> =>
    NodeAPI.get(`v1/secure/histories/${id}/checkListAnswers`).then(res =>
      _keyBy(res.data.questions, 'assetCheckListQuestionId')
    );
});

export const updateHistoryChecklistAction: any = createAction(
  'history/UPDATE_CHECKLIST',
  (id: number, checklist: History.ChecklistAnswer[]) => {
    return (): Promise<void> => NodeAPI.put(`v1/secure/histories/${id}/checkListAnswers`, checklist);
  }
);
