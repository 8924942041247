import React from 'react';
import { Matrix } from 'components/_charts';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import { PermissionsTypes } from 'constants/index';

const PageMonetizedRisk: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isMonetizedRiskEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <Matrix />
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageMonetizedRisk;
