export const renderOtherElectricityNetwork = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        type: 'fill',
        group: legendItem.group,
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: legendItem.name,
          hasData: true,
          type: 'fill',
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'fill-opacity': 0.3,
            'fill-outline-color': 'rgba(0,0,0,0)',
            'fill-color': [
              'match',
              ['get', 'gid'],
              1,
              '#a9a9a9',
              2,
              '#2f4f4f',
              3,
              '#556b2f',
              4,
              '#9b644b',
              5,
              '#228b22',
              6,
              '#864141',
              7,
              '#3f3f77',
              8,
              '#808000',
              9,
              '#3cb371',
              10,
              '#008b8b',
              11,
              '#4682b4',
              12,
              '#d2691e',
              13,
              '#9acd32',
              14,
              '#37378b',
              15,
              '#499149',
              16,
              '#ddb551',
              17,
              '#8fbc8f',
              18,
              '#8b008b',
              19,
              '#b35175',
              20,
              '#f88359',
              21,
              '#51d9db',
              22,
              '#63ec63',
              23,
              '#00fa9a',
              24,
              '#a269d8',
              25,
              '#c74761',
              26,
              '#f4a460',
              27,
              '#7b7bff',
              28,
              '#93b65e',
              29,
              '#a500a5',
              30,
              '#1e90ff',
              31,
              '#f0e68c',
              32,
              '#fa8072',
              33,
              '#ffff54',
              34,
              '#dda0dd',
              35,
              '#7b68ee',
              36,
              '#afeeee',
              37,
              '#ee82ee',
              38,
              '#87cefa',
              39,
              '#7fffd4',
              40,
              '#ff69b4',
              41,
              '#ffe4c4',
              42,
              '#ffc0cb',
              // If no type mathed then it will be:
              '#370644',
            ],
          },
        },
      ],
    },
  };
  return [style];
};
