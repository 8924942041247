import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { MapContext } from 'context/map';
import { mapStateAction } from 'modules/map';
import { mapStateEnabledLayersSelector, mapStateSatelliteVisibilitySelector } from 'modules/map/selectors';
import { showLayer, hideLayer } from 'utils/map';
import { Button } from 'components/_common';
import { IconSatellite } from '@utiligize/shared/resources';

const SatelliteButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { map } = useContext(MapContext);

  const satelliteVisibility = useSelector(mapStateSatelliteVisibilitySelector);
  const enabledLayers = useSelector(mapStateEnabledLayersSelector);

  const handleButtonClick = useCallback(() => {
    if (!map) return;
    const visibility = map.getLayoutProperty('mapbox-satellite', 'visibility');
    const state = visibility === 'visible' ? 'none' : 'visible';
    const action = state === 'visible' ? showLayer : hideLayer;
    action(map, 'mapbox-satellite');
    dispatch(
      mapStateAction({
        satelliteVisibility: !satelliteVisibility,
        enabledLayers: {
          ...enabledLayers,
          'mapbox-satellite': !satelliteVisibility,
        },
      })
    );
  }, [map, dispatch, enabledLayers, satelliteVisibility]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: satelliteVisibility })}
      onClick={handleButtonClick}
      icon={<IconSatellite />}
      tooltipKey={satelliteVisibility ? 'Hide satellite view' : 'Show satellite view'}
      tooltipPlacement="left"
    />
  );
};

export default SatelliteButton;
