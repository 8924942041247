import { useFormik, FormikProps as Props } from 'formik';
import * as Yup from 'yup';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { createAssetAction, updateAssetAction } from 'modules/assets';
import { AssetCategories, Routes } from 'constants/index';

const baseAssetCodeKeys: (keyof Asset.Item)[] = ['id', 'comments', 'dateDeployed', 'risk', 'replacementDate', 'models'];

export const AssetsFields: Record<string, (keyof Asset.Item)[]> = {
  [AssetCategories.TH]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
  ],
  [AssetCategories.TR]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'switchGroup',
    'shortCircuitImpedanceEk',
    'kva',
    'manufacturingNumber',
    'dateManufactured',
    'circuitBreakerManufacturer',
    'lowVoltageFuse',
    'lowVoltageNominalCurrent',
  ],
  [AssetCategories.MV]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.LV]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.RT]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.KS]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'address',
    'serialNumber',
  ],
  [AssetCategories.BD]: [
    'name',
    'elInTablID',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'dateManufactured',
    'monthsBetweenInspectionsCH',
    'serialNumber',
    'meteringPoint',
  ],
  [AssetCategories.TS]: [
    ...baseAssetCodeKeys,
    'dateManufactured',
    'address',
    'town',
    'phoneNumber',
    'serialNumber',
    'installation',
    'controlledByCab',
    'multiGuardCab',
    'inspectionDate',
  ],
  [AssetCategories.SS]: [
    'controlUnit',
    'road',
    'houseNumber',
    'postcode',
    'dateDeployed',
    'inspectionDate',
    'risk',
    'serialNumber',
    'comments',
    'models',
  ],
  [AssetCategories.GL]: [
    'section',
    'cabinet',
    'road',
    'postcode',
    'risk',
    'replacementDate',
    'numberOfFittings',
    'fittingComments',
    'fittingDateDeployed',
    'fittingReplacementYear',
    'bulbDateDeployed',
    'bulbNextReplacementDate',
    'mastDateDeployed',
    'mastInspectionDate',
    'mastReplacementDate',
    'mastDateManufactured',
    'fittingGuard',
    'mastInsulationClass',
    'fittingManufacturer',
    'fittingSupplier',
    'fittingType',
    'fittingName',
    'fittingLocalName',
    'fittingSerialNumber',
    'fittingDateManufactured',
    'bulbDateManufactured',
    'bulbManufacturer',
    'bulbSupplier',
    'bulbType',
    'bulbPower',
    'bulbLifetime',
    'bulbLosses',
    'bulbLumens',
    'bulbKelvin',
    'bulbSerialNumber',
    'mastDimensions',
    'mastHeight',
    'mastSupplier',
    'mastArea',
    'mastRiskGroup',
    'mastSafeguards',
    'mastType',
    'phoneNumber',
    'models',
  ],
};

export type FormikProps = Props<Asset.Item>;

export const DateTypeFields = [
  'dateDeployed',
  'dateInspected',
  'inspectionDate',
  'nextInspectionDate',
  'replacementDate',
  'dateManufactured',
  'inspectionDate',
  'fittingDateDeployed',
  'bulbDateDeployed',
  'bulbNextReplacementDate',
  'mastDateDeployed',
  'mastInspectionDate',
  'mastReplacementDate',
  'mastDateManufactured',
  'fittingDateManufactured',
  'bulbDateManufactured',
];

const useConfiguredFormik = (assetMeta: Asset.AssetMeta | null, isCreateMode: boolean) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Asset.Item>({
    initialValues: isCreateMode
      ? ({
          elInTablID: null,
          name: '',
          code: '',
          comments: '',
          dateDeployed: null,
          dateInspected: null,
          risk: '',
          replacementDate: null,
          models: [],
          nextInspectionDate: null,
          dateManufactured: null,
          monthsBetweenInspectionsCH: '',
          serialNumber: '',
          meteringPoint: '',
        } as any)
      : ({} as Asset.Item),

    validationSchema: isCreateMode
      ? Yup.object().shape({
          code: Yup.string().required("Field can't be empty!"),
        })
      : undefined,

    onSubmit: (values, { resetForm }) => {
      const formattedValues = Object.keys(values).reduce((acc: Asset.Item, key: string) => {
        const value = (values as any)?.[key];
        if (DateTypeFields.includes(key)) {
          (acc as any)[key] = value && value.set('hour', 12).format();
        } else {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as Asset.Item);

      if (isCreateMode) {
        return dispatch(createAssetAction({ ...formattedValues, images: undefined } as any)).then(() =>
          dispatch(push(Routes.Assets))
        );
      }

      return dispatch(updateAssetAction({ ...assetMeta, ...formattedValues, images: undefined } as any)).then(() =>
        resetForm({ values })
      );
    },
  });

  return formik;
};

export default useConfiguredFormik;
