import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usersIsFetchedSelector, usersHashSelector } from 'modules/users/selectors';
import { fetchUsersAction } from 'modules/users';

interface ReturnProps extends Users.Root {
  usersIds: number[];
  users: Users.User[];
  isLoading: boolean;
}

useUsers.fetchUsersActionFired = false;

export default function useUsers(): ReturnProps {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFetched: boolean = useSelector(usersIsFetchedSelector);
  const usersHash: Record<string, Users.User> = useSelector(usersHashSelector);

  const users: Users.User[] = useMemo(() => Object.values(usersHash), [usersHash]);

  const usersIds: number[] = useMemo(() => users.map((user: Users.User) => user.id), [users]);

  useEffect(() => {
    if (!useUsers.fetchUsersActionFired) {
      setIsLoading(true);
      dispatch(fetchUsersAction()).then(() => setIsLoading(false));
      useUsers.fetchUsersActionFired = true;
    }
  }, [dispatch]);

  return { usersHash, usersIds, users, isLoading, isFetched };
}
