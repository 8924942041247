import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.assetModels;

const assetModelsItemsHashSelector = createSelector(rootSelector, assetModels => assetModels.itemsHash);

export const assetModelsItemsSelector = createSelector(assetModelsItemsHashSelector, itemsHash =>
  Object.values(itemsHash)
);

export const assetModelsItemSelectorFactory = (id: number | null) =>
  createSelector(assetModelsItemsHashSelector, itemsHash => itemsHash[`_${id}_`]);

export const assetModelsCountSelector = createSelector(rootSelector, assetModels => assetModels.count);
