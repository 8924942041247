import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Form, Col, Row, ListGroup } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { taskModalMetaSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { taskSelectorFactory } from 'modules/tasks/selectors';
import { externalTimeSystemSelector, isMaterialsEnabledSelector } from 'modules/app/selectors';
import {
  Alert,
  Modal,
  Button,
  FormInput,
  HiddenFormSubmit,
  SelectTaskAppAction,
  SelectSubComponents,
  SelectLogMaintenanceForms,
  DateRangePicker,
  SelectTaskDepartment,
  SelectTaskType,
  SelectUser,
  SelectExtRepeatedTaskNumber,
  SelectMaintenancePlans,
  SelectMonth,
  Dropzone,
  SelectMapboxGeocoder,
  BootstrapSwitchButton,
  ExpectedTimeFormInput,
  SelectToolInspectionForms,
  SelectAssetCodes,
} from 'components/_common';
import useSetValuesEffect from './useSetValuesEffect';
import SelectTaskChecklist from './SelectTaskChecklist';
import SectionMaterials from './SectionMaterials';
import useConfiguredFormik from './useConfiguredFormik';
import SelectInstallationNumbers from './SelectInstallationNumbers';
import { getSelectTaskAppActionExcludedOptions } from 'utils';
import {
  TaskAppActions,
  TaskTypes,
  AssetTaskAppActions,
  NonAssetTaskAppActions,
  InstallationNumbersTaskAppActions,
} from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

interface Props {
  hideButton?: boolean;
}

const TasksModal: React.FC<Props> = ({ hideButton = false }) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const {
    id = null,
    departmentNumber = null,
    taskNumber = null,
    description = null,
    complaint = null,
  } = useSelector(taskModalMetaSelector) || ({} as Layouts.TaskModalMeta);
  const task: Tasks.Task | undefined = useSelector(taskSelectorFactory(id));
  const externalTimeSystem: boolean = useSelector(externalTimeSystemSelector);
  const isMaterialsEnabled = useSelector(isMaterialsEnabledSelector);
  const isEditMode: boolean = Boolean(id);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && (id || departmentNumber || taskNumber || complaint)) dispatch(setLayoutAction({ taskModalMeta: null }));
    setShow(!show);
  }, [show, id, departmentNumber, taskNumber, complaint, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue,
    setValues,
    setFieldTouched,
    resetForm,
  } = useConfiguredFormik({
    show,
    id,
    departmentNumber,
    taskNumber,
    description,
    complaint,
    toggleModal,
    externalTimeSystem,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);
  const isTaskTypeAutogenerated: boolean = values.type === TaskTypes.Autogenerated;
  const isTaskTypeCustomerComplaint: boolean = values.type === TaskTypes.CustomerComplaint;
  const isToolInspectionAppAction: boolean = values.appAction === TaskAppActions.ToolInspection;

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (departmentNumber || taskNumber || complaint) setShow(true);
  }, [departmentNumber, taskNumber, complaint]);

  useSetValuesEffect({ id, task, setValues, setShow });

  const handleSelectTaskTypeChange = useCallback(
    (value: any) => {
      const currentType = values.type;
      const nextType = value?.value || null;

      setValues({
        ...values,
        type: nextType,

        // for expected we need to reset taskNumber and name
        ...((nextType === TaskTypes.Expected || currentType === TaskTypes.Expected) &&
          externalTimeSystem && {
            name: null,
            taskNumber: null,
          }),

        ...(getSelectTaskAppActionExcludedOptions(nextType).includes(values.appAction) && {
          appAction: TaskAppActions.LogMaintenance,
          installationNumbers: [], // NewSmartMeter / ReplaceSmartMeter / RemoveSmartMeter actions
          address: null, // NoAsset action
          loc: null, // NoAsset action
        }),

        ...(nextType === TaskTypes.Autogenerated
          ? {
              startDate: null,
              endDate: null,
              assetCategoryCode: null,
              assets: [],
              formId: null,
              assetSubcomponentTypeId: null,
              taskCheckListId: null,
              expectedTime: null,
              // Switch from Immediate task type to Autogenerated can't map all task numbers
              ...(getSelectTaskAppActionExcludedOptions(nextType).includes(values.appAction) && {
                name: null,
                taskNumber: null,
                appAction: TaskAppActions.LogMaintenance,
              }),
            }
          : {
              planId: null,
              taskMonths: null,
              assetCategoryCode: values.assetCategoryCode || null,
            }),
      });
    },
    [setValues, values, externalTimeSystem]
  );

  const handleTaskNumberSelectChange = useCallback(
    (value: any) => {
      setValues({
        ...values,
        taskNumber: value?.value || null,
        name: value?.description
          ? `${value?.description}${complaint ? ` - ${complaint?.typeOfFault}, ${complaint?.nameOfRoad}` : ''}`
          : '',
        appAction: values.useFilteredTasks ? value?.appAction || null : values.appAction,
        assetSubcomponentTypeId: values.useFilteredTasks ? null : values.assetSubcomponentTypeId,
        formId: values.useFilteredTasks ? null : values.formId,
        taskCheckListId: values.useFilteredTasks ? null : values.taskCheckListId,

        ...(!InstallationNumbersTaskAppActions.includes(value?.appAction) && {
          installationNumbers: [],
        }),

        ...(value?.appAction === TaskAppActions.ToolInspection && {
          startDate: null,
          endDate: null,
          taskMonths: null,
        }),

        ...(NonAssetTaskAppActions.includes(value?.appAction) && {
          assetCategoryCode: null,
          assets: [],
        }),
      });
    },
    [setValues, values, complaint]
  );

  const handleDepartmentNumberSelectChange = useCallback(
    (value: any) => {
      if (values.type !== TaskTypes.Expected && externalTimeSystem) {
        return setValues({ ...values, departmentNumber: value?.value || null, name: null, taskNumber: null });
      }
      setFieldValue('departmentNumber', value?.value || null);
    },
    [setFieldValue, setValues, values, externalTimeSystem]
  );

  const handleDateRangePickerApply = useCallback(
    (event: React.SyntheticEvent, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      setValues({ ...values, startDate: picker.startDate, endDate: picker.endDate });
    },
    [setValues, values]
  );

  const handleAppActionSelectChange = useCallback(
    (v: any) => {
      const assets = !AssetTaskAppActions.includes(v.value) ? [] : values.assets;
      const assetCategoryCode = assets?.length ? values.assetCategoryCode : null;

      setValues({
        ...values,
        appAction: v.value,

        assetSubcomponentTypeId: ![TaskAppActions.Repair, TaskAppActions.Replacement].includes(v.value)
          ? null
          : values.assetSubcomponentTypeId,
        formId: null,
        taskCheckListId: null,

        ...(!InstallationNumbersTaskAppActions.includes(v.value) && {
          installationNumbers: [],
        }),

        // reset dates fields if app action switched to TaskAppActions.ToolInspection
        ...(v.value === TaskAppActions.ToolInspection && {
          startDate: null,
          endDate: null,
          taskMonths: null,
        }),

        assetCategoryCode: isTaskTypeAutogenerated ? values.assetCategoryCode : assetCategoryCode,
        assets,
        ...(NonAssetTaskAppActions.includes(v.value) && {
          assetCategoryCode: null,
          assets: [],
        }),
      });
    },
    [setValues, values, isTaskTypeAutogenerated]
  );

  const handleSelectAssetCodesChange = useCallback(
    (options: Type.SelectOption<string>[]) => {
      const willResetValues = !options?.length;

      setValues({
        ...values,
        assetCategoryCode: willResetValues ? null : options?.[0]?.categoryCode || values.assetCategoryCode || null,
        assets: options?.map(o => ({ code: o.value, categoryCode: o.categoryCode! })) || [],
        assetSubcomponentTypeId: willResetValues ? null : values.assetSubcomponentTypeId,
        formId: null,
        taskCheckListId: willResetValues ? null : values.taskCheckListId,
      });
    },
    [values, setValues]
  );

  const handleToolInspectionFormsSelectChange = useCallback(
    (v: any) => {
      setValues({
        ...values,
        formId: v?.value || null,
        userIds: [...new Set([...values.userIds, ...(v?.userIds || [])])],
      });
    },
    [values, setValues]
  );

  const filteredTaskAttachments = useMemo(
    () =>
      task?.taskAttachments?.filter(
        (taskAttachment: Tasks.TaskAttachment) => !values.deletedAttachmentIds.includes(taskAttachment.id)
      ),
    [task, values.deletedAttachmentIds]
  );

  const handleFileRemove = useCallback(
    (event: React.SyntheticEvent) => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      setFieldValue('deletedAttachmentIds', [...values.deletedAttachmentIds, id]);
    },
    [values.deletedAttachmentIds, setFieldValue]
  );

  const isNameInputDisabled = values.type !== TaskTypes.Expected && !values.taskNumber && externalTimeSystem;
  const isFilteredTasksToggleShown =
    !Boolean(taskNumber) && !(isEditMode && isTaskTypeCustomerComplaint) && !task?.isReopened;

  const renderSelectTaskChecklist = () => (
    <SelectTaskChecklist
      value={values.taskCheckListId}
      taskCheckListName={task?.taskCheckListName || null}
      appAction={values.appAction}
      assetCategoryCode={values.assetCategoryCode}
      onChange={(v: any) => setFieldValue('taskCheckListId', v?.value || null)}
      onBlur={() => setFieldTouched('taskCheckListId')}
      errorKey={Boolean(touched.taskCheckListId && errors.taskCheckListId) ? (errors.taskCheckListId as string) : ''}
      isDisabled={Boolean(task?.isReopened && values.taskCheckListId)}
    />
  );

  const renderExpectedTimeFormInput = () => (
    <ExpectedTimeFormInput
      name="expectedTime"
      value={values.expectedTime ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      errorKey={Boolean(touched.expectedTime && errors.expectedTime) ? (errors.expectedTime as string) : ''}
      useDynamicCalculation={
        values.type === TaskTypes.Autogenerated &&
        // service time table can calculate expected time only for these task app actions
        [TaskAppActions.LogMaintenance, TaskAppActions.Inspection, TaskAppActions.Repair].includes(values.appAction)
      }
      assetCategoryCode={values.assetCategoryCode}
      appAction={values.appAction}
      setFieldValue={setFieldValue}
    />
  );

  const handleInputChange = useCallback(
    (event: any) => {
      if (event.target.value.length > 80) return;
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      setFieldValue('updatedAttachmentsHash', { ...values.updatedAttachmentsHash, [id]: event.target.value });
    },
    [values.updatedAttachmentsHash, setFieldValue]
  );

  const assetCodes = useMemo(() => values.assets.map(asset => asset.code), [values.assets]);

  return (
    <>
      {!hideButton && <Button icon={<IconPlus />} labelKey="Create task" onClick={toggleModal} variant="primary" />}
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit task' : 'Create task'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Alert className="text-center" variant="light" hidden={!task?.isReopened}>
            {getIntl("Some fields can't be edited in reopened tasks")}
          </Alert>
          <Form.Row>
            <Form.Group as={Col}>
              <SelectTaskDepartment
                value={values.departmentNumber}
                onChange={handleDepartmentNumberSelectChange}
                onBlur={() => setFieldTouched('departmentNumber')}
                errorKey={
                  Boolean(touched.departmentNumber && errors.departmentNumber)
                    ? (errors.departmentNumber as string)
                    : ''
                }
                isDisabled={(isEditMode && isTaskTypeCustomerComplaint) || task?.isReopened}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <SelectTaskType
                types={values.type}
                onChange={handleSelectTaskTypeChange}
                onBlur={() => setFieldTouched('type')}
                errorKey={Boolean(touched.type && errors.type) ? (errors.type as string) : ''}
                isDisabled={isTaskTypeCustomerComplaint || task?.isReopened}
                excludedTaskTypes={isTaskTypeCustomerComplaint ? [] : [TaskTypes.CustomerComplaint]}
              />
            </Form.Group>
          </Form.Row>
          {values.type !== TaskTypes.Expected && externalTimeSystem && (
            <Form.Row>
              <Form.Group
                as={Col}
                style={{
                  width: isFilteredTasksToggleShown ? 'calc(100% - 210px)' : '100%',
                }}
              >
                <SelectExtRepeatedTaskNumber
                  value={values.taskNumber}
                  departmentNumber={values.departmentNumber}
                  onChange={handleTaskNumberSelectChange}
                  onBlur={() => setFieldTouched('taskNumber')}
                  errorKey={Boolean(touched.taskNumber && errors.taskNumber) ? (errors.taskNumber as string) : ''}
                  isDisabled={(isEditMode && isTaskTypeCustomerComplaint) || task?.isReopened}
                  useFilteredTasks={values.useFilteredTasks}
                  excludedAppActions={getSelectTaskAppActionExcludedOptions(values.type)}
                />
              </Form.Group>
              {isFilteredTasksToggleShown && (
                <Form.Group as={Col} xs="auto" style={{ paddingTop: '25px' }}>
                  <BootstrapSwitchButton
                    key={lng}
                    checked={values.useFilteredTasks}
                    offstyle="secondary"
                    onstyle="primary"
                    offlabel={getIntl('All tasks numbers')}
                    onlabel={getIntl('Only repeated tasks')}
                    width={200}
                    onChange={(useFilteredTasks: boolean) => {
                      setValues({ ...values, useFilteredTasks, taskNumber: null, name: null });
                    }}
                  />
                </Form.Group>
              )}
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col} xs={isToolInspectionAppAction ? 8 : 12}>
              <FormInput
                labelKey="Name"
                name="name"
                value={values.name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
                disabled={isNameInputDisabled}
                placeholderKey={isNameInputDisabled ? 'Please select task number' : ''}
              />
            </Form.Group>
            {isToolInspectionAppAction && (
              <Form.Group as={Col} xs={4}>
                {renderExpectedTimeFormInput()}
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            {!isToolInspectionAppAction && (
              <>
                <Form.Group as={Col} xs={8}>
                  {!isTaskTypeAutogenerated ? (
                    <>
                      <DateRangePicker
                        label={`${getIntl('Start date')} - ${getIntl('End date')}`}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        errorKey={
                          Boolean(touched.startDate && errors.startDate) ? (errors.startDate as unknown as string) : ''
                        }
                        onApply={handleDateRangePickerApply}
                      />
                    </>
                  ) : (
                    <SelectMonth
                      isMulti
                      months={values.taskMonths || []}
                      onChange={(months: Tasks.TaskMonthsEnum[]) => setFieldValue('taskMonths', months)}
                      onBlur={() => setFieldTouched('taskMonths')}
                      errorKey={Boolean(touched.taskMonths && errors.taskMonths) ? (errors.taskMonths as string) : ''}
                    />
                  )}
                </Form.Group>
                <Form.Group as={Col}>{renderExpectedTimeFormInput()}</Form.Group>
              </>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <SelectTaskAppAction
                actions={values.appAction}
                onChange={handleAppActionSelectChange}
                onBlur={() => setFieldTouched('appAction')}
                errorKey={Boolean(touched.appAction && errors.appAction) ? (errors.appAction as string) : ''}
                excludedOptions={getSelectTaskAppActionExcludedOptions(values.type)}
                isDisabled={
                  (values.useFilteredTasks && values.type !== TaskTypes.Expected && externalTimeSystem) ||
                  task?.isReopened
                }
              />
            </Form.Group>
            {isTaskTypeAutogenerated ? (
              <Form.Group as={Col}>
                <SelectMaintenancePlans
                  value={values.planId}
                  onChange={(v: any) => {
                    setValues({
                      ...values,
                      planId: v?.value || null,
                      assetCategoryCode: v?.assetCategoryCode || null,
                      formId: null,
                      taskCheckListId: null,
                    });
                  }}
                  onBlur={() => setFieldTouched('planId')}
                  errorKey={Boolean(touched.planId && errors.planId) ? (errors.planId as string) : ''}
                  isDisabled={task?.isReopened}
                />
              </Form.Group>
            ) : AssetTaskAppActions.includes(values.appAction) ? (
              <Form.Group as={Col} xs={8}>
                <SelectAssetCodes
                  values={values.assets}
                  onChange={handleSelectAssetCodesChange}
                  onBlur={() => setFieldTouched('assets')}
                  errorKey={Boolean(touched.assets && errors.assets) ? (errors.assets as string) : ''}
                  isDisabled={task?.isReopened}
                  isMulti
                />
              </Form.Group>
            ) : (
              <Form.Group as={Col} xs={6}>
                {isToolInspectionAppAction ? (
                  <SelectToolInspectionForms
                    value={values.formId}
                    onChange={handleToolInspectionFormsSelectChange}
                    onBlur={() => setFieldTouched('formId')}
                    errorKey={Boolean(touched.formId && errors.formId) ? (errors.formId as string) : ''}
                  />
                ) : (
                  renderSelectTaskChecklist()
                )}
              </Form.Group>
            )}
          </Form.Row>
          {values.appAction === TaskAppActions.NoAsset && (
            <Form.Row className="align-items-end">
              {values.useAddressGeocoding ? (
                <Form.Group as={Col} style={{ width: 'calc(100% - 210px)' }}>
                  <SelectMapboxGeocoder
                    value={values.address || ''}
                    onChange={(v: any) => {
                      setFieldValue('address', v?.value || null);
                      setFieldValue('loc', v?.value ? { x: v.x, y: v.y } : null);
                    }}
                  />
                </Form.Group>
              ) : (
                <>
                  <Form.Group as={Col}>
                    <FormInput
                      type="number"
                      labelKey="Latitude"
                      name="loc.y"
                      value={values.loc?.y || ''}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <FormInput
                      type="number"
                      labelKey="Longitude"
                      name="loc.x"
                      value={values.loc?.x || ''}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group as={Col} xs="auto">
                <BootstrapSwitchButton
                  key={lng}
                  checked={values.useAddressGeocoding}
                  offstyle="secondary"
                  onstyle="primary"
                  offlabel={getIntl('Geocoding off')}
                  onlabel={getIntl('Geocoding on')}
                  width={200}
                  onChange={(useAddressGeocoding: boolean) => {
                    setValues({ ...values, useAddressGeocoding, address: null, loc: null });
                  }}
                />
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            {[TaskAppActions.Repair, TaskAppActions.Replacement].includes(values.appAction) && (
              <Form.Group as={Col}>
                <SelectSubComponents
                  assetCategoryCode={values.assetCategoryCode}
                  setFieldValue={(id: number | null) => {
                    setFieldValue('assetSubcomponentTypeId', id);
                  }}
                  onBlur={() => setFieldTouched('assetSubcomponentTypeId')}
                  value={values.assetSubcomponentTypeId}
                  errorKey={
                    Boolean(touched.assetSubcomponentTypeId && errors.assetSubcomponentTypeId)
                      ? (errors.assetSubcomponentTypeId as string)
                      : ''
                  }
                  disabled={!values.assetCategoryCode}
                />
              </Form.Group>
            )}
            {values.appAction === TaskAppActions.LogMaintenance && (
              <Form.Group as={Col}>
                <SelectLogMaintenanceForms
                  assetCategoryCode={values.assetCategoryCode}
                  assetCodes={assetCodes}
                  name="formId"
                  value={values.formId}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errorKey={Boolean(touched.formId && errors.formId) ? (errors.formId as string) : ''}
                  isDisabled={!values.assetCategoryCode}
                />
              </Form.Group>
            )}
            {AssetTaskAppActions.includes(values.appAction) && (
              <Form.Group as={Col}>{renderSelectTaskChecklist()}</Form.Group>
            )}
          </Form.Row>
          <Form.Group>
            <SelectUser
              isMulti
              labelKey="Responsible users"
              userIds={values.responsibleUserIds}
              setUserIds={(responsibleUserIds: number[]) => setFieldValue('responsibleUserIds', responsibleUserIds)}
              onBlur={() => setFieldTouched('responsibleUserIds')}
              errorKey={
                Boolean(touched.responsibleUserIds && errors.responsibleUserIds)
                  ? (errors.responsibleUserIds as string)
                  : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectUser
              isMulti
              userIds={values.userIds}
              setUserIds={(userIds: number[]) => setFieldValue('userIds', userIds)}
              onBlur={() => setFieldTouched('userIds')}
              errorKey={Boolean(touched.userIds && errors.userIds) ? (errors.userIds as string) : ''}
            />
          </Form.Group>
          {isMaterialsEnabled && (
            <Form.Group>
              <SectionMaterials
                touched={touched}
                errors={errors}
                materials={values.materials}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Form.Group>
          )}
          {InstallationNumbersTaskAppActions.includes(values.appAction) && (
            <Form.Group>
              <SelectInstallationNumbers
                values={values.installationNumbers}
                onChange={(values: Type.SelectOption[]) =>
                  setFieldValue(
                    'installationNumbers',
                    values.map(v => v?.value || [])
                  )
                }
                onBlur={() => setFieldTouched('installationNumbers')}
                errorKey={
                  Boolean(touched.installationNumbers && errors.installationNumbers)
                    ? (errors.installationNumbers as string)
                    : ''
                }
              />
            </Form.Group>
          )}
          <Form.Group>
            <Dropzone onDrop={(files: File[]) => setFieldValue('files', files)} />
            {filteredTaskAttachments?.map((taskAttachment: Tasks.TaskAttachment) => (
              <ListGroup className="mt-2" key={taskAttachment.id}>
                <ListGroup.Item>
                  <Row className="align-items-center">
                    <Col xs="5">
                      <a href={taskAttachment.link} target="_blank" rel="noopener noreferrer" className="text-break">
                        {taskAttachment.name}
                      </a>
                    </Col>
                    <Col xs="6" className="ml-auto">
                      <FormInput
                        data-id={taskAttachment.id}
                        labelKey=""
                        value={values.updatedAttachmentsHash[taskAttachment.id] ?? (taskAttachment.description || '')}
                        onChange={handleInputChange}
                        placeholderKey="File description"
                      />
                    </Col>
                    <Col xs="auto">
                      <button
                        type="button"
                        className="btn btn-default btn-sm w-100"
                        data-id={taskAttachment.id}
                        onClick={handleFileRemove}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            ))}
          </Form.Group>
          <FormInput
            as="textarea"
            labelKey="Description"
            name="description"
            value={values.description || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            rows={3}
          />
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default TasksModal;
