import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
  dataMarker: string;
}

const ChartControlsContainer: React.FC<Props> = ({ dataMarker, children }) => (
  <StyledControlsContainer data-marker={dataMarker}>{children}</StyledControlsContainer>
);

const StyledControlsContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 25px;
  display: inline-flex;
  align-items: center;
  justify-self: anchor-center;
  transform: scale(0.95);
`;

export default ChartControlsContainer;
