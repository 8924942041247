import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useRecalculateButton } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { recalculateMaintenancePlanAction } from 'modules/maintenance';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { DataTableTemplate, SelectYear, RecalculationSpinner } from 'components/_common';
import { PaginationType } from 'constants/index';
import TablePlanSummary from './TablePlanSummary';

const ViewPlanSummary: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { loading, ButtonRecalculate } = useRecalculateButton(recalculateMaintenancePlanAction);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.MAINTENANCE_SUMMARY));
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  const handleChangeYearSelect = useCallback(
    (value: Type.SelectOption) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.MAINTENANCE_SUMMARY,
          modifier: { filters: { ...filters, year: value?.value || null }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectYear
            isSearchable={false}
            value={filters?.year!}
            onChange={handleChangeYearSelect}
            labelKey=""
            mutedTextLabelKey="Year"
          />
        </Col>
        {isLiveDataConnection && <Col className="text-right">{ButtonRecalculate}</Col>}
      </Row>
      {loading ? <RecalculationSpinner /> : <TablePlanSummary />}
    </DataTableTemplate>
  );
};

export default ViewPlanSummary;
