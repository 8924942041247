import styled from 'styled-components';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { bottomTabsLabelKeysIndexesMapSelector } from 'modules/map/selectors';
import {
  AssetTimeSeriesChart,
  AssetProfileChart,
  AssetDurationChart,
  ChartProbabilityOfFailure,
  EvolutionChart,
} from 'components/_charts';
import ScrollableTabs, { StyledTabScreen } from '../ScrollableTabs';
import TabTasks from './TabTasks';
import CustomerAssetTypeSupportAlert from './CustomerAssetTypeSupportAlert';
import { Spinner, Alert } from 'components/_common';
import { StorageKeys, TimeSeriesTypes } from 'constants/index';

interface Props {
  uuid: string;
  cnaim_id: number | null;
  isAssetMetaFetched: boolean;
  isCustomerAssetType: boolean;
  isCabinetAssetType: boolean;
}

const BottomPanelTabs: React.FC<Props> = memo(
  ({ uuid, cnaim_id, isAssetMetaFetched, isCustomerAssetType, isCabinetAssetType }) => {
    const { getIntl } = useLocale();
    const { BottomTabsLabelKeys, BottomTabsIndexesMap } = useSelector(bottomTabsLabelKeysIndexesMapSelector);

    const renderTab = (Component: React.JSX.Element) => {
      if (!isAssetMetaFetched) return <Spinner isInFullHeightContainer />;
      if (isCustomerAssetType) return <CustomerAssetTypeSupportAlert />;
      return Component;
    };
    return (
      <ScrollableTabs tabsLabelKeys={BottomTabsLabelKeys} storageKey={StorageKeys.MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX}>
        {activeTab => (
          <>
            {BottomTabsLabelKeys.includes('Power') &&
              (isAssetMetaFetched ? (
                <StyledTabScreen key="Power" activeTab={activeTab} index={BottomTabsIndexesMap.Power} $absolute>
                  <AssetTimeSeriesChart
                    uuid={uuid}
                    type={TimeSeriesTypes.POWER}
                    height="100%"
                    isTooltipOutside
                    isFiltersDisabled={isCustomerAssetType}
                  />
                </StyledTabScreen>
              ) : (
                <Spinner isInFullHeightContainer />
              ))}
            {BottomTabsLabelKeys.includes('Voltage') && (
              <StyledTabScreen
                key="Voltage"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Voltage}
                $absolute
                $paddings={isCustomerAssetType}
              >
                {renderTab(
                  <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.VOLTAGE} height="100%" isTooltipOutside />
                )}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Losses') && (
              <StyledTabScreen
                key="Losses"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Losses}
                $absolute
                $paddings={isCustomerAssetType || isCabinetAssetType}
              >
                {renderTab(
                  isCabinetAssetType ? (
                    <Alert className="mb-0 text-center" variant="warning">
                      {getIntl("Cabinet asset type doesn't support this tab")}
                    </Alert>
                  ) : (
                    <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.LOSSES} height="100%" isTooltipOutside />
                  )
                )}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Evolution') && (
              <StyledTabScreen
                key="Evolution"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Evolution}
                $absolute
                $paddings={isCustomerAssetType}
              >
                {renderTab(<EvolutionChart uuid={uuid} height="100%" isTooltipOutside />)}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Profile') && (
              <StyledTabScreen
                key="Profile"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Profile}
                $absolute
                $paddings={isCustomerAssetType}
              >
                {renderTab(<AssetProfileChart uuid={uuid} height="100%" isTooltipOutside />)}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Duration') && (
              <StyledTabScreen
                key="Duration"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Duration}
                $absolute
                $paddings={isCustomerAssetType}
              >
                {renderTab(<AssetDurationChart uuid={uuid} height="100%" isTooltipOutside />)}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Reliability') && (
              <StyledTabScreen
                key="Reliability"
                activeTab={activeTab}
                index={BottomTabsIndexesMap.Reliability}
                $absolute
                $paddings={isCustomerAssetType}
              >
                {renderTab(<StyledProbabilityOfFailureChart uuid={uuid} cnaim_id={cnaim_id} />)}
              </StyledTabScreen>
            )}
            {BottomTabsLabelKeys.includes('Tasks') && (
              <StyledTabScreen key="Tasks" activeTab={activeTab} index={BottomTabsIndexesMap.Tasks}>
                <TabTasks uuid={uuid} />
              </StyledTabScreen>
            )}
          </>
        )}
      </ScrollableTabs>
    );
  }
);

const StyledProbabilityOfFailureChart = styled(ChartProbabilityOfFailure)`
  height: 100%;
  width: 100%;
`;

export default BottomPanelTabs;
