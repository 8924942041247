import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.mjs`;

const convertPdfToPng = (file: File, callback: (images: Blob[]) => void) => {
  const reader = new FileReader();

  reader.onload = async function () {
    const arrayBuffer = reader.result as ArrayBuffer;
    const pdf = await getDocument({ data: arrayBuffer }).promise;

    const images: Blob[] = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 2 }); // Adjust scale as needed
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) continue;

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      await page.render(renderContext).promise;

      canvas.toBlob(blob => {
        if (blob) images.push(blob);
        if (images.length === pdf.numPages) {
          callback(images);
        }
      }, 'image/png');
    }
  };

  reader.readAsArrayBuffer(file);
};

export { convertPdfToPng };
