import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createTaskChecklistAction, editTaskChecklistAction } from 'modules/tasks';
import { _omit } from '@utiligize/shared/utils';
import { NonAssetTaskAppActions } from 'constants/index';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.TaskChecklistActionProps>({
    initialValues: {
      name: '',
      appAction: null,
      assetCategoryCode: null,
      questionInput: '',
      questions: [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      appAction: Yup.string().nullable().required("Field can't be empty!"),
      assetCategoryCode: Yup.string()
        .nullable()
        .when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (assetCategoryCode: number) => {
              return !appAction || !!assetCategoryCode || NonAssetTaskAppActions.includes(appAction);
            },
            message: "Field can't be empty!",
          })
        ),
      questions: Yup.array().min(1, 'Please add at least one question'),
    }),

    onSubmit: values => {
      const data = _omit(values, 'questionInput');
      return (() => {
        return id ? dispatch(editTaskChecklistAction(id, data)) : dispatch(createTaskChecklistAction(data));
      })()
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
