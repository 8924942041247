import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getTransformersVoltageChartData } from 'modules/networkLoading';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';

interface Props {
  type: Type.PaginationType;
}

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartTransformersVoltage: React.FC<Props> = ({ type }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId || !simulationId || !versionId) return;
    dispatch(
      getTransformersVoltageChartData({
        portfolioId,
        scenarioId,
        simulationId,
        versionId,
        year: filters?.year,
        voltage: filters?.voltage,
        voltage_side: filters?.voltageSide,
        percentile: filters?.percentile,
        flex: filters?.flex,
      })
    )
      .then((action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null>) => {
        setChartData({
          series: [
            {
              name: action.payload?.title || '',
              type: 'column' as any,
              data: action.payload?.data,
              color: Colors[0],
            },
          ],
          title: action.payload?.title || '',
          xAxisTitle: action.payload?.xaxis_title || '',
          yAxisTitle: action.payload?.yaxis_title || '',
        });
      })
      // Note. Show default "No data to display" message in case of API errors
      .catch(() => setChartData({} as any));
  }, [
    dispatch,
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    filters?.voltage,
    filters?.year,
    filters?.voltageSide,
    filters?.percentile,
    filters?.flex,
  ]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `<b>${getIntl('Voltage')}:</b> ${this.x} p.u.<br><b>${getIntl('Quantity')}:</b> ${this.y} ${getIntl('pcs')}.`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: {
        enabled: false,
      },
    };
  }, [chartData, getIntl]) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_network_loading_transformers_voltage" />;
};

export default ChartTransformersVoltage;
