import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { maintenanceSummaryCountSelector, maintenanceSummarySelector } from 'modules/maintenance/selectors';
import { fetchMaintenanceSummaryAction } from 'modules/maintenance';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TablePlanSummary: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenanceSummaryCountSelector);
  const items = useSelector(maintenanceSummarySelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchMaintenanceSummaryAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_SUMMARY}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
      maxHeight="calc(100vh - 240px)"
    >
      {items.map((item: Maintenance.Summary) => (
        <tr key={item.id}>
          <td>{item.assetCategory || '-'}</td>
          <td>{item.maintenanceYear || '-'}</td>
          <td>{item.number || '-'}</td>
          <td>{item.planName || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TablePlanSummary;
