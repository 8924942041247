import { useFormik, FormikProps as Props } from 'formik';
import { useDispatch } from 'react-redux';
import { updateAssetAction } from 'modules/assets';

type Values = Pick<Asset.Item, 'models'>;
export type FormikProps = Props<Values>;

const useConfiguredFormik = (assetMeta: Asset.Item) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      models: assetMeta.models,
    },

    onSubmit: (values, { resetForm }) => {
      return dispatch(updateAssetAction({ ...assetMeta, ...values })).then(() => resetForm({ values }));
    },
  });

  return formik;
};

export default useConfiguredFormik;
