import { createAction } from 'redux-actions';
import { portfolioIdSelector, scenarioIdHashSelector, selectedChartYearSelector } from './selectors';
import { scenariosYearsOptionsHashSelector } from 'modules/options/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setPaginationAction = createAction('layouts/SET_PAGINATION');

export const setModalConfirmationMetaAction = createAction('layouts/SET_MODAL_DELETE_CONFIRMATION_META');

export const toggleSidebarAction = createAction('layouts/TOGGLE_SIDEBAR');

export const closeSidebarAction = createAction('layouts/CLOSE_SIDEBAR');

export const setErrorToastAction = createAction('layouts/SET_ERROR_TOAST');

export const setInfoToastAction = createAction('layouts/SET_INFO_TOAST');

export const setSuccessToastAction = createAction('layouts/SET_SUCCESS_TOAST/(sync-store)');

export const deleteToastAction = createAction('layouts/DELETE_TOAST');

export const setLayoutAction = createAction(
  'layouts/SET_LAYOUT_MODIFIER',
  (payload: Partial<Layouts.Root>) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    if (!payload.portfolioId && !payload.scenarioIdHash) return payload;
    // Handler for SelectPortfolio or SelectScenarioType dropdowns
    const state = getState();
    const portfolioId = payload.portfolioId || portfolioIdSelector(state);
    const scenarioId = payload.scenarioIdHash?.[portfolioId!] || scenarioIdHashSelector(state)?.[portfolioId!];
    const value = selectedChartYearSelector(state);
    const scenariosYearsOptionsHash = scenariosYearsOptionsHashSelector(state);
    const options = scenariosYearsOptionsHash[`${portfolioId}_${scenarioId}`];

    if (!options || options?.some(i => i.value === value)) return payload;
    // Note. ChartYear - Step.2 - Automatically apply year value if exist in the cache
    const currentYear = new Date().getFullYear();
    return {
      ...payload,
      selectedChartYear: options.some(i => i.value === currentYear) ? currentYear : options[0]?.value,
    };
  }
);

export const setFeatureToggleAction =
  createAction<Partial<Layouts.Root['featureToggles']>>('layouts/SET_FEATURE_TOGGLE');
