import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useBuilderState } from 'hooks';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { builderDataSelector } from 'modules/forms/selectors';
import { categoriesSelector } from 'modules/assets/selectors';
import { subComponentsSelector } from 'modules/assets/selectors';
import { fetchFormCountByTypeAndAssetCategoryCodeAction, fetchFormSnapshotAction } from 'modules/forms';
import { setLayoutAction } from 'modules/layouts';
import { isNKTenant, transformBlocks } from 'utils';
import { BuilderFormTypeNames, Routes } from 'constants/index';

interface Values {
  id: number | null;
  name: string;
  formType: Builder.FormTypeNames;
  assetCategoryCode: Type.AssetCategories | null;
  assetCodes: string[];
  modelIds: number[];
  formSubComponentId: number | null;
  timestampOptions: string[] | null;
  error: string;
}

const useConfiguredFormik = ({
  formsModalId,
  isEditMode,
  toggleModal,
}: {
  formsModalId: number | null;
  isEditMode: boolean;
  toggleModal: () => void;
}) => {
  const dispatch: any = useDispatch();
  const builderData: Builder.Data = useSelector(builderDataSelector);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectValue, setLocationState } = isEditMode ? useBuilderState() : ({} as any);
  const categories = useSelector(categoriesSelector);
  const subComponents = useSelector(subComponentsSelector);

  const formik = useFormik<Values>({
    initialValues: {
      id: isEditMode ? selectValue.id : null,
      name: isEditMode ? selectValue.name : '',
      formType: isEditMode ? selectValue.formtype.name : BuilderFormTypeNames.LogMaintenance,
      assetCategoryCode: isEditMode ? selectValue.assetCategories?.code || null : null,
      assetCodes: isEditMode ? selectValue.assetCodes || [] : [],
      modelIds: isEditMode ? selectValue.modelIds || [] : [],
      formSubComponentId: isEditMode ? selectValue.formSubcomponents?.id || null : null,
      timestampOptions: isEditMode ? selectValue.timestampOptions || null : null,
      error: '',
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      assetCategoryCode: Yup.string()
        .nullable()
        .when('formType', (formType: Builder.FormTypeNames, schema: any) =>
          schema.test({
            test: (assetCategoryCode: number) => {
              return !!assetCategoryCode || [BuilderFormTypeNames.TaskRelatedForm].includes(formType);
            },
            message: "Field can't be empty!",
          })
        ),
      formSubComponentId: Yup.number()
        .nullable()
        .when('formType', (formType: Builder.FormTypeNames, schema: any) =>
          schema.test({
            test: (formSubComponentId: number) => {
              return !!formSubComponentId || formType !== BuilderFormTypeNames.Repair || !isNKTenant;
            },
            message: "Field can't be empty!",
          })
        ),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const formtype = builderData.formTypes.find((i: Type.FormType) => i.name === values.formType);

      // These form types can use Asset category only once.
      const shouldValidateCount = [BuilderFormTypeNames.Inspection, BuilderFormTypeNames.FinalCheck].includes(
        values.formType as Builder.FormTypeNames
      );

      if (shouldValidateCount && !isEditMode) {
        const action = await dispatch(
          fetchFormCountByTypeAndAssetCategoryCodeAction({
            formtypeId: formtype?.id as number,
            assetCategoryCode: values.assetCategoryCode as Type.AssetCategories,
          })
        );
        if (action.payload.count) {
          setSubmitting(false);
          setErrors({ error: 'Form count limit reached' });
          return;
        }
      }

      const assetCategory = categories?.find(i => i.code === values.assetCategoryCode) || null;
      const formSubcomponent = subComponents?.find(i => i.id === values.formSubComponentId);

      const selectValue = {
        id: values.id,
        name: values.name,
        formtype,
        assetCategories: assetCategory,
        assetCodes: values.assetCodes,
        modelIds: values.modelIds,
        formSubcomponents: formSubcomponent,
        timestampOptions: values.timestampOptions,
      };

      if (isEditMode) {
        setLocationState({ selectValue: { ...selectValue, name: values.name } });
        toggleModal();
      } else if (formsModalId) {
        dispatch(fetchFormSnapshotAction(formsModalId)).then(({ payload }: any) => {
          dispatch(
            push(Routes.FormBuilder, {
              isNew: false,
              isDuplicate: true,
              selectValue,
              blocks: transformBlocks(payload.lastSnapshot.blocks),
              snapshots: payload.snapshots,
              fieldTypes: builderData.fieldTypes,
              formTypes: builderData.formTypes,
            } as Builder.LocationState)
          );
          dispatch(setLayoutAction({ formsModalId: null }));
        });
      } else {
        dispatch(
          push(Routes.FormBuilder, {
            isNew: true,
            isDuplicate: false,
            selectValue,
            blocks: [],
            snapshots: [],
            fieldTypes: builderData.fieldTypes,
            formTypes: builderData.formTypes,
          } as Builder.LocationState)
        );
      }
    },
  });

  return formik;
};

export default useConfiguredFormik;
