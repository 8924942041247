import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { useLocale } from 'hooks';
import {
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchSimulationOptionsAction,
  fetchInvestmentScenariosOptionsAction,
  applyInvestmentScenariosOptionsAction,
} from 'modules/options';
import {
  portfolioOptionSelector,
  scenarioSelectedOptionSelector,
  simulationOptionSelector,
  investmentScenariosOptionSelector,
  scenarioOptionsSelector,
  simulationOptionsSelector,
  isPortfolioLoadflowEnabledSelector,
  investmentScenariosOptionsSelector,
  isSelectSimulationEnabledSelector,
} from 'modules/options/selectors';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { setAppErrorAction } from 'modules/app';
import { appErrorSelector } from 'modules/app/selectors';
import { Button } from 'components/_common';
import SelectPortfolio from './SelectPortfolio';
import SelectScenario from './SelectScenario';
import SelectSimulation from './SelectSimulation';
import SelectInvestmentScenario from './SelectInvestmentScenario';
import { AppErrorsTypes, isProduction } from 'constants/index';
import { IconSliders } from '@utiligize/shared/resources';

const PopoverAppSettings: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const appError = useSelector(appErrorSelector);

  const scenarioOptions = useSelector(scenarioOptionsSelector);
  const simulationOptions = useSelector(simulationOptionsSelector);
  const investmentScenariosOptions = useSelector(investmentScenariosOptionsSelector);

  const portfolioOption = useSelector(portfolioOptionSelector);
  const scenarioOption = useSelector(scenarioSelectedOptionSelector);
  const simulationOption = useSelector(simulationOptionSelector);
  const isSelectSimulationEnabled = useSelector(isSelectSimulationEnabledSelector);
  const investmentScenariosOption = useSelector(investmentScenariosOptionSelector);

  const portfolioId = useSelector(portfolioIdSelector);
  const isPortfolioLoadflowEnabled = useSelector(isPortfolioLoadflowEnabledSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  useEffect(() => {
    dispatch(fetchPortfolioOptionsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!portfolioId) return;
    if (scenarioOptions) {
      if (!appError && !scenarioOptions.length) {
        dispatch(setAppErrorAction(AppErrorsTypes.NO_SCENARIOS));
      }
      if (appError === AppErrorsTypes.NO_SCENARIOS && scenarioOptions.length) {
        dispatch(setAppErrorAction(null));
      }
      return;
    }
    dispatch(fetchScenarioOptionsAction());
  }, [portfolioId, scenarioOptions, dispatch, appError]);

  useEffect(() => {
    if (!portfolioId || !scenarioId) return;
    if (simulationOptions) {
      if (!appError && !simulationOptions.length) {
        dispatch(setAppErrorAction(AppErrorsTypes.NO_SIMULATIONS));
      }
      if (appError === AppErrorsTypes.NO_SIMULATIONS && simulationOptions.length) {
        dispatch(setAppErrorAction(null));
      }
      return;
    }
    dispatch(fetchSimulationOptionsAction(portfolioId, scenarioId));
  }, [portfolioId, isPortfolioLoadflowEnabled, scenarioId, simulationOptions, dispatch, appError]);

  useEffect(() => {
    if (!portfolioId || !scenarioId) return;
    if (investmentScenariosOptions) {
      dispatch(applyInvestmentScenariosOptionsAction(investmentScenariosOptions));
    } else {
      dispatch(fetchInvestmentScenariosOptionsAction());
    }
    // investmentScenariosOptions excluded to avoid double apply events: fetch + apply -> apply
  }, [portfolioId, scenarioId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const value = Number(simulationOptions?.[0]?.value);
    if (simulationId || !value) return;
    dispatch(setLayoutAction({ simulationId: value }));
  }, [simulationOptions, simulationId, dispatch]);

  return (
    <>
      <StyledInfo>
        <div>
          {getIntl('Portfolio')}: <b>{portfolioOption?.label}</b>
        </div>
        <div>
          {getIntl('Scenario')}: <b>{scenarioOption?.label || '-'}</b>
        </div>
        <div>
          {getIntl('Simulation')}:{' '}
          <b>{(isSelectSimulationEnabled ? simulationOption?.label : simulationOptions?.[0]?.label) || '-'}</b>
        </div>
        {!isProduction && (
          <div>
            {getIntl('Investment scenario')}: <b>{investmentScenariosOption?.label}</b>
          </div>
        )}
      </StyledInfo>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <StyledPopover id="popover-app-settings">
            <Popover.Content>
              <Form.Group data-marker="select_portfolio">
                <SelectPortfolio />
              </Form.Group>
              <Form.Group data-marker="select_scenario">
                <SelectScenario />
              </Form.Group>
              <Form.Group>
                <SelectSimulation />
              </Form.Group>
              {!isProduction && (
                <Form.Group>
                  <SelectInvestmentScenario />
                </Form.Group>
              )}
            </Popover.Content>
          </StyledPopover>
        }
      >
        <Button
          marginLeft
          variant="primary"
          icon={<IconSliders />}
          aria-label="App settings"
          data-marker="header__app_settings"
        />
      </OverlayTrigger>
    </>
  );
};

const StyledPopover = styled(Popover)`
  width: 325px;
  max-width: 325px;
  z-index: 10001;
`;

const StyledInfo = styled.div`
  font-family: Arial;
  font-size: 10px;
  line-height: 0.9;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default PopoverAppSettings;
