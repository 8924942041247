import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import { RouteComponentProps, matchPath } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchStatisticsActions, resetModule } from 'modules/statistics';
import {
  maintenanceAverageTimeSpentByAssetCategorySelector,
  paginationSelectorFactory,
} from 'modules/layouts/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import {
  Tabs,
  Tab,
  DataTableTemplate,
  DataTableHeaderFilters,
  BackToTableButton,
  ContentContainer,
  ContentCard,
} from 'components/_common';
import ChartAverageTimeSpentByTransformers from './ChartAverageTimeSpentByTransformers';
import ChartAverageTimeSpentByCabinets from './ChartAverageTimeSpentByCabinets';
import ChartTimeByActionType from './ChartTimeByActionType';
import ChartConditionAssessment from './ChartConditionAssessment';
import SelectManufacturerComparisonAssetCategories from './SelectManufacturerComparisonAssetCategories';
import SelectChartTimeByActionTypeAssetCategories from './SelectChartTimeByActionTypeAssetCategories';
import ViewStatisticsActions from './ViewStatisticsActions';
import TableStatisticsUsers from './TableStatisticsUsers';
import ViewStatisticsUserDetails from './ViewStatisticsUserDetails';
import { isNKTenant } from 'utils';
import {
  Routes,
  PaginationType,
  DataTableFilterKeys,
  MaintenanceAverageTimeSpentByAssetCategories,
  isProduction,
} from 'constants/index';

const ViewStatistics: React.FC<RouteComponentProps> = ({ location }) => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const maintenanceAverageTimeSpentByAssetCategory = useSelector(maintenanceAverageTimeSpentByAssetCategorySelector);

  const type = PaginationType.MAINTENANCE_STATISTICS_USERS;
  const id = matchPath<{ id: string }>(location.pathname, { path: Routes.MaintenanceStatisticsUsers, exact: true })
    ?.params?.id;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const isActionsTabActive = useSelector(isRouteActiveSelectorFactory(Routes.MaintenanceStatistics));
  const isUsersTabActive = useSelector(isRouteActiveSelectorFactory(Routes.MaintenanceStatisticsUsers));
  const isManufacturerComparisonTabActive = useSelector(
    isRouteActiveSelectorFactory(Routes.MaintenanceStatisticsManufacturerComparison)
  );
  const isAssetCategoriesTabActive = useSelector(
    isRouteActiveSelectorFactory(Routes.MaintenanceStatisticsAssetCategories)
  );

  useEffect(() => {
    if (!id) dispatch(fetchStatisticsActions());
    return () => dispatch(resetModule());
  }, [dispatch, filters, id]);

  const renderContent = () => {
    if (id) return <ViewStatisticsUserDetails id={id} filters={filters!} />;

    return (
      <Tabs unmountOnExit mountOnEnter>
        <Tab id="tab_maintenance_statistics_actions" eventKey={Routes.MaintenanceStatistics} title={getIntl('Actions')}>
          <ViewStatisticsActions />
        </Tab>
        <Tab
          id="tab_maintenance_statistics_users"
          eventKey={generatePath(Routes.MaintenanceStatisticsUsers)}
          title={getIntl('Users')}
        >
          <TableStatisticsUsers />
        </Tab>
        <Tab
          id="tab_maintenance_statistics_manufacturer_comparison"
          eventKey={Routes.MaintenanceStatisticsManufacturerComparison}
          title={getIntl('Manufacturer comparison')}
        >
          <ContentContainer>
            <ContentCard>
              {maintenanceAverageTimeSpentByAssetCategory ===
              MaintenanceAverageTimeSpentByAssetCategories.Transformers ? (
                <ChartAverageTimeSpentByTransformers />
              ) : (
                <ChartAverageTimeSpentByCabinets />
              )}
            </ContentCard>
          </ContentContainer>
        </Tab>
        <Tab
          id="tab_maintenance_statistics_asset_categories"
          eventKey={Routes.MaintenanceStatisticsAssetCategories}
          title={getIntl('Asset categories')}
        >
          <ContentContainer>
            <ContentCard>
              <ChartTimeByActionType />
            </ContentCard>
          </ContentContainer>
        </Tab>
        {!isProduction && isNKTenant && (
          <Tab
            id="tab_maintenance_statistics_condition_assessment"
            eventKey={Routes.MaintenanceStatisticsConditionAssessment}
            title={getIntl('Condition assessment')}
          >
            <ContentContainer>
              <ContentCard>
                <ChartConditionAssessment />
              </ContentCard>
            </ContentContainer>
          </Tab>
        )}
      </Tabs>
    );
  };

  return (
    <DataTableTemplate paddingBottom={Boolean(id)}>
      <Row>
        {Boolean(id) && (
          <Col xs="auto">
            <Link to={generatePath(Routes.MaintenanceStatisticsUsers)}>
              <BackToTableButton />
            </Link>
          </Col>
        )}
        {(isActionsTabActive || isUsersTabActive) && (
          <DataTableHeaderFilters
            paginationType={type}
            showKeys={[DataTableFilterKeys.START_TIME, DataTableFilterKeys.END_TIME]}
          />
        )}
        {isManufacturerComparisonTabActive && (
          <Col xs={3}>
            <SelectManufacturerComparisonAssetCategories />
          </Col>
        )}
        {isAssetCategoriesTabActive && (
          <Col xs={3}>
            <SelectChartTimeByActionTypeAssetCategories />
          </Col>
        )}
      </Row>
      {renderContent()}
    </DataTableTemplate>
  );
};

export default ViewStatistics;
