import { useLocale } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { fetchSimulationDetailsAction } from 'modules/setup';
import TableScenarioCalculation from './TableScenarioCalculation';
import { ContentContainer } from 'components/_common';

const ViewCalculationSchedule: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchSimulationDetailsAction()).then(action => setData(action.payload));
  }, [portfolioId, dispatch]);

  return (
    <>
      <TableScenarioCalculation />
      {data && (
        <ContentContainer className="border-top-0">
          <table className="table table-bordered bg-white">
            <tr>
              <th>{getIntl('Calculation type')}</th>
              {Object.keys(data).map(key => (
                <td>{getIntl(key)}</td>
              ))}
            </tr>
            <tr>
              <th>{getIntl('Timestamp')}</th>
              {Object.keys(data).map(key => (
                <td>{dateFormat(data[key], 'lll')}</td>
              ))}
            </tr>
          </table>
        </ContentContainer>
      )}
    </>
  );
};

export default ViewCalculationSchedule;
