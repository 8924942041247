import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { fetchMaintenancePlansOptionsAction } from 'modules/options';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { maintenancePlansOptionsSelector } from 'modules/options/selectors';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectMaintenancePlans: React.FC<Props> = ({ labelKey = 'Plan name', value, isDisabled, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector(maintenancePlansOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  useEffect(() => {
    if (!portfolioId) return;
    setIsLoading(true);
    dispatch(fetchMaintenancePlansOptionsAction()).finally(() => setIsLoading(false));
  }, [portfolioId, dispatch]);

  const { selectValue, selectOptions } = useMemo(() => {
    return (options || []).reduce(
      (acc: any, option: Type.SelectOption<number>) => {
        if (option.value === value) acc.selectValue = option;
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValue: null, selectOptions: [] }
    );
  }, [options, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      options={selectOptions}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      isClearable
    />
  );
};

export default SelectMaintenancePlans;
