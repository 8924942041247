import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: NewLoad.MapConnection['leading_lagging'];
}

const SelectCapacitiveInductive: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();

  const options = useMemo(
    () => [
      { value: 'leading', label: getIntl('Leading') },
      { value: 'lagging', label: getIntl('Lagging') },
    ],
    [getIntl]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey="Capacitive / Inductive"
      value={value ? options.find(o => o.value === value) || null : null}
      options={options}
    />
  );
};

export default SelectCapacitiveInductive;
